import { Button, Spin, Typography } from 'antd';
import { ArrowLeftIcon } from 'assets';
import { joinChatSession, leaveChatSession, useCompleteChatSessionMutation } from 'services';
import { useEffect } from 'react';
import { useOmniChatContext } from 'contexts';
import OmniChatInput from './OmniChatInput';
import { message } from 'components/common';
import OmniChatMessageBox from './OmniChatMessageBox';
import { omniChatMessages } from 'messages';
import { setCurrentChatSession, setGoBack } from 'store/features/omni-chat/omni-chat.slice';
import { useDispatch } from 'react-redux';
import { setIsCallSelected } from 'store/features/customer-support-session/customer-support-session.slice';

type OmniChatSessionProps = {
  chatId: number;
  chatSessionId: number;
  onClose?: () => void;
  unreadCount?: number;
};

const OmniChatSession: React.FC<OmniChatSessionProps> = ({ chatId, chatSessionId, onClose, unreadCount }) => {
  const { chatSession } = useOmniChatContext();
  const dispatch = useDispatch();
  const [completeChatSession, { isLoading: isLoadingComplete }] = useCompleteChatSessionMutation();

  useEffect(() => {
    if (chatSessionId) {
      // Chỉ lưu chatSessionId, không có callId
      localStorage.setItem(
        'omnichannel_chat_session',
        JSON.stringify({
          chatSessionId,
          chatId
        })
      );

      dispatch(setIsCallSelected(false));
      joinChatSession({ chatSessionId });
    }
    return () => {
      leaveChatSession({ chatSessionId });
    };
  }, [chatSessionId, chatId, dispatch]);

  const goBack = () => {
    dispatch(setCurrentChatSession(undefined));
    dispatch(setGoBack(true));
    //dispatch(setIsCallSelected(true))
    onClose?.();
  };

  const handleCompleteChatSession = () => {
    completeChatSession({
      chatSessionId
    })
      .unwrap()
      .then((rs) => {
        try {
          // Lọc dựa trên chatSessionId
          const storedSessions = localStorage.getItem('customerSupportSession');
          if (storedSessions) {
            const allSessions = JSON.parse(storedSessions);

            // Lọc bỏ phiên chat hiện tại
            interface CustomerSupportSession {
              chatSessionId: number;
              // Other potential properties
            }

            const filteredSessions: CustomerSupportSession[] = allSessions.filter(
              (session: CustomerSupportSession) => session.chatSessionId !== chatSessionId
            );

            if (filteredSessions.length > 0) {
              localStorage.setItem('customerSupportSession', JSON.stringify(filteredSessions));
            } else {
              localStorage.removeItem('customerSupportSession');
            }
          }

          localStorage.removeItem('omnichannel_chat_session');
        } catch (error) {
          console.error('Error removing chat session from storage:', error);
        }

        goBack();
        message.systemSuccess(rs.message);
      })
      .catch((error) => {
        console.error('Error completing chat session:', error);
        message.systemError('Failed to complete chat session');
      });
  };

  return (
    <div className='flex h-full flex-col'>
      <div className='relative flex h-14 items-center border-b bg-white py-3 pl-3 pr-5'>
        <Button onClick={goBack} type='text' className='h-8 w-8' shape='circle' icon={<ArrowLeftIcon />}></Button>

        {unreadCount && unreadCount > 0 ? (
          <div className='flex h-fit min-h-[18px] min-w-[18px] items-center justify-center rounded-full bg-colorBgError px-[6px]'>
            <Typography.Text className='text-xs font-semibold text-white'>{unreadCount}</Typography.Text>
          </div>
        ) : null}

        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
          <div className='relative m-auto flex max-w-[162px] flex-col items-center justify-center'>
            <Typography.Paragraph
              ellipsis={{
                rows: 1,
                tooltip: true
              }}
              className='mb-1 max-w-full text-sm font-semibold'
            >
              {chatSession.chat?.customerName}
            </Typography.Paragraph>

            <Typography.Paragraph
              ellipsis={{
                rows: 1,
                tooltip: true
              }}
              type='secondary'
              className='mb-0 max-w-full text-sm'
            >
              {chatSession.chat?.pageName}
            </Typography.Paragraph>
          </div>
        </div>
        <div className='ml-auto'>
          <Button
            onClick={handleCompleteChatSession}
            loading={isLoadingComplete}
            size='small'
            className='h-8 w-[88px] rounded-base text-sm'
            danger
          >
            {omniChatMessages.complete}
          </Button>
        </div>
      </div>

      {chatSession ? (
        <div className='flex h-[calc(100%-52px)] flex-col'>
          <OmniChatMessageBox />
          <OmniChatInput />
        </div>
      ) : (
        <Spin spinning />
      )}
    </div>
  );
};

export default OmniChatSession;
