import { Table, TableProps, Typography } from 'antd';
import { customerLookupDetailedMessages } from '../../messages/customer-lookup-detailed.message';
import { useEffect, useState } from 'react';
import {
  GetCustomerThirdParty,
  GetCustomerThirdPartyHistory
} from '../../hooks/customer-lookup/useCustomerReferenceInformation';
import { ThirdPartyPaymentHistoryDetail, ThirdPartyPaymentInforDetail } from '../../types/dto/customer-lookup';

type CollectionPaymentReferenceInformationScheduleProps = {
  customerCode: string;
};

const CollectionPaymentReferenceInformationSchedule: React.FC<CollectionPaymentReferenceInformationScheduleProps> = ({
  customerCode
}) => {
  const columns1: TableProps<ThirdPartyPaymentInforDetail>['columns'] = [
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.month}/{customerLookupDetailedMessages.year}-
          {customerLookupDetailedMessages.cycleNumber}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.cycleNumber,
      key: customerLookupDetailedMessages.cycleNumber,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] tracking-normal text-[#141414] opacity-100'>
          {data.year}-{data.period}
        </Typography.Text>
      )
    },
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.billCode}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.billCode,
      key: customerLookupDetailedMessages.billCode,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] font-semibold tracking-normal text-[#141414] opacity-100'>
          {data.invoiceId}
        </Typography.Text>
      )
    },
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.billType}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.billType,
      key: customerLookupDetailedMessages.billType,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] tracking-normal text-[#141414] opacity-100'>
          {data.invoiceName}
        </Typography.Text>
      )
    },
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.amount}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.amount,
      key: customerLookupDetailedMessages.amount,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] font-semibold tracking-normal text-[#141414] opacity-100'>
          {data.amount.toLocaleString('vi-VN')}
        </Typography.Text>
      )
    }
  ];
  const columns2: TableProps<ThirdPartyPaymentHistoryDetail>['columns'] = [
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.month}/{customerLookupDetailedMessages.year}-
          {customerLookupDetailedMessages.cycleNumber}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.cycleNumber,
      key: customerLookupDetailedMessages.cycleNumber,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] tracking-normal text-[#141414] opacity-100'>
          {data.year} - {data.period}
        </Typography.Text>
      )
    },
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.billType}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.billType,
      key: customerLookupDetailedMessages.billType,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] tracking-normal text-[#141414] opacity-100'>
          {data.invoiceTypeName}
        </Typography.Text>
      )
    },
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.collectionPoint}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.collectionPoint,
      key: customerLookupDetailedMessages.collectionPoint,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] font-semibold tracking-normal text-[#141414] opacity-100'>
          {data.paymentPoint}
        </Typography.Text>
      )
    },
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.amount}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.amount,
      key: customerLookupDetailedMessages.amount,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] font-semibold tracking-normal text-[#141414] opacity-100'>
          {data.totalAmount.toLocaleString('vn-VN')}
        </Typography.Text>
      )
    },
    {
      title: (
        <Typography.Text className='text-left text-[12px]/[20px] font-semibold uppercase tracking-normal text-[#868D96] opacity-100'>
          {customerLookupDetailedMessages.paymentCollectionDate}
        </Typography.Text>
      ),
      dataIndex: customerLookupDetailedMessages.paymentCollectionDate,
      key: customerLookupDetailedMessages.paymentCollectionDate,
      render: (_, data) => (
        <Typography.Text className='text-left text-[14px]/[18px] tracking-normal text-[#141414] opacity-100'>
          {data.paymentDateTime.split('T')[0]}
        </Typography.Text>
      )
    }
  ];

  const { thirdPartyInfo, isLoading: thirdPartyInfoLoading } = GetCustomerThirdParty(String(customerCode));
  const { thirdPartyHistory, isLoading: thirdPartyHistoryLoading } = GetCustomerThirdPartyHistory(String(customerCode));

  const [information, setInformation] = useState<ThirdPartyPaymentInforDetail[]>([]);
  const [history, setHistory] = useState<ThirdPartyPaymentHistoryDetail[]>([]);
  useEffect(() => {
    if (thirdPartyInfo.length || thirdPartyHistory.length) {
      setInformation(
        thirdPartyInfo.map((item) => ({
          ...item,
          amount: Number(item.amount)
        }))
      );
      setHistory(
        thirdPartyHistory.map((item) => ({
          key: item.customerCode,
          ...item,
          totalAmount: Number(item.totalAmount),
          paymentDateTime: item.paymentDateTime?.split('T')[0]
        }))
      );
    } else {
      setInformation([]);
      setHistory([]);
    }
  }, [thirdPartyInfo, thirdPartyHistory, customerCode]);

  return (
    <div className='mt-3 w-full bg-[#FFFFFF]'>
      <Typography.Text className='text-left text-[12px]/[16px] font-semibold uppercase tracking-normal text-[#6C737E] opacity-100'>
        {customerLookupDetailedMessages.electricityBillingInformationOnCollectionSystem}
      </Typography.Text>
      <Table columns={columns1} dataSource={information} className='mt-2' />
      <Typography.Text className='text-left text-[12px]/[16px] font-semibold uppercase tracking-normal text-[#6C737E] opacity-100'>
        {customerLookupDetailedMessages.customerPaymentInformationOnCollectionChanel}
      </Typography.Text>
      <Table columns={columns2} dataSource={history} className='mt-2' />
    </div>
  );
};

export default CollectionPaymentReferenceInformationSchedule;
