import { Modal, Typography } from 'antd';
import { NoticeCircleIcon } from 'assets';
import { customerLookupMessages } from 'messages/customer-lookup.message';


type FailedLoginWorldPhoneWarningModalProps = {
    open: boolean;
    onCancel: () => void;
};
const FailedLoginWorldPhoneWarningModal: React.FC<FailedLoginWorldPhoneWarningModalProps> = ({ open, onCancel }) => {
    return (
        <Modal
            title={
                <div className='flex items-center text-[#D1131D]'>
                    <NoticeCircleIcon className="mr-2 h-5 w-5 " />
                    Cảnh báo
                </div>
            }
            open={open}
            onCancel={onCancel}
            footer={null}
        >
            <div className='flex'>

                <Typography.Paragraph className='text-[#D1131D]'>
                    {customerLookupMessages.openWorldPhoneWarning}
                </Typography.Paragraph>
            </div>
        </Modal>
    );
};
export default FailedLoginWorldPhoneWarningModal;
