import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ResponsePagingDto, TicketDetail
} from 'types';
import axiosBaseQuery from 'utils/base-api';
import { FollowingRequestDto } from '../../types/dto/following-request';

export const followingRequestApi = createApi({
  reducerPath: 'followingRequestApi',
  tagTypes: [ ],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getFollowingTicketList: builder.query<
      ResponsePagingDto<TicketDetail>, FollowingRequestDto
    >({
      query: (requestData) => {
        const params = new URLSearchParams();

        Object.entries(requestData).forEach(([key, value]) => {
          if (value !== undefined && value !== null && value !== "" && value !== 0 ) {
            if (Array.isArray(value)) {
              value.forEach((v) => params.append(key, v.toString()));
            } else {
              params.append(key, value.toString());
            }
          }
        });

        return ({
          url: `/tickets/dtv/${requestData.endPoint}_tickets`,
          method: 'get',
          params
        })
      },
    }),

  })
});

export const {
  useGetFollowingTicketListQuery
} = followingRequestApi;
