import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatSessionDto, OmniChannelMessageDto } from 'types';

interface OmniChatState {
  currentChatSession?: ChatSessionDto;
  isGoBack: boolean;
  replyOmniMessage?: OmniChannelMessageDto;
}

const initialState: OmniChatState = {
  currentChatSession: undefined,
  isGoBack: false
};

const OmniChatSlice = createSlice({
  name: 'internalChat',
  initialState,
  reducers: {
    setCurrentChatSession: (state, actions: PayloadAction<ChatSessionDto | undefined>) => {
      state.currentChatSession = actions.payload;
    },
    setGoBack: (state, actions: PayloadAction<boolean>) => {
      state.isGoBack = actions.payload;
    },
    setReplyOmniMessage: (state, actions: PayloadAction<OmniChannelMessageDto | undefined>) => {
      state.replyOmniMessage = actions.payload;
    }
  },
  selectors: {
    selectCurrentChatSession: (state) => state.currentChatSession
  }
});

export const { setGoBack, setCurrentChatSession, setReplyOmniMessage } = OmniChatSlice.actions;
export const { selectCurrentChatSession } = OmniChatSlice.selectors;

export default OmniChatSlice.reducer;
