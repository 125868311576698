import { useGetWaitingCallsQuery } from "services";

export function useGetWatingCalls() {
    const { data, error, isLoading, refetch } = useGetWaitingCallsQuery();

    return {
        waitingCalls: data?.data?.waitingCalls || [],
        count: data?.data?.count || 0,
        error,
        isLoading,
        refetch
    };
}
