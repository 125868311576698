import { Button, Typography, ModalProps, Form } from 'antd';
import { permissionsMessages, messages } from 'messages';
import UpdatePermissionScopeForm, { PermissionScopeFormRefProps } from './UpdatePermissionScopeForm';
import { useState, useRef } from 'react';
import { useGetPermissionDetailQuery } from 'services';
import { Modal } from 'components/common';
import { OrganizationUnitHierarchyCompactDto } from 'types';
import { useOrganizationUnitsHierarchyOptions } from 'hooks';

type UpdatePermissionScopeModalProps = ModalProps & {
  permissionId?: number;
  onClose?: () => void;
};

const UpdatePermissionScopeModal = ({ permissionId, onClose, ...props }: UpdatePermissionScopeModalProps) => {
  const [loading, setLoading] = useState(false);
  const permissionFormRef = useRef<PermissionScopeFormRefProps>(null);

  const { data: permission } = useGetPermissionDetailQuery(permissionId!, {
    skip: !permissionId,
    refetchOnMountOrArgChange: true
  });

  const { organizationsUnitsHierarchy } = useOrganizationUnitsHierarchyOptions({});

  const countData = (items: OrganizationUnitHierarchyCompactDto[]): number =>
    items.reduce((count, item) => count + 1 + (item.children ? countData(item.children) : 0), 0);

  const organizationUnitIds = Form.useWatch('organizationUnitIds', permissionFormRef.current?.form) ?? [];

  const isSelectAll = countData(organizationsUnitsHierarchy) === organizationUnitIds.length;

  const getAllOrganizationUnitIds = (units: OrganizationUnitHierarchyCompactDto[]): number[] => {
    return units.flatMap(({ organizationUnitId, children }) => [
      organizationUnitId,
      ...(children ? getAllOrganizationUnitIds(children) : [])
    ]);
  };

  const handleSelectAll = () => {
    if (!isSelectAll) {
      const selectAll = getAllOrganizationUnitIds(organizationsUnitsHierarchy);
      permissionFormRef.current?.form.setFieldValue('organizationUnitIds', selectAll);
    } else {
      permissionFormRef.current?.form.setFieldValue('organizationUnitIds', []);
    }
  };

  return (
    <Modal.Sticker
      {...props}
      onCancel={() => onClose?.()}
      maskClosable={false}
      centered
      width={568}
      destroyOnClose
      footer={
        <div className='flex items-center justify-between'>
          <Button
            type='link'
            className='mr-auto font-normal text-colorPrimary no-underline'
            onClick={(e) => {
              e.stopPropagation();
              handleSelectAll();
            }}
          >
            {isSelectAll ? messages.deleteButtonText : messages.selectAllButtonText}
          </Button>

          <Button
            key='confirm'
            loading={loading}
            onClick={() => {
              if (permissionFormRef.current) {
                permissionFormRef.current.form.submit();
              }
            }}
            size='large'
            type='primary'
          >
            {messages.confirmButtonText}
          </Button>
        </div>
      }
      title={permissionsMessages.updateScopeTitle}
    >
      {/* <Typography.Title className='mb-1 mt-10 text-2.5xl' level={4}>
        {permissionsMessages.updateScopeTitle}
      </Typography.Title> */}
      <Typography.Paragraph className='mb-8'>
        {permissionsMessages.page}: {permission?.data.name}
      </Typography.Paragraph>
      <UpdatePermissionScopeForm
        onCreateSuccess={() => {
          onClose?.();
        }}
        ref={permissionFormRef}
        onChangeLoading={setLoading}
        permissionId={permissionId}
      />
    </Modal.Sticker>
  );
};

export default UpdatePermissionScopeModal;
