import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { calculateTimeDifference, convertTimeAgo } from 'utils';

const useCountTime = (
  time?: string | Date,
  debounce: number = 60000,
  format?: (time: string | Date | undefined) => string
) => {
  const [timeCount, setTimeCount] = useState('');

  useEffect(() => {
    if (!time) return;
    const updateCountTime = () => {
      setTimeCount(format ? format(time) : calculateTimeDifference(time, dayjs().toDate()));
    };

    updateCountTime();
    const interval: ReturnType<typeof setInterval> = setInterval(updateCountTime, debounce);
    return () => clearInterval(interval);
  }, [time]);

  return timeCount;
};

export { useCountTime };
