import { createApi } from '@reduxjs/toolkit/query/react';
import {

    AgentStatusChangeDto,
    ResponseDto,

} from 'types';
import { AgentStatusChangeResponseDto } from 'types/dto/agent-status-change/get-agent-status-change-response.dto';
import axiosBaseQuery from 'utils/base-api';

export const agentStatusApi = createApi({
    reducerPath: 'agentStatusApi',
    tagTypes: ['agent_status_change', 'get_agent_status_change'],
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        createAgentStatusChange: builder.mutation<ResponseDto<AgentStatusChangeDto>, AgentStatusChangeDto>({
            query: (newSchedule) => ({
                url: '/agent_status_change/change_status',
                method: 'post',
                data: newSchedule
            }),
            invalidatesTags: ['agent_status_change']
        }),
        getAgentStatusChange: builder.query<ResponseDto<AgentStatusChangeResponseDto[]>, void>({
            query: (agentStatusChange) => ({
                url: '/agent_status_change/get_all_agent_status_change',
                method: 'get',
                data: agentStatusChange
            }),
            providesTags: ['get_agent_status_change']
        }),
    })
});

export const {
    useCreateAgentStatusChangeMutation,
    useGetAgentStatusChangeQuery
} = agentStatusApi;
