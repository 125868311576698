import AgentMapModalInfoSeat, { AgentMapModalInfoSeatRef } from 'components/agent-map/AgentMaModalInfoSeat';
import { AgentMapContextMenuOtherObjectRef } from 'components/agent-map/AgentMapContextMenuOtherObject';
import { AgentMapContextMenuSeatRef } from 'components/agent-map/AgentMapContextMenuSeat';
import AgentMapModalAddSeat, { AgentMapModalAddSeatRef } from 'components/agent-map/AgentMapModalAddSeat';
import React, { createContext, useEffect, useRef } from 'react';
import { FabricAgentMapOtherObject, FabricAgentMapSeat } from 'types/fabric-agent-map';
import { AGENT_MAP_OBJECT_TYPE } from 'utils';
import { getMiddlePosition } from 'utils/agent-map';
import { AgentMapFormType } from 'pages/AgentMap';
import { Form, FormInstance } from 'antd';
import {
  useAgentMapCanvas,
  useAgentMapDetail,
  useAgentMapDrag,
  useAgentMapObjectCurd,
  useAgentMapSeatCurd,
  useCreateAgentMap,
  useUpdateAgentMap
} from 'hooks';
import { message } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { AgentMapContextMenuRef } from 'components/agent-map/AgentMapContextMenu';

export type AgentMapContextProps = {
  form: FormInstance<AgentMapFormType>;

  parentDiv: React.MutableRefObject<HTMLDivElement | null>;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  contextMenuSeatRef: React.RefObject<AgentMapContextMenuSeatRef>;
  contextMenuObjectRef: React.RefObject<AgentMapContextMenuOtherObjectRef>;
  contextMenuRef: React.RefObject<AgentMapContextMenuRef>;
  scale: number;

  handleAddSeat: (seat?: Partial<FabricAgentMapSeat>) => void;
  handleRemoveSeat: (seatId: string | number) => void;
  handleEditSeat: (seatId: string | number) => void;

  handleRemoveOtherObject: (seatId: string | number) => void;
  handleAddObject: (otherObject: Partial<FabricAgentMapOtherObject>) => void;
  handleDragObjectStart: (objectId: AGENT_MAP_OBJECT_TYPE) => (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragObjectOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDropOject: (parentRef: HTMLDivElement) => (event: React.DragEvent<HTMLDivElement>) => {
    top: number | null;
    left: number | null;
    objectId?: AGENT_MAP_OBJECT_TYPE | null;
  };
  onSubmit: (values: AgentMapFormType) => void;

  isLoading: boolean;
  initCanvas: () => void;
};

export const AgentMapContext = createContext<AgentMapContextProps | undefined>(undefined);

export const AgentMapProvider: React.FC<{
  children?: any;
  agentMapId: number;
}> = ({ children, agentMapId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const firstRender = useRef(true);
  const [form] = Form.useForm<AgentMapFormType>();
  const { agentMap, fabricSeats, fabricOtherObjects } = useAgentMapDetail(agentMapId, form);

  const {
    initCanvas,
    canvas,
    canvasRef,
    contextMenuSeatRef,
    parentDiv,
    contextMenuObjectRef,
    contextMenuRef,
    scale,
    seatsArr,
    objectArr,
    resetAgentMap
  } = useAgentMapCanvas();

  const { createAgentMap, isLoading: isLoadingCreate } = useCreateAgentMap();
  const { updateAgentMap, isLoading: isLoadingUpdate } = useUpdateAgentMap(agentMapId!);

  const infoSeatRef = useRef<AgentMapModalInfoSeatRef>(null);
  const addSeatRef = useRef<AgentMapModalAddSeatRef>(null);

  const { handleAddSeat, handleEditSeat, handleRemoveSeat, handleOpenEditSeatMd } = useAgentMapSeatCurd();
  const { handleAddObject, handleRemoveObject } = useAgentMapObjectCurd();
  const { handleDragObjectStart, handleDragObjectOver, handleDropOject } = useAgentMapDrag();

  const onSubmit = (values: AgentMapFormType) => {
    const { otherObjects, seats } = getObjects();
    if (agentMapId) {
      updateAgentMap(values, seats, otherObjects)
        ?.unwrap()
        .then((rs) => {
          message.success(rs.message);
        })
        .catch(() => {});
      return;
    }
    createAgentMap(values, seats, otherObjects)
      ?.unwrap()
      .then((rs) => {
        message.success(rs.message);
        navigate(`${ROUTE.AGENT_MAPS}/${rs.data.agentMapId}`, {
          state
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (agentMap && canvas.current && firstRender.current) {
      initAgentMap();
      console.log(objectArr.current);
      firstRender.current = false;
    }
  }, [fabricSeats, fabricOtherObjects, agentMap]);

  const initAgentMap = async () => {
    resetAgentMap();
    if (fabricSeats && fabricSeats.length > 0) {
      seatsArr.current = await Promise.all(
        fabricSeats.map((seat) => handleAddSeat({ canvas: canvas.current, seatsArr: seatsArr.current })(seat))
      ).then((results) => results.flat());
    }
    if (fabricOtherObjects && fabricOtherObjects.length > 0) {
      objectArr.current = await Promise.all(
        fabricOtherObjects.map((object) =>
          handleAddObject({ canvas: canvas.current, objectArr: objectArr.current })(object)
        )
      ).then((results) => results.flat());
    }
  };

  const handleOpenModalAddSeat = (seat?: Partial<FabricAgentMapSeat>) => {
    if (addSeatRef.current) {
      addSeatRef.current.setFieldsValue({
        ...seat,
        existingSeats: seatsArr.current
      });
      addSeatRef.current.setOpenNew(true);
    }
  };

  const onRemoveSeat = (seatId: string | number) => {
    if (seatsArr.current)
      seatsArr.current = handleRemoveSeat({ canvas: canvas.current, seatsArr: seatsArr.current })(seatId);
  };

  const onEditSeat = (seatId: string | number) => {
    handleOpenEditSeatMd({ canvas: canvas.current, seatsArr: seatsArr.current, updateSeatMd: infoSeatRef.current })(
      seatId
    );
  };

  const handleSubmitNewSeat = (newSeat?: Partial<FabricAgentMapSeat>) => {
    if (seatsArr.current) {
      const midPos = getMiddlePosition(parentDiv.current, scale);

      seatsArr.current = handleAddSeat({ canvas: canvas.current, seatsArr: seatsArr.current })({
        ...newSeat,
        left: newSeat?.left ?? midPos?.x,
        top: newSeat?.top ?? midPos?.y
      });
    }
  };

  const onAddObject = (otherObject: Partial<FabricAgentMapOtherObject>) => {
    const midPos = getMiddlePosition(parentDiv.current, scale);
    const object = {
      left: midPos?.x ? midPos.x : 0,
      top: midPos?.y ? midPos.y : 0,
      ...otherObject
    };

    handleAddObject({ canvas: canvas.current, objectArr: objectArr.current })(object).then((objects) => {
      objectArr.current = [...objects];
    });
  };
  const onRemoveObject = (objectId: string | number) => {
    objectArr.current = handleRemoveObject({ canvas: canvas.current, objectArr: objectArr.current })(objectId);
  };

  const getObjects = () => {
    return {
      seats: seatsArr.current,
      otherObjects: objectArr.current
    };
  };

  return (
    <AgentMapContext.Provider
      value={{
        form,
        scale,
        parentDiv,
        canvasRef,
        contextMenuObjectRef,
        contextMenuSeatRef,
        contextMenuRef,

        handleAddSeat: handleOpenModalAddSeat,
        handleRemoveSeat: onRemoveSeat,
        handleEditSeat: onEditSeat,

        handleAddObject: onAddObject,
        handleRemoveOtherObject: onRemoveObject,

        handleDragObjectStart,
        handleDragObjectOver,
        handleDropOject,

        onSubmit,
        isLoading: isLoadingCreate || isLoadingUpdate,
        initCanvas
      }}
    >
      {children}

      <AgentMapModalInfoSeat
        ref={infoSeatRef}
        handleSubmit={(values) => {
          if (seatsArr.current)
            seatsArr.current = handleEditSeat({ canvas: canvas.current, seatsArr: seatsArr.current })(values);
        }}
      />
      <AgentMapModalAddSeat ref={addSeatRef} handleSubmit={handleSubmitNewSeat} />
    </AgentMapContext.Provider>
  );
};
export default AgentMapProvider;
