import { Skeleton } from 'antd';
import { InfiniteScroll, InputSearchV2 } from 'components/common';
import { CheckboxOptionItem, RightSideCheckbox, RightSideCheckboxV2 } from 'components/common/checkbox';
import { useOrganizationUnitServiceTypeOptions } from 'hooks';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { FindAllOrganizationUnitServiceTypeOptionsDto, OrganizationUnitServiceTypeOptionDto } from 'types';
import { includesRemoveSpecial, removeVietnameseAccents } from 'utils';

type TreeCheckboxOrganizationUnitServiceTypeProps = {
  parentId?: number;
  value?: number[];
  onChange: (value: number[]) => void;
  apiProps?: Omit<FindAllOrganizationUnitServiceTypeOptionsDto, 'parentId'>;
};

type OrganizationOptionItem = CheckboxOptionItem & {
  code?: string;
  children?: OrganizationOptionItem[];
};

const TreeCheckboxOrganizationUnitServiceTypes = ({
  parentId,
  value,
  onChange,
  apiProps,
  ...props
}: TreeCheckboxOrganizationUnitServiceTypeProps) => {
  const [keyword, setKeyword] = useState('');

  const { serviceTypesOptions, isLoading, hasMore, handleLoadMore, isFetching } = useOrganizationUnitServiceTypeOptions(
    { ...(apiProps || {}), parentId: parentId! },
    {
      skip: !parentId,
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (value && value.length > 0 && !isLoading) {
      const validValue = filterInvalidValue(value);
      if (!isEqual(value, validValue)) {
        onChange?.(validValue);
      }
    }
  }, [value, serviceTypesOptions]);

  const formatData = (data: OrganizationUnitServiceTypeOptionDto[]): OrganizationOptionItem[] => {
    if (data.length === 0) return [];

    const result: OrganizationOptionItem[] = [];

    for (const option of data) {
      if (option.parentCompact) {
        const exists = result.find((res) => res.value === option.parentCompact?.serviceTypeId.toString());
        if (exists) {
          exists.children?.push({
            key: option.serviceTypeId.toString(),
            label: option.name,
            code: option.code,
            value: option.serviceTypeId.toString(),
            disabled: option.isDisabled
          });
        } else {
          result.push({
            key: option.parentCompact.serviceTypeId.toString(),
            label: option.parentCompact.name,
            code: option.parentCompact.code,
            value: option.parentCompact.serviceTypeId.toString(),
            children: [
              {
                key: option.serviceTypeId.toString(),
                label: option.name,
                code: option.code,
                value: option.serviceTypeId.toString(),
                disabled: option.isDisabled
              }
            ]
          });
        }
      } else {
        result.push({
          key: option.serviceTypeId.toString(),
          label: option.name,
          code: option.code,
          value: option.serviceTypeId.toString(),
          disabled: option.isDisabled
        });
      }
    }

    return result;
  };

  const handleChange = (selectedValue: string[]) => {
    const validValue = filterInvalidValue(selectedValue.map((val) => Number(val)));
    onChange?.(validValue);
  };

  const filterInvalidValue = (selectedValue: number[]) => {
    const invalidValues = serviceTypesOptions
      .filter((option) => option.isDisabled)
      .map((option) => option.serviceTypeId);
    return selectedValue.filter((val) => !invalidValues.includes(val));
  };

  const searchTree = (data: OrganizationOptionItem[], keyword = ''): OrganizationOptionItem[] => {
    const result: OrganizationOptionItem[] = [];
    data.forEach((item) => {
      const isValid = includesRemoveSpecial(item.label as string, keyword) || includesRemoveSpecial(item.code, keyword);

      const children = item.children && item.children.length > 0 ? searchTree(item.children, keyword) : [];
      if (isValid || children.length > 0) {
        result.push({
          ...item,
          children: children.length > 0 ? children : undefined
        });
      }
    });
    return result;
  };

  return (
    <div>
      <InputSearchV2 className='mb-3' isDebounce={false} onChange={(value) => setKeyword(value)} />
      <InfiniteScroll
        isLoading={isLoading || isFetching}
        hasMore={hasMore}
        next={handleLoadMore}
        loader={<Skeleton active title={false} paragraph={{ rows: 3 }} />}
      >
        <RightSideCheckboxV2
          options={searchTree(formatData(serviceTypesOptions || []), keyword)}
          onChange={handleChange}
          value={value?.map((item) => item.toString())}
          selectAll
          split
        />
      </InfiniteScroll>
    </div>
  );
};

export default TreeCheckboxOrganizationUnitServiceTypes;
