import { useListQueueNameQuery } from 'services';

export function useOmniQueueNames() {
  const { data, error, isLoading, isFetching } = useListQueueNameQuery();

  return {
    data: data?.data || [],
    isLoading: isFetching || isLoading,
    error
  };
}
