import { Button, Form, Input, Modal } from 'antd';
import { FormItem } from 'components/common';
import { validateMessages } from 'messages';
import { agentMapsMessages } from 'messages/agent-maps.messages';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FabricAgentMapSeat } from 'types/fabric-agent-map';

type SeatType = Partial<FabricAgentMapSeat> & {
  id?: string | number;
  existingSeats?: FabricAgentMapSeat[];
};

type AgentMapModalAddSeatProps = {
  handleSubmit: (value: SeatType) => void;
};

export type AgentMapModalAddSeatRef = {
  setFieldsValue: (value: Partial<SeatType>) => void;
  setOpenNew: (value: boolean) => void;
};

const AgentMapModalAddSeat = forwardRef<AgentMapModalAddSeatRef, AgentMapModalAddSeatProps>(({ handleSubmit }, ref) => {
  useImperativeHandle(ref, () => ({
    setFieldsValue,
    setOpenNew: setOpenNew
  }));
  const [openNew, setOpenNew] = useState(false);

  const [form] = Form.useForm<SeatType>();

  const setFieldsValue = (values: Partial<SeatType>) => {
    form.setFieldsValue(values);
  };

  const onSubmit = (values: SeatType) => {
    const { existingSeats, seatIp, seatName } = values;
    const currentSeats = (existingSeats && existingSeats) ?? [];

    let err = false;

    if (seatIp && currentSeats.map((o) => o.seatIp).includes(seatIp)) {
      form.setFields([
        {
          name: 'seatIp',
          errors: [agentMapsMessages.seatIpDuplicate]
        }
      ]);
      err = true;
    }
    if (seatName && currentSeats.map((o) => o.seatName).includes(seatName)) {
      form.setFields([
        {
          name: 'seatName',
          errors: [agentMapsMessages.seatNameDuplicate]
        }
      ]);
      err = true;
    }

    if (err === true) return;

    handleSubmit(values);
    setOpenNew(false);
  };

  useEffect(() => {
    if (!openNew) {
      form.resetFields();
    }
  }, [openNew]);

  return (
    <Modal
      width={568}
      title={agentMapsMessages.addSeatFormTitle}
      open={openNew}
      onCancel={() => setOpenNew(false)}
      footer={
        <Button
          onClick={() => {
            form.submit();
          }}
          type='primary'
        >
          {agentMapsMessages.save}
        </Button>
      }
      destroyOnClose
    >
      <Form
        labelAlign='right'
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        form={form}
        onFinish={onSubmit}
        labelCol={{
          flex: '180px'
        }}
        validateMessages={validateMessages}
      >
        <Form.Item<SeatType> noStyle name='top' />

        <Form.Item<SeatType> noStyle name='left' />

        <Form.Item<SeatType> noStyle name='existingSeats' />

        <FormItem.FloatLabel<SeatType> rules={[{ required: true }]} name='seatName' label={agentMapsMessages.seatName}>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<SeatType> label={agentMapsMessages.seatIp} name='seatIp' rules={[{ required: true }]}>
          <Input />
        </FormItem.FloatLabel>
      </Form>
    </Modal>
  );
});

export default AgentMapModalAddSeat;
