import { Checkbox, Tooltip, Typography } from 'antd';
import { find } from 'lodash';
import { useState } from 'react';
import FilterOrigin, { FilterPopoverOptionItem } from './Filter';
import FilterSearchBox from './FilterSearchBox';
import { messages } from 'messages';
import { twMerge } from 'tailwind-merge';

type FilterCheckboxSearchProps = React.ComponentProps<typeof FilterOrigin> & {
  options: FilterPopoverOptionItem[];
  onSearch: (value: string) => void;
  loadMore?: () => void;
  loading?: boolean;
  fetchData?: () => void;
  hasMore?: boolean;
  placeholder?: string;
  title: string;
  count?: number;
};
const FilterCheckboxSearch = ({
  value = [],
  options,
  children,
  icon,
  title,
  loadMore,
  onSearch,
  loading = false,
  fetchData,
  hasMore,
  placeholder,
  count,
  ...props
}: FilterCheckboxSearchProps) => {
  const [valueTemp, setValueTemp] = useState(value);
  const content = (
    <Checkbox.Group
      onChange={(val) => {
        setValueTemp(val);
      }}
      value={valueTemp}
      style={{ maxWidth: 250 }}
    >
      <FilterSearchBox
        hasMore={Boolean(hasMore)}
        options={options}
        selectedCount={Array.isArray(valueTemp) ? valueTemp.length : valueTemp ? 1 : 0}
        renderItem={(item) => (
          <Checkbox
            prefixCls='checkbox-filter'
            style={{ width: 'calc(100% - 14px)' }}
            value={item.value}
            disabled={item.disabled}
          >
            <Tooltip trigger={item.disabled ? 'hover' : []} title={messages.optionIsNotActive}>
              <Typography.Paragraph
                className='mb-0 max-w-full text-sm'
                ellipsis={{
                  rows: 1,
                  tooltip: !item.disabled
                }}
              >
                {item.label}
              </Typography.Paragraph>
            </Tooltip>
          </Checkbox>
        )}
        isLoading={loading}
        loadMore={loadMore}
        onSearch={onSearch}
        placeholder={placeholder}
      />
    </Checkbox.Group>
  );

  const classNameValue = 'mb-0 max-w-[50px] xl:max-w-[135px] text-sm';

  const childrenLabel = children ?? (
    <>
      {icon}
      <div>
        {value && value?.length > 0 ? (
          <Typography.Paragraph
            className={twMerge(classNameValue)}
            ellipsis={{
              rows: 1,
              tooltip: true
            }}
          >
            {value.length === count
              ? `${title}: ${messages.all}`
              : value.map((o: any) => find(options, { value: o })?.label).join(', ')}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph
            className={twMerge(classNameValue)}
            ellipsis={{
              rows: 1,
              tooltip: true
            }}
          >
            {`${title}: ${messages.all}`}
          </Typography.Paragraph>
        )}
      </div>
    </>
  );

  return (
    <FilterOrigin
      {...props}
      content={content}
      valueTemp={valueTemp}
      clearFilter={() => {
        setValueTemp([]);
      }}
      value={value}
      setValueTemp={setValueTemp}
      onOpenChange={(open) => {
        if (open && fetchData) fetchData();
        props.onOpenChange?.(open);
      }}
      selectedCount={Array.isArray(valueTemp) ? valueTemp.length : valueTemp ? 1 : 0}
    >
      {childrenLabel}
    </FilterOrigin>
  );
};

export default FilterCheckboxSearch;
