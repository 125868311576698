import { List, Typography, Modal } from 'antd';
import { LeftArrow, MapMarker } from 'assets';

import img from '../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import { IncorrectRecordingLocationRequestDto } from 'types';
import { useIncorrectRecordingLocationList } from '../../hooks';
import IncorrectRecordingLocationTicketDetail from './IncorrectRecordingLocationTicketDetail';

interface TicketsProps {
  status: string;
  requestData: IncorrectRecordingLocationRequestDto;
  refreshData: () => void;
}

const IncorrectRecordingLocationList: React.FC<TicketsProps> = ({ status, requestData, refreshData }) => {
  const { pending, isLoading, refetch } = useIncorrectRecordingLocationList({
    ...requestData,
    statusId: status === 'pending_new_address_change' ? 78 : 79
  });
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [openTicket, setOpenTicket] = useState<number>();

  const openDetailModal = (id: number) => {
    setOpenTicket(id);
    setDetailModalOpen(true);
  };
  const cancelDetailModal = () => setDetailModalOpen(false);
  useEffect(() => {
    if (!detailModalOpen) {
      refetch();
      refreshData();
    }
  }, [detailModalOpen, requestData]);

  const statusColors: Record<string, string> = {
    pending_new_address_change: 'border-[#FF9500]',
    completed_new_address_change: 'border-[#02D8A0]'
  };

  const borderColor = statusColors[status] || '';

  return (
    <div className='relative h-fit'>
      <List
        loading={isLoading}
        className='w-1008px list-item-hover-animation list-item-hover list-item-px-0-py-4 h-[700px] overflow-y-auto'
        itemLayout='vertical'
        size='large'
        dataSource={pending}
        renderItem={(item, index) => (
          <List.Item
            onClick={() => openDetailModal(item.ticketId)}
            className='flex justify-between'
            key={item.ticketId}
          >
            <div className='flex justify-start space-x-4 align-middle'>
              <div className='my-auto flex w-[55px] space-x-2'>
                <Typography.Text className='font-oswald text-[14px]/[18px] text-[#141414]'>
                  {index + 1 + requestData.pageSize * (requestData.pageIndex - 1)}.
                </Typography.Text>
              </div>

              <div className='my-auto min-w-[75px]'>
                <Typography.Text className='font-oswald text-[22px]/[26px] leading-[1.1] text-[#141414]'>
                  {item.createdAt &&
                    (() => {
                      const date = new Date(item.createdAt);
                      const vietnamTime = new Date(date.getTime());
                      const hours = vietnamTime.getHours().toString().padStart(2, '0');
                      const minutes = vietnamTime.getMinutes().toString().padStart(2, '0');

                      return `${hours}:${minutes}`;
                    })()}
                </Typography.Text>
                <br />
                <Typography.Text className='my-auto font-oswald text-[12px]/[16px] text-[#868D97]'>
                  {item.createdAt.split('T')[0].split('-').reverse().join('/')}
                </Typography.Text>
              </div>

              {/* General Information */}
              <div className={`flex items-center border-l-[3px] pl-8 ${borderColor}`}>
                <div className='space-y-2'>
                  <div className='flex items-center space-x-2'>
                    <div className='flex space-x-2'>
                      <img className='h-4 w-4' src={img} alt='' />
                      <Typography.Text className='my-auto align-middle text-[16px]/[20px] font-semibold text-[#181818]'>
                        {item.customerCode}
                      </Typography.Text>
                    </div>
                    <Typography.Text className='relative bottom-2 text-2xl font-bold'>.</Typography.Text>
                    <div className='flex space-x-2'>
                      <img className='h-4 w-4' src={img} alt='' />
                      <Typography.Text className='my-auto align-middle text-[16px]/[20px] font-semibold text-[#181818]'>
                        {/*{item.customerName}*/}
                      </Typography.Text>
                    </div>
                  </div>

                  <div className='flex h-4 space-x-2'>
                    <MapMarker />
                    <Typography.Text className='top-1 text-[14px]/[18px] text-[#000000]'>
                      {item.newAddress}
                    </Typography.Text>
                    <LeftArrow />
                    <Typography.Text className='top-1 text-[14px]/[18px] text-[#868D97]'>
                      {item.currentAddress}
                    </Typography.Text>
                  </div>
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />

      {/* Detail Modal */}
      <Modal
        centered
        className='ant-modal-body-p-none h-fit w-fit rounded-xl bg-[#F5F4F6]'
        open={detailModalOpen}
        onCancel={cancelDetailModal}
        footer={null}
        destroyOnClose
      >
        {openTicket && (
          <IncorrectRecordingLocationTicketDetail
            status={status}
            ticketId={openTicket}
            requestData={requestData}
            cancelDetailModal={cancelDetailModal}
          />
        )}
      </Modal>
    </div>
  );
};

export default IncorrectRecordingLocationList;
