import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { ThreeDots } from 'assets';
import { messages, rolesMessages } from 'messages';
import { RoleDto } from 'types';

type RoleActionsProps = {
  role: RoleDto;
  onDelete?: (shift: RoleDto) => void;
  onUpdateScope?: (shift: RoleDto) => void;
  onUpdateInfo?: (shift: RoleDto) => void;
};

const RoleActions = ({ role, onDelete, onUpdateScope, onUpdateInfo }: RoleActionsProps) => {
  const actionItems: ItemType[] = [
    {
      key: 'updateInfo',
      label: messages.updateInfo
    },
    {
      key: 'updateScope',
      label: rolesMessages.scope
    },
    {
      key: 'delete',
      danger: true,
      label: rolesMessages.delete
    }
  ];

  const onClickActionItem = (action: string, data: RoleDto) => {
    switch (action) {
      case 'updateInfo':
        onUpdateInfo?.(data);
        break;
      case 'updateScope':
        onUpdateScope?.(data);
        break;
      case 'delete':
        onDelete?.(data);
        break;
    }
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: actionItems,
        onClick: ({ key }) => {
          onClickActionItem(key, role);
        }
      }}
    >
      <Button type='text' shape='circle'>
        <ThreeDots />
      </Button>
    </Dropdown>
  );
};

export default RoleActions;
