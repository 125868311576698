import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useHangUpSoftPhoneMutation } from 'services/softphone/softphone.api';
import { setAuthenticated } from 'store/features/auth/auth.slice';
import { TokenSoftPhone } from 'types/dto/softphone/token-softphone.dto';

export function useHangUpSoftPhone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [hangUp, { isLoading, error }] = useHangUpSoftPhoneMutation();

  const onHangUpSoftPhoneHandle = async (values: TokenSoftPhone) => {
    try {
      const response = await hangUp(values).unwrap();
      return response      // You can dispatch actions here if needed
    } catch (err) {
      console.error("Error answering softphone:", err);
      throw err; // ✅ Re-throw error so it can be handled by caller
    }
  };

  return {
    isLoading,
    error,
    onHangUpSoftPhoneHandle,
  };
}
