import { createApi } from '@reduxjs/toolkit/query/react';
import {

  MuteSoftPhoneDto,
  ResponseDto,

  WorldPhoneResponseDto
} from 'types';
import { LoginSoftPhoneDto } from 'types/dto/softphone/login-softphone.dto';
import { LogoutSoftPhoneDto } from 'types/dto/softphone/logout-softphone.dto';
import { MakeCallSoftPhoneDto } from 'types/dto/softphone/makecall-softphone.dto';
import { TokenSoftPhone } from 'types/dto/softphone/token-softphone.dto';
import { TransferSoftPhoneDto } from 'types/dto/softphone/transfer-softphone.dto';
import { configuration } from 'utils';
import axiosBaseQuery from 'utils/base-api';
import jsonpBaseQuery from 'utils/jsonp-base-api';

export const softPhoneApi = createApi({
  reducerPath: 'softPhoneApi',
  baseQuery: jsonpBaseQuery,
  endpoints: (builder) => ({
    loginSoftPhone: builder.mutation<WorldPhoneResponseDto, LoginSoftPhoneDto>({
      query: (loginSoftPhoneDto) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}/login`,
        method: 'get',
        params: loginSoftPhoneDto,
        //callbackName: 'wfappcallback' // Specify the callback name explicitly
      })
    }),
    logoutSoftPhone: builder.mutation<ResponseDto<string>, LogoutSoftPhoneDto>({
      query: (logoutSoftPhoneDto) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}logout`,

        method: 'get',
        params: logoutSoftPhoneDto
      })
    }),
    answerSoftPhone: builder.mutation<WorldPhoneResponseDto, TokenSoftPhone>({
      query: (token) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}answer`,
        method: 'get',
        params: token
      })
    }),
    hangUpSoftPhone: builder.mutation<WorldPhoneResponseDto, TokenSoftPhone>({
      query: (token) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}hangup`,
        method: 'get',
        params: token
      })
    }),
    makeCallSoftPhone: builder.mutation<ResponseDto<any>, MakeCallSoftPhoneDto>({
      query: (makeCallDto) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}makecall`,
        method: 'get',
        params: makeCallDto
      })
    }),
    holdOnSoftPhone: builder.mutation<ResponseDto<any>, TokenSoftPhone>({
      query: (tokenSoftPhone) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}hold_on`,
        method: 'get',
        params: tokenSoftPhone
      })
    }),
    holdOffSoftPhone: builder.mutation<ResponseDto<any>, TokenSoftPhone>({
      query: (tokenSoftPhone) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}hold_off`,
        method: 'get',
        params: tokenSoftPhone
      })
    }),
    transferSoftPhone: builder.mutation<ResponseDto<any>, TransferSoftPhoneDto>({
      query: (transferSoftPhoneDto) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}transfer`,

        method: 'get',
        params: transferSoftPhoneDto
      })
    }),
    setMuteSoftPhone: builder.mutation<WorldPhoneResponseDto, MuteSoftPhoneDto>({
      query: (transferSoftPhoneDto) => ({
        url: `${configuration.WORLD_PHONE_CONNECTION_URL}set_mute`,
        method: 'get',
        params: transferSoftPhoneDto
      })
    })
  })
});

export const {
  useLoginSoftPhoneMutation,
  useLogoutSoftPhoneMutation,
  useAnswerSoftPhoneMutation,
  useHangUpSoftPhoneMutation,
  useHoldOnSoftPhoneMutation,
  useHoldOffSoftPhoneMutation,
  useTransferSoftPhoneMutation,
  useSetMuteSoftPhoneMutation
} = softPhoneApi;
