import { AuthLayout, ErrorBoundaryLayout, MainLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { accessDtvManagementRoutes } from './access-call-center-management.route';
import { accessConfigManagementRoutes } from './access-config-management.route';
import { accessConnectManagementRoutes } from './access-connect-management.route';
import { accessCoordinationRequiredRoutes } from './access-coordination-required.route';
import { accessCustomerLookupRoutes } from './access-customer-lookup.route';
import { accessIncorrectRecordingLocation } from './access-incorrect-recording-location.route';
import { accessOperationsManagementRoutes } from './access-operations-management.route';
import { accessShiftSupervisorRoutes } from './access-shift-supervisor.route';
import { accessTicketRequestRoute } from './access-ticket-request.route';
import { accessUserManagementRoutes } from './access-user-management.route';
import { accessCooperateRequestRoute } from './accessCooperateRequest.route';
import { accessFollowingRequestRoute } from './access-following-request.route';
import { ROUTE } from './constants';
import { accessCustomerSearchRoutes } from './access-customer-search.route';
import { accessCollectPaymentRoutes } from './access-collect-payment.route';
import LoginPage from 'pages/Login';

export const routes: RouteObject[] = [
  {
    Component: ErrorBoundaryLayout,
    children: [
      {
        Component: MainLayout,
        children: [
          {
            path: ROUTE.ALL,
            Component: lazy(() => import('pages/404'))
          },
          {
            path: ROUTE.HOME,
            Component: lazy(() => import('pages/Home'))
          },
          {
            path: ROUTE.PROFILE,
            Component: lazy(() => import('pages/Profile'))
          },
          ...accessUserManagementRoutes,
          ...accessConnectManagementRoutes,
          ...accessConfigManagementRoutes,
          ...accessOperationsManagementRoutes,
          ...accessCustomerLookupRoutes,
          ...accessCustomerSearchRoutes,
          ...accessShiftSupervisorRoutes,
          ...accessCoordinationRequiredRoutes,
          ...accessDtvManagementRoutes,
          ...accessCooperateRequestRoute,
          ...accessFollowingRequestRoute,

          //Electricity
          ...accessIncorrectRecordingLocation,
          ...accessCollectPaymentRoutes,
          ...accessTicketRequestRoute
        ]
      },
      {
        Component: AuthLayout,
        children: [
          {
            path: ROUTE.LOGIN,
            Component: LoginPage
          },
          {
            path: ROUTE.FORGOT_PASSWORD,
            Component: lazy(() => import('pages/ForgotPassword'))
          },
          {
            path: ROUTE.VERIFY_FORGOT_PASSWORD,
            Component: lazy(() => import('pages/VerifyForgotPassword'))
          },
          {
            path: ROUTE.RESET_PASSWORD,
            Component: lazy(() => import('pages/ResetPassword'))
          }
        ]
      },
      {
        path: '/icons',
        Component: lazy(() => import('pages/Icons'))
      }
    ]
  }
];
