import { SelectProps } from 'antd';
import { Select } from 'components/common';
import { useServiceTypesCMIS } from 'hooks';
import { useState } from 'react';
import { filterOptions } from 'utils';

type SelectServiceTypeCMISProps = SelectProps;

const SelectServiceTypeCMIS = ({ ...props }: SelectServiceTypeCMISProps) => {
  const [keyword, setKeyword] = useState('');

  const { serviceTypeCMIS, hasMore, handleLoadMore, isLoading, isFetching } = useServiceTypesCMIS({
    isLoadAll: true
  });

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const options = filterOptions(serviceTypeCMIS, keyword, 'name');

  return (
    <Select
      {...props}
      onLoadMore={handleLoadMore}
      loading={isLoading || isFetching}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      hasMore={hasMore}
      options={
        options.map((itm) => ({
          label: itm.name,
          value: itm.serviceTypeCmisId
        })) || []
      }
    />
  );
};

export default SelectServiceTypeCMIS;
