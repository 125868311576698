import { Button, Checkbox, Form, GetProp, message, Tooltip } from 'antd';
import { AgentMapDoubleDoor, AgentMapSingleDoor, AgentMapSlidingDoor, AgentMapWall } from 'assets';
import { Loadable } from 'components';
import { AgentMapRefProps } from 'components/access-mange-dtv/AgentMap';
import AgentMapName from 'components/agent-map/AgentMapName';
import { useAgentMapDetail, useAgentMapDetailDashboard, useAgentMapsPaging, useCreateAgentMap, useSearchParamsForm, useTitle, useUpdateAgentMap } from 'hooks';
import { sidebarMenuMessages, validateMessages } from 'messages';
import { agentMapsMessages } from 'messages/agent-maps.messages';
import React, { lazy, memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateAgentMapDto, CreateAgentMapSeatDashboardDto } from 'types';
import { FabricAgentMapOtherObject, FabricAgentMapSeat, FabricAgentMapSeatDashboard } from 'types/fabric-agent-map';
import { SingleAgentMapSingleStatusesType } from './AccessManageDtv';

const AgentMap = lazy(() => import('components/access-mange-dtv/AgentMap'));

export type AgentMapFormTypeDashboard = CreateAgentMapSeatDashboardDto & {
  fabricSeatsDashboard: FabricAgentMapSeatDashboard[];
  fabricOtherObjects: FabricAgentMapOtherObject[];
};
interface Option {
  key: any;
  value: any;
  label: any;
}
type AgentMapFormProps = {
  agentMapId: number;
  singleAgentMapAgentStatuses: SingleAgentMapSingleStatusesType[];
  seatTimers: Record<string, number>;
  updateSeatTimer: (extensionId: string, timestamp?: number) => void;
  showCounters: boolean; // Add this prop
  extensionCounters: Record<string, { call: number, chat: number }>;
};
const AccessManageDtvAgentMap: React.FC<AgentMapFormProps> = ({
  agentMapId,
  singleAgentMapAgentStatuses,
  seatTimers,
  updateSeatTimer,
  showCounters, // Add this prop
  extensionCounters
}) => {
  useTitle(sidebarMenuMessages.agentMaps);
  const navigate = useNavigate();
  const agentRef = useRef<AgentMapRefProps>(null);
  const [form] = Form.useForm<AgentMapFormTypeDashboard>();
  const [getAgentMapDetail, setGetAgentMapDetail] = useState<number>(0);

  const { setSearchParams, getSearchParams } = useSearchParamsForm();
  // Call the hook with filtering parameters

  const fabricSeatsDashboard = Form.useWatch('fabricSeatsDashboard', form) || [];
  const fabricOtherObjects = Form.useWatch('fabricOtherObjects', form) || [];
  useAgentMapDetailDashboard(agentMapId, form);

  return (
    <div className='h-full w-full overflow-hidden'>
      <Form
        labelAlign='right'
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        form={form}
        //onFinish={onSubmit}
        labelCol={{
          flex: '180px'
        }}
        layout='horizontal'
        validateMessages={validateMessages}
        className='flex h-full w-full'
        initialValues={{
          name: agentMapsMessages.newMap
        }}
      >
        <div className='flex h-full w-full flex-col overflow-hidden'>
          <div className='h-full w-full max-w-full'>
            <Form.Item<AgentMapFormTypeDashboard> name='fabricSeatsDashboard' noStyle />
            <Form.Item<AgentMapFormTypeDashboard> name='fabricOtherObjects' noStyle />
            {/* <Form.Item<AgentMapFormTypeDashboard> name='isActive' initialValue={true} noStyle /> */}

            <Loadable message={agentMapsMessages.drawGrid}>
              <AgentMap
                singleAgentMapAgentStatuses={singleAgentMapAgentStatuses}
                ref={agentRef}
                seats={fabricSeatsDashboard}
                otherObjects={fabricOtherObjects}
                seatTimers={seatTimers}
                updateSeatTimer={updateSeatTimer}
                showCounters={showCounters} // Pass the prop
                extensionCounters={extensionCounters}
              />
            </Loadable>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default memo(AccessManageDtvAgentMap);
