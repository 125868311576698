import { CloudyDayIcon, CloudyNightIcon, SunIcon } from 'assets';

export const SHIFT_DEFAULT_ICONS = [
  {
    key: 'sun',
    icon: SunIcon,
    path: '/svg/sunny-colored.svg'
  },
  {
    key: 'cloudyDay',
    icon: CloudyDayIcon,
    path: '/svg/cloudy-day-colored.svg'
  },
  {
    key: 'cloudyNight',
    icon: CloudyNightIcon,
    path: '/svg/cloudy-night-colored.svg'
  }
];
