import { FollowingRequestDto } from '../../types/dto/following-request';
import { useGetFollowingTicketListQuery } from '../../services';

export function GetFollowingRequestList(followingRequestDto: FollowingRequestDto) {
  const { data: followingList, isLoading, refetch } = useGetFollowingTicketListQuery(followingRequestDto!, {
    skip: !followingRequestDto,
    refetchOnMountOrArgChange: true
  });

  return {
    followingList: followingList?.data.rows || [],
    followingListCount: Number(followingList?.data.count),
    refetch: refetch ?? (() => { }),
    isLoading
  };
}