import { message } from 'components';
import { useAssignChatSessionMutation } from 'services';

export const useAssignChatSession = () => {
  const [handleAssign, { isLoading }] = useAssignChatSessionMutation();

  const onAssignChatSession = (chatSessionAssign: number, userAssign: number) => {
    return handleAssign({
      chatSessionId: chatSessionAssign,
      userId: userAssign
    })
      .unwrap()
      .then((rs) => message.systemSuccess(rs.message));
  };

  return {
    onAssignChatSession,
    isLoading
  };
};
