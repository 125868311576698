import { ChatSessionDto, JoinChatSessionDto, OmniChannelMessageDto, OutChatSessionDto } from 'types';
import { configuration, LOCAL_STORAGE_KEY, OMNI_CHANNEL_EVENT, SocketClient } from 'utils';
import { addMetadataOmniMessage } from './omni-channel.api';
import * as _ from 'lodash';
import { AgentTotalCallAndChatCounter } from 'types/dto/agent-maps/agent-total-call-and-chat-counter.dto';

let joinedChatSessionIds: number[] = [];
export const omniChatSocketClient = new SocketClient({
  uri: configuration.OMNI_CHANNEL_SOCKET_URL || '',
  transports: ['websocket'],
  auth: {
    token: localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
  },
  autoConnect: !!localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
});

omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.CONNECT, () => {
  joinedChatSessionIds.forEach((chatSessionId) => {
    joinChatSession({ chatSessionId: chatSessionId });
  });
});

export async function connectOmniChannelSocket() {
  omniChatSocketClient.connect();
}
export async function disconnectOmniChannelSocket() {
  omniChatSocketClient.disconnect();
}

/** chat session */
export function joinChatSession(joinChatSession: JoinChatSessionDto) {
  omniChatSocketClient.socket.emit(OMNI_CHANNEL_EVENT.JOIN_CHAT_SESSION, joinChatSession);
  joinedChatSessionIds = _.uniq([...joinedChatSessionIds, joinChatSession.chatSessionId]);
}

export function leaveChatSession(leaveChatSession: OutChatSessionDto) {
  omniChatSocketClient.socket.emit(OMNI_CHANNEL_EVENT.LEAVE_CHAT_SESSION, leaveChatSession);
  joinedChatSessionIds = joinedChatSessionIds.filter(
    (conversationId) => conversationId !== leaveChatSession.chatSessionId
  );
}

/** chat session processing */
export function onReceiveChatSessionProcessing(callback: (data: ChatSessionDto) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.CHAT_SESSION_PROCESSING, (data) => {
    callback(data);
  });
}
export function offReceiveChatSessionProcessing() {
  omniChatSocketClient.socket.off(OMNI_CHANNEL_EVENT.CHAT_SESSION_PROCESSING);
}

/** chat session waiting */
export function onReceiveChatSessionWaiting(callback: (data: ChatSessionDto) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.ADD_CHAT_SESSION_WAITING, (data) => {
    callback(data);
  });
}
export function offReceiveChatSessionWaiting() {
  omniChatSocketClient.socket.off(OMNI_CHANNEL_EVENT.ADD_CHAT_SESSION_WAITING);
}

export function onRemoveChatSessionWaiting(callback: (data: ChatSessionDto) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.REMOVE_CHAT_SESSION_WAITING, (data) => {
    callback(data);
  });
}
export function offRemoveChatSessionWaiting() {
  omniChatSocketClient.socket.off(OMNI_CHANNEL_EVENT.REMOVE_CHAT_SESSION_WAITING);
}

/** messages */
export function onReceiveOmniMessage(callback: (data: OmniChannelMessageDto) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.MESSAGE, async (data) => {
    let dataReceive = { ...data, senderId: isNaN(Number(data.senderId)) ? data.senderId : Number(data.senderId) };
    const message = await addMetadataOmniMessage(dataReceive);
    callback(message);
  });
}
export function offReceiveOmniMessage() {
  omniChatSocketClient.socket.off(OMNI_CHANNEL_EVENT.MESSAGE);
}

/** call events */

export function onReceiveCallStartEvent(callback: (data: any) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.START, async (data) => {
    callback(data)
  })
}
export function onReceiveCallDialAnswerEvent(callback: (data: any) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.DIALANSWER, async (data) => {
    callback(data)
  })
}
export function onReceiveCallHangUpEvent(callback: (data: any) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.HANGUP, async (data) => {
    callback(data)
  })
}
export function onReceiveCallDialingEvent(callback: (data: any) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.DIALING, async (data) => {
    callback(data)
  })
}

export function onReceiveGetCallDetailsEvent(callback: (data: any) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.GET_CALL_DETAILS, async (data) => {
    callback(data)
  })
}

export function onGetWaitingCallsEvent(callback: (data: any) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.GET_WAITING_CALLS, async (data) => {
    callback(data)
  })
}

export function onReceiveUpdateChatStatDetailEvent(callback: (data: any) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.UPDATE_CHAT_STAT_DETAILS, async (data) => {
    console.log('update nè')
    callback(data);
  });
}
export function onReceiveTotalCallandChat(callback?: (data: AgentTotalCallAndChatCounter) => void) {
  omniChatSocketClient.socket.on(OMNI_CHANNEL_EVENT.AGENT_COUNTER_UPDATE, (data: AgentTotalCallAndChatCounter) => {
    callback?.(data);
  });
}


