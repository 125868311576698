import { useDispatch } from 'react-redux';
import { useGetSoftPhoneTokenMutation } from 'services';
import { setAuthenticatedSoftPhone, setSoftPhoneToken } from 'store/features/sip-auth/sip-auth.slice';
import { GetWorldPhoneTokenDto } from 'types';

export function useGetSoftPhoneToken() {
  const dispatch = useDispatch();
  const [getWorldPhoneToken, { isLoading, error }] = useGetSoftPhoneTokenMutation();

  const onGetWorldPhoneToken = async (values: GetWorldPhoneTokenDto) => {
    try {
      const res = await getWorldPhoneToken(values).unwrap(); // ✅ Await the API response
      const token = res?.data?.token;

      if (token) {
        dispatch(setAuthenticatedSoftPhone(true));
        dispatch(setSoftPhoneToken(token));
      } else {
        console.warn("No token received from API response");
      }

      return res; // ✅ Return the full response for further processing if needed
    } catch (err) {
      console.error("Error fetching softphone token:", err);
      throw err; // ✅ Re-throw error so the caller can handle it
    }
  };

  return {
    isLoading,
    error,
    onGetWorldPhoneToken,
  };
}
