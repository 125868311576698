
/**
 * Parses complex date formats and returns a valid timestamp
 * Handles formats like "2025-03-19 10:06:27.8550000 +07:00"
 */
export const parseComplexDate = (dateString: string | undefined): number => {
    if (!dateString) return Date.now();

    try {
        // Try standard Date parsing first
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
            return date.getTime();
        }

        // If that fails, try handling the specific format
        // Format: "2025-03-19 10:06:27.8550000 +07:00"
        const regex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})\.(\d+) ([+-]\d{2}):(\d{2})/;
        const match = dateString.match(regex);

        if (match) {
            const [_, year, month, day, hours, minutes, seconds, ms, tzHour, tzMinute] = match;

            // Create ISO string that JavaScript can reliably parse
            const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${ms.substring(0, 3)}${tzHour}:${tzMinute}`;

            const parsedDate = new Date(isoString);
            if (!isNaN(parsedDate.getTime())) {
                return parsedDate.getTime();
            }
        }

        console.error(`Failed to parse date: ${dateString}`);
        return Date.now();
    } catch (e) {
        console.error(`Error parsing date: ${dateString}`, e);
        return Date.now();
    }
};
