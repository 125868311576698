import { BulbOutlined } from "@ant-design/icons"
import { Typography, Tabs, TabsProps } from "antd"
import { IdeaIcon } from "assets";
import { createTicketMessages } from "messages/create-ticket.messages"
import CreateTicketCustomerInFormation from "./CreateTicketCustomerInFormation";
import { useServiceInstructionsDetail } from "hooks/service-instructions/useServiceInstructionsDetailed";

type CreateTicketsProps = {
    customerId: number;
    serviceTypeId: number;
}



const onChange = (key: string) => {
    console.log(key);
};


const CreateTicketInStructions = ({customerId, serviceTypeId} : CreateTicketsProps) => {
    const {serviceInstruction,isLoading} = useServiceInstructionsDetail(serviceTypeId)
    const items: TabsProps['items'] = [
        {
            key: 'tab1',
            label: (
                <span className="text-center font-semibold text-[14px]/[18px] tracking-normal focus:text-black focus-visible:text-black opacity-100">
                    {createTicketMessages.detail}
                </span>
            ),
            children: <p className="pb-2" dangerouslySetInnerHTML={{
                __html: serviceInstruction?.detail || 'Không có thông tin',
            }}></p>,
        },
        {
            key: 'tab2',
            label: (
                <span className="text-center font-semibold text-[14px]/[18px] tracking-normal focus:text-black focus-visible:text-black opacity-100">
                    {createTicketMessages.document}
                </span>
            ),
            children: <p className="pb-2" dangerouslySetInnerHTML={{
                __html: serviceInstruction?.profile || 'Không có thông tin',
            }}></p>
        },
        {
            key: 'tab3',
            label: (
                <span className="text-center font-semibold text-[14px]/[18px] tracking-normal focus:text-black focus-visible:text-black opacity-100">
                    {createTicketMessages.relatedInformation}
                </span>
            ),
            children: <p className="pb-2" dangerouslySetInnerHTML={{
                __html: serviceInstruction?.general || 'Không có thông tin',
            }}></p>
        },
    ];
    return (
        <div className='h-[1000px] w-[47%] flex flex-col bg-[#EBEBED] rounded-br-[12px] bg-no-repeat bg-clip-padding opacity-100'>
            {customerId !== 0 && <CreateTicketCustomerInFormation customerId={customerId} />}
            <div className="flex flex-row gap-3">
                <IdeaIcon color="#06A77D" className="w-8 h-8 ml-6 mt-2" />
                <Typography.Text className="mt-3 text-left font-semibold text-[22px]/[26px] tracking-normal text-[#141414] opacity-100">{createTicketMessages.instructions}</Typography.Text>
            </div>
            <Tabs
                className="mx-6 overflow-y-scroll mt-3 ant-tabs-nav-list-spacing-adj ant-tabs-nav-mb-4 ant-tabs-tab-custom-btn ant-tabs-focus-visible-black"
                items={items}
                defaultActiveKey="tab1"
                onChange={onChange}
                style={{paddingBottom: '20px'}}
            />
        </div>
    )
}

export default CreateTicketInStructions