import { FabricAgentMapOtherObject } from 'types/fabric-agent-map';
import { addDoor, addText, addWall, removeObject } from 'utils/agent-map';
import * as fabric from 'fabric';
import { AGENT_MAP_OBJECT_TYPE } from 'utils';

type Props = {
  canvas: fabric.Canvas | null;
  objectArr: FabricAgentMapOtherObject[] | null;
};

export const useAgentMapObjectCurd = () => {
  const handleAddObject =
    ({ canvas, objectArr }: Pick<Props, 'canvas' | 'objectArr'>) =>
    async (otherObject: Partial<FabricAgentMapOtherObject>) => {
      if (!canvas || !otherObject.objectTypeId) return objectArr ?? [];

      if (otherObject.objectTypeId === AGENT_MAP_OBJECT_TYPE.WALL) {
        return handleAddWall(canvas, objectArr ?? [], otherObject);
      }
      if (otherObject.objectTypeId === AGENT_MAP_OBJECT_TYPE.TEXT) {
        return handleAddText(canvas, objectArr ?? [], otherObject);
      }
      return await handleAddDoor(canvas, objectArr ?? [], otherObject);
    };

  const handleAddWall = (
    canvas: fabric.Canvas | null,
    objectArr: FabricAgentMapOtherObject[],
    otherObject: Partial<FabricAgentMapOtherObject>
  ) => {
    if (!canvas) return [];
    const wall = addWall(canvas, {
      ...otherObject
    });
    return [...objectArr, wall];
  };

  const handleAddText = (
    canvas: fabric.Canvas | null,
    objectArr: FabricAgentMapOtherObject[],
    otherObject: Partial<FabricAgentMapOtherObject>
  ) => {
    if (!canvas) return [];
    const text = addText(canvas, {
      ...otherObject
    });
    return [...objectArr, text];
  };

  const handleAddDoor = async (
    canvas: fabric.Canvas | null,
    objectArr: FabricAgentMapOtherObject[],
    otherObject: Partial<FabricAgentMapOtherObject>
  ) => {
    if (!canvas) return objectArr;
    const door = await addDoor(canvas, {
      ...otherObject
    });
    if (door) return [...objectArr, door];
    return objectArr;
  };

  const handleRemoveObject =
    ({ canvas, objectArr }: Pick<Props, 'canvas' | 'objectArr'>) =>
    (seatId: string | number) => {
      if (canvas) {
        const seats = removeObject(seatId, canvas, objectArr ?? []);
        return [...seats];
      }
      return objectArr ?? [];
    };

  return { handleAddObject, handleRemoveObject };
};
