import { Typography, Card } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { TicketDetail } from 'types';
import { requestform, sidebarMenuMessages } from 'messages';
import { GreenCheck, PrinterIcon } from 'assets';
import { GetTicketDetail, GetUserListInOrganization } from 'hooks/request-form';
import { DownOutlined } from '@ant-design/icons';

import defaultTicketDetail from '../../types/dto/request-form/request-form.dto';
import RequestDetailCreateFormInformation from '../request-form/RequestDetailCreateFormInformation';
import RequestStatusBar from '../request-form/RequestStatusBar';
import RequestDetailMainInformation from '../request-form/RequestDetailMainInformation';
import RequestDetailProcessing from '../request-form/RequestDetailProcessing';
import { useReactToPrint } from 'react-to-print';
import NormalTicket from '../print-ticket-detail/NormalTicket';
import PrintComponent from '../print-ticket/PrintTikcet';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface ticketFromDetail {
  status?: string;
  ticketId: number;
  cancelDetailModal: () => void;
}

const FollowingRequestDetail: React.FC<ticketFromDetail> = ({ status, ticketId, cancelDetailModal }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const { requestDetail } = GetTicketDetail(ticketId);
  const [processingContent, setProcessingContent] = useState<string>('');
  const [requestItem, setRequestItem] = useState<TicketDetail | null>(null);

  const profile = useSelector((state: RootState) => state.users.userProfile);
  const { userInUnitCount, userInUnitList } = GetUserListInOrganization(
    profile?.organizationUnitId ? profile?.organizationUnitId : 0
  );

  useEffect(() => {
    if (requestDetail) {
      const formattedRequestDetail: TicketDetail = Object.assign({}, defaultTicketDetail, requestDetail);
      setRequestItem(formattedRequestDetail);
    }
  }, [requestDetail]);

  return (
    <Card
      title={
        <Typography.Text className='w-full text-center text-[16px]/[22px] font-semibold'>
          {requestform.requestTicket} {ticketId}
        </Typography.Text>
      }
      className='request-form-detail-card min-h-[1000px] text-center'
    >
      <div style={{ display: 'none' }}>
        <PrintComponent ref={contentRef}>
          <NormalTicket ticket={requestDetail} status={status} ticketId={ticketId} />
        </PrintComponent>
      </div>

      <div className='flex w-full text-left'>
        <RequestDetailCreateFormInformation requestItem={requestItem} />
        <section className='flex flex-col justify-between'>
          <section className='min-w-[768px] p-6'>
            <section className='flex items-center justify-between'>
              <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
                {requestItem?.serviceType?.name ? requestItem.serviceType.name.split(' ').slice(0, 20).join(' ') : ''}
              </Typography.Text>
              <button onClick={() => reactToPrintFn()} className='flex items-center space-x-2'>
                <PrinterIcon />
                <Typography.Text className='text-[16px]/[20px] text-[#1564E8]'>
                  {requestform.printTicket}
                </Typography.Text>
              </button>
            </section>

            <section>
              <Typography.Text
                className={`text-[14px]/[18px] ${requestItem?.isRequestMultipleTime ? 'text-[#D1131D]' : 'text-[#06A77D]'}`}
              >
                {requestItem?.isRequestMultipleTime
                  ? requestform.multiplleTimeRequest
                  : requestform.notMultiplleTimeRequest}
              </Typography.Text>
            </section>

            <section>
              {status && (
                <div className='pb-6'>
                  <RequestStatusBar status={status} />
                  {/* CMIS Transfer */}
                  {status === 'pending-reception' ? (
                    <div className='mt-1 justify-between rounded-[5px] bg-[#EBEBED] px-4 py-[11px]'>
                      <Typography.Text className='text-[14px]/[18px] font-semibold text-[#141414]'>
                        {requestform.isTranferCmisTicket}
                      </Typography.Text>
                      <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                        {requestform.typeCmisTicket}
                      </Typography.Text>{' '}
                      <DownOutlined className='h-2 w-[10px]' />
                    </div>
                  ) : requestItem?.cmisRequestId == null ? (
                    <div className='mt-1 flex justify-between rounded-[5px] border border-[#D1131D] bg-[#06A77D00] px-4 py-[11px]'>
                      <div>
                        <Typography.Text className='text-[14px]/[18px] font-semibold text-[#D1131D]'>
                          {requestform.cmisTransferFail}
                        </Typography.Text>{' '}
                        <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                          {requestform.typeCmisTicket}
                        </Typography.Text>{' '}
                        <DownOutlined className='h-2 w-[10px]' />
                      </div>
                      <button>
                        <Typography.Text className='text-[14px]/[18px] text-[#1564E8]'>
                          {requestform.tryAgainButton}
                        </Typography.Text>
                      </button>
                    </div>
                  ) : (
                    <div className='mt-1 flex justify-between rounded-[5px] bg-[#06A77D00] py-[11px]'>
                      <div className='flex space-x-1'>
                        <GreenCheck />
                        <Typography.Text className='text-[14px]/[18px] italic text-[#141414]'>
                          {requestform.cmisTranfered}
                          {requestItem.cmisRequestId}
                        </Typography.Text>
                      </div>{' '}
                      <Typography.Text className='text-[14px]/[18px] italic text-[#868D97]'>
                        {/* This is static data, add dynamic data latter */}
                        Lúc 16:32, 27/12/2024
                      </Typography.Text>
                    </div>
                  )}
                </div>
              )}
            </section>

            <section className={`h-[688px] overflow-y-auto`}>
              <RequestDetailMainInformation requestItem={requestItem} />
              <RequestDetailProcessing
                requestItem={requestItem}
                processingContent={processingContent}
                setProcessingContent={setProcessingContent}
              />
            </section>
          </section>
        </section>
      </div>
    </Card>
  );
};

export default FollowingRequestDetail;
