import { useGetServiceInstructionDetailQuery } from "services";

export function useServiceInstructionsDetail(serviceTypeId: number) {
    const { data: serviceInstructionRes, isLoading } = useGetServiceInstructionDetailQuery(serviceTypeId, {
        skip: !serviceTypeId,
        refetchOnMountOrArgChange: true
    });
    return {
        serviceInstruction: serviceInstructionRes?.data,
        isLoading
    };
}