import { Button, Typography } from 'antd';
import { NoticeCircleIcon } from 'assets';
import { customerLookupMessages } from 'messages/customer-lookup.message';

interface FailedLoginProps {
    isMinimized: boolean;
    fetchAndLoginSoftPhone: () => void;
}

const FailedLoginContent: React.FC<FailedLoginProps> = ({ isMinimized, fetchAndLoginSoftPhone }) => {
    return isMinimized ? (
        <div className="w-[400px] flex h-[244px] flex-col rounded-tl-xl bg-[#FCECED]">
            <div className="m-5 flex flex-col">
                <NoticeCircleIcon className="h-8 w-8" />
                <div className="mt-[25px] flex flex-col gap-2">
                    <Typography.Text className="text-left text-[18px]/[22px] font-semibold tracking-normal text-[#D1131D] opacity-100">
                        {customerLookupMessages.failedLogin}
                    </Typography.Text>
                    <Typography.Text className="text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                        {customerLookupMessages.isFailedLogin}
                    </Typography.Text>
                </div>
                <Button
                    onClick={fetchAndLoginSoftPhone}
                    type="primary"
                    className="mb-[20px] mt-[30px] h-[36px] w-fit"
                >
                    {customerLookupMessages.login}
                </Button>
            </div>
        </div>
    ) : (
        <div className="flex h-full flex-1  w-[400px] flex-col rounded-l-xl bg-[#FCECED]">
            <div className="m-5  h-full flex flex-col items-center justify-center">
                {/* <Typography.Text className="text-center text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    EXT #231321
                </Typography.Text> */}
                <NoticeCircleIcon className=" h-[112px] w-[112px]" />
                <Typography.Text className="mt-[52px] text-center text-[18px]/[22px] font-semibold tracking-normal text-[#D1131D] opacity-100">
                    {customerLookupMessages.failedLogin}
                </Typography.Text>
                <Typography.Text className="mt-2 text-center text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                    {customerLookupMessages.isFailedLogin}
                </Typography.Text>
                <Button
                    onClick={fetchAndLoginSoftPhone}
                    type="primary"
                    className="mt-8 w-fit"
                >
                    {customerLookupMessages.login}
                </Button>
            </div>
        </div>
    );
};

export default FailedLoginContent;
