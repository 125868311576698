import React, { useState } from 'react';
import { Typography, Button, Avatar as AntdAvatar } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { BlueArrow } from 'assets';
import { ticketDetailedMessages } from 'messages/ticket-detailed.messages';
import Avatar from 'components/common/avatar/Avatar';
import dayjs from 'dayjs';
import { TicketDetail } from 'types';
import progressIcon from '../../assets/svg/progressing-setting.svg';
import { requestform } from 'messages';
import { useLocation } from 'react-router-dom';

interface RequestDetailProcessingProps {
  requestItem: TicketDetail | null;
  processingContent: string;
  setProcessingContent: (value: string) => void;
  setRequestUpdateTicketFollowUpContent?: (value: string) => void;
  isSecondCall?: boolean;
}

const TicketDetailedIProcessing: React.FC<RequestDetailProcessingProps> = ({
  requestItem,
  processingContent,
  setProcessingContent,
  setRequestUpdateTicketFollowUpContent,
  isSecondCall
}) => {
  const [isShow, setIsShow] = useState(false);
  const setShowAll = () => setIsShow(true);
  const location = useLocation();
  console.log(requestItem?.status.code,'status code nè')

  return (
    <div>
      <div className='flex items-center space-x-3'>
        <img src={progressIcon} alt='' />
        <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
          {requestform.processingTitle}
        </Typography.Text>
      </div>

      {/* Timeline */}
      <div className='mt-[20px]'>
        {isSecondCall && (
          <TextArea
            placeholder='Nội dung phản hồi'
            autoSize={{ minRows: 5, maxRows: 10 }}
            className='rounded-lg bg-[#F7F8F6]'
            value={processingContent}
            onChange={(e) => setProcessingContent(e.target.value)}
          />
        )}

        {requestItem && requestItem?.ticketEvents.length > 0 ? (
          <>
            {isShow ? (
              requestItem?.ticketEvents.map((event, index) => (
                <>
                  <div className='relative mx-4 flex items-center justify-between'>
                    <div className={`absolute -left-4 top-[60%] rounded-full border bg-white p-2`}>
                      <BlueArrow />
                    </div>
                    <div className={`flex w-full items-center justify-between border-l pt-16`}>
                      <div className='mx-6 w-full'>
                        <Typography.Text className='text-[16px]/[18px] font-semibold text-[#141414]'>
                          {event.createdByName}
                        </Typography.Text>{' '}
                        <Typography.Text> {event.actionType?.name}</Typography.Text>
                        <br />
                        {(() => {
                          const formatDate = (date: string) => {
                            return date ? dayjs(date).format('DD [tháng] MM, YYYY - HH:mm') : '';
                          };

                          const formattedDate = formatDate(event.createdAt);

                          return (
                            <div className='text-[14px] italic text-[#141414]'>
                              {formattedDate.split('-')[1]}{' '}
                              <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                              {formattedDate.split('-')[0]}{' '}
                              <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                              {requestItem?.ticketEvents?.[0]?.organizationUnitName}
                            </div>
                          );
                        })()}
                      </div>
                      <Avatar name={requestItem?.ticketEvents[index].createdByName} size={32} />
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div className='relative mx-4 flex items-center justify-between'>
                <div
                  className={`absolute -left-4 ${
                    requestItem?.status.code === 'CHOTIEPNHAN' ? 'top-[20%]' : ''
                  } rounded-full border bg-white p-2`}
                >
                  <BlueArrow />
                </div>
                <div
                  className={`flex w-full items-center justify-between border-l ${
                    requestItem?.status.code === 'CHOTIEPNHAN'
                      ? ''
                      : requestItem?.ticketEvents.length >= 2
                        ? 'py-8'
                        : 'pt-16'
                  }`}
                >
                  <div className='mx-6 w-full'>
                    <Typography.Text className='text-[16px]/[18px] font-semibold text-[#141414]'>
                      {requestItem?.ticketEvents[0].createdByName}
                    </Typography.Text>{' '}
                    <Typography.Text> {requestItem?.ticketEvents[0]?.actionType?.name}</Typography.Text>
                    <br />
                    {(() => {
                      const formatDate = (date: string) => {
                        return date ? dayjs(date).format('DD [tháng] MM, YYYY - HH:mm') : '';
                      };

                      const formattedDate = formatDate(requestItem?.ticketEvents?.[0]?.createdAt);

                      return (
                        <div className='text-[14px] italic text-[#141414]'>
                          {formattedDate.split('-')[1]}
                          <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                          {formattedDate.split('-')[0]}{' '}
                          <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                          {requestItem?.ticketEvents?.[0]?.organizationUnitName}
                        </div>
                      );
                    })()}
                  </div>
                  <Avatar name={requestItem?.ticketEvents[0].createdByName} size={32} />
                </div>
              </div>
            )}

            <div className='relative flex items-center'>
              {requestItem?.ticketEvents.length >= 2 ? (
                <>
                  {isShow ? (
                    ''
                  ) : (
                    <div className={`relative flex items-center`}>
                      <div>
                        <AntdAvatar.Group>
                          {requestItem?.ticketEvents
                            .slice(1, requestItem?.ticketEvents.length)
                            .map((avt, index) => (
                              <AntdAvatar
                                key={index}
                                src={<Avatar name={requestItem?.createdByName} size={32} />}
                                size={32}
                                alt={`Avatar ${index + 1}`}
                                className={`rounded-full border-2 border-white ${index !== 0 ? '-ml-5' : ''}`}
                              />
                            ))}
                        </AntdAvatar.Group>
                      </div>

                      <Button
                        className='text border-none bg-transparent text-[#1564E8] shadow-none'
                        onClick={setShowAll}
                      >
                        {ticketDetailedMessages.buttonShowAllHistoryProcess}
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ' '
        )}
      </div>
    </div>
  );
};

export default TicketDetailedIProcessing;
