import { Button, Divider } from 'antd';
import { ChatIcon, HourglassIcon } from 'assets';
import { useOmniChatSessionsProcessingList, useOmniChatSessionsWaitingList } from 'hooks';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ChatSessionDto } from 'types';

type OmniChanelWaitingProps = {};

function OmniChanelWaiting({}: OmniChanelWaitingProps) {
  const [numberOfUnreadChat, setNumberOfUnreadChat] = useState(0);

  const { count } = useOmniChatSessionsWaitingList({ keyword: '' });
  const { chatSessionProcessing } = useOmniChatSessionsProcessingList({ keyword: '' });

  useEffect(() => {
    const totalUnreadCount = chatSessionProcessing.reduce((sum, item: ChatSessionDto) => sum + item.unreadCount, 0);
    setNumberOfUnreadChat(totalUnreadCount);
  }, [chatSessionProcessing]);

  return (
    <div className='ml-2 flex h-10 cursor-pointer items-center overflow-hidden rounded-full border border-defaultBorderColor'>
      <Button
        icon={
          <div>
            {numberOfUnreadChat > 0 ? (
              <div className='absolute right-4 top-1/3 h-2 w-2 -translate-y-1/2 rounded-full bg-[#FF2D54]'></div>
            ) : null}
            <ChatIcon className='text-[#06a77d]' height={20} width={20} />
          </div>
        }
        type='text'
        className={twMerge('h-10 w-13')}
      />

      <Divider type='vertical' className='mx-0 h-6' />
      <Button
        icon={<HourglassIcon height={20} width={20} />}
        type='text'
        className='h-10 w-[80px] rounded-none text-sm font-normal'
      >
        {count}
      </Button>
    </div>
  );
}

export default OmniChanelWaiting;
