import { MenuProps, Typography } from 'antd';
import { CopyIcon } from 'assets';
import dayjs from 'dayjs';
import { internalChatMessages } from 'messages';
import { memo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { OmniChannelSenderDto, OmniChannelMessageDto } from 'types';
import { getSelectedTextInDiv, stringToHslColor, TIME_FORMAT, transformUrlsToLinks } from 'utils';
import MessageOptions, { MessageOptionsRef } from './MessageOptions';
import MessageUrl from './MessageUrl';
import { useOmniChatContext } from 'contexts';
import MessageActions from './MessageActions';

type OmniMessageTextProps = {
  data: OmniChannelMessageDto;
  sender?: OmniChannelSenderDto;
  isGroup?: boolean;
  isFirstOfSender?: boolean;
  onForwardMessage?: (message: OmniChannelMessageDto) => void;
  isImageMessage?: boolean;
  className?: string;
};

const OmniMessageText: React.FC<OmniMessageTextProps> = ({
  data,
  onForwardMessage,
  sender,
  isGroup,
  isFirstOfSender,
  isImageMessage,
  className
}) => {
  const optionsRef = useRef<MessageOptionsRef>(null);
  const [selectedText, setSelectedText] = useState('');
  const [selectedUrl, setSelectedUrl] = useState('');

  const { chatSession } = useOmniChatContext();
  const customerId = chatSession.chat?.customerId;
  const isCustomer = data.senderId === customerId;

  const content = transformUrlsToLinks(data.content ?? '');
  const isUrls = !!data.metadata && !isImageMessage;
  const isHasSender = Boolean(sender && isFirstOfSender);

  const items: MenuProps['items'] = [
    {
      key: 'copy',
      label: selectedText.length > 0 ? internalChatMessages.copySelectedText : internalChatMessages.copy,
      onClick: async () => {
        try {
          const copyText = selectedText.length > 0 ? selectedText : (data.content ?? '');
          await navigator.clipboard.writeText(copyText);
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
      },
      icon: <CopyIcon />
    },
    ...(selectedUrl?.length > 0
      ? [
          {
            key: 'copy-link',
            label: internalChatMessages.copyLinkText,
            onClick: async () => {
              try {
                const copyText = selectedUrl;
                await navigator.clipboard.writeText(copyText);
              } catch (err) {
                console.error('Failed to copy text: ', err);
              }
            },
            icon: <CopyIcon />
          }
        ]
      : [])
  ];
  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'A') {
      const linkElement = target as HTMLAnchorElement;
      const url = linkElement.href;
      setSelectedUrl(url);
    } else {
      setSelectedUrl('');
    }
    const selection = getSelectedTextInDiv(e.currentTarget);
    setSelectedText(selection?.toString() ?? '');

    optionsRef.current?.open(e);
  };

  return (
    <div className='relative'>
      <div
        onContextMenu={handleContextMenu}
        className={twMerge(
          `relative min-w-[70px] whitespace-pre-wrap break-words rounded-lg px-3 pb-8 pt-2 text-left text-sm ${isUrls ? 'max-w-[336px]' : 'max-w-[300px]'} `,
          !isCustomer ? 'bg-colorBgMyMessage' : 'bg-colorBgCustomerMessage',
          data.isHidden && 'opacity-80',
          data.isDeleted && 'opacity-60 brightness-75',
          className
        )}
        style={{
          maxWidth: isUrls ? 336 : 556
        }}
      >
        {!isImageMessage && isHasSender && (
          <Typography.Paragraph
            style={{
              color: stringToHslColor(sender!.senderName, 80, 35)
            }}
            className='mb-2 text-sm font-semibold'
          >
            {sender!.senderName}
          </Typography.Paragraph>
        )}

        <div dangerouslySetInnerHTML={{ __html: content }}></div>

        {isUrls ? (
          <div>
            <MessageUrl data={data.metadata!} />
          </div>
        ) : null}

        <Typography.Paragraph className='absolute bottom-2 right-3 mb-0 select-none text-xs' type='secondary'>
          {dayjs(data.createdAt).format(TIME_FORMAT)}
        </Typography.Paragraph>
        <MessageOptions onForwardMessage={onForwardMessage} ref={optionsRef} items={items} message={data} />
      </div>
      {/* <div className={twMerge('absolute bottom-[3px] left-2')}>
        <MessageActions data={data} />
      </div> */}
    </div>
  );
};

export default memo(OmniMessageText);
