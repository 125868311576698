import { Badge, Button } from 'antd';
import { BellIcon } from 'assets';
import { useNotificationsUnreadCount } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import {
  connectNotificationSocket,
  disconnectNotificationSocket,
  offReceiveNotification,
  onReceiveNotification
} from 'services';
import { twMerge } from 'tailwind-merge';
import NotificationDrawer, { NotificationDrawerRefProps } from './NotificationDrawer';
import { NOTIFICATION_TYPE, showNewNotificationFileDownload } from 'utils';

type NotificationsManageProps = {};
const NotificationsManage: React.FC<NotificationsManageProps> = () => {
  const { data: notificationUnread } = useNotificationsUnreadCount();
  const [notificationOpen, setNotificationOpen] = useState(false);
  useEffect(() => {
    connectNotificationSocket();
    onReceiveNotification();
    return () => {
      disconnectNotificationSocket();
      offReceiveNotification();
    };
  }, []);

  const notificationRef = useRef<NotificationDrawerRefProps>(null);

  useEffect(() => {
    onReceiveNotification((data) => {
      if (data.type === NOTIFICATION_TYPE.FILE) {
        showNewNotificationFileDownload(data, () => {
          setNotificationOpen(true);
          if (notificationRef) notificationRef.current?.setActiveKey('2');
        });
      }

      if (data.type === NOTIFICATION_TYPE.TICKET) {
        const playSound = () => {
          const audio = new Audio('/sound/new-internal-message.mp3');
          audio.play().catch((error) => console.error('Error playing sound:', error));
        };

        playSound();
      }
    });
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          setNotificationOpen(true);
        }}
        icon={
          <Badge
            prefixCls='header-badge'
            offset={[-5, 5]}
            dot={!!notificationUnread}
            size='small'
            count={notificationUnread}
          >
            <BellIcon height={20} width={20} />
          </Badge>
        }
        type='text'
        shape='circle'
        className={twMerge('h-10 w-10', notificationOpen && 'active-btn')}
      />
      <NotificationDrawer
        ref={notificationRef}
        onCancel={() => {
          setNotificationOpen(false);
        }}
        open={notificationOpen}
      />
    </>
  );
};
export default NotificationsManage;
