import { useProfile } from 'hooks';
import React, { createContext, useEffect, useState } from 'react';
import { connectOmniChannelSocket, disconnectOmniChannelSocket, onReceiveCallDialAnswerEvent, onReceiveCallDialingEvent, onReceiveCallHangUpEvent, sendUpdateAgentMapNotification, useLazyGetAgentStatusQuery, useReadOmniChatMessagesMutation } from 'services';
import {
  AgentStatusDto,
  OmniGateWayQueueDto
} from 'types';
import { AGENT_STATUS } from 'utils';

export type OmniCallContextProps = {
  socketData: any,
  agentStatus: any,
  setGetAgentStatus: any,
  getAgentStatus: any
};

export const OmniCallContext = createContext<OmniCallContextProps | undefined>(undefined);

export const OmniCallProvider: React.FC<{
  children: React.ReactNode;

}> = ({ children }) => {
  const { profile } = useProfile();
  const [socketData, setSocketData] = useState()
  const [showPopUpReceiveCall, setShowPopUpReceiveCall] = useState(false)
  const [customerNumber, setCustomerNumber] = useState('')
  const [fetchAgentStatus, { data: agentStatusRes, error, isLoading }] = useLazyGetAgentStatusQuery();
  const handleFetchStatus = () => {
    if (profile?.extensionId) {
      fetchAgentStatus({ extension: profile.extensionId });
    }
  };
  const [getAgentStatus, setGetAgentStatus] = useState({
    worldPhoneState: AGENT_STATUS.UNKNOWN,
    agentState: AGENT_STATUS.AGENT_PAUSED
  })
  const extractAgentStatus = (agentStatusArr: AgentStatusDto[]) => {
    const isAgentReady = agentStatusArr[0]?.queues?.queue?.some(
      (queueMemberStatus: OmniGateWayQueueDto) => queueMemberStatus?.queueMemberPaused
    )
      ? AGENT_STATUS.AGENT_PAUSED
      : AGENT_STATUS.AGENT_UNPAUSED;
    const worldPhoneState = agentStatusArr[0].state

    setGetAgentStatus({
      worldPhoneState: worldPhoneState as AGENT_STATUS,
      agentState: isAgentReady
    })
  }

  useEffect(() => {
    handleFetchStatus()
  }, [])


  useEffect(() => {
    connectOmniChannelSocket();
    return () => {
      disconnectOmniChannelSocket();
    };
  }, []);


  useEffect(() => {
    handleFetchStatus()
    // onReceiveCallDialAnswerEvent((data) => {
    //   console.log('vào dialanswer')

    //   console.log(data)
    //   if (data?.callUuid && data?.customerNumber) {
    //     setShowPopUpReceiveCall(true)
    //     setCustomerNumber(data?.customerNumber)
    //     setSocketData(data)
    //   }
    // });
    onReceiveCallDialingEvent((data: any) => {

      if (data?.callUuid && data?.customerNumber) {
        setShowPopUpReceiveCall(true)
        setCustomerNumber(data?.customerNumber)
        setSocketData(data)
        console.log(data?.userExtension, AGENT_STATUS.RINGING, 'hehehe')
        sendUpdateAgentMapNotification({ extensionId: data?.userExtension, nextState: AGENT_STATUS.RINGING })

      }
    });
    onReceiveCallDialAnswerEvent((data: any) => {
      setSocketData(data)
    });
    onReceiveCallHangUpEvent((data: any) => {
      setSocketData(data)
    });
  }, [socketData]);
  return (
    <OmniCallContext.Provider
      value={{
        socketData,
        agentStatus: agentStatusRes?.data[0]?.state,
        setGetAgentStatus,
        getAgentStatus
      }}
    >
      {/* <Spin className='max-h-full overflow-hidden' spinning={isLoading || isFetching}> */}
      {children}
      {/* </Spin> */}
    </OmniCallContext.Provider>
  );
};
export default OmniCallProvider;
