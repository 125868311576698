import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersApi } from 'services';

interface AuthSoftPhoneState {
  isAuthenticated?: boolean;
  agentStatus: string;
  softPhoneToken?: string;
}

const initialState: AuthSoftPhoneState = {
  isAuthenticated: false,
  agentStatus: '',
  softPhoneToken: ''
};

const authSoftPhoneSlice = createSlice({
  name: 'authSoftPhone',
  initialState,
  reducers: {
    setAuthenticatedSoftPhone: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setSoftPhoneToken: (state, action: PayloadAction<string>) => {
      state.softPhoneToken = action.payload;
    },
    setAgentStatus: (state, action: PayloadAction<string>) => {
      state.agentStatus = action.payload;
    }
  }
});

export const { setAuthenticatedSoftPhone, setSoftPhoneToken, setAgentStatus } = authSoftPhoneSlice.actions;

export default authSoftPhoneSlice.reducer;
