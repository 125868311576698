import { usePaging } from "hooks/usePaging";
import { useGetTicketSamplesActiveQuery } from "services";
import { FindAllTicketSampleActiveDto } from "types";
import { DEFAULT_PAGE_SIZE } from "utils";
const pageSize = DEFAULT_PAGE_SIZE;
export function useTicketSampleActive(params: Omit<FindAllTicketSampleActiveDto, 'pageIndex' | 'pageSize'>) {
    const {hotkey, serviceTypeId} = params;
    const {pageIndex, changePage, resetPage} = usePaging(params);
    const {
        data: ticketSampleActiveResponse,
        error,
        isLoading,
        isFetching
    } = useGetTicketSamplesActiveQuery ({
        pageIndex: pageIndex,
        pageSize,
        hotkey,
        serviceTypeId,
    });
    const handleChangePage = changePage;

    return {
        ticketSampleActive: ticketSampleActiveResponse?.data.rows,
        count: ticketSampleActiveResponse ? ticketSampleActiveResponse.data.count : 0,
        pageIndex,
        isLoading: isFetching || isLoading,
        error,
        handleChangePage,
        resetPage
    };
}