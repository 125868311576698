import { useGetCustomerCountByFullTextQuery } from 'services';
import { FulltextSearchCustomerDto } from 'types/dto/customer-lookup';

export function useCustomersCountFulltext(params: Omit<FulltextSearchCustomerDto, 'pageIndex' | 'pageSize'>) {
  const {
    customerName,
    customerAddress,
    meterSerialNumber,
    customerCode,
    routeCode,
    meterPointCode,
    organizationUnitCode,
    wardId,
    districtId,
    customerPhoneNumber,
    keyword
  } = params;

  const shouldFetch = !!(
    customerName ||
    customerAddress ||
    meterSerialNumber ||
    customerCode ||
    routeCode ||
    meterPointCode ||
    organizationUnitCode ||
    wardId ||
    districtId ||
    customerPhoneNumber ||
    keyword
  );

  const { data: count } = useGetCustomerCountByFullTextQuery(params, {
    skip: !shouldFetch
  });

  return {
    count: shouldFetch ? count?.data || 0 : 0
  };
}
