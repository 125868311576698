import React, { useState, useEffect, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartOptions } from 'chart.js';
import { Table, TableProps, Typography } from 'antd';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useOmniChatSessionsProcessingList, useOmniChatSessionsWaitingList } from 'hooks';
import { CountChatSessionResultDto, GetCallDetailsDto } from 'types';
import { ConsoleSqlOutlined } from '@ant-design/icons';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    ChartDataLabels // Registering the plugin
);

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

type ChartDataItem = {
    status: string;
    count: number;
    color: string;
};

// Updated interface with new field names
interface CallStatSource {
    callSource: string;
    incomingCalls: number;
    waitingForAgent: number;
    isTalking: number;
    agentReceive: number;
    botReceive: number;
    missedCall: number;
    totalCall: number;
}

// Status-based interface (transposed structure)
interface CallStatByStatus {
    status: string;
    [source: string]: string | number; // Dynamic columns for each source
}

// Mapping from field names to display names
const fieldToDisplayName = {
    'incomingCalls': 'Đang gọi vào',
    'waitingForAgent': 'Đang chờ gặp ĐTV',
    'isTalking': 'Đang đàm thoại',
    'agentReceive': 'ĐTV đã tiếp nhận',
    'botReceive': 'Bot đã tiếp nhận',
    'missedCall': 'Gọi nhỡ',
    'totalCall': 'Đã gọi đến tổng đài',
};

// And the reverse mapping for reference
// const displayNameToField = {
//     'Đang gọi vào': 'incomingCalls',
//     'Đang chờ gặp ĐTV': 'waitingForAgent',
//     'Đang đàm thoại': 'isTalking',
//     'ĐTV đã tiếp nhận': 'agentReceive',
//     'Bot đã tiếp nhận': 'botReceive',
//     'Gọi nhỡ': 'missedCall',
//     'Đã gọi đến tổng đài': 'totalCall',
//     'Tổng': 'totalCallAllSource',
// };

// Mapping for source display names
const sourceDisplayNames: Record<string, string> = {
    'telco': 'VNPT',
    'webcall': 'WEBCALL',
    'appcall': 'APPCALL',
    'voice': 'VOICE',
    'video': 'VIDEO',
};

// Function to get display name for a source (with fallback to uppercase)
const getSourceDisplayName = (source: string): string => {
    return sourceDisplayNames[source.toLowerCase()] || source.toUpperCase();
};

interface DashboardProps {
    chartData: ChartDataItem[];
    callStatsBySource?: GetCallDetailsDto[];
    getChatSessionDetails?: CountChatSessionResultDto;
    totalAgents?: number; // Add new prop for total agents count
}

// Original call stats structure
// const callStats = [
//     { category: 'Đang gọi vào', total: 120, webCall: 1020, appCall: 1020, vnpt: 1020, voice: 1020, video: 1020 },
//     { category: 'Đang chờ gặp DTV', total: 1.02, webCall: 1020, appCall: 1020, vnpt: 1020, voice: 1020, video: 1020 },
//     { category: 'Đang đàm thoại', total: 1.02, webCall: 1020, appCall: 1020, vnpt: 1020, voice: 1020, video: 1020 },
//     { category: 'DTV đã tiếp nhận', total: 1.02, webCall: 1020, appCall: 1020, vnpt: 1020, voice: 1020, video: 1020 },
//     { category: 'BOT đã tiếp nhận', total: 1.02, webCall: 1020, appCall: 1020, vnpt: 1020, voice: 1020, video: 1020 },
//     { category: 'Gọi nhỡ', total: 21, webCall: 1020, appCall: 1020, vnpt: 1020, voice: 1020, video: 1020 },
//     { category: 'Đã gọi đến tổng đài', total: 1.02, webCall: 1020, appCall: 1020, vnpt: 1020, voice: 1020, video: 1020 },
// ];



const allExpectedSources = ['telco', 'webcall', 'appcall', 'voice', 'video'];

// Function to transform data from source-based to status-based (transpose)
const transformCallStatsBySource = (sourceBasedData: GetCallDetailsDto[]): CallStatByStatus[] => {
    // All field names (excluding call_source)
    const fields = [
        'incomingCalls',
        'waitingForAgent',
        'isTalking',
        'agentReceive',
        'botReceive',
        'missedCall',
        'totalCall',
    ];

    // Get all sources from the data
    const actualSources = sourceBasedData.map(item => item.callSource);

    // Combine actual sources with expected sources for complete coverage
    const allSources = Array.from(new Set([...actualSources, ...allExpectedSources]));

    // Transform data structure
    return fields.map(field => {
        // Create a row with the display name as status
        const row: CallStatByStatus = {
            status: fieldToDisplayName[field as keyof typeof fieldToDisplayName] || field
        };

        // Initialize all sources with 0 and prepare for total calculation
        let rowTotal = 0;

        allSources.forEach(source => {
            if (source) {
                row[source] = 0;
            }
        });

        // Add values from actual data (replacing defaults where data exists)
        sourceBasedData.forEach(sourceData => {
            // Access the value using the field name
            const value = sourceData[field as keyof CallStatSource];

            // Only replace if value is defined and not null
            if (value !== undefined && value !== null && sourceData.callSource) {
                row[sourceData.callSource] = value;
                // Add to running total
                rowTotal += Number(value);
            }
        });

        // Add total column
        row['total'] = rowTotal;

        return row;
    });
};

const Dashboard: React.FC<DashboardProps> = ({
    chartData,
    callStatsBySource,
    getChatSessionDetails,
    totalAgents = 0 // Default to 0 if not provided
}) => {
    // Memoize any derived state to prevent unnecessary recalculations
    const memoizedChartData = useMemo(() => chartData, [chartData]);

    // Use memoized data for table data source to prevent unnecessary renders
    const dataSource = useMemo(() => {
        return memoizedChartData.map((item, index) => ({
            key: index.toString(),
            status: item.status,
            count: item.count,
            color: item.color
        }));
    }, [memoizedChartData]);

    // Define columns outside of render to avoid recreating on each render
    const columns = [
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: any) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        style={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: record.color,
                            marginRight: 8,
                        }}
                    />
                    <Typography.Text>{text}</Typography.Text>
                </div>
            ),
        },
        {
            title: 'Số lượng',
            dataIndex: 'count',
            key: 'count',
        },
    ];

    // Transform from source-based to status-based structure
    const callStatsByStatus = transformCallStatsBySource(callStatsBySource || []);

    // Generate dynamic columns based on available sources
    // Update the render function in getCallStatColumns
    const getCallStatColumns = (): TableProps<CallStatByStatus>['columns'] => {
        // Use all expected sources rather than just those present in the data
        const allSources = Array.from(new Set([
            ...(callStatsBySource ? callStatsBySource.map(item => item.callSource) : []),
            ...allExpectedSources
        ]));

        // Base column for status
        const columns: TableProps<CallStatByStatus>['columns'] = [
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                className: 'text-left', // Keep this left-aligned
                render: (text: string) =>
                    text === 'Gọi nhỡ' ?
                        <span className="text-red-500 font-bold">{text}</span> :
                        text
            }
        ];

        // Add a column for each source
        allSources.forEach(source => {
            if (source) { // Skip undefined sources
                columns.push({
                    title: getSourceDisplayName(source),
                    dataIndex: source,
                    key: source,
                    className: 'text-center', // Center this column
                    render: (value: any, record) => {
                        // Ensure value is a primitive
                        const displayValue = typeof value === 'object' ?
                            JSON.stringify(value) :
                            value === undefined || value === null ? 0 : value;

                        return record.status === 'Gọi nhỡ' ?
                            <span className="text-red-500 font-bold">{displayValue}</span> :
                            displayValue;
                    }
                });
            }
        });

        // Add Total column at the end
        columns.push({
            title: 'Tổng',
            dataIndex: 'total',
            key: 'total',
            className: 'font-bold text-center', // Center this column and make bold
            render: (value: any, record) => {
                const displayValue = typeof value === 'object' ?
                    JSON.stringify(value) :
                    value === undefined || value === null ? 0 : value;

                return record.status === 'Gọi nhỡ' ?
                    <span className="text-red-500 font-bold">{displayValue}</span> :
                    <span className="font-semibold">{displayValue}</span>;
            }
        });

        return columns;
    };
    // Generate the transposed columns
    const callStatStatusColumns = getCallStatColumns();

    const barData = {
        labels: chartData.map((item) => item.status),
        datasets: [
            {
                label: 'Số lượng',
                data: chartData.map((item) => item.count),
                backgroundColor: chartData.map((item) => item.color),
            },
        ],
    };

    const barOptions: ChartOptions<"bar"> = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
            legend: { display: false },
            datalabels: {
                anchor: "start",
                align: "left",
                color: "black",
                font: { weight: "bold" },
                offset: 0,
            }
        },
        scales: {
            x: {
                grid: { display: false },
                ticks: { display: false },
            },
            y: {
                grid: { display: false },
                ticks: {
                    color: "#181818",
                    padding: 20,
                },
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 50,
                top: 10,
                bottom: 10,
            },
        },
        elements: {
            bar: {
                borderRadius: 5,
                borderSkipped: "start",
            },
        },
    };

    return (
        <div className="bg-white p-4 rounded-lg">
            {/* New Call Stats Table - Transposed (Status as rows, Sources as columns) */}
            <div className="mb-6 border-b">
                <h2 className="text-lg font-semibold mb-3">Cuộc gọi hôm nay</h2>
                <Table<CallStatByStatus>
                    columns={callStatStatusColumns}
                    dataSource={callStatsByStatus}
                    pagination={false}
                    rowKey="status"
                    size="small"
                    className="no-borders text-center" // Add text-center class to center content
                    bordered={false}
                    onRow={() => ({
                        style: { borderBottom: 'none' }
                    })}
                    components={{
                        header: {
                            cell: (props: React.HTMLProps<HTMLTableCellElement>) => <th {...props} className="text-center" style={{ textAlign: 'center' }} /> // Center header cells
                        },
                        body: {
                            row: (props: React.HTMLProps<HTMLTableRowElement>) => <tr {...props} style={{ border: 'none', borderBottom: 'none' }} />,
                            cell: (props: React.HTMLProps<HTMLTableCellElement>) => <td {...props} style={{ border: 'none', borderBottom: 'none', textAlign: 'center' }} /> // Center data cells
                        }
                    }}
                />
            </div>

            {/* Agent Status Chart using Chart.js */}
            <div className="mb-6 border-b">
                <div className="flex justify-between items-center mb-3">
                    <h2 className="text-lg font-semibold">Trạng thái Điện thoại viên (Tổng : {totalAgents})</h2>
                </div>
                <div className="w-full chart-container h-64">
                    <Bar data={barData} options={barOptions} />
                </div>
            </div>
            <Typography.Text className="text-lg font-semibold">Chat hôm nay</Typography.Text>
            {/* Chat Stats */}
            <div className="grid grid-cols-3 gap-4 mt-4">
                <div className="bg-white w-[192px] h-[90px] shadow rounded-lg flex flex-col justify-center pl-4">
                    <Typography.Text className="text-sm text-left text-[#868D97]">Đang chờ</Typography.Text>
                    <Typography.Text className="text-2xl font-bold text-gray-900 text-left">{getChatSessionDetails?.totalChatSessionWaiting || 0}</Typography.Text>
                </div>
                <div className="bg-white w-[192px] h-[90px] shadow rounded-lg flex flex-col justify-center pl-4">
                    <Typography.Text className="text-sm text-left text-[#868D97]">Đang xử lý</Typography.Text>
                    <Typography.Text className="text-2xl font-bold text-gray-900 text-left">{getChatSessionDetails?.totalChatSessionProcessing || 0}</Typography.Text>
                </div>
                <div className="bg-white w-[192px] h-[90px] shadow rounded-lg flex flex-col justify-center pl-4">
                    <Typography.Text className="text-sm text-left text-[#868D97]">Đã xử lý</Typography.Text>
                    <Typography.Text className="text-2xl font-bold text-gray-900 text-left">{getChatSessionDetails?.totalChatSessionCompleted || 0}</Typography.Text>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Dashboard);