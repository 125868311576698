import { useGetAgentsQuery } from 'services';
import { FindWithKeywordDto, UseQueryOptionsType } from 'types';
export function useAgents(params: FindWithKeywordDto, options?: UseQueryOptionsType) {
  const { keyword, endpoint } = params;

  const {
    data: dataResponse,
    isLoading,
    isFetching,
    refetch
  } = useGetAgentsQuery(
    {
      keyword,
      endpoint
    },
    {
      ...options
    }
  );

  const { rows, count } = dataResponse?.data || {};

  return {
    agents: rows || [],
    count: count || 0,
    isFetching,
    isLoading,
    refetch
  };
}
