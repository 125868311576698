export enum TRACKING_TICKET_PROCESSING_OPTION {
  TECHNICAL_IN_PROGRESS= 81,
  TECHNICAL_IN_PROGRESS_OVERDUE= 82,
  TECHNICAL_RECEPTION= 83,
  TECHNICAL_RECEPTION_OVERDUE= 84,
  TECHNICAL_COMPLETED= 85,
  BUSINESS_IN_PROGRESS = 86,
  BUSINESS_IN_PROGRESS_OVERDUE = 87,
  BUSINESS_RECEPTION = 88,
  BUSINESS_RECEPTION_OVERDUE = 89,
  BUSINESS_COMPLETED = 90
}

export enum TICKET{
  DEFAULT_PAGE_SIZE = 20,
  DEFAULT_PAGEINDEX = 1,
}