import { Button, Typography } from 'antd';
import {
    IncallAvatarIcon,
    MicrophoneIcon,
    PhoneFilledIcon,
    PhoneMuteIcon
} from 'assets';
import { useProfile } from 'hooks';
import { customerLookupMessages } from 'messages/customer-lookup.message';
import { useEffect, useState, useRef } from 'react';
import { useLazyGetAgentMapSeatByUserIdQuery } from 'services';
import { AGENT_STATUS } from 'utils';

interface InCallProps {
    isMinimized: boolean;
    socketData: { customerNumber?: string; callSource?: string };
    currentState: string;
    hangUpSoftPhone: () => void;
    muteSoftPhone: (mute: boolean) => void;
    getSourceDisplayName: (source: string) => string;
    isMuted?: boolean; // New prop to indicate if call is muted
}

const InCallContent: React.FC<InCallProps> = ({
    isMinimized,
    socketData,
    currentState,
    hangUpSoftPhone,
    muteSoftPhone,
    getSourceDisplayName,
    isMuted = false // Default to unmuted
}) => {
    const [seconds, setSeconds] = useState(0);
    const { profile } = useProfile();
    const [fetchSeat] = useLazyGetAgentMapSeatByUserIdQuery();
    const initializedRef = useRef(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    // Only fetch and initialize timer once when component mounts
    useEffect(() => {
        if (!initializedRef.current && profile?.userId) {
            initializedRef.current = true;

            fetchSeat(profile.userId).unwrap().then((res) => {
                const { data } = res;
                if (data.statusName && data.agentChangeStatusDate) {
                    const elapsedSeconds = Math.floor((Date.now() - new Date(data.agentChangeStatusDate).getTime()) / 1000);
                    setSeconds(Math.max(0, elapsedSeconds)); // Ensure non-negative
                }
            }).catch(err => {
                console.error("Error fetching seat data:", err);
            });
        }
    }, [profile?.userId, fetchSeat]);

    // Run timer continuously regardless of mute state changes
    useEffect(() => {
        // Clear any existing interval
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        // Start new interval if in ONCALL or MUTE_ON state
        if (currentState === AGENT_STATUS.ONCALL || currentState === AGENT_STATUS.MUTE_ON) {
            timerRef.current = setInterval(() => {
                setSeconds(prev => prev + 1);
            }, 1000);
        }

        return () => {
            // Clean up on component unmount
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        };
    }, [currentState]); // Only depend on currentState, not isMuted

    // Convert seconds to MM:SS format
    const formatTime = (time: number) => {
        const minutes = Math.floor((time % 3600) / 60);
        const secs = time % 60;
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    // Handle mute/unmute button click
    const toggleMute = () => {
        muteSoftPhone(!isMuted);
    };

    // Set button style based on mute state
    const micButtonStyle = {
        borderColor: "#EBEBED",
        backgroundColor: isMuted ? "#FFFFFF" : "#222A41"
    };

    // Determine which microphone icon to show based on mute state
    const MicIcon = isMuted ? MicrophoneIcon : MicrophoneIcon;

    return isMinimized ? (
        <div className="w-[400px] flex h-[244px] flex-col rounded-tl-xl bg-[#222A41]">
            <div className="m-5 flex flex-col gap-10">
                <Typography.Text className="text-left text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    {getSourceDisplayName(socketData?.callSource || 'VNPT')} ⮕ EXT #{profile?.extensionId || ''} - {profile?.shortName || ''}
                </Typography.Text>
                <div className="flex flex-row items-center gap-[45px]">
                    <div className="flex flex-col gap-2 mr-12">
                        <Typography.Text className="text-left text-[24px]/[32px] font-semibold tracking-normal text-[#FFFFFF] opacity-100">
                            {socketData?.customerNumber}
                        </Typography.Text>
                        <Typography.Text className="text-left text-[12px]/[16px] italic tracking-normal text-[#FFFFFF] opacity-100">
                            {customerLookupMessages.isTalking}
                        </Typography.Text>
                        <Typography.Text className="text-left text-[14px] font-semibold tracking-normal text-[#FFFFFF]">
                            {formatTime(seconds)} ⏳
                        </Typography.Text>
                    </div>
                    <div className="flex flex-row gap-2">
                        <Button
                            style={{ borderColor: "transparent" }}
                            shape="circle"
                            className="h-[56px] w-[56px] bg-[#D1131D] bg-clip-padding bg-no-repeat opacity-100"
                            icon={<PhoneFilledIcon color='#FFFFFF' fill='#FFFFFF' className="h-[28px] w-[28px]" />}
                            onClick={hangUpSoftPhone}
                        />
                        <Button
                            style={micButtonStyle}
                            shape="circle"
                            className="h-[56px] w-[56px] bg-clip-padding bg-no-repeat opacity-100"
                            icon={<MicIcon className="h-[28px] w-[28px]" style={{ color: isMuted ? '#222A41' : '#FFFFFF' }} />}
                            onClick={toggleMute}
                        />
                    </div>
                </div>
                <Typography.Text className="text-left text-[16px]/[20px] italic tracking-normal text-[#FFFFFF] opacity-70">
                    {customerLookupMessages.customerWaiting}
                </Typography.Text>
            </div>
        </div>
    ) : (
        <div className="flex h-full w-[400px] flex-col rounded-l-xl bg-[#222A41]">
            <div className="m-5 flex flex-col items-center justify-center">
                <Typography.Text className="text-center text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    {getSourceDisplayName(socketData?.callSource || 'VNPT')} ⮕ EXT #{profile?.extensionId || ''} - {profile?.shortName || ''}
                </Typography.Text>
                <IncallAvatarIcon className="mt-[52px]" />
                <Typography.Text className="mt-8 text-center text-[24px]/[32px] font-semibold tracking-normal text-[#FFFFFF] opacity-100">
                    {socketData?.customerNumber}
                </Typography.Text>
                <Typography.Text className="mt-1 text-center text-[12px]/[16px] italic tracking-normal text-[#FFFFFF] opacity-100">
                    {customerLookupMessages.isTalking}
                </Typography.Text>
                <Typography.Text className="mt-1 text-center text-[14px] font-semibold tracking-normal text-[#FFFFFF]">
                    {formatTime(seconds)} ⏳
                </Typography.Text>
                <Typography.Text className="mt-4 text-center text-[16px]/[20px] italic tracking-normal text-[#FFFFFF] opacity-70">
                    {customerLookupMessages.customerWaiting}
                </Typography.Text>
                <div className="flex flex-row gap-2">
                    <Button
                        style={{ borderColor: "transparent" }}
                        shape="circle"
                        className="h-[56px] w-[56px] bg-[#D1131D] bg-clip-padding bg-no-repeat opacity-100"
                        icon={<PhoneFilledIcon color='#FFFFFF' fill='#FFFFFF' className="h-[28px] w-[28px]" />}
                        onClick={hangUpSoftPhone}
                    />
                    <Button
                        style={micButtonStyle}
                        shape="circle"
                        className="h-[56px] w-[56px] bg-clip-padding bg-no-repeat opacity-100"
                        icon={<MicIcon className="h-[28px] w-[28px]" style={{ color: isMuted ? '#222A41' : '#FFFFFF' }} />}
                        onClick={toggleMute}
                    />
                </div>
            </div>
        </div>
    );
};

export default InCallContent;