export const sidebarMenuMessages = {
  usersManagement: 'Quản lý tài khoản',
  vipCustomers: 'Khách hàng VIP',
  customerCare: 'Chăm sóc khách hàng',
  requestForm: 'Xử lý yêu cầu',
  callCenterManagement: 'Quản lý điện thoại viên',
  reports: 'Báo cáo',
  management: 'Quản lý',
  /** Quản lý */
  internalAnnouncements: 'Thông báo nội bộ',
  vipCustomer: 'Khách hàng VIP',
  agentMaps: 'Sơ đồ chỗ ngồi',
  agentStatus: 'Trạng thái ĐTV',
  agentShift: 'Ca trực ĐTV',
  workingSchedules: 'Lịch nghỉ lễ làm bù',
  serviceInstructions: 'Thông tin hướng dẫn',
  customerSupportInformation: 'Thông tin hỗ trợ khách hàng',
  frequentlyAskedQuestions: 'Thông tin hỏi đáp KB',
  fanpageConnection: 'Mạng xã hội/live chat',
  ticketCancelReasons: 'Lý do hủy',
  busyReasons: 'Lý do bận của điện thoại viên',

  leaderShift: 'Trưởng ca',

  system: 'Hệ thống',
  /** Hệ thống */
  users: 'Người dùng',
  userGroups: 'Nhóm người dùng',
  permissions: 'Quản lý quyền',
  organizationUnit: 'Cơ cấu tổ chức',
  serviceTypes: 'Loại dịch vụ',
  ticketSamples: 'Mẫu tạo phiếu',
  systemConfigs: 'Kết nối bên thứ ba',
  profile: 'Hồ sơ tài khoản',

  internalAnnouncement: 'Tin nội bộ',
  apiSharingPartners: 'Cấu hình chia sẻ API',
  empty: 'Trống',
  organizationUnitTicketDefaultAssigns: 'Nhân sự xử lý phiếu',
  customerSupport: 'Hỗ trợ khách hàng',

  connectManagement: 'Quản lý kết nối',
  configManagement: 'Quản lý cấu hình',
  operationsManagement: 'Quản lý vận hành',
  shiftSupervisor: 'Giám sát ca trực',
  dashboardAgentMap: 'Dashboard',
  trackTicketProcessing: 'Theo dõi xử lý phiếu',
  coordinationRequired: 'Yêu cầu cần phối hợp',
  moveCenter: 'Chuyển trung tâm',
  transferCustomerServiceTeam: 'Chuyển Đội CSKH',
  moveTechnicalDepartment: 'Chuyển phòng KT',
  followupRequests: 'Yêu cầu cần theo dõi',
  incorrectMeasurementPointAddress: 'Điểm đo sai địa chỉ',
  confirmReflectionRequest: 'Xác nhận YC phản ánh',
  collectPayment: 'Thu hộ',
  sms: 'SMS',
  explainReport: 'Giải trình báo cáo',
  userGuide: 'Hướng dẫn sử dụng',
  convertUnitRoomTeam: 'Chuyển đổi đơn vị/phòng/đội',
  customerSearch: 'Tra cứu khách hàng',
};
