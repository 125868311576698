import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { setReplyOmniMessage } from 'store/features';
import { OmniChannelMessageDto } from 'types';

export function useOmniChatReplyMessage() {
  const dispatch = useDispatch();

  const messageReplied = useSelector((state: RootState) => state.omniChat.replyOmniMessage);

  const handleReplyOmniMessage = (message: OmniChannelMessageDto) => {
    dispatch(setReplyOmniMessage(message));
  };

  const handleRemoveOmniReplyMessage = () => {
    dispatch(setReplyOmniMessage(undefined));
  };

  return { messageReplied, handleReplyOmniMessage, handleRemoveOmniReplyMessage };
}
