import { ChatIcon, PhoneFilledIcon } from "assets";
import { useOmniCallContext } from "contexts";
import { useGetAgentStatusChange } from "hooks";
import { useEffect, useState, useRef, useCallback, memo, useMemo } from "react";
import { connectNotificationSocket, disconnectNotificationSocket, offReceiveNotification, onReceiveAgentUpdateNotification, useLazyGetAgentMapDetailQuery, useLazyGetAgentStatusQuery, useLazyListQueueQuery } from "services";
import { AgentMapDto, AgentStatusDto, UpdateAgentMapSocketDto } from "types";
import { AGENT_STATUS, CALLSTATUS } from "utils";
import { useAgentStatusSocket } from 'hooks/useAgentStatusSocket';
import { AgentTotalCallAndChatCounter } from "types/dto/agent-maps/agent-total-call-and-chat-counter.dto";

interface AgentStatusDisplay {
    backgroundColor: string;
    iconColor: string;
    textColor: string;
}

export type SingleAgentMapSingleStatusesType = {
    extension: string;
    agentName: string;
    state: string;
    backgroundColor: string;
    iconColor: string;
    textColor: string;
};
interface SeatProps {
    seat: any;
    seconds: number;
    mapAgentStatus: any;
    mapAgentStatusToDisplay: any;
    singleAgentMapAgentStatuses: any;
    formatTime: any;
    seatStatusName: string | undefined;
    showCounters: boolean; // Add this prop
    extensionCounters: Record<string, { call: number, chat: number }>; // Add this prop
}

// Get the alert threshold from environment variables (default to 5 minutes)
const ALERT_THRESHOLD_MINUTES = parseInt(process.env.REACT_APP_AGENT_STATUS_ALERT_THRESHOLD_MINUTES || '5', 10);
const ALERT_THRESHOLD_SECONDS = ALERT_THRESHOLD_MINUTES * 60;

// 2. Add a deep equality comparison for Seat component
const Seat = memo(({ seat, seconds, mapAgentStatus, mapAgentStatusToDisplay, singleAgentMapAgentStatuses, formatTime, seatStatusName, showCounters, extensionCounters }: SeatProps) => {
    // Find the agent status based on extensionId
    let agentStatus = mapAgentStatus(seatStatusName);

    // Find this seat's status in the status list using memoization
    const currentAgent = useMemo<SingleAgentMapSingleStatusesType | undefined>(() =>
        singleAgentMapAgentStatuses.find(
            (agent: SingleAgentMapSingleStatusesType) => agent.extension === seat.extensionId
        ),
        [singleAgentMapAgentStatuses, seat.extensionId]
    );

    // Get the status display properties with fallback
    const statusDisplay = mapAgentStatusToDisplay(agentStatus);
    const backgroundColor = currentAgent?.backgroundColor || statusDisplay.backgroundColor;
    const textColor = currentAgent?.textColor || statusDisplay.textColor;
    const iconColor = currentAgent?.iconColor || statusDisplay.iconColor;

    // Get current timer value
    const displayTime = useMemo(() => formatTime(seconds), [seconds]);

    // Check if we should show the alert indicator
    const showAlert = seconds >= ALERT_THRESHOLD_SECONDS;

    // Get counter values with proper fallback to seat values first, then extensionCounters
    // This prioritizes the values from the seat object over the extensionCounters
    const callCount = extensionCounters[seat.extensionId]?.call || 0;
    const chatCount = extensionCounters[seat.extensionId]?.chat || 0;

    // Debug output
    useEffect(() => {
        if (seat.extensionId) {
            console.log(`Seat ${seat.extensionId} rendering with call=${callCount}, chat=${chatCount}`);
        }
    }, [seat.extensionId, callCount, chatCount]);

    return (
        <div
            key={seat.agentMapSeatId}
            className="w-[88px] h-[88px] flex flex-col rounded-md shadow-md border border-[#B2B9C4] pt-2 pl-2 relative"
            style={{
                backgroundColor,
                color: textColor,
                transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out'
            }}
        >
            {/* Alert indicator - positioned correctly with white border */}
            {showAlert && (
                <div
                    className="absolute top-0 right-0 w-4 h-4 rounded-full bg-red-600"
                    style={{
                        boxShadow: '0 0 3px rgba(0,0,0,0.3)',
                        border: '1.5px solid white',  // Add white border
                        zIndex: 10
                    }}
                />
            )}

            {!showCounters ? (
                // Original layout when toggle is OFF
                <>
                    <div className="text-xs flex">
                        <div className="flex pb-1 pr-1">
                            <PhoneFilledIcon className="" height={16} width={16} style={{ fill: iconColor, transition: 'fill 0.2s ease-in-out' }} />
                        </div>
                        <div className="flex items-center">
                            <ChatIcon height={16} width={16} fill={iconColor} style={{ transition: 'fill 0.2s ease-in-out' }} />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xs">Ext.{seat.extensionId}</p>
                        <p className="text-xs">{seat.shortName}</p>
                        {seat.extensionId && <p className="text-xs">{displayTime}</p>}
                    </div>
                </>
            ) : (
                // New layout when toggle is ON - Move extension and name to top
                <>
                    <div className="flex flex-col">
                        <p className="text-xs">Ext.{seat.extensionId}</p>
                        <p className="text-xs">{seat.shortName}</p>
                    </div>
                    {/* Icon rows below agent name with more spacing */}
                    <div className="flex flex-col gap-1 mt-auto mb-2">
                        <div className="flex items-center">
                            <PhoneFilledIcon height={16} width={16} style={{ fill: iconColor }} />
                            <span className="ml-1 text-[12px]" style={{ color: textColor }}>
                                {callCount}
                            </span>
                        </div>
                        <div className="flex items-center">
                            <ChatIcon height={16} width={16} fill={iconColor} />
                            <span className="ml-1 text-[12px]" style={{ color: textColor }}>
                                {chatCount}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}, (prevProps, nextProps) => {
    // Check if timer changed
    if (prevProps.seconds !== nextProps.seconds) return false;

    // Check if status name changed
    if (prevProps.seatStatusName !== nextProps.seatStatusName) return false;

    // Check if showCounters changed
    if (prevProps.showCounters !== nextProps.showCounters) return false;

    // IMPORTANT: Always check counter values
    // Get counter values for this seat
    const prevCall = prevProps.extensionCounters[prevProps.seat.extensionId]?.call ?? 0;
    const nextCall = nextProps.extensionCounters[nextProps.seat.extensionId]?.call ?? 0;
    const prevChat = prevProps.extensionCounters[prevProps.seat.extensionId]?.chat ?? 0;
    const nextChat = nextProps.extensionCounters[nextProps.seat.extensionId]?.chat ?? 0;

    // Log counter changes
    if (prevCall !== nextCall || prevChat !== nextChat) {
        console.log(`Counter changed for ${prevProps.seat.extensionId}:`,
            { prevCall, nextCall, prevChat, nextChat });
        return false; // Force re-render if counters changed
    }

    // Remaining status checks
    // Check if the status for this extension ID changed in the status list
    const prevStatus = prevProps.singleAgentMapAgentStatuses.find(
        (s: SingleAgentMapSingleStatusesType) => s.extension === prevProps.seat.extensionId
    );
    const nextStatus = nextProps.singleAgentMapAgentStatuses.find(
        (s: SingleAgentMapSingleStatusesType) => s.extension === nextProps.seat.extensionId
    );

    // Only rerender if the status actually changed
    return !!(
        prevStatus &&
        nextStatus &&
        prevStatus.state === nextStatus.state &&
        prevStatus.backgroundColor === nextStatus.backgroundColor
    );
});

const MultipleAgentMap: React.FC<{
    multipleAgentMaps: AgentMapDto[],
    singleAgentMapAgentStatuses: SingleAgentMapSingleStatusesType[],
    seatTimers: Record<string, number>;
    updateSeatTimer: (extensionId: string, timestamp?: number) => void;
    showCounters: boolean; // Add this prop
    extensionCounters: Record<string, { call: number, chat: number }>; // Add this prop
}> = ({
    multipleAgentMaps,
    singleAgentMapAgentStatuses,
    seatTimers,
    updateSeatTimer,
    showCounters,
    extensionCounters
}) => {
        const [fetchAgentStatus] = useLazyGetAgentStatusQuery();

        const uniqueStatuses = [
            { status: "Không bật", backgroundColor: "#EBEBED", iconColor: '#B2B9C4', textColor: '#000000' },
            { status: "Đàm thoại", backgroundColor: "#D1131D", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
            { status: "Đang reo", backgroundColor: "#AF52DE", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
            { status: "Tạo phiếu", backgroundColor: "#1564E8", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
            { status: "Tạm nghỉ", backgroundColor: "#FF9500", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
            { status: "Sẵn sàng", backgroundColor: "#06A77D", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
        ];

        const mapAgentStatusToDisplay = useCallback((status: string): AgentStatusDisplay => {
            const statusInfo = uniqueStatuses.find(s => s.status === status);
            return statusInfo || { backgroundColor: "#FFFFFF", iconColor: "#B2B9C4", textColor: "#000000" };
        }, [uniqueStatuses]);

        const mapAgentStatus = useCallback((statusName: string | undefined): string => {
            let mappedStatus = "Không bật"; // Default status
            switch (statusName) {
                case "UNPAUSED":
                    mappedStatus = "Sẵn sàng";
                    break;
                case "PAUSED":
                    mappedStatus = "Tạm nghỉ";
                    break;
                case "RINGING":
                    mappedStatus = "Đang reo";
                    break;
                case "ONCALL":
                    mappedStatus = "Đàm thoại";
                    break;
                case "CREATING_TICKET":
                    mappedStatus = "Tạo phiếu";
                    break;
                case "FAILED_LOGIN":
                case "UNKNOWN":
                default:
                    mappedStatus = "Không bật";
                    break;
            }
            return mappedStatus;
        }, []);

        const formatTime = useCallback((totalSeconds: number): string => {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }, []);

        const previousAgentStatuses = useRef<Record<string, string>>({}); // Initialize the ref
        const isFirstRender = useRef<boolean>(true);
        const [updatedSeatIds, setUpdatedSeatIds] = useState<Set<string>>(new Set());

        // Initialize previous statuses on mount or when maps change
        useEffect(() => {
            if (multipleAgentMaps.length > 0) {
                // Initialize previous agent statuses
                const initialPreviousStatuses: Record<string, string> = {};

                multipleAgentMaps.forEach(agentMap => {
                    agentMap.seats?.forEach(seat => {
                        if (seat.extensionId) {
                            initialPreviousStatuses[seat.extensionId] = seat.statusName || "";
                        }
                    });
                });

                previousAgentStatuses.current = initialPreviousStatuses;
                isFirstRender.current = false;
            }
        }, [multipleAgentMaps]);

        // Update statuses and tell parent to update timers when needed
        useEffect(() => {
            // Only update if we have status data
            if (Object.keys(previousAgentStatuses.current).length === 0) return;

            const updatedSeats = new Set<string>();

            multipleAgentMaps.forEach(agentMap => {
                agentMap.seats?.forEach(seat => {
                    if (!seat.extensionId) return;

                    const currentStatus = seat.statusName || "";
                    const previousStatus = previousAgentStatuses.current[seat.extensionId] || "";

                    // Only update timer if status has changed
                    if (currentStatus !== previousStatus) {
                        updateSeatTimer(seat.extensionId);
                        previousAgentStatuses.current[seat.extensionId] = currentStatus;
                        updatedSeats.add(seat.extensionId); // Track which seats changed
                    }
                });
            });

            // Update the set of seats that need re-rendering
            if (updatedSeats.size > 0) {
                setUpdatedSeatIds(updatedSeats);
            }
        }, [multipleAgentMaps, updateSeatTimer]);

        // This effect triggers the display refresh
        const [_, setForceUpdate] = useState<number>(0);
        useEffect(() => {
            const displayInterval = setInterval(() => {
                setForceUpdate(prev => prev + 1);
            }, 1000);

            return () => clearInterval(displayInterval);
        }, []);

        // Use a more efficient way to track updates
        const [seatStatusUpdates, setSeatStatusUpdates] = useState<Record<string, number>>({});

        // Unified socket handling
        // Define an interface for the socket payload

        const handleAgentStatusUpdate = useCallback((payload: UpdateAgentMapSocketDto) => {
            console.log("🔄 MultipleMap received agent update:", payload);
            const { extensionId } = payload;
            if (extensionId) {
                // Only update the timestamp for the changed seat
                setSeatStatusUpdates(prev => ({
                    ...prev,
                    [extensionId]: Date.now()
                }));

                // Update timer
                updateSeatTimer(extensionId);
            }
        }, [updateSeatTimer]);

        // Add a debug counter update handler and state to track counter updates
        const [debugCounterUpdates, setDebugCounterUpdates] = useState<Record<string, number>>({});

        // Add this handler to debug the counter updates
        const handleCounterUpdate = useCallback((payload: AgentTotalCallAndChatCounter) => {
            console.log("🔢 MultipleMap received counter update:", payload);
            if (payload.extensionId && payload.counterType) {
                // For debugging - track when this component gets counter updates
                setDebugCounterUpdates(prev => ({
                    ...prev,
                    [payload.extensionId]: (prev[payload.extensionId] || 0) + 1
                }));
            }
        }, []);

        // Add a socket hook just for debugging
        useAgentStatusSocket('MultipleAgentMapCounters', undefined, handleCounterUpdate);

        // Use our shared socket hook
        useAgentStatusSocket('MultipleAgentMap', handleAgentStatusUpdate);

        // 6. Add top-level logging in MultipleAgentMap for props
        useEffect(() => {
            console.log("extensionCounters prop changed in MultipleAgentMap:", extensionCounters);
            console.log("Counter values for each extension:");
            Object.entries(extensionCounters).forEach(([ext, counts]) => {
                console.log(`Extension ${ext}: call=${counts.call}, chat=${counts.chat}`);
            });
        }, [extensionCounters]);

        // 1. First, let's add a force update mechanism
        const [forceUpdateKey, setForceUpdateKey] = useState(0);

        // Add this effect to force re-render when extensionCounters changes
        useEffect(() => {
            // Force a re-render of the entire component when extensionCounters changes
            setForceUpdateKey(prev => prev + 1);
        }, [extensionCounters]);

        return (
            <div className="h-full flex flex-col overflow-hidden">
                {/* Scrollable container for multiple maps */}
                <div className="flex-1 overflow-auto p-4" style={{ height: 'calc(100% - 20px)' }}>
                    {multipleAgentMaps.map((agentMap) => (
                        <div key={agentMap.agentMapId} className="mb-6">
                            {/* Floor Name with sticky header */}
                            <h2 className="text-lg font-bold mb-4 top-0 z-10 py-2">
                                {agentMap.name}
                            </h2>

                            {/* Seats Grid */}
                            <div className="flex flex-wrap gap-2">
                                {agentMap.seats && agentMap.seats.map((seat) => {
                                    // Use parent timer or fallback to current time
                                    let seconds = 0;
                                    if (seat.extensionId && seatTimers[seat.extensionId]) {
                                        seconds = Math.floor((Date.now() - seatTimers[seat.extensionId]) / 1000);
                                    }

                                    // Calculate a key that includes the counter values
                                    const callCount = seat.extensionId ? (extensionCounters[seat.extensionId]?.call || 0) : 0;
                                    const chatCount = seat.extensionId ? (extensionCounters[seat.extensionId]?.chat || 0) : 0;
                                    const counterKey = `call${callCount}chat${chatCount}`;

                                    // Get update timestamp
                                    const updateTimestamp = seat.extensionId ? seatStatusUpdates[seat.extensionId] : undefined;

                                    return (
                                        <Seat
                                            key={`${seat.agentMapSeatId}-${updateTimestamp || 0}-${counterKey}`}
                                            seat={seat}
                                            seconds={seconds}
                                            mapAgentStatus={mapAgentStatus}
                                            mapAgentStatusToDisplay={mapAgentStatusToDisplay}
                                            singleAgentMapAgentStatuses={singleAgentMapAgentStatuses}
                                            formatTime={formatTime}
                                            seatStatusName={seat?.statusName}
                                            showCounters={showCounters} // Pass the prop
                                            extensionCounters={extensionCounters} // Pass the prop
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Optional pagination indicator */}
                {multipleAgentMaps.length > 1 && (
                    <div className="text-center text-xs text-gray-500 py-1">
                        Kéo xuống để xem thêm tầng
                    </div>
                )}
            </div>
        );
    };

export default MultipleAgentMap;