import { Button, Skeleton, Spin, Typography } from 'antd';
import { InfiniteScroll, LoadingThreeDots } from 'components/common';
import { useLazyNotificationsDownload } from 'hooks/notifications';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { useReadNotificationMutation } from 'services';
import { NotificationDto } from 'types';
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE, convertTimeAgo, convertTimeLeft } from 'utils';
import { NotificationListRefProps } from './NotificationList';
import { notificationsMessages } from 'messages';
import { twMerge } from 'tailwind-merge';
import { useDownloadFile } from 'hooks/files';

type DownloadListProps = {
  closeDrawer?: () => void;
};

const DownloadList = forwardRef<NotificationListRefProps, DownloadListProps>(({ closeDrawer }, ref) => {
  useImperativeHandle(ref, () => ({
    refetch: handleRefetch
  }));

  const navigate = useNavigate();
  const { fetchData, data: notifications, hasMore, handleLoadMore } = useLazyNotificationsDownload();
  const [readNotif, { isLoading }] = useReadNotificationMutation();
  const { downloadFile } = useDownloadFile();

  useEffect(() => {
    fetchData();
  }, []);

  const handleReadNotif = (notif: NotificationDto) => {
    readNotif(notif.notificationId)
      .unwrap()
      .then(() => handleRefetch());
    switch (notif.type) {
      case NOTIFICATION_TYPE.INTERNAL_NOTIFICATION:
        navigate({
          pathname: ROUTE.CUSTOMER_LOOKUP,
          search: `?IA=${notif.refId}`
        });
        closeDrawer?.();
        break;
      default:
    }
  };
  const handleRefetch = () => {
    fetchData({
      limit: notifications.length,
      skip: 0
    });
  };

  const statusNofif = (notif: NotificationDto) => {
    const status = notif.status;
    switch (status) {
      case NOTIFICATION_STATUS.ACTIVE:
        return {
          status: <Typography.Text className='text-sm'>{convertTimeAgo(notif.createdAt)}</Typography.Text>
        };

      case NOTIFICATION_STATUS.ERROR:
        return {
          status: (
            <Typography.Text className='text-sm text-colorError'>{notificationsMessages.fileError}</Typography.Text>
          )
        };

      case NOTIFICATION_STATUS.EXPIRED:
        return {
          status: (
            <Typography.Text className='text-sm' type='secondary'>
              {notificationsMessages.fileExpired}
            </Typography.Text>
          )
        };

      case NOTIFICATION_STATUS.PROCESSING:
        return {
          status: (
            <div className='flex items-end'>
              <Typography.Text className='text-sm text-colorPrimary'>
                {notificationsMessages.fileProcessing}
              </Typography.Text>
              <LoadingThreeDots className='text-colorPrimary' />
            </div>
          )
        };

      default:
        return {
          status: <Typography.Text className='text-sm'>{convertTimeAgo(notif.createdAt)}</Typography.Text>
        };
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className='h-full max-h-[calc(100vh-186px)] overflow-auto'>
        <InfiniteScroll
          hasMore={hasMore}
          next={handleLoadMore}
          loader={<Skeleton active={isLoading} title={false} paragraph={{ rows: 5 }} />}
        >
          {notifications.map((notif) => (
            <div
              onClick={() => handleReadNotif(notif)}
              key={notif.notificationId}
              className='relative cursor-pointer border-b border-colorBorder px-5 py-6 hover:bg-colorBgItemHover'
            >
              <div className='flex w-full items-center'>
                <div>
                  <div className='mb-4'>
                    <Typography.Paragraph
                      type={notif.status === NOTIFICATION_STATUS.EXPIRED ? 'secondary' : undefined}
                      className={twMerge(
                        'mb-1 font-semibold',
                        notif.status === NOTIFICATION_STATUS.EXPIRED ? 'line-through' : ''
                      )}
                      ellipsis={{ rows: 2, tooltip: true }}
                    >
                      {notif.content}
                    </Typography.Paragraph>
                    {statusNofif(notif).status}
                  </div>
                  <div className='flex items-center gap-3'>
                    {notif.status === NOTIFICATION_STATUS.ACTIVE ? (
                      <>
                        <Button
                          onClick={() => {
                            downloadFile(notif.refId);
                          }}
                          className='h-[26px] w-14 text-sm'
                          size='small'
                          type='primary'
                          shape='round'
                        >
                          {notificationsMessages.download}
                        </Button>
                        <Typography.Text className='text-xs' type='secondary'>
                          {notificationsMessages.expiredAt}{' '}
                          {notif.expiredAt ? convertTimeLeft(notif.expiredAt, true, '') : '-'}
                        </Typography.Text>
                      </>
                    ) : null}
                  </div>
                </div>
                {/* <Button icon={<TrashBinIcon />} type='link' className='text-colorTextBase' /> */}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </Spin>
  );
});

export default DownloadList;
