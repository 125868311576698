import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessCooperateRequestRoute: RouteObject[] = [
  {
    children: [
      {
        element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_NEED_COOPERATE_REQUEST, PERMISSION.READ_TICKET_TRANSFERED_TO_TRUONGCA]} />,
        children: [
          {
            path: ROUTE.MOVE_CENTER,
            Component: lazy(() => import('pages/CollaborationRequest'))
          }
        ]
      },
      {
        element: <CheckPermissionLayout permission={[PERMISSION.READ_TICKET_TRANSFERED_TO_CSKH]} />,
        path: ROUTE.TRANSFER_CUSTOMER_SERVICE_TEAM,
        Component: lazy(() => import('pages/CollaborationRequest'))
      },
      {
        element: <CheckPermissionLayout permission={[PERMISSION.READ_TICKET_TRANSFERED_TO_KYTHUAT]} />,
        path: ROUTE.MOVE_TECHNICAL_DEPARTMENT,
        Component: lazy(() => import('pages/CollaborationRequest'))
      },
    ]
  }
];
