import { Typography } from 'antd';
import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useCustomerFulltextLoadmore } from 'hooks/customers';
import { useState } from 'react';
import { FindAllCustomerDto } from 'types';

type SelectCustomersProps = SelectProps & Omit<FindAllCustomerDto, 'keyword' | 'pageIndex' | 'pageSize'>;

const SelectCustomersFullTextSearch = ({ ...props }: SelectCustomersProps) => {
  const [keyword, setKeyword] = useState('');

  const { customers, handleLoadMore, isLoading, resetPage, count } = useCustomerFulltextLoadmore({
    keyword: keyword
  });

  const handleSearch = (val: string) => {
    resetPage();
    setKeyword(val);
  };

  const hasMore = customers.length < count;

  return (
    <Select
      {...props}
      onLoadMore={handleLoadMore}
      loading={isLoading}
      hasMore={hasMore}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={
        customers.map((itm) => ({
          ...itm,
          label: (
            <div className='flex items-center gap-2'>
              <Typography.Text>
                {itm.customerCode} - {itm.customerName}
              </Typography.Text>
            </div>
          ),
          value: itm.customerCode
        })) || []
      }
      onDropdownVisibleChange={(open) => {
        if (!open) {
          handleSearch('');
        }
      }}
    />
  );
};
export default SelectCustomersFullTextSearch;
