import { Button, Checkbox, GetProp, Switch, Typography } from 'antd';
import AccessManageDtvAgentMap from './AccessManageDtvAgentMap'
import { useAgentMapsPaging, useTitle } from 'hooks';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import MultipleAgentMap from './MultipleAgentMap';
import Dashboard from './Dashboard';
// Remove socket imports that aren't needed anymore
import { onReceiveGetCallDetailsEvent, useLazyCountChatSessionQuery, useLazyGetAgentMapDetailQuery, useLazyGetAgentStatusQuery, useLazyGetCallDetailsQuery, useLazyListQueueQuery, onReceiveTotalCallandChat, onReceiveUpdateChatStatDetailEvent } from 'services';
import { useOmniCallContext } from 'contexts';
import { AgentMapDto, UpdateAgentMapSocketDto } from 'types';
import { AGENT_STATUS } from 'utils';
import { useAgentMapData } from 'hooks/useAgentMapData';
import { AgentTotalCallAndChatCounter } from 'types/dto/agent-maps/agent-total-call-and-chat-counter.dto';
import { useAgentStatusSocket } from 'hooks/useAgentStatusSocket';
import { parseComplexDate } from 'utils/dateUtils';
import { sidebarMenuMessages } from 'messages';
import { PageHeader } from 'components/common';

export type SingleAgentMapSingleStatusesType = {
    extension: string;
    agentName: string;
    state: string;
    backgroundColor: string;
    iconColor: string;
    textColor: string;
    startTime: number;
};
export type chartDataType = {
    extensionId: string;
    statusName: string
}
const AccessManageDtv = () => {
    useTitle(sidebarMenuMessages.dashboardAgentMap);

    const [getAgentMapDetail, setGetAgentMapDetail] = useState<number | number[]>(0);
    const [renderMultipleAgentMap, setRenderMultipleAgentMap] = useState<boolean>(false);
    const { agentMaps } = useAgentMapsPaging({ isActive: true, pageSize: 50 });
    const [multipleAgentMaps, setMultipleAgentMaps] = useState<AgentMapDto[]>([]);
    const { socketData } = useOmniCallContext();
    const [singleAgentMapAgentStatuses, setSingleAgentMapAgentStatuses] = useState<SingleAgentMapSingleStatusesType[]>([]);
    const [seatTimers, setSeatTimers] = useState<Record<string, number>>({});
    const [getCallDetailsTrigger, { data: getCallDetails }] = useLazyGetCallDetailsQuery();
    const [getChatSessionDetailsTrigger, { data: getChatSessionDetails }] = useLazyCountChatSessionQuery();
    const [showCounters, setShowCounters] = useState<boolean>(false); // Add state for toggle
    const [extensionCounters, setExtensionCounters] = useState<Record<string, { call: number, chat: number }>>({});

    // Track the last incremented timestamps to prevent duplicates
    const lastIncrementTimestampRef = useRef<Record<string, number>>({});

    // Function to increment a counter for a specific extension
    const incrementCounter = useCallback((extensionId: string, counterType: 'call' | 'chat') => {
        // Create a unique key for this extension and counter type
        const counterKey = `${extensionId}-${counterType}`;

        // Get the current time
        const now = Date.now();

        // Check if we've incremented this counter recently (within 500ms)
        const lastTime = lastIncrementTimestampRef.current[counterKey] || 0;
        if (now - lastTime < 500) {
            console.log(`🛑 Skipping duplicate increment for ${extensionId} ${counterType} (too soon)`);
            return;
        }

        // Update the timestamp for this counter
        lastIncrementTimestampRef.current[counterKey] = now;

        console.log(`🔄 INCREMENT START: ${extensionId} ${counterType}`,
            extensionCounters[extensionId]?.[counterType] || 0);

        setExtensionCounters(prev => {
            // Make a completely new object to ensure React detects the change
            const newCounters = { ...prev };

            // Initialize if needed
            if (!newCounters[extensionId]) {
                newCounters[extensionId] = { call: 0, chat: 0 };
            }

            // Increment the counter only by 1
            newCounters[extensionId][counterType] = (newCounters[extensionId][counterType] || 0) + 1;

            console.log(`🔄 INCREMENT RESULT:`, newCounters);
            return newCounters;
        });
    }, []); // Keep empty dependency array

    // Function to increment a counter for a specific extension
    // Define interface for counter update data

    const handleCounterUpdate = useCallback((data: AgentTotalCallAndChatCounter) => {
        console.log("🔢 Parent received counter update:", data);

        if (data.extensionId && data.counterType) {
            // Validate that counterType is either 'call' or 'chat'
            if (data.counterType === 'call' || data.counterType === 'chat') {
                console.log(`Incrementing counter for ${data.extensionId} - ${data.counterType}`);
                incrementCounter(data.extensionId, data.counterType);
            }
        }
    }, [incrementCounter]); // Only depend on incrementCounter

    // Use the shared socket hook to manage counter updates
    useAgentStatusSocket('AccessManageDtv-' + Date.now(), undefined, handleCounterUpdate);

    // Create stable timer update function that won't change between renders
    const updateSeatTimer = useCallback((extensionId: string, timestamp?: number) => {
        // If timestamp is provided directly, use it
        if (timestamp) {
            console.log(`Setting timer for ${extensionId} with explicit timestamp:`,
                new Date(timestamp).toISOString());

            setSeatTimers(prev => ({
                ...prev,
                [extensionId]: timestamp
            }));
            return;
        }

        // Otherwise use current time
        const now = Date.now();
        console.log(`Setting timer for ${extensionId} with current time:`,
            new Date(now).toISOString());

        setSeatTimers(prev => ({
            ...prev,
            [extensionId]: now
        }));
    }, []);

    // Memoize uniqueStatuses to prevent recreation on each render
    const uniqueStatuses = useMemo(() => [
        { status: "Không bật", backgroundColor: "#EBEBED", iconColor: '#B2B9C4', textColor: '#000000' },
        { status: "Đàm thoại", backgroundColor: "#D1131D", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
        { status: "Đang reo", backgroundColor: "#AF52DE", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
        { status: "Tạo phiếu", backgroundColor: "#1564E8", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
        { status: "Tạm nghỉ", backgroundColor: "#FF9500", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
        { status: "Sẵn sàng", backgroundColor: "#06A77D", iconColor: '#FFFFFF', textColor: '#FFFFFF' },
    ], []);

    // Create default chart data with all zeros using useMemo
    const defaultChartData = useMemo(() => {
        return uniqueStatuses.map(status => ({
            status: status.status,
            count: 0,
            color: status.backgroundColor
        }));
    }, [uniqueStatuses]);

    // Initialize chart data with default values
    const [chartData, setChartData] = useState<{ status: string; count: number; color: string }[]>(defaultChartData);

    // Stable testFunc that won't change between renders
    const testFunc = useCallback((data: chartDataType[]) => {
        if (!data || data.length === 0) {
            setChartData(defaultChartData);
            return;
        }

        const statusCounts: Record<string, number> = {};

        data.forEach(agent => {
            if (!agent) return;

            let mappedStatus = agent.statusName;

            // Map status names
            switch (mappedStatus) {
                case AGENT_STATUS.AGENT_PAUSED:
                    mappedStatus = "Tạm nghỉ";
                    break;
                case AGENT_STATUS.AGENT_UNPAUSED:
                    mappedStatus = "Sẵn sàng";
                    break;
                case AGENT_STATUS.ONCALL:
                    mappedStatus = "Đàm thoại";
                    break;
                case AGENT_STATUS.RINGING:
                    mappedStatus = "Đang reo";
                    break;
                case AGENT_STATUS.CREATING_TICKET:
                    mappedStatus = "Tạo phiếu";
                    break;
                case AGENT_STATUS.UNKNOWN:
                case AGENT_STATUS.LOGGEDOFF:
                    mappedStatus = "Không bật";
                    break;
                default:
                    mappedStatus = "Không bật";
                    break;
            }

            // Count status occurrences
            statusCounts[mappedStatus] = (statusCounts[mappedStatus] || 0) + 1;
        });

        // Convert data to chartData format
        const agentStatuses = uniqueStatuses.map(({ status, backgroundColor }) => ({
            status,
            count: statusCounts[status] || 0,
            backgroundColor,
            textColor: uniqueStatuses.find(statusObj => statusObj.status === status)?.textColor,
            iconColor: uniqueStatuses.find(statusObj => statusObj.status === status)?.iconColor
        }));

        // Update chart data
        setChartData(agentStatuses.map(item => ({ ...item, color: item.backgroundColor })));
    }, [uniqueStatuses, defaultChartData]);

    // Use the hook with stable references
    const { fetchAgentMapDetail, resetAllData } = useAgentMapData({
        getAgentMapDetail,
        setMultipleAgentMaps,
        setSingleAgentMapAgentStatuses,
        testFunc
    });

    // Checkbox handler with memoization to prevent recreation on each render
    const onChange = useCallback((checkedValues: string[]) => {
        // Reset all data when selection changes
        resetAllData();

        if (checkedValues.length === 0) {
            setGetAgentMapDetail(0);
            setRenderMultipleAgentMap(false);
            setMultipleAgentMaps([]);
            setChartData(defaultChartData);
            return;
        }

        if (checkedValues.length === 1) {
            setGetAgentMapDetail(parseInt(checkedValues[0]));
            setRenderMultipleAgentMap(false);
        } else {
            setGetAgentMapDetail(checkedValues.map(id => parseInt(id)));
            setRenderMultipleAgentMap(true);
        }
    }, [resetAllData, defaultChartData]);

    // Effect to update chart data when agent map selection changes
    useEffect(() => {
        if (getAgentMapDetail === 0 || (Array.isArray(getAgentMapDetail) && getAgentMapDetail.length === 0)) {
            setChartData(defaultChartData);
        }
    }, [getAgentMapDetail, defaultChartData]);

    useEffect(() => {
        onReceiveGetCallDetailsEvent(() => {
            getCallDetailsTrigger()
        })

        onReceiveUpdateChatStatDetailEvent(() => {
            getChatSessionDetailsTrigger({
                fromDate: startOfDay.toISOString(),
                toDate: endOfDay.toISOString()
            })
        })


        // Get call details
        getCallDetailsTrigger()

        // Set fromDate to start of today (00:00:00)
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0);

        // Set toDate to end of today (23:59:59)
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);

        // Get chat session details for the entire current day
        getChatSessionDetailsTrigger({
            fromDate: startOfDay.toISOString(),
            toDate: endOfDay.toISOString()
        })
    }, [])

    // Memoize the checkbox options to prevent recreating on every render
    const checkboxOptions = useMemo(() => {
        return (agentMaps || []).map(map => ({
            label: map.name,
            value: map.agentMapId.toString()
        }));
    }, [agentMaps]);

    // Initialize counter state with a useEffect that runs when agent maps change
    useEffect(() => {
        if (multipleAgentMaps.length > 0) {
            // Create a map of extension IDs to counter values
            const initialCounters: Record<string, { call: number, chat: number }> = {};

            multipleAgentMaps.forEach(agentMap => {
                agentMap.seats?.forEach(seat => {
                    if (seat.extensionId) {
                        // Only initialize if seat has a valid extensionId
                        initialCounters[seat.extensionId] = {
                            // Use explicit seat values if available
                            call: seat.totalCall !== undefined ? seat.totalCall : 0,
                            chat: seat.totalChat !== undefined ? seat.totalChat : 0
                        };
                    }
                });
            });

            // Only update if we have new data
            if (Object.keys(initialCounters).length > 0) {
                console.log("Initializing counter data from agent maps:", initialCounters);
                setExtensionCounters(prev => {
                    // Merge with existing state, preserving any counters that might already exist
                    const newState = { ...prev };

                    // Only add/update values where the seat has them
                    Object.entries(initialCounters).forEach(([extensionId, counters]) => {
                        // Initialize this extension's counters if they don't exist yet
                        if (!newState[extensionId]) {
                            newState[extensionId] = { call: 0, chat: 0 };
                        }

                        // Only update counter values if the seat has them defined
                        // This way we don't overwrite existing values with zeros
                        if (counters.call !== undefined) {
                            newState[extensionId].call = counters.call;
                        }
                        if (counters.chat !== undefined) {
                            newState[extensionId].chat = counters.chat;
                        }
                    });

                    return newState;
                });
            }
        }
    }, [multipleAgentMaps]);

    // 3. Add effect to log when extensionCounters changes
    useEffect(() => {
        console.log("extensionCounters changed in AccessManageDtv:", extensionCounters);
    }, [extensionCounters]);

    // Add logging to verify the socket connection and counter handling
    useEffect(() => {
        // Add this to detect if the counters are actually updated
        console.log("COUNTER STATE UPDATED:", extensionCounters);

        // When in renderMultipleAgentMap mode, specially log what counters are passed down
        if (renderMultipleAgentMap) {
            console.log("PASSING TO MULTIPLEAGENTMAP:", extensionCounters);
        }
    }, [extensionCounters, renderMultipleAgentMap]);

    // Calculate the total number of unique agents (extension IDs)
    const totalUniqueAgents = useMemo(() => {
        if (!multipleAgentMaps.length) return 0;

        // Collect all extension IDs from all agent maps
        const allExtensionIds = new Set<string>();

        multipleAgentMaps.forEach(agentMap => {
            agentMap.seats?.forEach(seat => {
                if (seat.extensionId) {
                    allExtensionIds.add(seat.extensionId);
                }
            });
        });

        return allExtensionIds.size;
    }, [multipleAgentMaps]);

    return (
        <div>
            <PageHeader className='flex items-center gap-1'>
                <Typography.Title className='text-header-label mb-0'>{sidebarMenuMessages.dashboardAgentMap}</Typography.Title>
            </PageHeader>
            <div className="grid grid-cols-[640px_1fr] bg-gray-100 min-h-screen">
                {/* Left column with Dashboard */}
                <div className="flex flex-col bg-white rounded-tl-xl shadow-md h-[calc(100vh-100px)]">
                    <Dashboard
                        chartData={chartData}
                        callStatsBySource={getCallDetails?.data ? getCallDetails.data : undefined}
                        getChatSessionDetails={getChatSessionDetails?.data ? getChatSessionDetails.data : undefined}
                        totalAgents={totalUniqueAgents} // Pass the agent count
                    />
                </div>

                {/* Right column with AgentMap */}
                <div className="flex flex-col h-[calc(100vh-100px)]">
                    <div className="flex w-full items-center justify-between rounded-tr-xl bg-[#EBEBED] px-6 py-6">
                        <div className="w-[80%]">
                            <Checkbox.Group
                                options={checkboxOptions}
                                onChange={onChange}
                            />
                        </div>
                        <div className="w-[20%] flex items-center justify-end gap-2">
                            <Typography.Text className="text-xs">Xem số cuộc gọi & chat</Typography.Text>
                            <Switch
                                checked={showCounters}
                                onChange={setShowCounters}
                            />
                        </div>
                    </div>
                    <div className="bg-[#EBEBED] flex-grow overflow-hidden">
                        {getAgentMapDetail === 0 ? (
                            <div className="flex items-center justify-center h-full">
                                <Typography.Text className="text-gray-500">Vui lòng chọn một bản đồ</Typography.Text>
                            </div>
                        ) : renderMultipleAgentMap ? (
                            <MultipleAgentMap
                                multipleAgentMaps={multipleAgentMaps}
                                singleAgentMapAgentStatuses={singleAgentMapAgentStatuses}
                                seatTimers={seatTimers}
                                updateSeatTimer={updateSeatTimer}
                                showCounters={showCounters} // Pass the state to child component
                                extensionCounters={extensionCounters} // Pass counter data
                            />
                        ) : (
                            <AccessManageDtvAgentMap
                                singleAgentMapAgentStatuses={singleAgentMapAgentStatuses}
                                agentMapId={getAgentMapDetail as number}
                                seatTimers={seatTimers}
                                updateSeatTimer={updateSeatTimer}
                                showCounters={showCounters} // Pass the state to child component
                                extensionCounters={extensionCounters} // Pass counter data
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AccessManageDtv;