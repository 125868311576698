import React from 'react';
import { Typography } from 'antd';
import { HourGlassStatusIcon } from 'assets';
import { requestform } from 'messages';

interface RequestStatusBarProps {
  status?: string;
}

const getStatusInfo = (status: string) => {
  const statusMap: Record<string, { name: string; color: string }> = {
    'pending-reception': { name: 'Chờ tiếp nhận', color: '#FF9500' },
    'in-progress': { name: 'Đang xử lý', color: '#1564E8' },
    collaboration: { name: 'Phối hợp xử lý', color: '#AF52DE' },
    completed: { name: 'Đã xử lý', color: '#02D8A0' },
    obstacle: { name: 'Trở ngại', color: '#D1131D' },

    complaint: { name: 'Chờ xác nhận', color: '#02D8A0' },
    electrical_safety: { name: 'Chờ xác nhận', color: '#FF4D4F' },
    vip_customer: { name: 'Chờ xác nhận', color: '#1890FF' },
    outage_entire_area: { name: 'Chờ xác nhận', color: '#722ED1' },
    not_on_time: { name: 'Chờ xác nhận', color: '#13C2C2' },

    pending_new_address_change: { name: 'Chờ xác nhận', color: '#FF9500' },
    completed_new_address_change: { name: 'Đã xác nhận', color: '#02D8A0' }
  };

  return statusMap[status] || { name: 'Không xác định', color: 'gray' };
};

const RequestStatusBar: React.FC<RequestStatusBarProps> = ({ status = 'pending-reception' }) => {
  const statusInfo = getStatusInfo(status);
  return (
    <div
      style={{ background: statusInfo.color }}
      className={`request-status-bar mt-3 flex justify-between rounded-[5px] px-4 py-[11px]`}
    >
      <div>
        <Typography.Text className='text-[14px]/[18px] font-semibold text-[#FFFFFF]'>
          {requestform.detailStatusRequestForm}
        </Typography.Text>{' '}
        <Typography.Text className='text-[14px]/[18px] text-[#FFFFFF]'>{statusInfo.name}</Typography.Text>
      </div>
      <HourGlassStatusIcon />
    </div>
  );
};

export default RequestStatusBar;
