import { CustomerLookupPhone } from 'components';
import { useProfile } from 'hooks';
import { PERMISSION } from 'utils';
import { checkPermissions } from 'utils/check-permission';

type CustomerLookupCallProps = {};

function CustomerLookupCall({}: CustomerLookupCallProps) {
  const { userPermissionIds } = useProfile();

  const isAllow = checkPermissions(userPermissionIds, PERMISSION.CALL_CUSTOMER);

  if (!isAllow) {
    return null;
  }

  return <CustomerLookupPhone />;
}

export default CustomerLookupCall;
