import { Button, Tooltip, Typography } from 'antd';
import { DeletedIcon, EyedIcon, EyeInvisibleIcon, LikedIcon, LikeIcon, LinkIcon, MailV2Icon, ReplyIcon } from 'assets';
import { useOmniChatContext } from 'contexts';
import { omniChatMessages } from 'messages';
import { OmniChannelMessageDto } from 'types';
import { useMemo } from 'react';
import { useOmniChatReplyMessage } from 'hooks/omni-chat/useOmniChatReplyMessage';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

type MessageActionsProps = {
  data: OmniChannelMessageDto;
};

const MessageActions: React.FC<MessageActionsProps> = ({ data }) => {
  const { isShowActions, chatSession, handleLikeMessage, handleHideMessage, handleDeleteMessage, handleChatUser } =
    useOmniChatContext();

  const { handleReplyOmniMessage } = useOmniChatReplyMessage();

  const customerId = chatSession.chat?.customerId;
  const isCustomer = data.senderId === customerId;

  const handleReply = (message: OmniChannelMessageDto) => {
    handleReplyOmniMessage(message);
    const inputChat = document.getElementById(`chat-input-fake-${message.chatSessionId}`);

    if (inputChat) {
      inputChat.focus();
    }
  };

  if (!isShowActions) return null;

  const renderButton = (onClick: () => void, icon: React.ReactNode, tooltip?: string, disabled = false) => (
    <Tooltip title={tooltip}>
      <Button
        onClick={onClick}
        disabled={disabled}
        shape='circle'
        className='h-6 min-h-0 w-6 min-w-0 border-0 bg-transparent p-0 text-xs'
        size='small'
        icon={icon}
      />
    </Tooltip>
  );

  if (data.isDeleted)
    return (
      <Typography.Text type='danger' className='text-sm'>
        {omniChatMessages.messageDeleted}
      </Typography.Text>
    );
  return (
    <div className='flex items-center gap-1'>
      {isCustomer &&
        (data.isLiked ? (
          <div className='flex h-6 w-6 items-center justify-center'>
            <LikedIcon width={16} height={16} />
          </div>
        ) : (
          renderButton(
            () => handleLikeMessage(data),
            <LikeIcon width={16} height={16} />,
            omniChatMessages.likeComment,
            data.isDeleted
          )
        ))}

      {renderButton(
        () => handleHideMessage(data),
        data.isHidden ? <EyeInvisibleIcon width={18} height={18} /> : <EyedIcon width={18} height={18} />,
        data.isHidden ? omniChatMessages.unhideComment : omniChatMessages.hideComment,
        data.isDeleted
      )}

      {data.isDeleted ? (
        <div className='flex h-6 w-6 items-center justify-center'>
          <DeletedIcon width={16} height={16} />
        </div>
      ) : (
        renderButton(
          () => handleDeleteMessage(data),
          <DeletedIcon width={16} height={16} />,
          omniChatMessages.deleteComment,
          data.isDeleted
        )
      )}

      {isCustomer &&
        renderButton(() => handleChatUser(data), <MailV2Icon width={16} height={16} />, undefined, data.isDeleted)}

      {isCustomer &&
        renderButton(
          () => handleReply(data),
          <ReplyIcon width={16} height={16} />,
          omniChatMessages.reply,
          data.isDeleted
        )}
      {data.isDeleted || !data.commentUrl ? (
        <div className='flex h-6 w-6 items-center justify-center'>
          <LinkOutlined className='text-sm text-subTextColor' />
        </div>
      ) : (
        <Tooltip title={omniChatMessages.openLinkComment}>
          <Link
            to={data.commentUrl}
            target='_blank'
            style={{
              width: 312
            }}
            className='w-auto'
          >
            <Button
              disabled={data.isDeleted}
              shape='circle'
              className='h-6 min-h-0 w-6 min-w-0 border-0 bg-transparent p-0 text-xs'
              size='small'
              icon={<LinkOutlined className='text-sm' />}
            />
          </Link>
        </Tooltip>
      )}
    </div>
  );
};

export default MessageActions;
