import { Button, Typography } from 'antd';
import {
    PhoneFilledIcon,
} from 'assets';
import { useProfile } from 'hooks';
import { customerLookupMessages } from 'messages/customer-lookup.message';

interface IncomingCallProps {
    isMinimized: boolean;
    socketData: { customerNumber?: string; callSource?: string };
    answerSoftPhone: () => void;
    getSourceDisplayName: (source: string) => string;
    isAnswering?: boolean; // Add loading state prop
}

const IncomingCallContent: React.FC<IncomingCallProps> = ({
    isMinimized,
    socketData,
    answerSoftPhone,
    getSourceDisplayName,
    isAnswering = false // Default to false
}) => {
    const { profile } = useProfile();

    return isMinimized ? (
        <div className="w-[400px] flex h-[244px] flex-col rounded-tl-xl bg-[#222A41]">
            <div className="m-5 flex flex-col">
                <Typography.Text className="text-left text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    {getSourceDisplayName(socketData?.callSource || 'VNPT')} ⮕ EXT #{profile?.extensionId || ''} - {profile?.shortName || ''}
                </Typography.Text>
                <div className="mt-10 flex flex-row items-center gap-[109px]">
                    <div className="flex flex-col gap-2 mr-14">
                        <Typography.Text className="text-left text-[24px]/[32px] font-semibold tracking-normal text-[#FFFFFF] opacity-100 text-nowrap">
                            {socketData?.customerNumber}
                        </Typography.Text>
                        <Typography.Text className="text-left text-[12px]/[16px] italic tracking-normal text-[#FFFFFF] opacity-100">
                            {customerLookupMessages.inComingCall}
                        </Typography.Text>
                    </div>
                    <Button
                        style={{ borderColor: "transparent" }}
                        shape="circle"
                        className="h-[56px] w-[56px] bg-[#02D8A0] bg-clip-padding bg-no-repeat opacity-100"
                        icon={<PhoneFilledIcon color='#FFFFFF' fill='#FFFFFF' className="h-[28px] w-[28px]" style={{ fill: "#FFFFFF" }} />}
                        onClick={answerSoftPhone}
                        loading={isAnswering} // Add loading state
                        disabled={isAnswering} // Disable button while loading
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="flex h-full w-[400px] flex-col rounded-l-xl bg-[#222A41]">
            <div className="m-5 h-full flex flex-col items-center justify-center">
                <Typography.Text className="text-center text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    {getSourceDisplayName(socketData?.callSource || 'VNPT')} ⮕ EXT #{profile?.extensionId || ''} - {profile?.shortName || ''}
                </Typography.Text>
                <Typography.Text className="text-center text-[24px]/[32px] font-semibold tracking-normal text-[#FFFFFF] opacity-100 text-nowrap">
                    {socketData?.customerNumber}
                </Typography.Text>
                <Typography.Text className="mt-2 text-center text-[12px]/[16px] italic tracking-normal text-[#FFFFFF] opacity-100">
                    {customerLookupMessages.inComingCall}
                </Typography.Text>
                <Button
                    style={{ borderColor: "transparent" }}
                    shape="circle"
                    className="mt-8 h-[56px] w-[56px] bg-[#02D8A0] bg-clip-padding bg-no-repeat opacity-100"
                    icon={<PhoneFilledIcon color='#FFFFFF' fill='#FFFFFF' className="h-[28px] w-[28px]" style={{ fill: "#FFFFFF" }} />}
                    onClick={answerSoftPhone}
                    loading={isAnswering} // Add loading state
                    disabled={isAnswering} // Disable button while loading
                />
            </div>
        </div>
    );
};

export default IncomingCallContent;
