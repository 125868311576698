export const leaderShiftMessage = {
    exportExel: 'Xuất excel',

    overdue: "Quá hạn:",
    evn: "Công ty điện lực",
    technique: "KĨ THUẬT",
    bussiness: "KINH DOANH",

    pendingReception: "CHỜ TIẾP NHẬN",
    inporgress: "ĐANG XỬ LÝ",
    completed: "ĐÃ XỬ LÝ",

    businessTicket: "Phiếu kinh doanh",
    technicalTicket: "Phiếu kĩ thuật"
};
