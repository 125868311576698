import { OmniChannelChat } from 'components';
import { useProfile } from 'hooks';
import { PERMISSION } from 'utils';
import { checkSomePermissions } from 'utils/check-permission';

type OmniChanelChatCustomerProps = {};

function OmniChanelChatCustomer({}: OmniChanelChatCustomerProps) {
  const { userPermissionIds } = useProfile();

  const isAllow = checkSomePermissions(userPermissionIds, [PERMISSION.ASSIGN_CHAT_CUSTOMER, PERMISSION.CHAT_CUSTOMER]);

  if (!isAllow) {
    return null;
  }

  return <OmniChannelChat />;
}

export default OmniChanelChatCustomer;
