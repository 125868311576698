import React from 'react';
import { Typography } from 'antd';
import { HourGlassStatusIcon } from 'assets';
import { requestform } from 'messages';

interface RequestStatusBarProps {
  status: string;
}

const getStatusInfo = (status: string) => {
  const statusMap: Record<string, { name: string; color: string }> = {
    'CHOTIEPNHAN': { name: 'Chờ tiếp nhận', color: '#FF9500' },
    'DANGXULY': { name: 'Đang xử lý', color: '#1564E8' },
    'CHUYENTRUNGTAM': { name: 'Chuyển trung tâm', color: '#AF52DE' },
    'TRUONGCACHUYENPHONG': { name: 'Chuyển phòng CSKH', color: '#AF52DE' },
    'TRUONGCACHUYENPHONGKYTHUAT': { name: 'Chuyển phòng KT', color: '#AF52DE' },
    'HOANTAT': { name: 'Đã xử lý', color: '#02D8A0' },
    'HUY': { name: 'Hủy', color: 'gray' },
  };

  return statusMap[status] || { name: 'Không xác định', color: 'gray' };
};

const TicketStatusBar: React.FC<RequestStatusBarProps> = ({ status }) => {
  return (
    <div
      style={{ background: getStatusInfo(status).color }}
      className={`mt-3 flex justify-between rounded-[5px] px-4 py-[11px]`}
    >
      <div>
        <Typography.Text className='text-[14px]/[18px] font-semibold text-[#FFFFFF]'>
          {requestform.detailStatusRequestForm}
        </Typography.Text>{' '}
        <Typography.Text className='text-[14px]/[18px] text-[#FFFFFF]'>{getStatusInfo(status).name}</Typography.Text>
      </div>
      <HourGlassStatusIcon />
    </div>
  );
};

export default TicketStatusBar;
