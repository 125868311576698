import { Form, FormInstance, Skeleton } from 'antd';
import { message } from 'components/common';
import { validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { UpdateRoleScopeDto } from 'types';
import { TreeCheckboxOrganizationUnits } from 'components';
import { useGetRoleDetailQuery, useUpdateRoleScopeMutation } from 'services';
import { updateRoleScopeInitialValues } from 'utils/initial-values/roles';
export type RoleScopeFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  roleId?: number;
};

export type RoleScopeFormRefProps = {
  form: FormInstance<RoleScopeFormType>;
  isLoading: boolean;
};

export type RoleScopeFormType = Omit<UpdateRoleScopeDto, 'roleId'>;
const RoleScopeForm = forwardRef<RoleScopeFormRefProps, RoleScopeFormProps>(
  ({ onChangeLoading, onCreateSuccess, roleId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingUpdate
    }));

    const [form] = Form.useForm<RoleScopeFormType>();
    const organizationUnitIds = Form.useWatch('organizationUnitIds', form) ?? [];

    const { data: role, isLoading: isLoadingDetail } = useGetRoleDetailQuery(roleId!, {
      skip: !roleId,
      refetchOnMountOrArgChange: true
    });

    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateRoleScopeMutation();

    useEffect(() => {
      if (role && roleId) {
        form.setFieldsValue({
          organizationUnitIds: role.data.organizationUnits?.map((orgUnit) => orgUnit.organizationUnitId)
        });
      }
    }, [role, roleId]);

    const onFinish = (values: RoleScopeFormType) => {
      if (!roleId) return;

      onUpdate({
        ...values,
        roleId: roleId
      })
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
          onCreateSuccess?.();
        });
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingUpdate]);

    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='roleScopeForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={updateRoleScopeInitialValues}
      >
        <Skeleton loading={isLoadingDetail}>
          <Form.Item<UpdateRoleScopeDto> name='organizationUnitIds' noStyle />
          <TreeCheckboxOrganizationUnits
            value={organizationUnitIds}
            onChange={(value) => form.setFieldValue('organizationUnitIds', value)}
          />
        </Skeleton>
      </Form>
    );
  }
);
export default RoleScopeForm;
