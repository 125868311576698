export const omniChatMessages = {
  nameFeature: 'Chat KH',
  customerBeingSupported: 'KH đang hỗ trợ',
  notHaveChatSessions: 'Chưa hỗ trợ KH nào',
  descriptionReceiveCustomer: 'Nhấn nhận chat để nhận hỗ trợ cho một khách hàng đang chờ',
  receiveChat: 'Nhận chat',
  waitingCustomer: 'KH đang chờ',
  complete: 'Kết thúc',
  notHaveWaitingCustomer: '0 Khách hàng đang chờ',
  assign: 'Phân công',
  assignChat: 'Phân công chat',
  searchCustomer: 'Tên khách hàng',
  chooseCustomerAssign: 'Chọn một khách hàng đang chờ để xem nội dung tin nhắn và bắt đầu phân công',
  chooseDTV: 'Chọn điện thoại viên',
  nameDTV: 'Tên điện thoại viên',
  confirmAssign: 'Xác nhận phân công',
  notPermission: 'Bạn không có quyền chat khách hàng',
  chatSession: 'cuộc chat',
  likeComment: 'Thích bình luận',
  hideComment: 'Ẩn bình luận',
  unhideComment: 'Bỏ ẩn bình luận',
  deleteComment: 'Xóa bình luận',
  chatCustomer: 'Chat riêng với khách hàng',
  reply: 'Trả lời bình luận',
  openLinkComment: 'Mở link bình luận',
  ready: 'Sẵn sàng',
  pause: 'Tạm ngưng',
  messageDeleted: 'Tin nhắn đã xóa'
};
