import { IncorrectRecordingLocationRequestDto } from '../../types';
import { useGetIncorrectRecordingLocationTicketListQuery } from '../../services';

export function useIncorrectRecordingLocationList(IncorrectRecordingLocationRequestDto: IncorrectRecordingLocationRequestDto) {
  const { data: incorrectRecordingLocaitonList, isLoading, refetch } = useGetIncorrectRecordingLocationTicketListQuery(IncorrectRecordingLocationRequestDto!, {
    skip: !IncorrectRecordingLocationRequestDto,
    refetchOnMountOrArgChange: true
  });

  return {
    pending: incorrectRecordingLocaitonList?.data.rows || [],
    pendingCount: Number(incorrectRecordingLocaitonList?.data.count),
    refetch: refetch ?? (() => { }),
    isLoading
  };
}