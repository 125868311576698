import { createApi } from '@reduxjs/toolkit/query/react';


import axiosBaseQuery from 'utils/base-api';
import {
  OrganizationUnitDetail,
  ResponseDto, ResponsePagingDto, TicketDetail, ticketType,
  TrackingTicketProcessingList, TrackingTicketProcessingListDetailRequest,
  TrackingTicketProcessingListRequest
} from '../../types';
import dayjs from 'dayjs';

export const trackingTicketsProcessingApi = createApi({
  reducerPath: 'trackingTicketsProcessingApi',
  tagTypes: [],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getSupervisorTicketTracking: builder.query<ResponseDto<TrackingTicketProcessingList>, TrackingTicketProcessingListRequest>({
      query: (requestData) => {
        const params = new URLSearchParams();
        params.append('fromDate', dayjs(requestData.fromDate, "DD/MM/YYYY").format('DD/MM/YYYY'));
        params.append('toDate', dayjs(requestData.toDate, "DD/MM/YYYY").format('DD/MM/YYYY'));

        return {
          url: `/tickets/dtv/supervisor_ticket_tracking`,
          method: 'get',
          params: params
        }
      },
    }),
    getSupervisorTicketTrackingDetail: builder.query<ResponsePagingDto<TicketDetail>, TrackingTicketProcessingListDetailRequest>({
      query: (requestData) => {
        const params = new URLSearchParams();
        params.append('fromDate', dayjs(requestData.fromDate, "DD/MM/YYYY").format('DD/MM/YYYY'));
        params.append('toDate', dayjs(requestData.toDate, "DD/MM/YYYY").format('DD/MM/YYYY'));
        params.append('pageIndex', requestData.pageIndex.toString());
        params.append('pageSize', requestData.pageSize.toString());
        params.append('unitId', requestData.unitId.toString());
        params.append('column', requestData.column.toString());

        return {
          url: `/tickets/dtv/supervisor_ticket_tracking_detail`,
          method: 'get',
          params: params
        }
      },
    }),

    getOrganizationUnitName: builder.query<ResponseDto<OrganizationUnitDetail>, number>({
      query: (organizationUnitId) => {
        return {
          url: `/organization_units/${organizationUnitId}`,
          method: 'get',
        }
      }
    }),
  })
});

export const {
  useGetSupervisorTicketTrackingQuery,
  useGetOrganizationUnitNameQuery,
  useGetSupervisorTicketTrackingDetailQuery
} = trackingTicketsProcessingApi;
