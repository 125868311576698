import { Button, Typography } from 'antd';
import { TreeChildrenIcon } from 'assets';
import { RightSideCheckbox } from 'components/common/checkbox';
import { useOrganizationUnitsHierarchyOptions } from 'hooks';
import { messages } from 'messages';
import { FindOrganizationUnitHierarchyDto, OrganizationUnitHierarchyCompactDto } from 'types';

type TreeCheckboxOrganizationUnitsProps = FindOrganizationUnitHierarchyDto & {
  value: number[];
  onChange: (value: number[]) => void;
};

const TreeCheckboxOrganizationUnits: React.FC<TreeCheckboxOrganizationUnitsProps> = ({
  parentId,
  organizationUnitId,
  value,
  onChange
}) => {
  const { organizationsUnitsHierarchy } = useOrganizationUnitsHierarchyOptions({
    parentId,
    organizationUnitId
  });

  const formatData = (data: OrganizationUnitHierarchyCompactDto[]): any[] => {
    if (data.length === 0) return [];

    return data.map((item) => ({
      key: item.organizationUnitId.toString(),
      value: item.organizationUnitId.toString(),
      label: item.name,
      children: item.children && item.children.length > 0 ? formatData(item.children) : []
    }));
  };

  const handleChange = (selectedValue: string[]) => {
    onChange?.(selectedValue.map((val) => Number(val)));
  };

  const getAllOrganizationUnitIds = (units: OrganizationUnitHierarchyCompactDto[]): number[] => {
    return units.flatMap(({ organizationUnitId, children }) => [
      organizationUnitId,
      ...(children ? getAllOrganizationUnitIds(children) : [])
    ]);
  };

  const handleSelectAll = () => {
    const selectAll = getAllOrganizationUnitIds(organizationsUnitsHierarchy);
    onChange?.(selectAll.map((val) => Number(val)));
  };

  const countData = (items: OrganizationUnitHierarchyCompactDto[]): number =>
    items.reduce((count, item) => count + 1 + (item.children ? countData(item.children) : 0), 0);

  return (
    <div>
      <RightSideCheckbox
        options={formatData(organizationsUnitsHierarchy)}
        onChange={handleChange}
        prefix={<Typography.Text className='font-semibold'>•</Typography.Text>}
        childrenPrefix={<TreeChildrenIcon />}
        value={value.map((item) => item.toString())}
      />
    </div>
  );
};
export default TreeCheckboxOrganizationUnits;
