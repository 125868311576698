import { FormInstance } from 'antd';
import { AgentMapFormTypeDashboard } from 'pages/AccessDtvManagement';
import { useEffect } from 'react';
import { useGetAgentMapDetailQuery } from 'services';
import { transformToFabricAgentMapOtherObject, transformToFabricAgentMapSeatDashboard } from 'utils/agent-map';
export function useAgentMapDetailDashboard(agentMapId: number, form?: FormInstance<AgentMapFormTypeDashboard>) {
    const { data: agentRes, isLoading } = useGetAgentMapDetailQuery(agentMapId!, {
        skip: !agentMapId,
        refetchOnMountOrArgChange: true
    });
    const data = agentRes?.data;
    const fabricSeatsDashboard = transformToFabricAgentMapSeatDashboard(data?.seats || []);
    const fabricOtherObjects = transformToFabricAgentMapOtherObject(data?.otherObjects || []);
    useEffect(() => {
        if (data && form) {
            form.setFieldsValue({
                ...data,
                fabricSeatsDashboard,
                fabricOtherObjects
            });
        }
    }, [data]);

    return {
        agentMap: data,
        isLoading: isLoading,
        fabricSeatsDashboard,
        fabricOtherObjects
    };
}
