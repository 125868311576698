export type ticketType = {
  ticketId: number; //Phieu yeu cau
  customerCode: string;
  organizationUnitCode: string;
  customerRequestName: string;
  customerRequestPhoneNumber: string;
  customerRequestAddress: string;
  customerRequestCicNumber: number;
  customerRequestCicIssueDate: string;
  customerRequestCicIssuePlace: number;
  customerRequestCicType: number;
  numOfRequests: number;
  content: string;
  isPriority: boolean;
  isDoubleCheck: boolean;
  isRequestMultipleTime: boolean;
  isNotOnTime: boolean;
  channelId: number;
  channel: {
    channelId: number;
    channelCode: string;
    channelName: string;
  };
  serviceTypeId: number;
  serviceType: {
    serviceTypeId: number;
    code: string;
    name: string;
  };
  administrativeUnitId: number;
  administrativeUnit: {
    administrativeUnitId: number;
    administrativeUnitCode: number;
    administrativeUnitName: string;
  };
  cmisRequestId: number;
  appointmentDate: string;
  statusId: number;
  status: {
    optionId: number;
    code: string;
    name: string;
  };
  latitude: number;
  longitude: number;
  createdBy: number;
  createdByName: string;
  createdAt: string;
  createdAtDateOnly: string;
  updatedAt: string;
  closedAt: string;
  closedAtDateOnly: string;
  closedBy: string;
  additionalInfo: string;
};

export type tickets = ticketType[];

export type Option = {
  optionId: number;
  code: string;
  name: string;
};

export interface TicketDetail {
  ticketId: number;
  customerCode: string;
  organizationUnitCode: string;
  organizationUnit: {
    organizationUnitId: number;
    code: string;
    name: string;
    shortName: string;
  };
  customerRequestName: string;
  customerRequestPhoneNumber: string;
  customerRequestAddress: string;
  customerRequestCicNumber: string;
  customerRequestCicIssueDate: string;
  customerRequestCicIssuePlace: string;
  customerRequestCicType: number;
  numOfRequests: number;
  content: string;
  isPriority: boolean;
  isDoubleCheck: boolean;
  isRequestMultipleTime: boolean;
  isNotOnTime: boolean;
  channelId: number;
  channel: {
    channelId: number;
    channelCode: string;
    channelName: string;
  };
  serviceTypeId: number;
  serviceType: {
    serviceTypeId: number;
    code: string;
    name: string;
    childrenCompact: [string];
    parentCompact: {
      serviceTypeId: number;
      code: string;
      name: string;
      childrenCompact: [string];
      parentCompact: string;
      serviceTypeCmisId: number;
      isRequiredCmis: true;
    };
    serviceTypeCmisId: number;
    isRequiredCmis: true;
  };
  administrativeUnitId: number;
  administrativeUnit: {
    administrativeUnitId: number;
    administrativeUnitCode: string;
    administrativeUnitName: string;
  };
  cmisRequestId: string;
  appointmentDate: string;
  statusId: number;
  status: {
    optionId: number;
    code: string;
    name: string;
  };
  latitude: number;
  longitude: number;
  createdBy: number;
  createdByName: string;
  createdAt: string;
  createdAtDateOnly: string;
  updatedAt: string;
  closedAt: string;
  closedAtDateOnly: string;
  closedBy: number;
  ticketEvents: {
    ticketEventId: number;
    batchUuid: string;
    ticketId: number;
    organizationUnitId: number;
    organizationUnitCode: string;
    organizationUnitName: string;
    previousContent: string;
    currentContent: string;
    actionTypeId: number;
    actionType: {
      optionId: number;
      code: string;
      name: string;
    };
    shiftCode: string;
    createdAt: string;
    createdBy: number;
    createdByName: string;
  }[];
  files: {
    id: number;
    ticketId: number;
    ticketEventId: number;
    fileId: number;
    createdBy: number;
    createdAt: string;
    updatedAt: string;
  }[];
  additionalInfo: {
    ticketId: number;
    phaseNumber: number;
    usagePurpose: string;
    outageAt: string;
    outageReasonId: number;
    powerRestorationAt: string;
    isOutageEntireArea: boolean;
    isNotRequestToSendSms: boolean;
    incidenAddress: string;
    incidentAdministrativeUnitId: number;
    createdAt: string;
    createdBy: number;
    createdByName: string;
  };
  customer: {
    customerId: number;
    customerCode: string;
    customerName: string;
    customerAddress: string;
    customerEmail: string;
    customerPhoneNumber: string;
    customerCicNumber: number;
    customerCicIssueDate: string;
    customerCicIssuePlace: string;
    status: boolean;
    organizationUnitCode: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    createdBy: string;
    updatedBy: string;
    deletedBy: string;
    administrativeUnitCode: string;
    meterSerialNumber: number;
    routeCode: string;
    meterPointCode: string;
    organizationUnit: {
      organizationUnitId: number;
      code: string;
      name: string;
      shortName: string;
      organizationUnitClassifyId: number;
      parent: string;
    };
    distributionStationCode: string;
    outgoingLineCode: string;
  };
  ticketHoldManager: [
    {
      ticketHoldManagerId: number;
      ticketId: number;
      isActive: true;
      estimatedEndDate: string;
      content: string;
      obstacleSourceId: number;
      obstacleSource: {
        optionId: number;
        code: string;
        name: string;
      };
      createdBy: number;
      updatedBy: number;
      closedBy: number;
      createdAt: string;
      updatedAt: string;
      closedAt: string;
    }
  ];
  resolution: string;
  receptionBy: number;
  receptionDate: string;
}

export interface FileDetail {
  fileId: number;
  url: string;
  publicUrl: string;
  bucketName: string;
  objectName: string;
  fileType: string;
  fileSize: number;
  createdAt: string;
  updatedAt: string;
  originalName: string;
}

export type RequestData = {
  pageIndex: number;
  pageSize: number;
  ticketId: string;
  fromOrganizationUnitId: number;
  toOrganizationUnitId: number;
  isPrimary: number;
  primaryOrganizationUnitId: number;
  isCollaborative: number;
  collaborativeOrganizationUnitId: number;
  fromDate: string;
  toDate: string;
  isActive: true;
  filterKey?: number;
  filterValue?: string;
  isObstacleTickets?: boolean;
  serviceTypeId?: number[];

  //Status of ticket
  status: string;
};
export interface RequestTicketList {
  currentStatus: string;
  requestData: RequestData;
}

// Transfer Cmis Services
export type CmisServicesRequestData = {
  pageIndex: number;
  pageSize: number;
  code: string;
  prefixCode: string;
  isLoadAll: boolean;
};
export type CmisServicesResponseData = {
  serviceTypeCmisId: number;
  code: string;
  name: string;
  description: string;
  cmisPrefixCode: string;
  priority: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
};

//PATCH TRANSFER TO
export interface TransferToUnit {
  toUnit: string;
  ticketId: number;
}

// PATCH Change Tickets Status
export interface ChangeStatus { }

export interface InprogressTickets {
  ticketId: number;
  processingContent: string;
}

export interface OrganizationUnitChildren {
  organizationUnitId: number;
  code: string;
  name: string;
  shortName: string;
}

export interface CollaborationTicket {
  processingContent: string;
  collaborationTeamId: number;
  fromTeamId: number;
}
export interface CollaborationRequest {
  collaborationDetail: CollaborationTicket;
  ticketId: number;
}

export type UpdateObstacleTicketRequest = {
  ticketId: number;
  content: string;
  estimatedEndDate: string;
  obstacleSourceId: number;

  ticketHoldManagerId: number;
};

export type CompleteCollaborationTicketRequest = {
  ticketId: number;
  processingContent: string;
};

export type TransferCmis = {
  ticketId: number;
  serviceTypeCmisId: number;
};

export type AssignEmployeeTicketRequest = {
  ticketId: number;
  assigneeId: number;
};

export type OrganizationUnitDetail = {
  organizationUnitId: number;
  code: string;
  name: string;
  shortName: string;
  description: string;
  isSystem: true;
  level: number;
  parentId: number;
  parent: {
    organizationUnitId: number;
    code: string;
    name: string;
    shortName: string;
    organizationUnitClassifyId: number;
    parent: string;
  };
  createdBy: number;
  updatedBy: number;
  deletedBy: number;
  createdAt: string;
  updatedAt: string;
  serviceTypes: [
    {
      serviceTypeId: number;
      code: string;
      name: string;
      childrenCompact: [string];
      parentCompact: string;
      serviceTypeCmisId: number;
      isRequiredCmis: true;
    }
  ];
  organizationUnitClassifyId: number;
  organizationUnitClassify: {
    optionId: number;
    code: string;
    name: string;
  };
  headUserId: number;
  headUser: {
    userId: number;
    email: string;
    fullName: string;
    shortName: string;
    avatar: number;
    employeeId: string;
    extensionId: string;
  };
  deputyUsers: [
    {
      userId: number;
      email: string;
      fullName: string;
      shortName: string;
      avatar: number;
      employeeId: string;
      extensionId: string;
    }
  ];
  countServiceTypes: number;
  countUsers: number;
  users: [
    {
      userId: number;
      email: string;
      fullName: string;
      shortName: string;
      avatar: number;
      employeeId: string;
      extensionId: string;
    }
  ];
  countChildren: number;
  children: {
    organizationUnitId: number;
    code: string;
    name: string;
    shortName: string;
    organizationUnitClassifyId: number;
    parent: string;
  };
};

export type OrganizationUnitService = {
  serviceTypeId: number;
  code: string;
  name: string;
  childrenCompact?: string[];
  parentCompact?: string;
  serviceTypeCmisId: number;
  isRequiredCmis: boolean;
};
export type OrganizationUnitServices = OrganizationUnitService[];

export type OptionDetail = {
  optionId: number;
  code: string;
  name: string;
};
export type CreateObstacleTicketRequest = {
  ticketId: number;
  content: string;
  estimatedEndDate: string;
  obstacleSourceId: number;
};

export type UserInOrganizationUnitResponse = {
  userId: number;
  fullName: string;
  shortName: string;
  avatar: number;
  employeeId: string;
  email: string;
  extensionId: string;
};

//Default Data
const defaultTicketDetail: TicketDetail = {
  ticketId: 0,
  customerCode: '',
  organizationUnitCode: '',
  organizationUnit: {
    organizationUnitId: 0,
    code: '',
    name: '',
    shortName: ''
  },
  customerRequestName: '',
  customerRequestPhoneNumber: '',
  customerRequestAddress: '',
  customerRequestCicNumber: '',
  customerRequestCicIssueDate: '',
  customerRequestCicIssuePlace: '',
  customerRequestCicType: 0,
  numOfRequests: 0,
  content: '',
  isPriority: false,
  isDoubleCheck: false,
  isRequestMultipleTime: false,
  isNotOnTime: false,
  channelId: 0,
  channel: {
    channelId: 0,
    channelCode: '',
    channelName: ''
  },
  serviceTypeId: 0,
  serviceType: {
    serviceTypeId: 0,
    code: '',
    name: '',
    childrenCompact: [''],
    parentCompact: {
      serviceTypeId: 0,
      code: '',
      name: '',
      childrenCompact: [''],
      parentCompact: '',
      serviceTypeCmisId: 0,
      isRequiredCmis: true
    },
    serviceTypeCmisId: 0,
    isRequiredCmis: true
  },
  administrativeUnitId: 0,
  administrativeUnit: {
    administrativeUnitId: 0,
    administrativeUnitCode: '',
    administrativeUnitName: ''
  },
  cmisRequestId: '',
  appointmentDate: '',
  statusId: 0,
  status: {
    optionId: 0,
    code: '',
    name: ''
  },
  latitude: 0,
  longitude: 0,
  createdBy: 0,
  createdByName: '',
  createdAt: '',
  createdAtDateOnly: '',
  updatedAt: '',
  closedAt: '',
  closedAtDateOnly: '',
  closedBy: 0,
  ticketEvents: [],
  files: [],
  additionalInfo: {
    ticketId: 0,
    phaseNumber: 0,
    usagePurpose: '',
    outageAt: '',
    outageReasonId: 0,
    powerRestorationAt: '',
    isOutageEntireArea: false,
    isNotRequestToSendSms: false,
    incidenAddress: '',
    incidentAdministrativeUnitId: 0,
    createdAt: '',
    createdBy: 0,
    createdByName: ''
  },
  customer: {
    customerId: 0,
    customerCode: '',
    customerName: '',
    customerAddress: '',
    customerEmail: '',
    customerPhoneNumber: '',
    customerCicNumber: 0,
    customerCicIssueDate: '',
    customerCicIssuePlace: '',
    status: true,
    organizationUnitCode: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    createdBy: '',
    updatedBy: '',
    deletedBy: '',
    administrativeUnitCode: '',
    meterSerialNumber: 0,
    routeCode: '',
    meterPointCode: '',
    organizationUnit: {
      organizationUnitId: 0,
      code: '',
      name: '',
      shortName: '',
      organizationUnitClassifyId: 0,
      parent: ''
    },
    distributionStationCode: '',
    outgoingLineCode: ''
  },
  ticketHoldManager: [
    {
      ticketHoldManagerId: 0,
      ticketId: 0,
      isActive: true,
      estimatedEndDate: '',
      content: '',
      obstacleSourceId: 0,
      obstacleSource: {
        optionId: 0,
        code: '',
        name: ''
      },
      createdBy: 0,
      updatedBy: 0,
      closedBy: 0,
      createdAt: '',
      updatedAt: '',
      closedAt: ''
    }
  ],
  resolution: '',
  receptionBy: 0,
  receptionDate: ''
};
export default defaultTicketDetail;
