export const createTicketMessages = {
    requesterInformation: 'Thông tin người yêu cầu',
    relatives: 'Thân nhân, người liên hệ',
    rightfullOwner: 'Chính chủ',
    notProvide: 'KH không cung cấp',
    fullName: 'Họ và tên:',
    phoneNumber: 'Điện thoại liên lạc:',
    address: 'Địa chỉ:',
    ward: 'Phường',
    district: 'Quận',
    newAddress: 'Đây là địa chỉ mới',
    citizenIdNumber: 'Số CCCD:',
    issueDate: 'Ngày cấp:',
    placeOfIssue: 'Nơi cấp:',
    selectReceptionChannel: 'Chọn kênh tiếp nhận',
    requestInformation: 'Thông tin yêu cầu',
    selectServiceType: 'Chọn loại dịch vụ',
    appointmentDate: 'Ngày hẹn:',
    requestContent: 'Nội dung yêu cầu:',
    template: 'Mẫu',
    notMessageSendingRequest: 'Không phải yêu cầu gửi SMS',
    outgoingCall: 'Đây là cuộc gọi ra',
    forwardForProcessing: 'Chuyển xử lý',
    redirectToTheCenter: 'Chuyển trung tâm',
    instructions: 'Hướng dẫn',
    detail: 'Chi tiết',
    document: 'Hồ sơ',
    relatedInformation: 'Thông tin liên quan',
    sample1: 'Mẫu 1',
    sample2: 'Mẫu 2',
    sample3: 'Mẫu 3',
    dragAndDropFilesToUploadOr: 'Kéo thả tệp để tải lên.Hoặc',
    selectFromDevice: 'chọn từ thiết bị',
    directCustomerContact: 'KH trực tiếp liên hệ',
    customerFullName: 'Họ tên KH:',
    customerPhone: 'Điện thoại KH',
    customerAddress: 'Địa chỉ KH',
    routeSession: 'Phiên lộ trình',
    designation: 'Chỉ danh',
    unit: 'Đơn vị',
    addressAndStreet: 'Số nhà, tên đường',
    selectWard: 'Chọn Phường',
    selectDistrict: 'Chọn Quận',
    contactPerson: 'Người liên hệ',
    createTicketSuccess: 'Hệ thông đã ghi nhận tạo phiếu!',
    createTicketError: 'Có lỗi xảy ra khi tạo phiếu!',
    citizenId: 'CCCD:',
    phaseNumber: 'Số pha:',
    usagePurpose: 'Mục đích sử dụng:',
    outageAt: 'Ngày giờ mất điện:',
    incidenAddress: 'Địa chỉ sự cố:',
    isOutageEntireArea: 'Mất điện cả khu vực',
    selectOutageReason: 'Chọn nguyên nhân mất điện',
    customerRequestNameRequired: 'Tên người yêu cầu không được để trống',
    customerRequestAddressRequired: 'Địa chỉ người yêu cầu không được để trống',
    customerRequestPhoneNumberRequired: 'Số điện thoại người yêu cầu không được để trống',
    contentRequired: 'Nội dung yêu cầu không được để trống',
    residential: 'Sinh hoạt',
    nonResidential: 'Ngoài sinh hoạt',
    phaseNumber1: '1 pha',
    phaseNumber3: '3 pha',
    phone: 'Số điện thoại',
    serviceType: 'Loại dịch vụ',
    confirmSendTicket: 'Xác nhận gửi phiếu',
    edit:'Chỉnh sửa',
    transferProcessingUnit: 'Chuyển đơn vị xử lý',
    customerCode: 'Mã KH:',
    receptionChannel: 'Kênh tiếp nhận:',
    attachFile: 'File đính kèm',
    customerNotEnter: 'KH không cung cấp',
    requestServiceInfo: 'Thông tin dịch vụ yêu cầu',
    channelRequired: 'Kênh tiếp nhận không được để trống',
    administrativeUnitRequired: 'Phường/Quận không được để trống',
    districtRequired: 'Quận không được để trống',
    wardRequired: 'Phường không được để trống',
    wardDistrict: 'Phường/Quận:',
    customerRequestPhoneNumberInvalid: 'Số điện thoại không hợp lệ',
    contentTooLong: 'Nội dung không được quá 1000 ký tự',
    customerRequestNameTooLong: 'Tên người yêu cầu không được quá 255 ký tự',
    customerRequestAddressTooLong: 'Địa chỉ người yêu cầu không được quá 255 ký tự',
    customerRequestPhoneNumberTooLong: 'Số điện thoại không được quá 20 ký tự',
    customerRequestCicNumberTooLong: 'Số CCCD không được quá 20 ký tự',
    customerRequestCicIssuePlaceTooLong: 'Nơi cấp CCCD không được quá 255 ký tự',
    ddMmYyyy: '(dd/mm/yyyy)',
};