import { Typography } from 'antd';
import { TreeChildrenIcon } from 'assets';
import { InputSearch, InputSearchV2 } from 'components/common';
import { CheckboxOptionItem, RightSideCheckboxV2 } from 'components/common/checkbox';
import { usePermissionsHierarchyOptions } from 'hooks';
import { uniqBy } from 'lodash';
import { permissionsMessages } from 'messages';
import { useState } from 'react';
import {
  FindPermissionHierarchyDto,
  OrganizationUnitHierarchyCompactDto,
  PermissionCompactDto,
  PermissionHierarchyCompactDto
} from 'types';
import { removeVietnameseAccents } from 'utils';

type TreeCheckboxPermissionsProps = FindPermissionHierarchyDto & {
  value?: PermissionCompactDto[];
  onChange?: (value: PermissionCompactDto[]) => void;
};

const TreeCheckboxPermissions: React.FC<TreeCheckboxPermissionsProps> = ({
  organizationUnitIds,
  roleIds,
  value,
  onChange
}) => {
  const [keyword, setKeyword] = useState('');

  const { data: permissionHierachy } = usePermissionsHierarchyOptions({
    organizationUnitIds,
    roleIds
  });

  const getAllOrganizationUnitIds = (units: PermissionHierarchyCompactDto[]): PermissionHierarchyCompactDto[] => {
    return units.flatMap(({ children, ...item }) => [item, ...(children ? getAllOrganizationUnitIds(children) : [])]);
  };

  const allPermissionFlatMap = getAllOrganizationUnitIds(permissionHierachy);

  const formatData = (data: PermissionHierarchyCompactDto[]): CheckboxOptionItem[] => {
    if (data.length === 0) return [];

    return data.map((item) => ({
      key: item.permissionId.toString(),
      value: item.permissionId.toString(),
      label: item.name,
      children: item?.children && item.children.length > 0 ? formatData(item.children) : []
    }));
  };

  const handleChange = (selectedValue: string[]) => {
    const selectedIds = selectedValue.map((val) => Number(val));

    const selectedPermissions = uniqBy([...(value || []), ...allPermissionFlatMap], 'permissionId').filter((opt) =>
      selectedIds.includes(opt.permissionId)
    );

    onChange?.(selectedPermissions);
  };

  const handleSelectAll = () => {
    const selectAll = getAllOrganizationUnitIds(permissionHierachy);
    handleChange(selectAll.map((o) => o.permissionId.toString()));
  };

  const countData = (items: OrganizationUnitHierarchyCompactDto[]): number =>
    items.reduce((count, item) => count + 1 + (item.children ? countData(item.children) : 0), 0);

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const searchTree = (data: CheckboxOptionItem[], keyword = ''): CheckboxOptionItem[] => {
    const trimKeyword = removeVietnameseAccents(keyword).toLowerCase();
    const result: CheckboxOptionItem[] = [];
    data.forEach((item) => {
      const isValid = removeVietnameseAccents(item.label as string)
        .toLowerCase()
        .includes(trimKeyword);
      const children = item.children && item.children.length > 0 ? searchTree(item.children, keyword) : [];
      if (isValid || children.length > 0) {
        result.push({
          ...item,
          children: children.length > 0 ? children : undefined
        });
      }
    });
    return result;
  };

  return (
    <div>
      <InputSearch
        className='mb-6 rounded-lg border'
        placeholder={permissionsMessages.name}
        onChange={(e) => handleSearch(e.toString())}
      />
      <RightSideCheckboxV2
        split
        options={searchTree(formatData(permissionHierachy), keyword)}
        onChange={handleChange}
        prefix={<Typography.Text className='font-semibold'>•</Typography.Text>}
        childrenPrefix={<TreeChildrenIcon />}
        value={value?.map((item) => item.permissionId.toString())}
      />
    </div>
  );
};
export default TreeCheckboxPermissions;
