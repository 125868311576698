export * from './chat-file-type-accept.constant';
export * from './file-symbol.constant';
export * from './item-select-all.constant';
export * from './local-storage-key.constant';
export * from './message.constant';
export * from './service-types-form.constant';
export * from './sidebar-menu.constant';
export * from './weekdays.constant';
export * from './regexp.constant';
export * from './agent-map-grid.constant';
export * from './shift-default-icons.constant';
export * from './editor-plugins-init.constant';
export * from './editor-toolbar-init.constant';
export * from './contact-event-channel.constant'

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PASSWORD_CHART = '********';
export const DAY_FORMAT = 'dddd';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'HH:mm DD/MM/YYYY';
export const WEEKDAY_FORMAT = 'ddd, DD/MM/YYYY';
export const MONTH_FORMAT = '[Tháng] MM, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_STAMP_FORMAT = 'HH:mm:ss';
export const ID_CRM_HEADER = 'crm-header';
export const ID_PAGE_HEADER = 'page-header';
export const DEFAULT_LIST_INFO_SIZE = 5;
