export enum AGENT_STATUS {
  LOGGEDOFF = 'LOGGEDOFF',
  IDLE = 'IDLE',
  ONCALL = 'ONCALL',
  RINGING = 'RINGING',
  UNKNOWN = 'UNKNOWN',
  AGENT_PAUSED = 'PAUSED',
  AGENT_UNPAUSED = 'UNPAUSED',
  INCOMING_CALL = 'INCOMING_CALL',
  FAILED_LOGIN = 'FAILED_LOGIN',
  MUTE_ON = 'MUTE ON',
  CREATING_TICKET = 'CREATING_TICKET',
  DEFAULT = 'DEFAULT STATE'
}

export enum CALL_DIRECTION {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound'
}
