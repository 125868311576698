import { TicketDetail } from '../../types';
import { Typography } from 'antd';
import { GreenCheck } from '../../assets';
import { requestform } from '../../messages';
import RequestStatusBar from '../request-form/RequestStatusBar';
import { DownOutlined } from '@ant-design/icons';
import RequestDetailMainInformation from '../request-form/RequestDetailMainInformation';
import React from 'react';
import NormalTicketOpenProcessing from './NormalTicketOpenProcessing';
import NormalRequestDetailCreateInformation from './NormalTicketDetailCreateInformation';

interface NormalTikcetProps {
  ticket: TicketDetail;
  status?: string;
  ticketId: number;
}
const NormalTicket: React.FC<NormalTikcetProps> = ({ ticket, status }) => {
  return (
    <div className='p-10'>
      <div className='flex justify-center'>
        <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
          {requestform.requestTicketCRM}
        </Typography.Text>
      </div>
      <div className='flex w-full p-2 text-left'>
        <div className='flex items-center'>
          <NormalRequestDetailCreateInformation requestItem={ticket} />
        </div>
        <div className='flex w-[70%] flex-col justify-between'>
          <section className='p-6'>
            <section className='items-center justify-between'>
              <div>
                <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
                  {requestform.serviceType}
                </Typography.Text>
              </div>
              <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
                {ticket?.serviceType?.name ? ticket.serviceType.name.split(' ').slice(0, 20).join(' ') : ''}
              </Typography.Text>
            </section>

            <section>
              <Typography.Text
                className={`text-[14px]/[18px] ${ticket?.isRequestMultipleTime ? 'text-[#D1131D]' : 'text-[#06A77D]'}`}
              >
                {ticket?.isRequestMultipleTime ? requestform.multiplleTimeRequest : requestform.notMultiplleTimeRequest}
              </Typography.Text>
            </section>

            <section>
              {status && (
                <div className='pb-6'>
                  <RequestStatusBar status={status} />
                  {status === 'pending-reception' ? (
                    <div className='request-status-bar mt-1 justify-between rounded-[5px] bg-[#EBEBED] px-4 py-[11px]'>
                      <Typography.Text className='text-[14px]/[18px] font-semibold text-[#141414]'>
                        {requestform.isTranferCmisTicket}
                      </Typography.Text>
                      <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                        {requestform.typeCmisTicket}
                      </Typography.Text>{' '}
                      <DownOutlined className='h-2 w-[10px]' />
                    </div>
                  ) : ticket?.cmisRequestId == null ? (
                    <div className='request-status-bar mt-1 flex justify-between rounded-[5px] border border-[#D1131D] bg-[#06A77D00] px-4 py-[11px]'>
                      <div>
                        <Typography.Text className='text-[14px]/[18px] font-semibold text-[#D1131D]'>
                          {requestform.cmisTransferFail}
                        </Typography.Text>{' '}
                        <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                          {requestform.typeCmisTicket}
                        </Typography.Text>{' '}
                        <DownOutlined className='h-2 w-[10px]' />
                      </div>
                    </div>
                  ) : (
                    <div className='request-status-bar mt-1 flex justify-between rounded-[5px] bg-[#06A77D00] py-[11px]'>
                      <div className='flex space-x-1'>
                        <GreenCheck />
                        <Typography.Text className='text-[14px]/[18px] italic text-[#141414]'>
                          {requestform.cmisTranfered}
                          {ticket.cmisRequestId}
                        </Typography.Text>
                      </div>{' '}
                      <Typography.Text className='text-[14px]/[18px] italic text-[#868D97]'>
                        {/* This is static data, add dynamic data latter */}
                        Lúc 16:32, 27/12/2024
                      </Typography.Text>
                    </div>
                  )}
                </div>
              )}
            </section>

            <section className={`h-[688px]`}>
              <RequestDetailMainInformation requestItem={ticket} />
              <NormalTicketOpenProcessing requestItem={ticket} />
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};

export default NormalTicket;
