import { MenuProps } from 'antd';
import {
  CollectPaymentIcon,
  ConnectIcon,
  CoordinationRequiredIcon,
  CustomerLookupIcon,
  EditFormIcon,
  ExplainReportIcon,
  FollowFormIcon,
  FormHandleIcon,
  HeadPhoneIcon,
  IncorrectPointIcon,
  ManagementIcon,
  ReportIcon,
  SmsIcon,
  SupervisorIcon,
  SystemIcon,
  UserCircleOutlinedIcon,
  UserGuideIcon
} from 'assets';
import { sidebarMenuMessages } from 'messages/sidebar-menu.messages';
import { ReactNode } from 'react';
import { ROUTE } from 'routes/constants';
import { PERMISSION, SIDEBAR_MENU_KEY } from 'utils/enums';

export type MenuItemType = Required<MenuProps>['items'][number] & {
  key?: string;
  href?: string | string[];
  label?: string | ReactNode;
  icon?: ReactNode | string;
  permission?: number | number[];
  children?: MenuItemType[];
  hidden?: boolean;
};

export const MENU_LIST_ITEM: MenuItemType[] = [
  {
    key: SIDEBAR_MENU_KEY.SHIFT_SUPERVISOR,
    label: sidebarMenuMessages.shiftSupervisor,
    icon: <SupervisorIcon />,
    children: [
      {
        key: SIDEBAR_MENU_KEY.DASHBOARD_AGENT_MAP,
        label: sidebarMenuMessages.dashboardAgentMap,
        href: ROUTE.ACCESS_DTV_MANAGEMENT
        // permission:
      },
      {
        key: SIDEBAR_MENU_KEY.TRACK_TICKET_PROCESSING,
        label: sidebarMenuMessages.trackTicketProcessing,
        href: ROUTE.TRACKING_TICKETS_PROCESSING
        // permission:
      }
    ],
    permission: PERMISSION.ACCESS_MANAGE_DTV
  },

  {
    key: SIDEBAR_MENU_KEY.CUSTOMER_CARE,
    label: sidebarMenuMessages.customerCare,
    icon: <HeadPhoneIcon />,
    href: ROUTE.CUSTOMER_LOOKUP,
    permission: PERMISSION.ACCESS_CUSTOMER_LOOKUP
  },
  {
    key: SIDEBAR_MENU_KEY.USERS_MANAGEMENT,
    label: sidebarMenuMessages.usersManagement,
    icon: <UserCircleOutlinedIcon />,
    children: [
      {
        key: SIDEBAR_MENU_KEY.USERS,
        label: sidebarMenuMessages.users,
        href: [ROUTE.USERS, ROUTE.USER_GROUPS],
        permission: [PERMISSION.READ_USER, PERMISSION.READ_USER_GROUP]
      },
      {
        key: SIDEBAR_MENU_KEY.PERMISSIONS,
        label: sidebarMenuMessages.permissions,
        href: [ROUTE.PERMISSIONS, ROUTE.ROLES],
        permission: [PERMISSION.UPDATE_PERMISSION, PERMISSION.READ_ROLE]
      }
    ],
    permission: PERMISSION.ACCESS_MANAGE_ACCOUNT
  },

  {
    key: SIDEBAR_MENU_KEY.CONNECT_MANAGEMENT,
    label: sidebarMenuMessages.connectManagement,
    icon: <ConnectIcon />,
    children: [
      {
        key: SIDEBAR_MENU_KEY.SYSTEM_CONFIGS,
        label: sidebarMenuMessages.systemConfigs,
        href: [ROUTE.RATING_SYSTEM, ROUTE.CALL_CENTER_SYSTEM],
        permission: PERMISSION.UPDATE_SYSTEM_CONFIG
      },
      {
        key: SIDEBAR_MENU_KEY.API_SHARING_PARTNERS,
        label: sidebarMenuMessages.apiSharingPartners,
        href: ROUTE.API_SHARING_PARTNERS,
        permission: PERMISSION.READ_API_SHARING_PARTNER
      },
      {
        key: SIDEBAR_MENU_KEY.FANPAGE_CONNECTION,
        label: sidebarMenuMessages.fanpageConnection,
        href: ROUTE.FANPAGE_CONNECTIONS
      }
    ],
    permission: PERMISSION.ACCESS_MANAGE_CONNECTION
  },

  {
    key: SIDEBAR_MENU_KEY.CONFIG_MANAGEMENT,
    label: sidebarMenuMessages.configManagement,
    icon: <SystemIcon />,
    children: [
      {
        key: SIDEBAR_MENU_KEY.SERVICE_TYPES,
        label: sidebarMenuMessages.serviceTypes,
        href: ROUTE.SERVICE_TYPES,
        permission: PERMISSION.READ_SERVICE_TYPE
      },
      {
        key: SIDEBAR_MENU_KEY.TICKET_SAMPLES,
        label: sidebarMenuMessages.ticketSamples,
        href: ROUTE.TICKET_SAMPLES,
        permission: PERMISSION.READ_TICKET_SAMPLE
      },
      {
        key: SIDEBAR_MENU_KEY.AGENT_STATUS,
        label: sidebarMenuMessages.agentStatus,
        href: ROUTE.REASONS,
        permission: PERMISSION.READ_REASON
      },
      {
        key: SIDEBAR_MENU_KEY.SHIFTS,
        label: sidebarMenuMessages.agentShift,
        href: ROUTE.SHIFTS,
        permission: PERMISSION.READ_SHIFT
      },
      {
        key: SIDEBAR_MENU_KEY.TICKET_CANCEL_REASONS,
        label: sidebarMenuMessages.ticketCancelReasons,
        href: ROUTE.TICKET_CANCEL_REASONS,
        permission: PERMISSION.READ_TICKET_CANCEL_REASON
      }
    ],
    permission: PERMISSION.ACCESS_SYSTEM
  },

  {
    key: SIDEBAR_MENU_KEY.OPERATIONS_MANAGEMENT,
    label: sidebarMenuMessages.operationsManagement,
    icon: <ManagementIcon />,
    children: [
      {
        key: SIDEBAR_MENU_KEY.AGENT_MAPS,
        label: sidebarMenuMessages.agentMaps,
        href: ROUTE.AGENT_MAPS,
        permission: PERMISSION.READ_AGENT_MAP
      },
      {
        key: SIDEBAR_MENU_KEY.ORGANIZATION_UNIT,
        label: sidebarMenuMessages.organizationUnit,
        href: ROUTE.ORGANIZATION_UNITS,
        permission: PERMISSION.READ_ORGANIZATION_UNIT
      },
      {
        key: SIDEBAR_MENU_KEY.ORGANIZATION_UNIT_TICKET_DEFAULT_ASSIGNS,
        label: sidebarMenuMessages.organizationUnitTicketDefaultAssigns,
        href: ROUTE.ORGANIZATION_UNIT_TICKET_DEFAULT_ASSIGNS,
        permission: PERMISSION.CONFIG_ORGANIZATION_UNIT_DEFAULT_HANDLE_TICKET
      },
      {
        key: SIDEBAR_MENU_KEY.INTERNAL_ANNOUNCEMENTS,
        label: sidebarMenuMessages.internalAnnouncements,
        href: ROUTE.INTERNAL_ANNOUNCEMENTS,
        permission: PERMISSION.READ_INTERNAL_ANNOUNCEMENT
      },
      {
        key: SIDEBAR_MENU_KEY.VIP_CUSTOMERS,
        label: sidebarMenuMessages.vipCustomers,
        href: ROUTE.VIP_CUSTOMERS,
        permission: PERMISSION.READ_VIP_CUSTOMER
      },
      {
        key: SIDEBAR_MENU_KEY.WORKING_SCHEDULES,
        label: sidebarMenuMessages.workingSchedules,
        href: ROUTE.WORKING_SCHEDULES,
        permission: PERMISSION.READ_WORKING_SCHEDULE
      },
      {
        key: SIDEBAR_MENU_KEY.SERVICE_INSTRUCTIONS,
        label: sidebarMenuMessages.serviceInstructions,
        href: ROUTE.SERVICE_INSTRUCTIONS,
        permission: PERMISSION.READ_SERVICE_INSTRUCTION
      },
      {
        key: SIDEBAR_MENU_KEY.CUSTOMER_SUPPORT_INFORMATION,
        label: sidebarMenuMessages.customerSupportInformation,
        href: ROUTE.CUSTOMER_SUPPORT_INFORMATION,
        permission: PERMISSION.READ_CUSTOMER_SUPPORT_INFORMATION
      },
      {
        key: SIDEBAR_MENU_KEY.FREQUENTLY_ASKED_QUESTIONS,
        label: sidebarMenuMessages.frequentlyAskedQuestions,
        href: ROUTE.FREQUENTLY_ASKED_QUESTION,
        permission: PERMISSION.READ_FREQUENTLY_ASKED_QUESTION
      }
    ],
    permission: PERMISSION.ACCESS_MANAGEMENT
  },

  {
    key: SIDEBAR_MENU_KEY.CUSTOMER_SEARCH,
    label: sidebarMenuMessages.customerSearch,
    icon: <CustomerLookupIcon />,
    href: ROUTE.CUSTOMER_SEARCH,
    permission: PERMISSION.ACCESS_DIENLUC_CUSTOMER_SEARCH
  },
  {
    key: SIDEBAR_MENU_KEY.COORDINATION_REQUIRED,
    label: sidebarMenuMessages.coordinationRequired,
    icon: <CoordinationRequiredIcon />,
    children: [
      {
        key: SIDEBAR_MENU_KEY.MOVE_CENTER,
        label: sidebarMenuMessages.moveCenter,
        href: ROUTE.MOVE_CENTER,
        permission: PERMISSION.READ_TICKET_TRANSFERED_TO_TRUONGCA
      },
      {
        key: SIDEBAR_MENU_KEY.TRANSFER_CUSTOMER_SERVICE_TEAM,
        label: sidebarMenuMessages.transferCustomerServiceTeam,
        href: ROUTE.TRANSFER_CUSTOMER_SERVICE_TEAM,

        permission: PERMISSION.READ_TICKET_TRANSFERED_TO_CSKH
      },
      {
        key: SIDEBAR_MENU_KEY.MOVE_TECHNICAL_DEPARTMENT,
        label: sidebarMenuMessages.moveTechnicalDepartment,
        href: ROUTE.MOVE_TECHNICAL_DEPARTMENT,
        permission: PERMISSION.READ_TICKET_TRANSFERED_TO_KYTHUAT
      }
    ],
    permission: PERMISSION.ACCESS_NEED_COOPERATE_REQUEST
  },
  {
    key: SIDEBAR_MENU_KEY.FOLLOWUP_REQUEST,
    label: sidebarMenuMessages.followupRequests,
    icon: <FollowFormIcon />,
    href: ROUTE.FOLLOWING_REQUEST,
    permission: PERMISSION.ACCESS_NEED_TRACKING_REQUEST
  },
  {
    key: SIDEBAR_MENU_KEY.REQUEST_FORM,
    label: sidebarMenuMessages.requestForm,
    icon: <FormHandleIcon />,
    href: ROUTE.REQUEST_FORM,
    permission: PERMISSION.ACCESS_TICKET
  },
  {
    key: SIDEBAR_MENU_KEY.INCORRECT_MEASUREMENT_POINT_ADDRESS,
    label: sidebarMenuMessages.incorrectMeasurementPointAddress,
    icon: <IncorrectPointIcon />,
    href: ROUTE.INCORRECT_MEASUREMENT_POINT_ADDRESS,
    permission: PERMISSION.ACCESS_INCORRECT_RECORDING_LOCATION
  },
  {
    key: SIDEBAR_MENU_KEY.CONFIRM_REFLECTION_REQUEST,
    label: sidebarMenuMessages.confirmReflectionRequest,
    icon: <EditFormIcon />,
    href: '/#confirmReflectionRequest',
    permission: PERMISSION.ACCESS_CONFIRM_FEEDBACK
  },
  {
    key: SIDEBAR_MENU_KEY.COLLECT_PAYMENT,
    label: sidebarMenuMessages.collectPayment,
    icon: <CollectPaymentIcon />,
    href: ROUTE.COLLECT_PAYMENT,
    permission: PERMISSION.ACCESS_PAYMENT_COLLECTION
  },
  {
    key: SIDEBAR_MENU_KEY.SMS,
    label: sidebarMenuMessages.sms,
    icon: <SmsIcon />,
    href: '/#sms',
    permission: PERMISSION.ACCESS_SMS
  },
  {
    key: SIDEBAR_MENU_KEY.EXPLAIN_REPORT,
    label: sidebarMenuMessages.explainReport,
    icon: <ExplainReportIcon />,
    href: '/#explainReport',
    permission: PERMISSION.ACCESS_REPORT_EXPLANATION
  },
  {
    key: SIDEBAR_MENU_KEY.REPORTS,
    label: sidebarMenuMessages.reports,
    href: '/#reports',
    icon: <ReportIcon />,
    permission: PERMISSION.ACCESS_REPORT
  },
  {
    key: SIDEBAR_MENU_KEY.USER_GUIDE,
    label: sidebarMenuMessages.userGuide,
    href: '/#userGuide',
    icon: <UserGuideIcon />,
    permission: PERMISSION.ACCESS_USER_GUIDE
  },
  {
    key: SIDEBAR_MENU_KEY.PROFILE,
    label: sidebarMenuMessages.profile,
    icon: 'avatar',
    href: ROUTE.PROFILE,
    hidden: true
  }
  // {
  //   key: SIDEBAR_MENU_KEY.CONVERT_UNIT_ROOM_TEAM,
  //   label: sidebarMenuMessages.convertUnitRoomTeam,
  //   href: '#',
  //   icon: <ConvertIcon />,
  //   permission: PERMISSION.CHANGE_ORGANIZATION_UNIT
  // }
];
