import { useContext } from 'react';
import { AgentMapContext, AgentMapContextProps } from './AgentMapProvider';

export const useAgentMapContext = (): AgentMapContextProps => {
  const context = useContext(AgentMapContext);
  if (!context) {
    throw new Error('useAgentMapContext must be used within a AgentMapContextProps');
  }
  return context;
};
