import { connectNotificationSocket, disconnectNotificationSocket, offReceiveNotification, onReceiveAgentUpdateNotification } from 'services';

/**
 * A true singleton implementation for socket connections that ensures
 * only one socket connection exists for the entire application lifetime.
 */
class SocketConnectionSingleton {
    private static instance: SocketConnectionSingleton;
    private isConnected = false;
    private handlers: Map<string, Function> = new Map();
    private masterHandler: Function | null = null;
    private debug = process.env.NODE_ENV === 'development';

    private constructor() {
        // Connect immediately on initialization
        this.connect();

        // Set up disconnect on page unload
        window.addEventListener('beforeunload', () => {
            this.disconnect();
        });

        if (this.debug) {
            console.log('[SocketSingleton] Initialized');
        }
    }

    public static getInstance(): SocketConnectionSingleton {
        if (!SocketConnectionSingleton.instance) {
            SocketConnectionSingleton.instance = new SocketConnectionSingleton();
        }
        return SocketConnectionSingleton.instance;
    }

    private connect(): void {
        if (!this.isConnected) {
            connectNotificationSocket();
            this.isConnected = true;

            if (this.debug) {
                console.log('[SocketSingleton] Connection established');
            }
        }
    }

    private disconnect(): void {
        if (this.isConnected) {
            if (this.masterHandler) {
                offReceiveNotification();
                this.masterHandler = null;
            }

            disconnectNotificationSocket();
            this.isConnected = false;

            if (this.debug) {
                console.log('[SocketSingleton] Connection closed');
            }
        }
    }

    /**
     * Registers an agent update handler. Will reuse the existing socket connection.
     */
    public onAgentUpdate(id: string, handler: Function): void {
        if (!this.isConnected) {
            this.connect();
        }

        // Store handler by ID
        this.handlers.set(id, handler);

        // Create master handler if not already created
        if (!this.masterHandler) {
            this.masterHandler = (payload: any) => {
                // Call all registered handlers with the payload
                this.handlers.forEach((h) => {
                    try {
                        h(payload);
                    } catch (err) {
                        console.error('[SocketSingleton] Error in handler:', err);
                    }
                });
            };

            // Register the master handler with the socket
            onReceiveAgentUpdateNotification(this.masterHandler as any);

            if (this.debug) {
                console.log('[SocketSingleton] Master handler registered');
            }
        }

        if (this.debug) {
            console.log(`[SocketSingleton] Handler ${id} registered, total: ${this.handlers.size}`);
        }
    }

    /**
     * Removes a registered handler by ID
     */
    public offAgentUpdate(id: string): void {
        if (this.handlers.has(id)) {
            this.handlers.delete(id);

            if (this.debug) {
                console.log(`[SocketSingleton] Handler ${id} removed, remaining: ${this.handlers.size}`);
            }

            // If no more handlers, remove the master handler
            if (this.handlers.size === 0 && this.masterHandler) {
                offReceiveNotification();
                this.masterHandler = null;

                if (this.debug) {
                    console.log('[SocketSingleton] All handlers removed, master handler unregistered');
                }
            }
        }
    }
}

export default SocketConnectionSingleton;
