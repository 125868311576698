import { Button, ButtonProps, Popconfirm } from 'antd';
import { PlusIcon } from 'assets';
import { customerLookupDetailedMessages } from 'messages/customer-lookup-detailed.message';
import React, { useState } from 'react';
import { useCreateFinishedTicketQuickSearchMutation } from 'services/tickets';
import { RequestCreateFinishedTicketQuickSearchDto } from 'types/dto/ticket';
import { message } from 'components/common';
import { createTicketMessages } from 'messages/create-ticket.messages';

export type CreateFinishedTicketQuickSearchFormType = {
  customerCode: string;
  customerRequestPhoneNumber: string;
  channelId: number;
  serviceTypeId: number;
  quickSearchType: string;
};

interface QuickSearchButtonProps extends ButtonProps {
  formData: RequestCreateFinishedTicketQuickSearchDto;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

const QuickSearchButton: React.FC<QuickSearchButtonProps> = ({ formData, onSuccess, onError,children, ...buttonProps }) => {
  const [onCreateFinishedTicketQuickSearch, { isLoading }] = useCreateFinishedTicketQuickSearchMutation();
  const [loading, setLoading] = useState(false);

  const onFinishTicketQuickSearch = async () => {
    try {
      setLoading(true);
      await onCreateFinishedTicketQuickSearch(formData).unwrap();
      onSuccess?.();
      message.success(createTicketMessages.createTicketSuccess);
    } catch (error) {
      onError?.(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popconfirm
      title="Bạn có muốn tạo phiếu hoàn tất tra cứu nhanh không?"
      onConfirm={onFinishTicketQuickSearch}
      okText="Tạo phiếu"
      cancelText="Không"
    >
      <Button
      className='w-[174px] h-[32px] text-[#1564E8] border-[#1564E8]'
      icon={<PlusIcon />}
      iconPosition='start'
      loading={loading || isLoading}
      {...buttonProps}
      >
      {children || customerLookupDetailedMessages.quickFinish}
      </Button>
    </Popconfirm>
  );
};

export default QuickSearchButton;
