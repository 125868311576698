export const ChannelObject = {
    DIEN_THOAI: { id: 1, code: 'dienthoai', name: 'Tổng đài CSKH' },
    EMAIL: { id: 2, code: 'email', name: 'Email' },
    SMS: { id: 3, code: 'sms', name: 'Sms' },
    TRUC_TIEP: { id: 4, code: 'tructiep', name: 'Giao dịch ĐLực' },
    WEBSITE: { id: 5, code: 'website', name: 'Website' },
    DICH_VU_CONG: { id: 6, code: 'DICHVUCONG', name: 'Dịch vụ công' },
    MXH: { id: 7, code: 'MXH', name: 'Mạng xã hội' },
    OTT: { id: 8, code: 'OTT', name: 'Ứng dụng CSKH' },
    DIEN_THOAI_WEB: { id: 9, code: 'dienthoai_web', name: 'Điện thoại từ Web' },
    DIEN_THOAI_APP: { id: 10, code: 'dienthoai_app', name: 'Điện thoại từ App' },
    CALL_BOT: { id: 11, code: 'callbot', name: 'Call bot' },
    LIVE_CHAT: { id: 12, code: 'livechat', name: 'Chat Website CSKH' },
    FB_COMMENT: { id: 13, code: 'fbcomment', name: 'Bình luận Facebook' },
    FB_CHAT: { id: 14, code: 'fbchat', name: 'Chat Facebook' },
    ZALO_CHAT: { id: 15, code: 'zalochat', name: 'Chat Zalo' },
    WEB360_AUDIO: { id: 16, code: 'web360_audio', name: 'Web360 Audio' },
    WEB360_VIDEO: { id: 17, code: 'web360_video', name: 'Web360 Video' },
    WEB_BAN_DO_MAT_DIEN: { id: 18, code: 'WebBanDoMatDien', name: 'Bản đồ mất điện' },
    UNG_DUNG_HIEN_TRUONG: { id: 19, code: 'ungdunghientruong', name: 'Ứng dụng hiện trường' }
};