import { useState } from 'react';
import { AGENT_MAP_GRID_SIZE, AGENT_MAP_OBJECT_TYPE, roundToNearestMultipleX } from 'utils';

export const useAgentMapDrag = () => {
  const [dragOffset, setDragOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [objectIdDrag, setObjectIdDrag] = useState<AGENT_MAP_OBJECT_TYPE>();

  const handleDragObjectStart = (objectId: AGENT_MAP_OBJECT_TYPE) => (event: React.DragEvent<HTMLDivElement>) => {
    // Lấy vị trí chuột so với phần tử được kéo
    const rect = event.currentTarget.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;
    setObjectIdDrag(objectId);
    setDragOffset({ x: offsetX, y: offsetY });
    event.dataTransfer.setData('text/plain', 'dragged'); // Dữ liệu cần thiết để kéo
  };

  const handleDragObjectOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Cho phép thả
  };

  const handleDropOject = (parentRef: HTMLDivElement) => (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!parentRef)
      return {
        left: null,
        top: null,
        objectId: null
      };

    // Lấy vị trí vùng chứa
    const parentRect = parentRef.getBoundingClientRect();

    // Tính vị trí chính xác so với parent
    const x = event.clientX - parentRect.left - dragOffset.x + parentRef.scrollLeft;
    const y = event.clientY - parentRect.top - dragOffset.y + parentRef.scrollTop;

    return {
      left: roundToNearestMultipleX(x, AGENT_MAP_GRID_SIZE / 2),
      top: roundToNearestMultipleX(y, AGENT_MAP_GRID_SIZE / 2),
      objectId: objectIdDrag
    };
  };

  return {
    handleDragObjectOver,
    handleDragObjectStart,
    handleDropOject
  };
};
