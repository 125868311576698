import { CheckPermissionLayout } from 'layouts';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';

export const accessCoordinationRequiredRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_NEED_COOPERATE_REQUEST]} />,
    children: []
  }
];
