export const customerLookupDetailedMessages = {
  vipCustomer: 'Khách hàng VIP',
  customerInformation: 'Thông tin KH',
  customerFullName: 'Họ tên KH:',
  customerPhone: 'Điện thoại KH:',
  customerAddress: 'Địa chỉ KH:',
  routeSession: 'Phiên lộ trình:',
  addressName: 'Chỉ danh:',
  unit: 'Đơn vị:',
  customerPaymentCode: 'Mã KH thanh toán:',
  contract: 'Hợp đồng',
  signedDate: 'Ngày ký HĐ:',
  effectiveFrom: 'Hiệu lực từ:',
  effectiveTo: 'Hiệu lực đến:',
  progressing: 'Đang xử lý',
  requestHistory: 'Lịch sử yêu cầu',
  outageHistory: 'Lịch sử mất điện',
  questionAndAnswer: 'Hỏi đáp',
  outageSchedule: 'Lịch cắt điện',
  billingSchedule: 'Lịch ghi thu tiền điện',
  electricityPrice: 'Giá bán điện',
  paymentCollection: 'Thu hộ',
  electricityMeterCode: 'Mã ghi điện',
  ottInformation: 'Thông tin OTT',
  powerGridCondition: 'Tình trạng lưới điện',
  session: 'Phiên',
  cycleNumber: 'Số kỳ',
  month: 'Tháng',
  year: 'Năm',
  meterReadingDate: 'Ngày ghi điện',
  paymentCollectionDate: 'Ngày thu tiền',
  paymentDeadline: 'Thời hạn thanh toán',
  powerCutoffDeadline: 'Kỳ hạn cắt điện',
  effectiveDate: 'Ngày hiệu lực',
  entity: 'Đối tượng',
  voltageLevel: 'Cấp điện áp',
  numberOfHouseholds: 'Số hộ',
  setOfIndicators: 'Bcs',
  tariff: 'Định mức',
  price: 'Giá',
  previousReading: 'Chỉ số cũ',
  currentReading: 'Chỉ số mới',
  electricityOutput: 'Sản lượng',
  billCode: 'Mã hóa đơn',
  billType: 'Loại hóa đơn',
  amount: 'Số tiền',
  collectionPoint: 'Điểm thu',
  ott: 'OTT',
  status: 'Trạng thái:',
  account: 'Tài khoản',
  electricityConsumptionAndPayment: 'Điện năng tiêu thụ và thanh toán',
  samePeriod: 'Xem Cùng kỳ',
  debt: 'Nợ',
  electricMeter: 'Công tơ',
  typeOfMeter: 'Loại công tơ',
  typeOfContract: 'Loại HĐ',
  multiplier: 'HSN',
  electricityConsumption: 'Điện tiêu thụ',
  amountOfDebt: 'Số tiền nợ',
  vat: 'VAT',
  openingBalance: 'Số đầu kì',
  closingBalance: 'Số cuối kì',
  totalAmount: 'Tổng tiền',
  paymentDate: 'Ngày thanh toán',
  debtCancellationDate: 'Ngày xóa nợ',
  viewAll: 'Xem tất cả',
  createTicket: 'Tạo phiếu',
  electricalRepairReport: 'Báo sửa điện',
  powerOutageDueToDebt: 'Mất điện do nợ',
  requestForElectricitySupply: 'Cấp lại điện theo yêu cầu',
  removal: 'Di dời',
  measuringDevice: 'Thiết bị do đếm',
  customerInquiryResolution: 'Giải đáp thắc mắc khách hàng',
  lookup: 'Tra cứu',
  elementCode: 'Mã phần tử',
  reason: 'Nguyên nhân',
  solution: 'Biện pháp xử lý',
  region: 'Khu vực',
  note: 'Ghi chú',
  notARepeatedRequest: 'Không phải yêu cầu nhiều lần',
  detailed: 'Chi tiết',
  search: 'Tìm kiếm',
  refresh: 'Làm mới',
  powerIsOn: 'Đang có điện',
  electricityBillingInformationOnCollectionSystem: 'Thông tin tiền điện được công ty điện lực đưa lên hệ thống thu hộ',
  customerPaymentInformationOnCollectionChanel: 'Thông tin thanh toán khách hàng qua kênh thu hộ',
  registered: 'Đã đăng ký',
  notRegistered: 'Chưa đăng ký',
  noDebt: 'Không nợ',
  electricityCutoffPeriod: 'Hạn cắt điện',
  detailedInformation: 'Thông tin chi tiết',
  customer: 'Khách hàng',
  customerInfor: 'Thông tin khách hàng',
  customerPE: 'Mã khách hàng:',
  customerName: 'Tên khách hàng:',
  idCard: 'CMND:',
  address: 'Địa chỉ:',
  phoneNumber: 'Điện thoại:',
  meterNumber: 'Số công tơ:',
  transformerStationCode: 'Mã trạm biến thế:',
  cadastralUnit: 'Đơn vị địa chính:',
  groupCode: 'Mã tổ',
  powerCompany: 'Công ty điện lực:',
  cmisSmsEmailInformation: 'Thông tin CMIS - SMS - Email',
  primaryPhoneNumber: 'Số điện thoại chính chủ:',
  primaryEmail: 'Email chính chủ:',
  contactPhoneNumber: 'Số điện thoại liên hệ:',
  contactEmail: 'Email liên hệ:',
  phoneNumberInSMS: 'Số điện thoại SMS:',
  emailInSMS: 'Email SMS:',
  requesterInformation: 'Thông tin người yêu cầu',
  requesterFullName: 'Họ tên người Y/C',
  requesterPhone: 'Điện thoại người Y/C',
  requesterAddress: 'Địa chỉ người Y/C',
  numberOfCalls: 'Số lần gọi',
  citizenIdCard: 'Số CCCD',
  dateOfIssue: 'Ngày cấp',
  placeOfIssue: 'Nơi cấp',
  appointment: 'Hẹn',
  completeAt: 'Hoàn tất lúc',
  cause: 'Lý do',
  numericalOrder: 'STT',
  unitPrice: 'Đơn giá',
  totalPrice: 'Thành tiền',
  sum: 'Tổng cộng',
  startDatePeriod: 'Bắt đầu kì',
  endDatePeriod: 'Kết thúc kì',
  oldMeterReading: 'Chỉ số cũ',
  newMeterReading: 'Chỉ số mới',
  meterReadingType: 'Loại chỉ số',
  phaseNumber: 'Số pha',
  detailTitlePeriods: 'CHI TIẾT ĐIỆN NĂNG TIÊU THỤ THEO ',
  isUsingOTT: 'Sử dụng OTT',
  useOTT: 'CÓ',
  dontUseOTT: 'KHÔNG',
  dateCreateLinking: 'Ngày tạo liên kết',
  ticket: 'Phiếu',
  initializationInformation: 'Thông tin khỏi tạo',
  receivingChannel: 'Kênh tiếp nhận:',
  requestDate: 'Ngày yêu cầu:',
  creator: 'Người tạo:',
  appointmentDate: 'Ngày hẹn:',
  powerCompanyHandling: 'Điện lực xử lý',
  receptionDate: 'Ngày tiếp nhận:',
  recipient: 'Người tiếp nhận:',
  completeInformation: 'Thông tin hoàn tất',
  completedDate: 'Ngày hoàn tất:',
  completedByName: 'Người hoàn tất:',
  requestContent: 'Nội dung yêu cầu',
  processingProcess: 'Quá trình xử lý',
  period: 'KÌ',
  outAgeArea: 'Mất điện khu vực',
  secondCall: 'Gọi lần 2',
  selectServiceTypes: 'Chọn loại dịch vụ tạo phiếu',
  noCustomerSelected: 'Bạn chưa chọn khách hàng để tra cứu . Vui lòng bấm nút bên dưới để tìm kiếm khách hàng',
  searchCustomer: 'Tìm kiếm khách hàng',
  ticketMinimized: 'Phiếu đang tạo',
  confirmBackToSearch: 'Bạn có chắc chắn muốn quay lại trang tìm kiếm không?',
  confirmYes: 'Đồng ý',
  confirmCancel: 'Hủy',
  backToSearch: 'Quay lại tìm kiếm',
  searching: 'Đang tìm kiếm',
  comfirmDeteleTicket: 'Bạn có chắc chắn muốn xóa phiếu đang tạo này không?',
  quickFinish: 'Hoàn tất nhanh',
  detail: 'Chi tiết hóa đơn',
  periodicDetail: 'Chi tiết chi tiêu điện',
  enterCustomerCode: 'Nhập mã khách hàng',
  noData: "Chưa có dữ liệu",
  otherDetail: "Chi tiết khác",
  exposed: "Lộ ra:",
  customerDetailInfo: 'Thông tin chi tiết khách hàng',
  selectServiceType: 'Chọn loại dịch vụ',
  enterSearchKeyword: 'Nhập thông tin cần tìm',
};
