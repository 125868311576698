import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { useCountTime } from 'hooks/useCountTime';

type Props = {
  time?: string | Date;
  debounce?: number;
  format?: (time: string | Date | undefined) => string;
} & TextProps;

const TypographyTime: React.FC<Props> = ({ time, debounce = 6000, format, ...props }) => {
  const timeDisplay = useCountTime(time, 1000, format);

  return <Typography.Text {...props}>{timeDisplay}</Typography.Text>;
};

export default TypographyTime;
