import { Checkbox, Tooltip, TreeDataNode, Typography } from 'antd';
import { find, flatMap } from 'lodash';
import { useState } from 'react';
import FilterOrigin, { FilterPopoverOptionItem } from './Filter';
import FilterSearchBox from './FilterSearchBox';
import { messages } from 'messages';
import { twMerge } from 'tailwind-merge';
import FilterTreeSearchBox from './FilterTreeSearchBox';

type FilterTreeCheckboxSearchProps = Omit<React.ComponentProps<typeof FilterOrigin>, 'value'> & {
  options: TreeDataNode[];
  onSearch: (value: string) => void;
  loadMore?: () => void;
  loading?: boolean;
  fetchData?: () => void;
  hasMore?: boolean;
  placeholder?: string;
  title: string;
  value?: Array<any>;
};
const FilterTreeCheckboxSearch = ({
  value = [],
  options,
  children,
  icon,
  title,
  loadMore,
  onSearch,
  loading = false,
  fetchData,
  hasMore,
  placeholder,
  ...props
}: FilterTreeCheckboxSearchProps) => {
  const [valueTemp, setValueTemp] = useState(value);

  const countData = (items: TreeDataNode[]): number =>
    items.reduce((count, item) => count + 1 + (item.children ? countData(item.children) : 0), 0);

  const getLabelChecked = (items: TreeDataNode[]) =>
    items.reduce((result, item) => {
      if (value.includes(item.key)) {
        result.push(item.title);
      } else if (item.children) {
        result.push(...getLabelChecked(item.children));
      }
      return result;
    }, [] as Array<any>);

  const content = (
    <FilterTreeSearchBox
      onChange={(checked) => setValueTemp(checked)}
      options={options}
      onSearch={onSearch}
      placeholder={placeholder}
      value={valueTemp}
    />
  );

  const classNameValue = 'mb-0 max-w-[50px] xl:max-w-[135px] text-sm';

  const childrenLabel = children ?? (
    <>
      {icon}
      <div>
        {value && value?.length > 0 ? (
          <Typography.Paragraph
            className={twMerge(classNameValue)}
            ellipsis={{
              rows: 1,
              tooltip: true
            }}
          >
            {value.length === countData(options) ? `${title}: ${messages.all}` : getLabelChecked(options).join(', ')}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph
            className={twMerge(classNameValue)}
            ellipsis={{
              rows: 1,
              tooltip: true
            }}
          >
            {`${title}: ${messages.all}`}
          </Typography.Paragraph>
        )}
      </div>
    </>
  );

  return (
    <FilterOrigin
      {...props}
      content={content}
      valueTemp={valueTemp}
      clearFilter={() => {
        setValueTemp([]);
      }}
      value={value}
      setValueTemp={setValueTemp}
      onOpenChange={(open) => {
        if (open && fetchData) fetchData();
        props.onOpenChange?.(open);
      }}
      selectedCount={Array.isArray(valueTemp) ? valueTemp.length : valueTemp ? 1 : 0}
    >
      {childrenLabel}
    </FilterOrigin>
  );
};

export default FilterTreeCheckboxSearch;
