import { usePaging } from 'hooks/usePaging';
import { useGetCustomersByFullTextQuery } from 'services';
import { FulltextSearchCustomerDto } from 'types/dto/customer-lookup';

const pageSize = 7;
export function useCustomerFulltextPaging(params: Omit<FulltextSearchCustomerDto, 'pageIndex' | 'pageSize'>) {
  const {
    customerName,
    customerAddress,
    meterSerialNumber,
    customerCode,
    routeCode,
    meterPointCode,
    organizationUnitCode,
    wardId,
    districtId,
    customerPhoneNumber,
    keyword
  } = params;

  const { pageIndex, changePage, resetPage } = usePaging(params);

  // Kiểm tra nếu có giá trị tìm kiếm nào được nhập thì gọi API
  const shouldFetch = !!(
    customerName ||
    customerAddress ||
    meterSerialNumber ||
    customerCode ||
    routeCode ||
    meterPointCode ||
    organizationUnitCode ||
    wardId ||
    districtId ||
    customerPhoneNumber ||
    keyword
  );

  const {
    data: customersResponse,
    error,
    isFetching,
    isLoading
  } = useGetCustomersByFullTextQuery(
    { ...params, pageIndex: pageIndex, pageSize: pageSize },
    {
      skip: !shouldFetch // Chặn gọi API khi không có giá trị tìm kiếm
    }
  );

  const handleChangePage = changePage;

  return {
    customers: shouldFetch ? customersResponse?.data.rows || [] : [],
    count: shouldFetch ? customersResponse?.data.count || 0 : 0,
    isLoading: shouldFetch && (isFetching || isLoading),
    pageIndex,
    handleChangePage,
    resetPage,
    error
  };
}
