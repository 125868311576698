import { Button, Image, Typography } from 'antd';
import {
    PauseIcon,
} from 'assets';
import { customerLookupMessages } from 'messages/customer-lookup.message';
import { hourglass, } from 'assets/images';
import { AGENT_STATUS } from 'utils';
import { useProfile } from 'hooks';


interface ReadyStatusProps {
    isMinimized: boolean;
    toggleAgentStatus: (status: AGENT_STATUS.AGENT_PAUSED | AGENT_STATUS.AGENT_UNPAUSED) => void;
}

const ReadyStatusContent: React.FC<ReadyStatusProps> = ({ isMinimized, toggleAgentStatus }) => {
    const { profile } = useProfile();

    return isMinimized ? (
        <div className="w-[400px] flex h-[244px] flex-col rounded-tl-xl bg-[#B3E6D7]">
            <div className="m-5 flex flex-col">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col">
                        <Typography.Text className="text-left text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                            EXT #{profile?.extensionId || ''} - {profile?.shortName || ''}
                        </Typography.Text>
                        <Typography.Text className="mt-3 text-left font-semibold tracking-normal text-[#000000] opacity-100">
                            {customerLookupMessages.isReady}
                        </Typography.Text>
                    </div>
                    {/* <Image src={hourglass} className="h-[58px] w-[58px]" alt="hourglass" /> */}
                </div>
                <Typography.Text className="mt-3 text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                    {customerLookupMessages.isReadyMessage}
                </Typography.Text>
                <Button
                    style={{ borderColor: "#D4D8DE" }}
                    className="mt-9 h-9 w-[170px]"
                    icon={<PauseIcon />}
                    onClick={() => toggleAgentStatus(AGENT_STATUS.AGENT_PAUSED)}
                >
                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">
                        {customerLookupMessages.pause}
                    </Typography.Text>
                </Button>
            </div>
        </div>
    ) : (
        <div className="flex h-full w-[400px] flex-col rounded-l-xl bg-[#FFFFFF]">
            <div className="m-5 flex flex-col items-center ">
                <Typography.Text className="text-center text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    EXT #{profile?.extensionId || ''} - {profile?.shortName || ''}
                </Typography.Text>
                {/* <Image src={hourglass} className="mt-[80px] h-[112px] w-[112px]" alt="hourglass" /> */}
                <Typography.Text className="mt-[40%] text-center font-semibold tracking-normal text-[#000000] opacity-100">
                    {customerLookupMessages.isReady}
                </Typography.Text>
                <Typography.Text className="mt-2 text-center text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                    {customerLookupMessages.isReadyMessage}
                </Typography.Text>
                <Button
                    style={{ borderColor: "#D4D8DE" }}
                    className="mt-9 h-9 w-[170px]"
                    icon={<PauseIcon />}
                    onClick={() => toggleAgentStatus(AGENT_STATUS.AGENT_PAUSED)}
                >
                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">
                        {customerLookupMessages.pause}
                    </Typography.Text>
                </Button>
            </div>
        </div>
    );
};

export default ReadyStatusContent;
