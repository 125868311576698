import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useServiceTypesOptions } from 'hooks';
import { useState } from 'react';
import { filterOptions, SERVICE_TYPE_SELECT_ALL } from 'utils';

type SelectServiceTypesProps = SelectProps & {
  isAll?: boolean;
  isActive?: boolean;
};

const SelectServiceTypes = ({ isAll = false, isActive, ...props }: SelectServiceTypesProps) => {
  const [keyword, setKeyword] = useState('');
  const { serviceTypesOptions, hasMore, handleLoadMore, resetPage, isLoading, isFetching } = useServiceTypesOptions({
    isActive,
    isLoadAll: true
  });

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const data = [
    ...(isAll ? [SERVICE_TYPE_SELECT_ALL] : []),
    ...(serviceTypesOptions.map((itm) => ({
      label: itm.name,
      value: itm.serviceTypeId
    })) || [])
  ];

  const options = filterOptions(data, keyword, 'label');

  return (
    <Select
      {...props}
      onLoadMore={handleLoadMore}
      loading={isLoading || isFetching}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      hasMore={hasMore}
      options={options}
    />
  );
};
export default SelectServiceTypes;
