import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Typography, Collapse } from 'antd';
import { ArrowDownIcon, CheckIcon, CrossIcon, DashIcon, ExpandMinimizeModalIcon, PlusIcon, SearchIcon, TrashBinIcon, TrashBinWhiteIcon } from 'assets';
import CreateTicketForm, { CreateTicketFormRefProps } from 'components/create-ticket/CreateTicketForm';
import CreateTicketInStructions from 'components/create-ticket/CreateTicketInStructions';
import { ServiceTypeList } from 'components/service-types';
import { useDistrictsPaging, useWardsByDistrictId } from 'hooks/administrative-units';
import { useCustomerNameByPhoneNumber } from 'hooks/tickets/useGetCustomerNameByPhoneNumber';
import { customerLookupDetailedMessages } from 'messages/customer-lookup-detailed.message';
import { customerLookupMessages } from 'messages/customer-lookup.message';
import { TabData } from 'pages/CustomerSupport';
import { useEffect, useRef, useState } from 'react';
import { onReceiveCallDialingEvent, useGetServiceTypeDetailQuery } from 'services';

const { Panel } = Collapse;
const CustomerLookupForm = ({ onSearch }: { onSearch: (criteria: any) => void }) => {
  const [form] = Form.useForm();
  const [componentSize, setComponentSize] = useState<'default' | 'small' | 'large'>('default');
  const [disabledFields, setDisabledFields] = useState<Record<string, 'default' | 'enabled' | 'disabled'>>({
    customerDistrictAndWard: 'default',
    customerCode: 'default',
  });
  const [districtId, setDistrictId] = useState(0);
  const [showQuickSelect, setShowQuickSelect] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [isServiceTypeModalOpen, setIsServiceTypeModalOpen] = useState(false);
  const [isCreateTicketModalOpen, setIsCreateTicketModalOpen] = useState(false);
  const [selectedServiceTypeId, setSelectedServiceTypeId] = useState<number | null>(null);
  const [serviceTypeId, setServiceTypeId] = useState<number | undefined>(undefined);
  const [tabs, setTabs] = useState<TabData[]>([]);
  const [tabKey] = useState<string>('customerLookup');
  const formRef = useRef<CreateTicketFormRefProps>(null);
  const [minimizedModals, setMinimizedModals] = useState<{ id: number, name: string, formData: any }[]>([]);

  const { data: serviceType, isLoading: isLoadingServiceTypeDetail } = useGetServiceTypeDetailQuery(selectedServiceTypeId!, {
    skip: !serviceTypeId,
    refetchOnMountOrArgChange: true
  });

  const { districts, isLoading: loadingDistricts } = useDistrictsPaging({});
  const { wards, isLoading: loadingWards } = useWardsByDistrictId(districtId);

  const [customerNumber, setCustomerNumber] = useState('');
  const { customerNameByPhoneNumber } = useCustomerNameByPhoneNumber(customerNumber);
  const [customerPhoneLabel, setCustomerPhoneLabel] = useState(customerLookupMessages.customerPhone);

  useEffect(() => {
    onReceiveCallDialingEvent((data) => {
      if (data?.callUuid && data?.customerNumber) {
        setCustomerNumber(data?.customerNumber);
        console.log('số điện thoại gọi tới nè:', data?.customerNumber);
      }
    });
  }, []);

  useEffect(() => {
    if (customerNameByPhoneNumber && customerNameByPhoneNumber.length > 0) {
      setCustomerPhoneLabel(`${customerLookupMessages.customerPhone}`);
    } else {
      setCustomerPhoneLabel(customerLookupMessages.customerPhone);
    }
    form.setFieldsValue({ customerPhoneNumber: customerNumber || undefined });
    onFinish(form.getFieldsValue());
  }, [customerNumber, customerNameByPhoneNumber]);

  const handleSelectWard = (value: number) => {
    setDistrictId(value);
  };

  const cycleFieldState = (fieldName: string) => {
    setDisabledFields((prev) => ({
      ...prev,
      [fieldName]: prev[fieldName] === 'default' ? 'enabled' : prev[fieldName] === 'enabled' ? 'disabled' : 'default'
    }));
  };

  const handleFieldChange = (fieldName: string, value: any) => {
    setDisabledFields((prev) => ({
      ...prev,
      [fieldName]: value ? 'enabled' : 'default'
    }));
  };

  const onFinish = (values: any) => {
    const filteredValues = Object.entries(values)
      .filter(
        ([key, value]) => value !== undefined && value !== null && value !== '' && disabledFields[key] !== 'disabled'
      )
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    onSearch(filteredValues);
  };

  const renderLabel = (fieldName: string, label: string) => {
    const state = disabledFields[fieldName];
    return (
      <div className='flex cursor-pointer items-center justify-between' onClick={() => cycleFieldState(fieldName)}>
        <Typography.Text
          className={
            state === 'default'
              ? 'text-xs font-semibold text-black'
              : state === 'enabled'
                ? 'text-xs font-semibold text-green-600'
                : 'text-xs font-semibold text-gray-400'
          }
        >
          {label}
        </Typography.Text>
        {state === 'enabled' && <CheckIcon className='ml-1 text-sm' />}
        {state === 'disabled' && <CrossIcon className='ml-1 text-sm' />}
      </div>
    );
  };

  const handleMinimizeCreateTicketModal = () => {
    if (selectedServiceTypeId !== null) {
      const formData = formRef.current?.getFieldsValue();
      setMinimizedModals((prev) => [...prev, { id: selectedServiceTypeId, name: serviceType?.data.name || 'Unnamed Service', formData }]);
      setIsCreateTicketModalOpen(false);
    }
  };

  const handleCloseCreateTicketModal = () => {
    setIsCreateTicketModalOpen(false);
  };

  const restoreMinimizedModal = (id: number) => {
    const minimizedModal = minimizedModals.find((modal) => modal.id === id);
    if (minimizedModal) {
      setSelectedServiceTypeId(id);
      setServiceTypeId(id);
      setIsCreateTicketModalOpen(true);
      formRef.current?.setFieldsValue(minimizedModal.formData);
      setMinimizedModals((prev) => prev.filter((modal) => modal.id !== id));
    }
  };

  const deleteMinimizedModal = (id: number) => {
    setMinimizedModals((prev) => prev.filter((modal) => modal.id !== id));
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} layout='vertical' className='text-sm-label'>
        {/* Tên khách hàng */}
        <Form.Item name='customerName' label={renderLabel('customerName', customerLookupMessages.customerName)}>
          <Input
            disabled={disabledFields.customerName === 'disabled'}
            onChange={(e) => handleFieldChange('customerName', e.target.value)}
          />
        </Form.Item>

        {/* Địa chỉ khách hàng */}
        <Form.Item
          name='customerAddress'
          label={renderLabel('customerAddress', customerLookupMessages.customerAddress)}
        >
          <Input
            disabled={disabledFields.customerAddress === 'disabled'}
            onChange={(e) => handleFieldChange('customerAddress', e.target.value)}
          />
        </Form.Item>

        {/* Quận/Huyện - Phường/Xã (Chung label & disable chung) */}
        <Form.Item
          label={renderLabel(
            'customerDistrictAndWard',
            `${customerLookupMessages.district}, ${customerLookupMessages.ward}`
          )}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name='districtId' noStyle>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={
                    <Typography.Text className='text-[14px]/[20px] font-normal text-[#181818]'>
                      {customerLookupMessages.selectDistrict}
                    </Typography.Text>
                  }
                  loading={loadingDistricts}
                  options={districts?.map((itm) => ({
                    label: itm.administrativeUnitName,
                    value: itm.administrativeUnitId
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setDistrictId(value);
                    handleFieldChange('customerDistrictAndWard', value);
                  }}
                  disabled={disabledFields.customerDistrictAndWard === 'disabled'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='wardId' noStyle>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={
                    <Typography.Text className='text-[14px]/[20px] font-normal text-[#181818]'>
                      {customerLookupMessages.selectWard}
                    </Typography.Text>
                  }
                  loading={loadingWards}
                  options={wards?.map((itm) => ({
                    label: itm.administrativeUnitName,
                    value: itm.administrativeUnitId
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => handleFieldChange('customerDistrictAndWard', value)}
                  allowClear
                  disabled={disabledFields.customerDistrictAndWard === 'disabled'}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        {/* Số điện thoại khách hàng */}
        {customerNameByPhoneNumber && customerNameByPhoneNumber.length > 0 && (
          <Typography.Text className='text-xs font-semibold text-green-600'>
            {customerNameByPhoneNumber[0]}
          </Typography.Text>
        )}
        <Form.Item name='customerPhoneNumber' label={renderLabel('customerPhoneNumber', customerPhoneLabel)}>
          <Input
            disabled={disabledFields.customerPhoneNumber === 'disabled'}
            onChange={(e) => handleFieldChange('customerPhoneNumber', e.target.value)}
          />
        </Form.Item>

        {/* Mã yêu cầu */}
        <Form.Item
          name='customerRequestCode'
          label={renderLabel('customerRequestCode', customerLookupMessages.requestCode)}
        >
          <Input
            disabled={disabledFields.customerRequestCode === 'disabled'}
            onChange={(e) => handleFieldChange('customerRequestCode', e.target.value)}
          />
        </Form.Item>

        {/* Customer Code - Customer Code Type (Chung label & disable chung) */}
        <Form.Item name='customerCode' label={renderLabel('customerCode', customerLookupMessages.other)}>
          <Row gutter={16}>
            <Col span={12}>
              <Input
                placeholder={customerLookupMessages.PE}
                disabled={disabledFields.customerCode === 'disabled'}
                onChange={(e) => handleFieldChange('customerCode', e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Select
                defaultValue='customerCode'
                options={[{ value: 'customerCode', label: customerLookupMessages.customerCode }]}
                disabled={disabledFields.customerCode === 'disabled'}
              />
            </Col>
          </Row>
        </Form.Item>

        {/* Nút tìm kiếm */}
        <Form.Item>
          <Button htmlType="submit" icon={<SearchIcon />} className="mt-2 w-[360px] bg-[#1564E8] text-[#FFFFFF] border-[#1564E8] font-semibold">
            {customerLookupMessages.lookup}
          </Button>
        </Form.Item>

        {/* Nút đăng ký mới và Tạo phiếu không định danh */}
        <Form.Item>
          <Button
            icon={<PlusIcon />}
            className="w-[360px] bg-[#06A77D] border-[#06A77D] text-[#FFFFFF] font-semibold"
            onClick={() => setShowQuickSelect(!showQuickSelect)}
          >
            {customerLookupMessages.newRegister}
          </Button>
          {showQuickSelect && (
            <div className="mt-2">
              <div className="flex flex-col gap-2">
                {[
                  { label: 'Cấp điện Hạ Áp', keyword: 'capDienH' },
                  { label: 'Cấp điện Trung Áp', keyword: 'capDienT' },
                  { label: 'Cấp điện Cao Áp', keyword: 'capDienC' }
                ].map((btn, index) => (
                  <Button
                    key={index}
                    className="w-[360px] bg-[#FF9500] border-[#FF9500] text-[#FFFFFF] font-semibold"
                    onClick={() => {
                      setSearchKeyword(btn.keyword);
                      setIsServiceTypeModalOpen(true);
                    }}
                  >
                    {btn.label}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            className="w-[360px] bg-[#FF9500] border-[#FF9500] text-[#FFFFFF] font-semibold"
            onClick={() => {
              setSearchKeyword(' ');
              setIsServiceTypeModalOpen(true);
            }}
          >
            Tạo phiếu không định danh
          </Button>
        </Form.Item>
      </Form>
      <Modal
        className="w-[1068px] rounded-xl ant-modal-body-p-none overflow-y-scroll shadow-[0px 6px 24px #00000029]"
        open={isServiceTypeModalOpen}
        onCancel={() => setIsServiceTypeModalOpen(false)}
        footer={null}
        zIndex={1100}
      >
        <div className='flex flex-col rounded-xl mb-4 bg-[#F5F5F7]'>
          <Typography.Text className='my-4 ml-6 text-left font-bold text-[26px]/[34px] text-[#141414] tracking-normal opacity-100'>
            Chọn loại dịch vụ
          </Typography.Text>
          <ServiceTypeList
            onSelectServiceType={(serviceTypeId) => {
              setSelectedServiceTypeId(serviceTypeId);
              setServiceTypeId(serviceTypeId);
              setIsServiceTypeModalOpen(false);
              setIsCreateTicketModalOpen(true);
            }}
            initialKeyword={searchKeyword}
          />
        </div>
      </Modal>
      <Modal className='w-[1536px] ant-modal-body-p-none ' closable={false} onCancel={handleMinimizeCreateTicketModal} open={isCreateTicketModalOpen} footer={null}>
        <div className='flex flex-row justify-between items-center border-b h-[64px]'>
          <div className='flex flex-row items-center gap-2 ml-6 cursor-pointer' onClick={() => setIsServiceTypeModalOpen(true)}>
            <Typography.Text className='text-left font-semibold text-[24px]/[32px] text-[#141414] tracking-normal opacity-100'>
              {serviceType?.data.name}
            </Typography.Text>
            <Button shape='circle' onClick={() => setIsServiceTypeModalOpen(true)} className=' hover:bg-[#EBEBED] bg-[#F5F5F7] border-[#F5F5F7] bg-transparent bg-no-repeat bg-clip-padding opacity-100' icon={<ArrowDownIcon className='w-4 h-4' />} />
          </div>
          <div className='flex flex-row gap-6 my-4 mr-4'>
            <Popconfirm
              title={customerLookupDetailedMessages.comfirmDeteleTicket}
              onConfirm={handleCloseCreateTicketModal}
              okText={customerLookupDetailedMessages.confirmYes}
              cancelText={customerLookupDetailedMessages.confirmCancel}
            >
              <Button shape='circle' className=' hover:bg-[#EBEBED] bg-[#F5F5F7] border-[#F5F5F7] bg-transparent bg-no-repeat bg-clip-padding opacity-100' icon={<TrashBinIcon className='w-4 h-4 text-colorError' />} />
            </Popconfirm>
            <Button shape='circle' onClick={handleMinimizeCreateTicketModal} className=' hover:bg-[#EBEBED] bg-[#F5F5F7] border-[#F5F5F7] bg-transparent bg-no-repeat bg-clip-padding opacity-100' icon={<DashIcon className='w-4 h-4' />} />
          </div>
        </div>
        <div className='flex flex-row'>
          <CreateTicketForm ref={formRef} customerId={0} serviceTypeId={serviceTypeId} onCloseModal={handleCloseCreateTicketModal} />
          <CreateTicketInStructions customerId={0} serviceTypeId={serviceTypeId as number} />
        </div>
      </Modal>
      <div className="fixed z-[1] bottom-0 right-0 w-fit p-2 flex gap-2">

        {minimizedModals.map((modal) => (
          // <Button key={modal.id} onClick={() => restoreMinimizedModal(modal.id)}>
          //     {modal.name}
          // </Button>
          <div className='bg-[#1564E8] rounded-t-lg w-[328px] h-10 flex justify-between items-center' key={modal.id} onClick={() => restoreMinimizedModal(modal.id)}>
            <Typography.Text className='ml-3 truncate text-left font-normal text-[16px]/[20px] text-[#FFFFFF] tracking-normal opacity-100'>{modal.name}</Typography.Text>
            <div className='flex gap-2 mr-5'>
              <Popconfirm
                title={customerLookupDetailedMessages.comfirmDeteleTicket}
                onConfirm={(e) => {
                  e?.stopPropagation(); // Đảm bảo sự kiện không lan ra ngoài
                  deleteMinimizedModal(modal.id);
                }}
                onCancel={(e) => e?.stopPropagation()} // Ngăn chặn sự kiện từ div cha
                okText={customerLookupDetailedMessages.confirmYes}
                cancelText={customerLookupDetailedMessages.confirmCancel}
              >
                <Button
                  shape='circle'
                  onClick={(e) => e.stopPropagation()} // Tránh popconfirm bị đóng khi click
                  className=' hover:bg-[#FFFFFF29] bg-[#F5F5F7] border-[#1564E8] bg-transparent bg-no-repeat bg-clip-padding opacity-100'
                  icon={<TrashBinWhiteIcon className='w-4 h-4' />}
                />
              </Popconfirm>
              <Button
                shape='circle'
                onClick={(e) => {
                  e.stopPropagation(); // Ngăn chặn sự kiện click từ div cha
                  restoreMinimizedModal(modal.id);
                }}
                className=' hover:bg-[#FFFFFF29] bg-[#F5F5F7] border-[#1564E8] bg-transparent bg-no-repeat bg-clip-padding opacity-100'
                icon={<ExpandMinimizeModalIcon className='w-4 h-4' />}
              />
            </div>
          </div>
        ))}

      </div>
    </>
  );
};

export default CustomerLookupForm;
