import { requestform } from '../../messages';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useExportTicketsMutation } from '../../services';
import { ExportExcelTicketRequestDto } from '../../types';

type ExportExelTicketProps = {
  status: string;
};

const ExportExcelTicket: React.FC<ExportExelTicketProps> = ({ status }) => {
  const [fromDate, setFromDate] = useState<dayjs.Dayjs>(dayjs().subtract(3, 'month'));
  const [toDate, setToDate] = useState<dayjs.Dayjs>(dayjs());
  const { RangePicker } = DatePicker;
  const dateFormat = 'DD/MM/YYYY';
  const handleDateChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates?.[0]) setFromDate(dates[0]);
    if (dates?.[1]) setToDate(dates[1]);
  };

  const [exportTicket] = useExportTicketsMutation();
  const exportExcelTicket = () => {
    const modifiedStatus = status.replace(/-/g, '_');
    const exportTicketRequestData: ExportExcelTicketRequestDto = {
      fromDate: fromDate.format(dateFormat),
      toDate: toDate.format(dateFormat),
      status: modifiedStatus
    };
    exportTicket(exportTicketRequestData)
      .unwrap()
      .then((res) => console.log(res))
      .catch();
  };

  return (
    <div className='flex justify-center space-x-4'>
      <RangePicker value={[fromDate, toDate]} format={dateFormat} onChange={handleDateChange} />
      <button onClick={exportExcelTicket} className='flex items-center gap-2 underline'>
        {requestform.exportExcel}
      </button>
    </div>
  );
};

export default ExportExcelTicket;
