import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Form, FormInstance, Input, MenuProps, Space, Spin, Typography, UploadFile } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { CameraIcon } from 'assets';
import { message, Upload, UploadFileItem } from 'components/common';
import { FormItem } from 'components/common/form-item';
import { ServerUploadFile } from 'components/common/upload/ServerUpload';
import { SelectOmniCallGroup, SelectOmniQueueNames } from 'components/omni-channel';
import { SelectOptions } from 'components/options';
import { TreeSelectOrganizationUnits } from 'components/organization-units';
import { SelectUserGroups, UserGroupOptionType } from 'components/user-groups';
import { useUploadForm } from 'hooks';
import { messages, validateMessages } from 'messages';
import { usersMessages } from 'messages/users.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCreateUserMutation, useGetUserQuery, useUpdateUserMutation } from 'services';
import { CreateUserDto, UserDto } from 'types';
import { AUTH_OPTION_TYPES, MICROSERVICES, USER_GROUP_CLASSIFY } from 'utils';
import { createUserInitialValues, updateUserInitialValues } from 'utils/initial-values';
import { userUpdateValidationRules, userValidationRules } from 'utils/validation-rules';
export type UserFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onSubmitSuccess?: (user?: UserDto) => void;
  userId?: number;
};

export type UserFormRefProps = {
  form: FormInstance<UserFormType>;
  isLoading: boolean;
};

export type UserFormType = Omit<CreateUserDto, 'userGroupId' | 'organizationUnitId' | 'avatar'> & {
  organizationUnitId: DefaultOptionType;
  userGroupId?: UserGroupOptionType;
  avatar?: ServerUploadFile;
};
const UserForm = forwardRef<UserFormRefProps, UserFormProps>(({ onChangeLoading, onSubmitSuccess, userId }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingCreate || isLoadingUpdate
  }));
  const [onCreate, { isLoading: isLoadingCreate }] = useCreateUserMutation();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserMutation();
  const { handleMultiUpload: handleUpload } = useUploadForm();

  const { data: user, isLoading: isLoadingDetail } = useGetUserQuery(userId!, {
    skip: !userId,
    refetchOnMountOrArgChange: true
  });

  const [form] = Form.useForm<UserFormType>();
  const organizationUnitId = Form.useWatch('organizationUnitId', form);
  const userGroupId = Form.useWatch('userGroupId', form);

  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingCreate || isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

  useEffect(() => {
    if (user && userId) {
      form.setFieldsValue({
        ...user.data,
        avatar: user.data.avatar
          ? {
              fileId: user.data.avatar,
              uid: user.data.avatar
            }
          : undefined,
        organizationUnitId: user.data.organizationUnit
          ? {
              label: user.data.organizationUnit?.name,
              value: user.data.organizationUnitId
            }
          : undefined,
        userGroupId: user.data.userGroup
          ? {
              label: user.data.userGroup.name,
              value: user.data.userGroupId,
              userGroupClassifyId: user.data.userGroup.userGroupClassifyId
            }
          : undefined
      });
    }
  }, [user, userId]);

  const onFinish = async ({ ...values }: UserFormType) => {
    const iconFile = values.avatar ? await handleUpload([values.avatar], []) : undefined;

    const data: CreateUserDto = {
      ...values,
      organizationUnitId: values.organizationUnitId.value as number,
      userGroupId: !values.userGroupId ? null : (values.userGroupId?.value as number),
      avatar: values.avatar === null ? null : (iconFile && iconFile[0]) || undefined
    };

    if (!userId) {
      onCreate(data)
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
          onSubmitSuccess?.(rs.data);
        });
    } else {
      onUpdate({
        userId,
        ...data
      })
        .unwrap()
        .then((rs) => {
          message.systemSuccess(rs.message);
          onSubmitSuccess?.();
        });
    }
  };

  const validationRules = userId ? userUpdateValidationRules : userValidationRules;

  const normFile = ({ file }: { file: UploadFile }) => {
    return file;
  };

  const avatar = Form.useWatch('avatar', form);

  const onDeleteHandle = () => {
    form.setFieldValue('avatar', null);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Upload.Crop
          onChange={({ file }: { file: UploadFile }) => form.setFieldValue('avatar', file)}
          multiple={false}
          showUploadList={false}
        >
          <Typography.Text>{messages.changeAvatar}</Typography.Text>
        </Upload.Crop>
      ),

      icon: <UploadOutlined />
    },
    {
      key: '2',
      label: messages.deleteAvatar,
      icon: <DeleteOutlined />,
      danger: true,
      onClick: onDeleteHandle
    }
  ];

  return (
    <Form
      scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
      labelAlign='right'
      labelCol={{
        flex: '180px'
      }}
      form={form}
      name='userForm'
      onFinish={onFinish}
      layout='horizontal'
      validateMessages={validateMessages}
      initialValues={userId ? updateUserInitialValues : createUserInitialValues}
    >
      <Spin spinning={isLoadingCreate || isLoadingUpdate || isLoadingDetail}>
        <>
          <div className='flex flex-col items-center justify-center'>
            <FormItem<UserFormType> name='avatar' getValueFromEvent={normFile} rules={validationRules.avatar}>
              {!avatar ? (
                <Upload.Crop onRemove={() => {}} multiple={false} showUploadList={false}>
                  <div className='hov flex h-32 w-32 cursor-pointer items-center justify-center rounded-full bg-backgroundColor2 text-white hover:bg-hoverColor1'>
                    <CameraIcon />
                  </div>
                </Upload.Crop>
              ) : (
                <Dropdown menu={{ items }} trigger={['click']}>
                  <div className='hov flex h-32 w-32 cursor-pointer items-center justify-center rounded-full'>
                    <UploadFileItem
                      file={avatar}
                      className='h-32 w-32 cursor-pointer overflow-hidden rounded-full p-0 hover:bg-hoverColor1 hover:brightness-50'
                    />
                  </div>
                </Dropdown>
              )}
            </FormItem>
            <Typography.Text className='mb-5 text-lg'>{usersMessages.avatar}</Typography.Text>
          </div>
          <Typography.Title level={5} className='mb-5 text-lg'>
            {usersMessages.personalInfo}
          </Typography.Title>
          <Space.Compact direction='vertical' className='w-full'>
            <FormItem.FloatLabel<UserFormType>
              name='fullName'
              label={usersMessages.fullName}
              rules={validationRules.fullName}
            >
              <Input />
            </FormItem.FloatLabel>
            <Space.Compact direction='horizontal' className='w-[calc(100%-1px)]'>
              <FormItem.FloatLabel<UserFormType>
                label={usersMessages.email}
                rules={validationRules.email}
                name='email'
                className='flex-1'
                validateTrigger='onBlur'
                normalize={(value) => value.trim()}
              >
                <Input />
              </FormItem.FloatLabel>

              <FormItem.FloatLabel<UserFormType>
                name='phoneNumber'
                label={usersMessages.phoneNumber}
                rules={validationRules.phoneNumber}
                className='flex-1'
              >
                <Input />
              </FormItem.FloatLabel>
            </Space.Compact>
            <FormItem.FloatLabel<UserFormType>
              name='genderId'
              label={usersMessages.gender}
              rules={validationRules.genderId}
            >
              <SelectOptions showSearch={false} service={MICROSERVICES.AUTH} optionTypeId={AUTH_OPTION_TYPES.GENDER} />
            </FormItem.FloatLabel>
          </Space.Compact>
          <FormItem.FloatLabel<UserFormType>
            name='employeeId'
            label={usersMessages.employeeId}
            rules={validationRules.employeeId}
            classFloatLabel='mt-4'
          >
            <Input />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<UserFormType>
            name='shortName'
            label={usersMessages.shortName}
            rules={validationRules.shortName}
          >
            <Input />
          </FormItem.FloatLabel>
          <Divider className='mt-4' />
        </>

        <>
          <Typography.Title level={5} className='mb-5 text-lg'>
            {usersMessages.loginInfo}
          </Typography.Title>

          <input type='text' name='username' className='absolute left-[-99999px]' />
          <input type='password' className='absolute left-[-99999px]' />
          <Space.Compact direction='vertical' className='w-full'>
            <FormItem.FloatLabel<UserFormType>
              name='username'
              label={usersMessages.username}
              rules={validationRules.username}
            >
              <Input />
            </FormItem.FloatLabel>

            <FormItem.FloatLabel<UserFormType>
              name='password'
              label={usersMessages.newPassword}
              rules={validationRules.password}
            >
              <Input.Password />
            </FormItem.FloatLabel>
          </Space.Compact>
        </>

        <Divider className='mt-4' />

        <>
          <Typography.Title level={5} className='mb-5 text-lg'>
            {usersMessages.organizationUnit}
          </Typography.Title>
          <FormItem.FloatLabel<UserFormType>
            name='organizationUnitId'
            label={usersMessages.organizationUnit}
            rules={validationRules.organizationUnitId}
          >
            <TreeSelectOrganizationUnits
              treeCheckable={false}
              showSearch
              labelInValue
              showCheckedStrategy='SHOW_ALL'
              treeDefaultExpandAll
              onSelect={(value: number, option) => {
                if (organizationUnitId?.value !== option.value) form.setFieldValue('userGroupId', null);
              }}
            />
          </FormItem.FloatLabel>
          <Divider className='mt-4' />
        </>

        <>
          <Typography.Title level={5} className='mb-5 text-lg'>
            {usersMessages.group}
          </Typography.Title>
          <Form.Item
            noStyle
            shouldUpdate={(prev: UserFormType, curr: UserFormType) => {
              return prev?.organizationUnitId?.value !== curr?.organizationUnitId?.value;
            }}
          >
            {() =>
              organizationUnitId ? (
                <>
                  <FormItem.FloatLabel<UserFormType>
                    name='userGroupId'
                    label={usersMessages.userGroup}
                    rules={validationRules.userGroupId}
                    className='mt-5'
                  >
                    <SelectUserGroups
                      allowClear
                      disabled={!organizationUnitId}
                      organizationUnitId={organizationUnitId?.value ? [Number(organizationUnitId.value)] : undefined}
                      labelInValue
                    />
                  </FormItem.FloatLabel>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev: UserFormType, curr: UserFormType) => prev?.userGroupId !== curr?.userGroupId}
                  >
                    {() =>
                      userGroupId?.userGroupClassifyId &&
                      userGroupId.userGroupClassifyId === USER_GROUP_CLASSIFY.DTV ? (
                        <>
                          <Divider className='mt-4' />
                          <Typography.Title level={5} className='mb-5 text-lg'>
                            {usersMessages.teleAgentInfo}
                          </Typography.Title>
                          <FormItem.FloatLabel<UserFormType>
                            name='extensionId'
                            label={usersMessages.extensionId}
                            rules={validationRules.extensionId}
                            className='mt-5'
                          >
                            <Input />
                          </FormItem.FloatLabel>
                          <FormItem.FloatLabel<UserFormType>
                            name='omniGroupId'
                            label={usersMessages.omniGroupId}
                            rules={validationRules.omniGroupId}
                          >
                            <SelectOmniCallGroup />
                          </FormItem.FloatLabel>

                          <FormItem.FloatLabel<UserFormType>
                            name='companyName'
                            label={usersMessages.companyName}
                            rules={validationRules.companyName}
                          >
                            <Input />
                          </FormItem.FloatLabel>

                          <FormItem.FloatLabel<UserFormType>
                            name='dynamicQueues'
                            label={usersMessages.dynamicQueues}
                            rules={validationRules.dynamicQueues}
                          >
                            <SelectOmniQueueNames />
                          </FormItem.FloatLabel>
                        </>
                      ) : null
                    }
                  </Form.Item>
                </>
              ) : (
                <Typography.Text type='secondary' className='mt-2'>
                  {usersMessages.chooseOrganizationUnitFirst}
                </Typography.Text>
              )
            }
          </Form.Item>
        </>
      </Spin>
    </Form>
  );
});
export default UserForm;
