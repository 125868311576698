import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessIncorrectRecordingLocation: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_INCORRECT_RECORDING_LOCATION]} />,
    path: ROUTE.INCORRECT_MEASUREMENT_POINT_ADDRESS,
    Component: lazy(() => import('pages/IncorrectMeasurementPointAddress'))
  }
];
