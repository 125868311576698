import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'utils/base-api';
import {
  FindAllFrequentlyAskedQuestionDto,
  FrequentlyAskedQuestionOmitContentDto,
  IncorrectRecordingLocationRequestDto,
  IncorrectRecordingLocationResponseDto,
  ResponseDto,
  ResponsePagingDto
} from '../../types';

export const incorrectrecordinglocationApi = createApi({
  reducerPath: 'incorrectrecordinglocationApi',
  tagTypes: [],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getIncorrectRecordingLocationTicketList: builder.query<
      ResponsePagingDto<IncorrectRecordingLocationResponseDto>,
      IncorrectRecordingLocationRequestDto
    >({
      query: (requestData) => {
        const params = new URLSearchParams();

        Object.entries(requestData).forEach(([key, value]) => {
          if (value !== undefined && value !== null && value !== '' && value !== 0) {
            if (Array.isArray(value)) {
              value.forEach((v) => params.append(key, v.toString()));
            } else {
              params.append(key, value.toString());
            }
          }
        });

        return {
          url: '/tickets/dienluc/address_change',
          method: 'get',
          params
        };
      }
    }),

    pathConfirmIncorrectRecordingLocation: builder.mutation<ResponseDto<IncorrectRecordingLocationResponseDto>, number>(
      {
        query: (ticketAddressChangeId) => {
          return {
            url: `/tickets/dienluc/address_change/${ticketAddressChangeId}/confirm`,
            method: 'patch',
            data: { ticketAddressChangeId }
          };
        }
      }
    )
  })
});

export const { useGetIncorrectRecordingLocationTicketListQuery, usePathConfirmIncorrectRecordingLocationMutation } =
  incorrectrecordinglocationApi;
