import { Divider, Tooltip, Typography } from "antd"
import { logo } from "assets"
import { useCustomerDetail } from "hooks/customers/useCustomerDetailed";
import { createTicketMessages } from "messages/create-ticket.messages"

type CreateTicketsProps = {
    customerId: number;
}

const CreateTicketCustomerInFormation = ({customerId}: CreateTicketsProps) => {
    const {customer, isLoading} = useCustomerDetail(customerId);
    return (
        <div className="flex flex-col mx-6 mt-8">
            <div className="flex flex-row gap-3">
                <img src={logo} alt="" className="w-8 h-8"></img>
                <Typography.Text className="text-left font-semibold text-[18px]/[22px] tracking-normal text-[#141414] opacity-100 mt-1">{customer?.customerCode}</Typography.Text>
            </div>
            <div className="flex flex-row mt-5 gap-4">
                <Typography.Text className="w-[112px] text-left text-[16p]/[16px] tracking-normal text-[#6C737E] opacity-100">{createTicketMessages.customerFullName}</Typography.Text>
                <Tooltip title={customer?.customerName}>
                    <Typography.Text className="w-[550px] text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100 truncate">{customer?.customerName}</Typography.Text>
                </Tooltip>
            </div>
            <div className="flex flex-row mt-2 gap-4">
                <Typography.Text className="w-[112px] text-left text-[16p]/[16px] tracking-normal text-[#6C737E] opacity-100">{createTicketMessages.customerPhone}:</Typography.Text>
                <Typography.Text className="text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customer?.customerPhoneNumber}</Typography.Text>
            </div>
            <div className="flex flex-row mt-2 gap-4">
                <Typography.Text className="w-[112px] text-left text-[16p]/[16px] tracking-normal text-[#6C737E] opacity-100">{createTicketMessages.customerAddress}:</Typography.Text>
                <Tooltip title={customer?.customerAddress}>
                    <Typography.Text className="w-[550px] text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100 truncate">{customer?.customerAddress}</Typography.Text>
                </Tooltip>
            </div>
            <div className="flex flex-row mt-2 gap-4">
                <Typography.Text className="w-[112px] text-left text-[16p]/[16px] tracking-normal text-[#6C737E] opacity-100">{createTicketMessages.routeSession}:</Typography.Text>
                <Typography.Text className="text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customer?.routeCode}</Typography.Text>
            </div>
            <div className="flex flex-row mt-2 gap-4">
                <Typography.Text className="w-[112px] text-left text-[16p]/[16px] tracking-normal text-[#6C737E] opacity-100">{createTicketMessages.designation}:</Typography.Text>
                <Typography.Text className="text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customer?.distributionStationCode}</Typography.Text>
            </div>
            <div className="flex flex-row mt-2 gap-4">
                <Typography.Text className="w-[112px] text-left text-[16p]/[16px] tracking-normal text-[#6C737E] opacity-100">{createTicketMessages.unit}</Typography.Text>
                <Typography.Text className="text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customer?.organizationUnit?.name}:</Typography.Text>
            </div>

            <Divider style={{borderColor:'#D4D8DE', borderWidth:'1px'}} className='w-full mt-6' type='horizontal'></Divider>
        </div>
    )
}

export default CreateTicketCustomerInFormation