import { Card, Select, Typography, Spin, DatePicker, message, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { GetOptionDetailQuery } from 'hooks/request-form';
import { requestform } from 'messages';
import { useEffect, useState } from 'react';
import { CreateObstacleTicketRequest, OptionDetail } from 'types';
import dayjs, { Dayjs } from 'dayjs';
import { error } from 'console';
import { usePatchNewObstacleTicketMutation } from 'services';

interface ObstacleTickets {
  ticketId: number;
  closeObstacleModal: () => void;
}

const ModalObstacle: React.FC<ObstacleTickets> = ({ ticketId, closeObstacleModal }) => {
  const { data: optionList, isLoading: optionsListLoading } = GetOptionDetailQuery(11);
  const [processingContent, setProcessingContent] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<OptionDetail>();
  const [estimatedEndDate, setEstimatedEndDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    if (optionList && optionList.length > 0) {
      setSelectedOption(optionList[0]);
    }
  }, [optionList]);
  const handleDateChange = (date: Dayjs) => setEstimatedEndDate(date);

  const [patchNewObstacleTicket, loading] = usePatchNewObstacleTicketMutation();
  const patchCreateNewObstacleTicket = () => {
    if (processingContent === '') {
      return message.error('vui lòng không để trống nội dung');
    }

    const createObstacleTicketRequest: CreateObstacleTicketRequest = {
      ticketId,
      content: processingContent.trim(),
      estimatedEndDate: estimatedEndDate ? estimatedEndDate.format('DD/MM/YYYY') : '',
      obstacleSourceId: selectedOption?.optionId || 0
    };

    Modal.confirm({
      title: 'Xác nhận chuyển phiếu trở ngại',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        patchNewObstacleTicket(createObstacleTicketRequest)
          .unwrap()
          .then((res) => {
            message.success('Chuyển phiếu trở ngại thành công');
            closeObstacleModal();
          })
          .catch((err) => message.error('Chuyển phiếu trở ngại thất bại'));
      }
    });
  };

  return (
    <Card
      title={
        <Typography.Text className='w-full text-center text-[16px]/[22px] font-semibold'>
          {requestform.obstacleTicketProcessing}
        </Typography.Text>
      }
      className='bg-[#F5F5F7] text-center'
    >
      <div className='pb-4 text-left'>
        <TextArea
          placeholder={requestform.obstacleContent}
          autoSize={{ minRows: 5, maxRows: 10 }}
          className='rounded-lg bg-[#FFFFFF]'
          value={processingContent}
          onChange={(e) => setProcessingContent(e.target.value)}
        />

        {optionsListLoading ? (
          <Spin className='mt-2' />
        ) : (
          <Select
            className='mt-2 w-full'
            placeholder={requestform.obstacleContent}
            value={selectedOption?.optionId}
            onChange={(value) => {
              const selected = optionList.find((item: OptionDetail) => item.optionId === value);
              setSelectedOption(selected);
            }}
          >
            {optionList?.map((item) => (
              <Select.Option key={item.optionId} value={item.optionId}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        )}

        <div className='my-8'>
          <Typography.Text className='block font-semibold'>Thời gian dự kiến hoàn tất</Typography.Text>
          <DatePicker
            placeholder={requestform.estimatedEndDatePicker}
            value={estimatedEndDate}
            onChange={handleDateChange}
            className='mt-2 w-1/2'
          />
        </div>
      </div>

      <div className='flex h-fit items-center justify-end border-t-2 pt-2 text-left'>
        <button
          onClick={patchCreateNewObstacleTicket}
          className='rounded-lg bg-[#D1131D] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
        >
          {requestform.obstacleTickets}
        </button>
      </div>
    </Card>
  );
};

export default ModalObstacle;
