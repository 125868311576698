import { Button, Divider } from 'antd';
import {
  HourglassIcon,
  PhoneCreateTicketIcon,
  PhoneFailedIcon,
  PhoneOnCallIcon,
  PhonePauseIcon,
  PhoneReadyIcon,
  PhoneRingingIcon
} from 'assets';
import { WaitingCallsDetail } from 'components';
import { useOmniCallContext } from 'contexts';
import { useProfile } from 'hooks';
import { useGetWatingCalls } from 'hooks/omni_channel/useGetWaitingCalls';
import { useEffect, useRef, useState } from 'react';
import { onGetWaitingCallsEvent, onReceiveCallHangUpEvent, useLazyGetAgentStatusQuery } from 'services';
import { twMerge } from 'tailwind-merge';
import { AGENT_STATUS } from 'utils';

type CustomerLookupWaitingCallProps = {};

function CustomerLookupWaitingCall({}: CustomerLookupWaitingCallProps) {
  const { profile } = useProfile();

  const waitingCallsRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { waitingCalls, refetch, count: countWaitingCalls } = useGetWatingCalls();

  const [currentPhoneColor, setCurrentPhoneColor] = useState(<PhoneFailedIcon height={20} width={20} />);
  const [showWaitingCallsDetail, setShowWaitingCallsDetail] = useState(false);

  const { socketData, agentStatus, getAgentStatus, setGetAgentStatus } = useOmniCallContext();
  const [fetchAgentStatus, { data: currentFetchAgentStatus }] = useLazyGetAgentStatusQuery();
  const isAgentCreatingTicket = localStorage.getItem('isAgentCreatingTicket');

  const toggleWaitingCallsDetail = (event: React.MouseEvent) => {
    event.stopPropagation();
    // Sử dụng setTimeout để đảm bảo setState chạy sau khi các sự kiện khác đã được xử lý
    setTimeout(() => {
      setShowWaitingCallsDetail((prev) => !prev);
    }, 0);
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Kiểm tra xem click có phải là trên nút không
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      return; // Không làm gì nếu click là trên nút
    }
    // Kiểm tra xem click có phải là bên ngoài waitingCallsDetail hay không
    if (waitingCallsRef.current && !waitingCallsRef.current.contains(event.target as Node) && showWaitingCallsDetail) {
      setShowWaitingCallsDetail(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [showWaitingCallsDetail]);

  useEffect(() => {
    switch (getAgentStatus?.agentState) {
      case AGENT_STATUS.AGENT_UNPAUSED:
        setCurrentPhoneColor(<PhoneReadyIcon height={20} width={20} />);
        break;
      case AGENT_STATUS.AGENT_PAUSED:
        setCurrentPhoneColor(<PhonePauseIcon height={20} width={20} />);
        break;
    }
  }, [getAgentStatus]);

  onGetWaitingCallsEvent(() => {
    refetch();
  });
  onReceiveCallHangUpEvent(() => {
    refetch();
  });

  useEffect(() => {
    handleFetchStatus();
    const extractAgentStatus = currentFetchAgentStatus?.data[0].state;
    switch (extractAgentStatus) {
      case AGENT_STATUS.LOGGEDOFF:
      case AGENT_STATUS.UNKNOWN:
      case AGENT_STATUS.FAILED_LOGIN:
        setCurrentPhoneColor(<PhoneFailedIcon height={20} width={20} />);
        break;
      case AGENT_STATUS.RINGING:
        setCurrentPhoneColor(<PhoneRingingIcon height={20} width={20} />);
        break;
      case AGENT_STATUS.ONCALL:
        setCurrentPhoneColor(<PhoneOnCallIcon height={20} width={20} />);
        break;
      case AGENT_STATUS.CREATING_TICKET:
        setCurrentPhoneColor(<PhoneCreateTicketIcon height={20} width={20} />);
        break;
      case AGENT_STATUS.IDLE:
        if (isAgentCreatingTicket && parseInt(isAgentCreatingTicket)) {
          setCurrentPhoneColor(<PhoneCreateTicketIcon height={20} width={20} />);
          return;
        }
        if (getAgentStatus?.agentState === AGENT_STATUS.AGENT_UNPAUSED) {
          setCurrentPhoneColor(<PhoneReadyIcon height={20} width={20} />);
        } else {
          setCurrentPhoneColor(<PhonePauseIcon height={20} width={20} />);
        }
        break;
      default:
        setCurrentPhoneColor(<PhoneFailedIcon height={20} width={20} />);
        break;
    }
  }, [getAgentStatus, agentStatus]);

  const handleFetchStatus = () => {
    if (profile?.extensionId) {
      fetchAgentStatus({ extension: profile.extensionId });
    }
  };

  return (
    <>
      {showWaitingCallsDetail && (
        <div
          ref={waitingCallsRef}
          className='absolute right-10 top-20 z-50 rounded-[10px] bg-white'
          style={{ boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.3), 0 8px 10px -6px rgba(0, 0, 0, 0.2)' }}
        >
          <WaitingCallsDetail waitingCalls={waitingCalls} />
        </div>
      )}
      <Divider type='vertical' className='mx-3 h-10 2xl:mx-5' />
      <div className='flex h-10 cursor-pointer items-center overflow-hidden rounded-full border border-defaultBorderColor'>
        <Button
          ref={buttonRef}
          icon={currentPhoneColor}
          type='text'
          className={twMerge(
            'h-10 w-13 rounded-none transition-colors duration-200',
            showWaitingCallsDetail ? 'bg-[#EBEBED]' : ''
          )}
          onClick={toggleWaitingCallsDetail}
        />
        <Divider type='vertical' className='mx-0 h-6' />
        <Button
          icon={<HourglassIcon height={20} width={20} />}
          type='text'
          className='h-10 w-[60px] rounded-none text-sm font-normal'
        >
          {countWaitingCalls}
        </Button>
        <Divider type='vertical' className='mx-0 h-6' />
      </div>
    </>
  );
}

export default CustomerLookupWaitingCall;
