import { Typography, Card, Modal, message, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { IncorrectRecordingLocationRequestDto, IncorrectRecordingLocationResponseDto, TicketDetail } from 'types';
import { requestform } from 'messages';
import { ArrowRightIcon } from 'assets';
import { useIncorrectRecordingLocationList } from '../../hooks';
import img from '../../assets/images/logo.png';
import { usePathConfirmIncorrectRecordingLocationMutation } from '../../services';

interface ticketFromDetail {
  status?: string;
  ticketId: number;
  cancelDetailModal: () => void;
  requestData: IncorrectRecordingLocationRequestDto;
}

const IncorrectRecordingLocationTicketDetail: React.FC<ticketFromDetail> = ({
  ticketId,
  requestData,
  cancelDetailModal,
  status
}) => {
  console.log(status);
  const [ticketData, setTicketData] = useState<IncorrectRecordingLocationResponseDto>();
  const { pending: data } = useIncorrectRecordingLocationList({ ...requestData, ticketId });

  useEffect(() => {
    data && setTicketData(data[0]);
  }, [data]);

  const [cofirmAddressTicket] = usePathConfirmIncorrectRecordingLocationMutation();
  const confirmChangeAddress = () => {
    Modal.confirm({
      title: 'Xác nhận thay đổi địa chỉ',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketData?.ticketAddressChangeId) {
          cofirmAddressTicket(ticketData?.ticketAddressChangeId)
            .unwrap()
            .then((res) => {
              message.success('Thay đổi địa chỉ thành công');
              cancelDetailModal();
            })
            .catch();
        }
      }
    });
  };

  return (
    <Card
      title={
        <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
          {requestform.changeLocationRecord}
        </Typography.Text>
      }
      className='request-form-detail-card h-fit text-center'
    >
      <div className='flex w-full text-left'>
        <section className='flex min-w-[768px] flex-col justify-between'>
          <section className='p-6'>
            <div className='flex items-center space-x-3'>
              <img src={img} className='h-8 w-8' alt='' />
              <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
                {requestform.customerInformationTitle}
              </Typography.Text>
            </div>

            <div className='space-y-2 pt-[20px]'>
              <div className='flex space-x-4'>
                <div className='w-[160px]'>
                  <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                    {requestform.customerName}
                  </Typography.Text>
                </div>
                <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
                  {ticketData?.customerName ? ticketData?.customerName : ''}
                </Typography.Text>
              </div>

              <div className='flex space-x-4'>
                <div className='w-[160px]'>
                  <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                    {requestform.customerCode}
                  </Typography.Text>
                </div>
                <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
                  {ticketData?.customerCode ? ticketData?.customerCode : ''}
                </Typography.Text>
              </div>

              <div className='flex space-x-4'>
                <div className='w-[160px]'>
                  <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                    {requestform.customerAddress}
                  </Typography.Text>
                </div>
                <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
                  {ticketData?.currentAddress ? ticketData?.currentAddress : ''}
                </Typography.Text>
              </div>

              <div className='flex space-x-4'>
                <div className='w-[160px]'>
                  <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                    {requestform.customerAddressUnitCode}
                  </Typography.Text>
                </div>
                <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
                  {ticketData?.newAdministrativeUnit.administrativeUnitCode
                    ? ticketData?.newAdministrativeUnit.administrativeUnitCode
                    : ''}
                </Typography.Text>
              </div>
            </div>
            <Divider className='mt-[20px] bg-[#D4D8DE]' />
          </section>

          <section className='space-y-3 p-6 pt-0'>
            <div className='flex justify-between'>
              <Typography className='text-[18px]/[18px] font-semibold uppercase text-[#000000]'>
                {requestform.oldLocation}
              </Typography>
              <ArrowRightIcon />
              <Typography className='text-[18px]/[18px] text-[#6C737E]'>{requestform.changeTo}</Typography>
              <ArrowRightIcon />
              <Typography className='text-[18px]/[18px] font-semibold uppercase text-[#000000]'>
                {requestform.newLocation}
              </Typography>
            </div>
            <div className='flex justify-between gap-4 text-left'>
              <div className='w-1/2 rounded-md bg-[#DC8888] p-4'>
                <div className='flex space-x-4'>
                  <div className='min-w-[100px]'>
                    <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                      {requestform.customerAddress}
                    </Typography.Text>
                  </div>
                  <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
                    {ticketData?.currentAddress ? ticketData?.currentAddress : ''}
                  </Typography.Text>
                </div>
                <div className='flex space-x-4'>
                  <div className='min-w-[100px]'>
                    <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                      {requestform.customerAddressUnitCode}
                    </Typography.Text>
                  </div>
                  <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
                    {ticketData?.currentAdministrativeUnit.administrativeUnitName
                      ? ticketData?.currentAdministrativeUnit.administrativeUnitName
                      : ''}
                  </Typography.Text>
                </div>
              </div>
              <div className='w-1/2 rounded-md bg-[#CF2E2E] p-4'>
                <div className='flex space-x-4'>
                  <div className='min-w-[100px]'>
                    <Typography.Text className='text-[16px]/[16px] text-[#FFFFFF]'>
                      {requestform.customerAddress}
                    </Typography.Text>
                  </div>
                  <Typography.Text className='text-[16px]/[20px] text-[#FFFFFF]'>
                    {ticketData?.currentAddress ? ticketData?.currentAddress : ''}
                  </Typography.Text>
                </div>
                <div className='flex space-x-4'>
                  <div className='min-w-[100px]'>
                    <Typography.Text className='text-[16px]/[16px] text-[#FFFFFF]'>
                      {requestform.customerAddressUnitCode}
                    </Typography.Text>
                  </div>
                  <Typography.Text className='text-[16px]/[20px] text-[#FFFFFF]'>
                    {ticketData?.newAdministrativeUnit.administrativeUnitName
                      ? ticketData?.newAdministrativeUnit.administrativeUnitName
                      : ''}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </section>

          {status !== 'completed_new_address_change' && (
            <div className='flex h-[88px] items-center justify-end border-t-2 p-6 text-left'>
              <button
                onClick={confirmChangeAddress}
                className='rounded-lg bg-[#1564E8] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
              >
                {requestform.confirmButton}
              </button>
            </div>
          )}
        </section>
      </div>
    </Card>
  );
};

export default IncorrectRecordingLocationTicketDetail;
