import { useState, useEffect, useCallback, useRef } from 'react';
import { AgentMapDto } from 'types';
import { useLazyGetAgentMapDetailQuery } from 'services';
import { UpdateAgentMapSocketDto } from 'types/dto/agent-maps/update-agent-map-socket.dto';
import { useSocketHandler } from 'hooks/useSocketHandler';
import _ from 'lodash';

export type chartDataType = {
    extensionId: string;
    statusName: string;
};

interface UseAgentMapDataProps {
    getAgentMapDetail: number | number[];
    setMultipleAgentMaps: React.Dispatch<React.SetStateAction<AgentMapDto[]>>;
    setSingleAgentMapAgentStatuses: React.Dispatch<React.SetStateAction<any[]>>;
    testFunc: (data: chartDataType[]) => void;
}

export const useAgentMapData = ({
    getAgentMapDetail,
    setMultipleAgentMaps,
    setSingleAgentMapAgentStatuses,
    testFunc
}: UseAgentMapDataProps) => {
    const [fetchAgentMapDetail] = useLazyGetAgentMapDetailQuery();
    const isQueryInitializedRef = useRef(false);
    const fetchInProgressRef = useRef(false);
    const instanceIdRef = useRef(`useAgentMapData_${Math.random().toString(36).substring(2, 9)}`);

    // Debounced version of testFunc to avoid too frequent chart updates
    const debouncedTestFunc = useCallback(
        _.debounce((data: chartDataType[]) => {
            testFunc(data);
        }, 100),
        [testFunc]
    );

    const memoizedTestFunc = useCallback((data: chartDataType[]) => {
        debouncedTestFunc(data);
    }, [debouncedTestFunc]);

    const fetchMaps = useCallback(async () => {
        // Prevent concurrent fetches
        if (fetchInProgressRef.current) return;
        fetchInProgressRef.current = true;

        if (!getAgentMapDetail) {
            setMultipleAgentMaps([]);
            setSingleAgentMapAgentStatuses([]);
            fetchInProgressRef.current = false;
            return;
        }

        const currentAgentMapIds = Array.isArray(getAgentMapDetail) ? getAgentMapDetail : [getAgentMapDetail];

        try {
            const results = await Promise.all(
                currentAgentMapIds.map(async (id) => {
                    try {
                        const { data } = await fetchAgentMapDetail(id).unwrap();
                        return data || null;
                    } catch (error) {
                        console.error(`Error fetching agent map ${id}:`, error);
                        return null;
                    }
                })
            );

            const allAgentMapDetails = results.filter((map: any): map is AgentMapDto => !!map);
            setMultipleAgentMaps(allAgentMapDetails);

            // Extract extension IDs and statuses
            const extensionIds = allAgentMapDetails
                .flatMap(item =>
                    item.seats?.map(seat => ({
                        extensionId: seat.extensionId,
                        statusName: seat.statusName
                    })) ?? []
                )
                .filter(item => item.extensionId !== null && item.extensionId !== undefined) as chartDataType[];

            if (extensionIds.length > 0) {
                memoizedTestFunc(extensionIds);
            }

            isQueryInitializedRef.current = true;
        } catch (error) {
            console.error('Error fetching agent map details:', error);
            setMultipleAgentMaps([]);
            setSingleAgentMapAgentStatuses([]);
        } finally {
            fetchInProgressRef.current = false;
        }
    }, [getAgentMapDetail, fetchAgentMapDetail, setMultipleAgentMaps, setSingleAgentMapAgentStatuses, memoizedTestFunc]);

    // Add resetAllData function
    const resetAllData = useCallback(() => {
        setMultipleAgentMaps([]);
        setSingleAgentMapAgentStatuses([]);
        memoizedTestFunc([]);
    }, [setMultipleAgentMaps, setSingleAgentMapAgentStatuses, memoizedTestFunc]);

    // Debounced version of fetchMaps to prevent excessive calls
    const debouncedFetchMaps = useCallback(
        _.debounce(() => {
            fetchMaps();
        }, 300),
        [fetchMaps]
    );

    // Create a stable handler for socket updates
    const handleAgentUpdate = useCallback((payload: UpdateAgentMapSocketDto) => {
        if (isQueryInitializedRef.current) {
            debouncedFetchMaps();
        }
    }, [debouncedFetchMaps]);

    // Use our socket handler hook
    useSocketHandler(instanceIdRef.current, handleAgentUpdate);

    // Initial data fetch
    useEffect(() => {
        fetchMaps();

        return () => {
            debouncedFetchMaps.cancel();
        };
    }, [fetchMaps, getAgentMapDetail]);

    return { fetchAgentMapDetail, resetAllData };
};