import { useGetChatAgentStatusQuery } from 'services';

export function useChatAgentStatus() {
  const { data, isLoading } = useGetChatAgentStatusQuery();

  return {
    data: data?.data,
    isLoading
  };
}
