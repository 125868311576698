import { Typography, Card, Modal, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { InprogressTickets, TicketDetail } from 'types';
import { requestform } from 'messages';
import { GreenCheck, PrinterIcon } from 'assets';
import { GetTicketDetail } from 'hooks/request-form';

import { DownOutlined } from '@ant-design/icons';
import {
  usePatchCompleteTicketMutation,
  usePatchInprogressTicketMutation,
  usePatchTransferToCustomerCareCenterMutation
} from 'services';

import defaultTicketDetail from 'types/dto/request-form/request-form.dto';

import { useLocation } from 'react-router-dom';
import { useRequestUpdateTicketFollowUpMutation } from 'services/tickets';
import { RequestUpdateTicketFollowUpDto } from 'types/dto/ticket/request-update-ticket.dto';
import RequestDetailCreateFormInformation from 'components/request-form/RequestDetailCreateFormInformation';
import RequestStatusBar from 'components/request-form/RequestStatusBar';
import RequestDetailMainInformation from 'components/request-form/RequestDetailMainInformation';
import RequestDetailProcessing from 'components/request-form/RequestDetailProcessing';
import ModalObstacle from 'components/request-form/ModalObstacle';
import ModalTransferCmis from 'components/request-form/ModalTransferCmis';
import ModalTransferUnit from 'components/request-form/ModalTransferUnit';
import TicketDetailProcessing from './TicketDetailedProcessing';
import TicketDetailedIProcessing from './TicketDetailedProcessing';
import TicketStatusBar from './TicketStatusBar';

interface ticketFromDetail {
  status?: string;
  ticketId: number;
  cancelDetailModal: () => void;
  isSecondCall?: boolean;
}

const TicketDetailed: React.FC<ticketFromDetail> = ({ status, ticketId, cancelDetailModal, isSecondCall }) => {
  const { requestDetail } = GetTicketDetail(ticketId);
  const location = useLocation();

  const [isModal, setIsModal] = useState(false);
  const [isOpenObstacleModal, setIsOpenObstacleModal] = useState(false);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [processingContent, setProcessingContent] = useState<string>('');
  const [requestUpdateTicketFollowUpContent, setRequestUpdateTicketFollowUpContent] = useState<string>('');
  const [requestItem, setRequestItem] = useState<TicketDetail | null>(null);
  const [isCallAgainClicked, setIsCallAgainClicked] = useState(false);

  // Control dispatch API
  const [completeTicket] = usePatchCompleteTicketMutation();
  const [inprocessTicket] = usePatchInprogressTicketMutation();
  const [transferToCustomerCareCenter] = usePatchTransferToCustomerCareCenterMutation();
  const [requestUpdateTicketFollowUp] = useRequestUpdateTicketFollowUpMutation();

  useEffect(() => {
    if (requestDetail) {
      const formattedRequestDetail: TicketDetail = Object.assign({}, defaultTicketDetail, requestDetail);
      setRequestItem(formattedRequestDetail);
    }
  }, [requestDetail]);

  // Control Modal Open / Close
  const openModalCMIS = () => setIsModal(true);
  const closeModalCMIS = () => {
    setIsModal(false);
    cancelDetailModal();
  };
  const openTransferModal = () => setIsOpenTransferModal(true);
  const closeTransferModal = () => setIsOpenTransferModal(false);
  const openObstacleModal = () => setIsOpenObstacleModal(true);
  const closeObstacleModal = () => setIsOpenObstacleModal(false);

  

  const completeTicketAction = () => {
    Modal.confirm({
      title: 'Xác nhận hoàn thành phiếu yêu cầu',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketId) {
          completeTicket(ticketId)
            .unwrap()
            .then((res) => {
              message.success('Hoàn thành phiếu yêu cầu', 2);
              cancelDetailModal();
            })
            .catch((err) => {});
        }
      }
    });
  };
  
  const inprogressTicketAction = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Vui lòng nhập nội dung xử lý!', 3);
      return;
    }
    const data: InprogressTickets = {
      ticketId,
      processingContent
    };
    Modal.confirm({
      title: 'Xác nhận cập nhật phiếu',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketId) {
          inprocessTicket(data)
            .unwrap()
            .then((res) => {
              message.success('Cập nhật phiếu thành công', 2);
              cancelDetailModal();
            })
            .catch((err) => {});
        }
      }
    });
  };
  const requestUpdateTicketFollowUpAction = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Vui lòng nhập nội dung xử lý!', 3);
      return;
    }
    const data: InprogressTickets = {
      ticketId,
      processingContent
    };
    Modal.confirm({
      title: 'Xác nhận cập nhật phiếu',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketId) {
          requestUpdateTicketFollowUp(data)
            .unwrap()
            .then((res) => {
              message.success('Cập nhật phiếu thành công', 2);
              cancelDetailModal();
              setIsCallAgainClicked(false);
            })
            .catch((err) => {});
        }
      }
    });
  };

  const transferCustomerCareCenter = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Nhập nội dung xử lý trước khi chuyển về TTCSKH', 3);
      return;
    }
    const data: InprogressTickets = {
      ticketId,
      processingContent
    };
    Modal.confirm({
      title: 'Xác nhận cập nhật phiếu',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketId) {
          transferToCustomerCareCenter(data)
            .unwrap()
            .then((res) => {
              message.success('Chuyển phiếu về TTCSKH thành công', 2);
              cancelDetailModal();
            })
            .catch((err) => {});
        }
      }
    });
  }

  return (
    <Card
      title={
        <Typography.Text className='w-full text-center text-[16px]/[22px] font-semibold'>
          {requestform.requestTicket} {ticketId}
        </Typography.Text>
      }
      className='request-form-detail-card min-h-[1000px] text-center'
    >
      <div className='flex w-full text-left'>
        <RequestDetailCreateFormInformation requestItem={requestItem} />
        <section className='flex flex-col justify-between'>
          <section className='min-w-[768px] p-6'>
            <section className='flex items-center justify-between'>
              <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
                {requestItem?.serviceType?.name ? requestItem.serviceType.name.split(' ').slice(0, 20).join(' ') : ''}
              </Typography.Text>
              <button className='flex items-center space-x-2 hover:border'>
                <PrinterIcon />
                <Typography.Text className='text-[16px]/[20px] text-[#1564E8]'>
                  {requestform.printTicket}
                </Typography.Text>
              </button>
            </section>

            <section>
              <Typography.Text
                className={`text-[14px]/[18px] ${requestItem?.isRequestMultipleTime ? 'text-[#D1131D]' : 'text-[#06A77D]'}`}
              >
                {requestItem?.isRequestMultipleTime
                  ? requestform.multiplleTimeRequest
                  : requestform.notMultiplleTimeRequest}
              </Typography.Text>
            </section>

            <section>
              {status && (
                <div className='pb-6'>
                  <TicketStatusBar status={status} />
                  {/* CMIS Transfer */}
                  {status !== 'HOANTAT' && status !== 'HUY' ? (
                    <div className='mt-1 justify-between rounded-[5px] bg-[#EBEBED] px-4 py-[11px]'>
                      <Typography.Text className='text-[14px]/[18px] font-semibold text-[#141414]'>
                        {requestform.isTranferCmisTicket}
                      </Typography.Text>
                      <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                        {requestform.typeCmisTicket}
                      </Typography.Text>{' '}
                      <DownOutlined className='h-2 w-[10px]' />
                    </div>
                  ) : requestItem?.cmisRequestId == null ? (
                    <div className='mt-1 flex justify-between rounded-[5px] border border-[#D1131D] bg-[#06A77D00] px-4 py-[11px]'>
                      <div>
                        <Typography.Text className='text-[14px]/[18px] font-semibold text-[#D1131D]'>
                          {requestform.cmisTransferFail}
                        </Typography.Text>{' '}
                        <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                          {requestform.typeCmisTicket}
                        </Typography.Text>{' '}
                        <DownOutlined className='h-2 w-[10px]' />
                      </div>
                      <button>
                        <Typography.Text className='text-[14px]/[18px] text-[#1564E8]'>
                          {requestform.tryAgainButton}
                        </Typography.Text>
                      </button>
                    </div>
                  ) : (
                    <div className='mt-1 flex justify-between rounded-[5px] bg-[#06A77D00] py-[11px]'>
                      <div className='flex space-x-1'>
                        <GreenCheck />
                        <Typography.Text className='text-[14px]/[18px] italic text-[#141414]'>
                          {requestform.cmisTranfered}
                          {requestItem.cmisRequestId}
                        </Typography.Text>
                      </div>{' '}
                      <Typography.Text className='text-[14px]/[18px] italic text-[#868D97]'>
                        {/* This is static data, add dynamic data latter */}
                        Lúc 16:32, 27/12/2024
                      </Typography.Text>
                    </div>
                  )}
                </div>
              )}
            </section>

            <section
              className={`${status !== 'CHOTIEPNHAN' && status !== 'HUY' ? 'h-[688px] overflow-y-auto' : 'max-h-[600px] overflow-y-auto'}`}
            >
              <RequestDetailMainInformation requestItem={requestItem} />
              <TicketDetailedIProcessing
                requestItem={requestItem}
                processingContent={processingContent}
                setProcessingContent={setProcessingContent}
                setRequestUpdateTicketFollowUpContent={setRequestUpdateTicketFollowUpContent}
                isSecondCall={isSecondCall || isCallAgainClicked}
              />
            </section>
          </section>

          {/* Button */}
          {!location.pathname.includes('customer-lookup') && status !== 'completed' && (
            <div className='flex h-[88px] items-center justify-end border-t-2 p-6 text-left'>
              {status === 'pending-reception' && (
                <button
                  onClick={openModalCMIS}
                  className='rounded-lg bg-[#1564E8] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                >
                  {requestform.acceptTicket}
                </button>
              )}

              <div className='flex space-x-4'>
                {(status === 'in-progress' || status === 'collaboration') && (
                  <>
                    <button
                      onClick={openObstacleModal}
                      className='rounded-lg border border-[#D1131D] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#D1131D]'
                    >
                      {requestform.obstacleTickets}
                    </button>

                    {status !== 'collaboration' && (
                      <>
                        <button onClick={transferCustomerCareCenter} className='rounded-lg border border-[#D1131D] bg-transparent px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#D1131D]'>
                          {requestform.transferCustomerCareCenter}
                        </button>
                        <button
                          onClick={openTransferModal}
                          className='rounded-lg border border-[#1564E8] bg-transparent px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#1564E8]'
                        >
                          {requestform.transferCollaboration}
                        </button>
                      </>
                    )}

                    <button
                      onClick={inprogressTicketAction}
                      className='rounded-lg bg-[#FF9500] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                    >
                      {requestform.inprocessTicket}
                    </button>
                    <button
                      onClick={completeTicketAction}
                      className='rounded-lg bg-[#28A745] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                    >
                      {requestform.completedButton}
                    </button>
                  </>
                )}

                {status === 'obstacle' && (
                  <>
                    <button
                      // onClick={inprogressTicketAction}
                      className='rounded-lg bg-[#FF9500] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                    >
                      {requestform.updateObstacleTicket}
                    </button>
                    <button
                      // onClick={completeTicketAction}
                      className='rounded-lg bg-[#28A745] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                    >
                      {requestform.completedObstacleTicket}
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

            {location.pathname.includes('customer-lookup') && status !== 'HOANTAT' && status !== 'HUY' && (
            <div className='flex h-[88px] items-center justify-end border-t-2 p-6 text-left'>
              <button
              onClick={() => {
                if (isCallAgainClicked) {
                requestUpdateTicketFollowUpAction();
                } else {
                setIsCallAgainClicked(true);
                }
              }}
              className='rounded-lg bg-[#1564E8] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
              >
              {isSecondCall || isCallAgainClicked ? 'Cập nhật' : requestform.callAgain}
              </button>
            </div>
            )}
        </section>
      </div>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isOpenObstacleModal}
        onCancel={closeObstacleModal}
        footer={null}
        destroyOnClose
      >
        <ModalObstacle ticketId={ticketId} closeObstacleModal={closeObstacleModal} />
      </Modal>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isModal}
        onCancel={closeModalCMIS}
        footer={null}
        destroyOnClose
      >
        <ModalTransferCmis requestItem={requestItem} closeModalCMIS={closeModalCMIS} />
      </Modal>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isOpenTransferModal}
        onCancel={closeTransferModal}
        footer={null}
        destroyOnClose
      >
        <ModalTransferUnit
          requestItem={requestItem}
          processingContent={processingContent}
          cancelDetailModal={cancelDetailModal}
        />
      </Modal>
    </Card>
  );
};

export default TicketDetailed;
