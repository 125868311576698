import { useOmniChatContext } from 'contexts';
import { useProfile } from 'hooks';
import { internalChatMessages } from 'messages';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { colorsBase } from 'themes';
import { OmniChannelMessageDto, OmniChannelSenderDto } from 'types';

type OmniMessageSystemProps = {
  data: OmniChannelMessageDto;
  sender?: OmniChannelSenderDto;
};

const OmniMessageSystem: React.FC<OmniMessageSystemProps> = ({ data, sender }) => {
  const { variables } = useOmniChatContext();
  const { profile } = useProfile();
  const isMe = data.senderId === profile?.userId;

  return (
    <div
      className={twMerge(
        'relative min-w-[70px] max-w-[700px] whitespace-pre-wrap break-words px-3 py-2 text-center text-sm'
      )}
      style={{
        color: variables?.systemMessText ?? '#ffff'
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: `${data.content}` }} />
    </div>
  );
};

export default memo(OmniMessageSystem);
