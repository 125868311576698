export enum AGENT_MAP_OBJECT_TYPE {
  SINGLE_DOOR = 25,
  WALL = 26,
  DOUBLE_DOOR = 52,
  SLIDING_DOOR = 53,
  TEXT = 80,

  /** Textbox của seat  ( chỉ trên FE )*/
  SEAT_IP = 1,
  SEAT_NAME = 2,
  SEAT = 3,
  SEAT_EXTENSION = 4,
  SEAT_SHORTNAME = 5,
  SEAT_CALL_ICON = 6,
  SEAT_CHAT_ICON = 7
}
