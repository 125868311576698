import { Button, Typography } from 'antd';
import {
    CheckIcon,

} from 'assets';
import { useProfile } from 'hooks';
import { customerLookupMessages } from 'messages/customer-lookup.message';
import { AGENT_STATUS } from 'utils';


interface TemporarilyPausedProps {
    isMinimized: boolean;
    toggleAgentStatus: (status: AGENT_STATUS.AGENT_PAUSED | AGENT_STATUS.AGENT_UNPAUSED) => void;
}

const TemporarilyPausedContent: React.FC<TemporarilyPausedProps> = ({ isMinimized, toggleAgentStatus }) => {
    const { profile } = useProfile();

    return isMinimized ? (
        <div className="w-[400px] flex h-[244px] flex-col rounded-tl-xl bg-[#FFE6B3]">
            <div className="m-5 flex flex-col">
                <Typography.Text className="text-left text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    EXT #{profile?.extensionId || ''}
                </Typography.Text>
                <Typography.Text className="mt-[14px] text-left font-semibold tracking-normal text-[#000000] opacity-100">
                    {customerLookupMessages.isPause}
                </Typography.Text>
                <Typography.Text className="mt-2 text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                    {customerLookupMessages.notReadyMessage}
                </Typography.Text>
                <Button
                    style={{ borderColor: "#06A77D" }}
                    className="mt-9 h-9 w-[170px]"
                    icon={<CheckIcon />}
                    onClick={() => toggleAgentStatus(AGENT_STATUS.AGENT_UNPAUSED)}
                >
                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">
                        {customerLookupMessages.ready}
                    </Typography.Text>
                </Button>
            </div>
        </div>
    ) : (
        <div className="flex h-full w-[400px] flex-col rounded-l-xl bg-[#FFFFFF]">
            <div className="m-5 flex h-full flex-col items-center ">
                <Typography.Text className="text-left text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    EXT #{profile?.extensionId || ''}
                </Typography.Text>
                <Typography.Text className="mt-[40%] text-center font-semibold tracking-normal text-[#000000] opacity-100">
                    {customerLookupMessages.isPause}
                </Typography.Text>
                <Typography.Text className="mt-2 text-center text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                    {customerLookupMessages.notReadyMessage}
                </Typography.Text>
                <Button
                    style={{ borderColor: "#06A77D" }}
                    className="mt-9 h-9 w-[170px]"
                    icon={<CheckIcon />}
                    onClick={() => toggleAgentStatus(AGENT_STATUS.AGENT_UNPAUSED)}
                >
                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">
                        {customerLookupMessages.ready}
                    </Typography.Text>
                </Button>
            </div>
        </div>
    );
};

export default TemporarilyPausedContent;
