import { Filter } from 'components/common';
import { useLazyOptions, useOptions } from 'hooks';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FindAllOptionsDto } from 'types';
import { filterOptions } from 'utils';

type FilterOptionsProps = PropsWithChildren &
  Pick<FindAllOptionsDto, 'optionTypeId' | 'service'> & {
    title?: string;
    icon?: React.JSX.Element;
  };

const FilterOptions = ({ optionTypeId, service, title, ...props }: FilterOptionsProps) => {
  const { data, isLoading } = useOptions({
    optionTypeId,
    service
  });

  const [keyword, setKeyword] = useState('');

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const options = filterOptions(data, keyword, 'name');
  return (
    <Filter.CheckboxSearch
      {...props}
      count={data.length}
      options={options.map((o) => ({
        label: o.name,
        value: o.optionId
      }))}
      title={title || ''}
      onSearch={handleSearch}
      loading={isLoading}
      placeholder={title}
      fetchData={() => setKeyword('')}
    />
  );
};
export default FilterOptions;
