import { Button, Form, Tooltip, Typography } from 'antd';
import { AgentMapDoubleDoor, AgentMapSingleDoor, AgentMapSlidingDoor, AgentMapWall } from 'assets';
import { Loadable } from 'components';
import { useTitle } from 'hooks';
import { sidebarMenuMessages, validateMessages } from 'messages';
import { agentMapsMessages } from 'messages/agent-maps.messages';
import React, { lazy, memo } from 'react';
import { CreateAgentMapDto } from 'types';
import { FabricAgentMapOtherObject, FabricAgentMapSeat } from 'types/fabric-agent-map';
import { AGENT_MAP_OBJECT_TYPE } from 'utils';
import AgentMapName from './AgentMapName';
import { useAgentMapContext } from 'contexts';

const AgentMap = lazy(() => import('components/agent-map/AgentMap'));

export type AgentMapFormType = CreateAgentMapDto & {
  fabricSeats: FabricAgentMapSeat[];
  fabricOtherObjects: FabricAgentMapOtherObject[];
};
type AgentMapFormProps = {
  agentMapId: number;
};
const AgentMapForm: React.FC<AgentMapFormProps> = ({ agentMapId }) => {
  useTitle(sidebarMenuMessages.agentMaps);

  const { form, onSubmit, isLoading, handleAddSeat, handleAddObject, handleDragObjectStart } = useAgentMapContext();

  const onAddSeat = (seat?: Partial<FabricAgentMapSeat>) => {
    handleAddSeat(seat);
  };

  const handleAddOtherObject = (objectTypeId: AGENT_MAP_OBJECT_TYPE) => {
    handleAddObject({ objectTypeId });
  };

  return (
    <div className='h-full w-full'>
      <Form
        labelAlign='right'
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        form={form}
        onFinish={onSubmit}
        labelCol={{
          flex: '180px'
        }}
        layout='horizontal'
        validateMessages={validateMessages}
        className='flex h-full w-full'
        initialValues={{
          name: agentMapsMessages.newMap
        }}
      >
        <div className='flex h-full w-full flex-col'>
          <div className='relative flex h-18 w-full items-center gap-6 rounded-t-xl bg-backgroundColor2 px-6'>
            <AgentMapName agentMapId={agentMapId} form={form} />

            <div className='absolute left-1/2 flex -translate-x-1/2 gap-4'>
              <Tooltip trigger={'hover'} title={agentMapsMessages.addSeat}>
                <Button
                  draggable
                  onDragStart={handleDragObjectStart(AGENT_MAP_OBJECT_TYPE.SEAT)}
                  ghost
                  className='h-10 w-10 rounded-base border-none p-0 text-colorTextBase hover:bg-hoverColor2'
                  onClick={() => onAddSeat()}
                >
                  <div className='h-6 w-6 rounded-[3px] border border-colorTextBase'></div>
                </Button>
              </Tooltip>

              <Tooltip trigger={'hover'} title={agentMapsMessages.addSingleDoor}>
                <Button
                  ghost
                  className='h-10 w-10 rounded-base border-none p-0 text-colorTextBase hover:bg-hoverColor2'
                  onClick={() => handleAddOtherObject(AGENT_MAP_OBJECT_TYPE.SINGLE_DOOR)}
                  onDragStart={handleDragObjectStart(AGENT_MAP_OBJECT_TYPE.SINGLE_DOOR)}
                  draggable
                >
                  <AgentMapSingleDoor className='h-8 w-8' />
                </Button>
              </Tooltip>

              <Tooltip trigger={'hover'} title={agentMapsMessages.addDoubleDoor}>
                <Button
                  ghost
                  className='h-10 w-10 rounded-base border-none p-0 text-colorTextBase hover:bg-hoverColor2'
                  onClick={() => handleAddOtherObject(AGENT_MAP_OBJECT_TYPE.DOUBLE_DOOR)}
                  onDragStart={handleDragObjectStart(AGENT_MAP_OBJECT_TYPE.DOUBLE_DOOR)}
                  draggable
                >
                  <AgentMapDoubleDoor className='w-8' />
                </Button>
              </Tooltip>

              <Tooltip trigger={'hover'} title={agentMapsMessages.addSlidingDoor}>
                <Button
                  ghost
                  className='h-10 w-10 rounded-base border-none p-0 text-colorTextBase hover:bg-hoverColor2'
                  onClick={() => handleAddOtherObject(AGENT_MAP_OBJECT_TYPE.SLIDING_DOOR)}
                  onDragStart={handleDragObjectStart(AGENT_MAP_OBJECT_TYPE.SLIDING_DOOR)}
                  draggable
                >
                  <AgentMapSlidingDoor className='w-8' />
                </Button>
              </Tooltip>

              <Tooltip trigger={'hover'} title={agentMapsMessages.addWall}>
                <Button
                  ghost
                  className='h-10 w-10 rounded-base border-none p-0 text-colorTextBase hover:bg-hoverColor2'
                  onClick={() => handleAddOtherObject(AGENT_MAP_OBJECT_TYPE.WALL)}
                  onDragStart={handleDragObjectStart(AGENT_MAP_OBJECT_TYPE.WALL)}
                  draggable
                >
                  <AgentMapWall className='w-8' />
                </Button>
              </Tooltip>

              <Tooltip trigger={'hover'} title={agentMapsMessages.addText}>
                <Button
                  ghost
                  className='h-10 w-10 rounded-base border-none p-0 text-colorTextBase hover:bg-hoverColor2'
                  onClick={() => handleAddOtherObject(AGENT_MAP_OBJECT_TYPE.TEXT)}
                  onDragStart={handleDragObjectStart(AGENT_MAP_OBJECT_TYPE.TEXT)}
                  draggable
                >
                  <Typography.Text className='font-serif text-xl'>T</Typography.Text>
                </Button>
              </Tooltip>
            </div>

            <Button
              size='small'
              className='ml-auto h-8 w-[77px] rounded-base'
              loading={isLoading}
              type='primary'
              onClick={() => {
                form.submit();
              }}
            >
              {agentMapsMessages.save}
            </Button>
          </div>

          <div className='max-w-full overflow-auto border'>
            <Form.Item<AgentMapFormType> name='isActive' initialValue={true} noStyle />

            <Loadable message={agentMapsMessages.drawGrid}>
              <AgentMap />
            </Loadable>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default memo(AgentMapForm);
