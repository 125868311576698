import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';
import SystemConfigLayout from 'layouts/SystemConfigLayout';

export const accessConnectManagementRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_MANAGE_CONNECTION]} />,
    children: [
      {
        element: <SystemConfigLayout />,
        children: [
          {
            path: ROUTE.RATING_SYSTEM,
            element: <CheckPermissionLayout permission={[PERMISSION.UPDATE_SYSTEM_CONFIG]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/RatingSystem'))
              }
            ]
          },
          {
            path: ROUTE.CALL_CENTER_SYSTEM,
            Component: lazy(() => import('pages/CallCenterSystem'))
          }
        ]
      },
      {
        path: ROUTE.API_SHARING_PARTNERS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_API_SHARING_PARTNER]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/ApiSharingPartners'))
          }
        ]
      },
      {
        path: ROUTE.FANPAGE_CONNECTIONS,
        Component: lazy(() => import('pages/FanpageConnections'))
      }
    ]
  }
];
