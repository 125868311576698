import { FileDto, ResponseDto } from 'types';
import axiosClient from './axios-client';
import { FILE_TYPE } from './enums';
import axios from 'axios';

export const convertFileType = (type?: string) => {
  if (!type) return FILE_TYPE.UNKNOWN;

  if (type.startsWith('image/')) return FILE_TYPE.IMAGE;

  if (
    type === 'application/vnd.ms-excel' || // .xls
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
  ) {
    return FILE_TYPE.EXCEL;
  }

  if (type.endsWith('pdf')) {
    return FILE_TYPE.PDF;
  }

  if (type.endsWith('mp4')) {
    return FILE_TYPE.VIDEO;
  }

  if (
    type === 'application/msword' || // .doc
    type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
  ) {
    return FILE_TYPE.DOCS;
  }

  return FILE_TYPE.UNKNOWN;
};

export const loadFileFromUrl = async (url: string, options: RequestInit = {}, timeout = 1000): Promise<File | null> => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal // Gán signal để có thể hủy request
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch file from URL: ${response.statusText}`);
    }

    const blob = await response.blob();
    const fileName = url.split('/').pop() || 'downloaded_file';
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } catch (error) {
    console.error('Error loading file:', error);
    return null;
  } finally {
    clearTimeout(timeoutId);
  }
};

const getFileById = async (id: number, timeout = 1000): Promise<ResponseDto<FileDto>> => {
  return axiosClient.get(`/files/${id}`, { timeout });
};

export const loadFileFromId = async (id: number, timeout = 1000): Promise<FileDto | null> => {
  try {
    const response = await getFileById(id, timeout);
    return response.data || null;
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === 'ECONNABORTED') {
      console.warn(`Request for file ${id} timed out after ${timeout}ms`);
    } else {
      console.error('Error loading file:', error);
    }
    return null;
  }
};
