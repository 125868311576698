import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CustomerSupportSessionState {
    isCallSelected: boolean; // true = call được chọn, false = chat được chọn
    inLookupMode: boolean;
    // ...other state properties
}

const initialState: CustomerSupportSessionState = {
    isCallSelected: true, // Mặc định luôn chọn call
    inLookupMode: false,
    // ...other initial state properties
};

const customerSupportSessionSlice = createSlice({
    name: 'customerSupportSession',
    initialState,
    reducers: {
        setIsCallSelected: (state, action: PayloadAction<boolean>) => {
            state.isCallSelected = action.payload;
        },
        setInLookupMode: (state, action: PayloadAction<boolean>) => {
            state.inLookupMode = action.payload;
        },
        // ...other reducers
    },
});

export const { setIsCallSelected, setInLookupMode } = customerSupportSessionSlice.actions;

export default customerSupportSessionSlice.reducer;


