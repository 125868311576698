import { createTicketMessages } from 'messages/create-ticket.messages';
import {
    Button,
    Checkbox,
    CheckboxProps,
    Descriptions,
    Divider,
    Form,
    FormInstance,
    Input,
    Modal,
    Select,
    Space,
    Spin,
    Typography,
    UploadFile,
    Image,
    Tooltip,
} from 'antd';
import { DatePicker, FormItem, message, Upload } from 'components/common';
import TextArea from 'antd/es/input/TextArea';
import { validateMessages } from 'messages';
import { ServerUploadFile } from 'components/common/upload/ServerUpload';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    useCreateTicketAndTransferToOrgUnitMutation,
    useCreateTicketAndTransferToSupervisorMutation,
    useGetAppointmentDateQuery,
    useGetTicketDetailQuery
} from 'services/tickets';
import { CreateTicketDto } from 'types/dto/ticket';
import { useOptions, useServiceTypeClassifyOptions, useServiceTypesOptions, useUploadForm } from 'hooks';
import { SelectChannels } from 'components/channels';
import { DefaultOptionType } from 'antd/es/select';
import { useCustomerDetail } from 'hooks/customers/useCustomerDetailed';
import { useAdministrativeUnitDetailedByCode, useDistrictsPaging, useWardsByDistrictId } from 'hooks/administrative-units';
import { CORE_OPTION_TYPES, DATE_FORMAT, LOCAL_STORAGE_KEY, MICROSERVICES } from 'utils';
import { useTicketSampleActive } from 'hooks/ticket-samples/useTicketSampleActive';
import dayjs from 'dayjs';
import 'dayjs/locale/vi'; // Import ngôn ngữ tiếng Việt cho dayjs
import locale from 'antd/es/date-picker/locale/vi_VN'; // Import locale tiếng Việt của Ant Design
import { useGetServiceTypeDetailQuery } from 'services';
import { FindAllServiceTypeDto, ServiceTypeFormDto } from 'types';
import { createTicketValidationRules } from 'utils/validation-rules/create-ticket.validation-rules';
import { SelectServiceTypes } from 'components/service-types';
import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { AttentionRedCircleIcon, CalendarColoredIcon, CalendarRedIcon, CheckIcon, CloseIcon } from 'assets';
import logoEVN from '../../assets/images/logo.png';
import TicketIDetailedFileAttachment from 'components/ticket-detailed/TicketDetailedFileAttachment';
import { useChannelsPaging } from 'hooks/channels';
import RequestDetail from 'components/request-form/RequestDetail';
import TicketDetailed from 'components/ticket-detailed/TicketDetailed';
import { useTicketDetail } from 'hooks/tickets';

// Add CustomerSupportSession interface if not already imported
interface CustomerSupportSession {
    chatSessionId?: number;
    searchData?: any[];
    callId?: string;
    type?: 'call' | 'chat';
}

export type CreateTicketFormProps = {
    onChangeLoading?: (value: boolean) => void;
    onCreateSuccess?: () => void;
    ticketId?: number;
    customerId: number;
    refetchOnMountOrArgChange?: boolean;
    serviceTypeId?: number;
    onCloseModal?: () => void;
    // Add these new props
    currentSessionId?: string | number;
    sessionType?: 'call' | 'chat';
};

export type CreateTicketFormRefProps = {
    form: FormInstance<CreateTicketFormType>;
    isLoading: boolean;
    getFieldsValue: () => any;
    setFieldsValue: (values: any) => void;
};

export type CreateTicketFormType = Omit<CreateTicketDto, 'channelId' | 'serviceTypeId' | 'uploadFileIds'> & {
    channelId: DefaultOptionType;
    serviceTypeId: DefaultOptionType;
    uploadFileIds: ServerUploadFile[];
    district?: number;
    ward?: DefaultOptionType;
    wardLabel?: string;
    districtLabel?: string;
    channelLabel?: string;
};

const CreateTicketForm = forwardRef<CreateTicketFormRefProps, CreateTicketFormProps>(
    (
        {
            onChangeLoading,
            onCreateSuccess,
            ticketId,
            customerId,
            serviceTypeId,
            refetchOnMountOrArgChange = true,
            onCloseModal,
            currentSessionId,
            sessionType
        },
        ref
    ) => {
        const [form] = Form.useForm<CreateTicketFormType>();
        useImperativeHandle(ref, () => ({
            form,
            isLoading: isLoadingCreate,
            getFieldsValue: () => form.getFieldsValue(),
            setFieldsValue: (values) => form.setFieldsValue(values)
        }));
        const [formFields, setFormFields] = useState<ServiceTypeFormDto | null>(null);
        const { handleMultiUpload } = useUploadForm();
        const { customer } = useCustomerDetail(customerId);
        const { districts } = useDistrictsPaging({});
        const [districtId, setDistrictId] = useState(0);
        const { wards } = useWardsByDistrictId(districtId);
        const { administrativeUnitByCode } = useAdministrativeUnitDetailedByCode(customer?.administrativeUnitCode!, {
            skip: !customer?.administrativeUnitCode,
            refetchOnMountOrArgChange: true
        });
        const { data: serviceType, isLoading: isLoadingServiceTypeDetail } = useGetServiceTypeDetailQuery(serviceTypeId!, {
            skip: !serviceTypeId,
            refetchOnMountOrArgChange: true
        });
        useEffect(() => {
            if (serviceType?.data?.form) {
                setFormFields(serviceType.data.form as ServiceTypeFormDto);
            }
        }, [serviceType]);
        const handleSelectWard = (value: number) => {
            setDistrictId(value);
        };
        const { channels } = useChannelsPaging(form.getFieldValue('channelId'))
        const { data: createTicket, isLoading: isLoadingDetail } = useGetTicketDetailQuery(ticketId!, {
            skip: !ticketId,
            refetchOnMountOrArgChange: true
        });

        const { data: optionsCicType } = useOptions({
            optionTypeId: CORE_OPTION_TYPES.TICKET_CIC_TYPE,
            service: MICROSERVICES.CORE
        });

        const { data: optionsOutageReason } = useOptions({
            optionTypeId: CORE_OPTION_TYPES.OUTAGE_REASON,
            service: MICROSERVICES.CORE
        })

        useEffect(() => {
            if (createTicket && ticketId) {
                form.setFieldsValue({
                    ...createTicket.data,
                    serviceTypeId: createTicket.data.serviceTypeId
                        ? {
                            label: createTicket.data.serviceType.name,
                            value: createTicket.data.serviceTypeId
                        }
                        : undefined,
                    channelId: createTicket.data.channelId
                        ? {
                            label: createTicket.data.channel.channelName,
                            value: createTicket.data.channelId
                        }
                        : undefined,
                    uploadFileIds: createTicket.data.files.map((o) => ({
                        uid: `file-${o}`,
                        fileId: o
                    })),
                    customerRequestCicIssueDate: createTicket.data.customerRequestCicIssueDate
                        ? dayjs(createTicket.data.customerRequestCicIssueDate).format(DATE_FORMAT)
                        : undefined,
                    appointmentDate: createTicket.data.appointmentDate
                        ? dayjs(createTicket.data.appointmentDate).format(DATE_FORMAT)
                        : undefined,
                });
            }
        }, [createTicket, ticketId]);

        const [isDirectCustomerContact, setIsDirectCustomerContact] = useState(false);
        const [isCustomerNotEnter, setIsCustomerNotEnter] = useState(false);
        const [onCreateAndTransferToOrg, { isLoading: isLoadingCreate }] = useCreateTicketAndTransferToOrgUnitMutation();
        const [onCreateAndTransferToSupervisor, { isLoading: isLoadingSupervisor }] =
            useCreateTicketAndTransferToSupervisorMutation();
        const [submitType, setSubmitType] = useState<'orgUnit' | 'supervisor' | null>(null);
        const [isModalVisible, setIsModalVisible] = useState(false);
        const [reviewData, setReviewData] = useState<CreateTicketFormType | null>(null);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [selectedTicketId, setSelectedTicketId] = useState<number | null>(null);
        const { ticketDetail } = useTicketDetail(selectedTicketId || 0);

        const showModal = (ticketId: number) => {
            setIsModalOpen(true);
            setSelectedTicketId(ticketId);
        };
        const handleCancel = () => {
            setIsModalOpen(false);
            setSelectedTicketId(null);
        };


        const onCheckbox: CheckboxProps['onChange'] = (e) => {

        };

        const onCheckboxDirectCustomerContact: CheckboxProps['onChange'] = (e) => {
            const isChecked = e.target.checked;
            setIsDirectCustomerContact(isChecked);
            if (isChecked && customer) {
                form.setFieldsValue({
                    customerRequestName: customer.customerName,
                    customerRequestPhoneNumber: customer.customerPhoneNumber,
                    customerRequestAddress: customer.customerAddress,
                    district: administrativeUnitByCode?.parentId,// Gán ID quận/huyện
                    incidenAddress: customer.customerAddress,
                    ward: administrativeUnitByCode
                        ? {
                            label: administrativeUnitByCode.administrativeUnitName,
                            value: administrativeUnitByCode.administrativeUnitId
                        }
                        : undefined, // Gán ID phường/xã
                });
            } else {
                form.setFieldsValue({
                    customerRequestName: '',
                    customerRequestPhoneNumber: '',
                    customerRequestAddress: '',
                    incidenAddress: '',
                    district: undefined, // Reset giá trị
                    ward: undefined, // Reset giá trị
                });
            }
        };

        const onCheckboxCustomerNotEnter: CheckboxProps['onChange'] = (e) => {
            const isChecked = e.target.checked;
            setIsCustomerNotEnter(isChecked);
            if (isChecked) {
                form.setFieldsValue({
                    customerRequestName: 'không cung cấp',
                    customerRequestPhoneNumber: 'không cung cấp',
                    customerRequestAddress: 'không cung cấp',
                });
            } else {
                form.setFieldsValue({
                    customerRequestName: '',
                    customerRequestPhoneNumber: '',
                    customerRequestAddress: '',
                });
            }
        };

        const [content, setContent] = useState('');
        const handleTicketSampleClick = (sampleContent: string) => {
            form.setFieldsValue({ content: sampleContent });
            setContent(sampleContent);
        };
        const [suggestions, setSuggestions] = useState<string[]>([]); // Danh sách gợi ý
        const [isSuggestionVisible, setIsSuggestionVisible] = useState(false); // Hiển thị gợi ý
        const textareaRef = useRef<HTMLTextAreaElement>(null);
        const { ticketSampleActive } = useTicketSampleActive({ serviceTypeId: serviceTypeId });

        const handleHotkeyInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const inputValue = e.target.value;
            setContent(inputValue);

            // Kiểm tra nếu có #hotkey trong nội dung nhập
            const match = inputValue.match(/#(\w*)$/); // Tìm từ sau dấu #
            if (match) {
                const hotkey = match[1]; // Lấy hotkey từ input
                const filteredSuggestions = ticketSampleActive
                    ?.filter((sample) => sample.hotkey?.startsWith(hotkey))
                    .map((sample) => sample.hotkey) || [];

                // Loại bỏ các giá trị undefined
                setSuggestions(filteredSuggestions.filter((suggestion): suggestion is string => suggestion !== undefined));
                setIsSuggestionVisible(filteredSuggestions.length > 0);
            } else {
                setIsSuggestionVisible(false);
            }
        };

        const handleSuggestionSelect = (hotkey: string) => {
            if (textareaRef.current) {
                const cursorPosition = textareaRef.current.selectionStart;
                const beforeCursor = content.slice(0, cursorPosition);
                const afterCursor = content.slice(cursorPosition);
        
                // Tìm nội dung gợi ý tương ứng với hotkey
                const suggestionContent = ticketSampleActive?.find(sample => sample.hotkey === hotkey)?.content || hotkey;
        
                // Thay thế hotkey (bao gồm cả dấu #) bằng nội dung gợi ý
                const updatedContent = beforeCursor.replace(/#\w*$/, suggestionContent) + afterCursor;
        
                setContent(updatedContent.trim()); // Loại bỏ khoảng trắng thừa nếu có
                setIsSuggestionVisible(false);
                textareaRef.current.focus();
            }
        };
        useEffect(() => {
            form.setFieldsValue({ content }); // Đồng bộ giá trị state với Form
        }, [content, form]);
         

        const appointmentDate = useGetAppointmentDateQuery('');
        const defAppointmentDate = dayjs(appointmentDate.data?.data);
        const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
    const [previewVisible, setPreviewVisible] = useState(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as Blob);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const handleChange = ({ fileList: newFileList }: { fileList: UploadFile[] }) => {
        setFileList(newFileList);
    };

    const renderFilePreview = (file: UploadFile) => {
        const fileType = file.type || '';
        if (fileType.startsWith('image/')) {
            // Hiển thị hình ảnh
            return (
                <img
                    src={file.url || (file.preview as string)}
                    alt={file.name}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
            );
        } else if (fileType === 'application/pdf') {
            // Hiển thị PDF
            return (
                <a href={file.url || (file.preview as string)} target="_blank" rel="noopener noreferrer">
                    <FileOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                    <p>{file.name}</p>
                </a>
            );
        } else {
            // Hiển thị các định dạng khác
            return (
                <div>
                    <FileOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                    <p>{file.name}</p>
                </div>
            );
        }
    };

        const showReviewModal = (type: 'orgUnit' | 'supervisor') => {
            form.validateFields().then((values) => {
                const channelValue = typeof values.channelId === 'object' ? values.channelId?.value : values.channelId;

                const fullReviewData: CreateTicketFormType & { channelLabel?: string, districtLabel?: string, wardLabel?: string } = {
                    ...values,
                    customerCode: customer?.customerCode || "",
                    organizationUnitId: customer?.organizationUnit?.organizationUnitId || 0,
                    administrativeUnitId: customer?.administrativeUnitCode ? Number(customer.administrativeUnitCode) : 0,
                    // Thêm thông tin để hiển thị
                    channelLabel: channels?.find(ch => ch.channelId === channelValue)?.channelName || "N/A",
                    //channelLabel: values.channelId?.label || "N/A",
                    districtLabel: districts?.find(d => d.administrativeUnitId === values.district)?.administrativeUnitName || "N/A",
                    wardLabel: wards?.find(w => w.administrativeUnitId === (typeof values.ward === 'object' ? values.ward.value : values.ward))?.administrativeUnitName || "N/A",
                };

                setReviewData(fullReviewData);
                setSubmitType(type);
                setIsModalVisible(true);
            }).catch((error) => {
                console.error('Validation failed:', error);
            });
        };


        const onFinish = async ({ ...values }: CreateTicketFormType) => {
            const updateFileList = values.uploadFileIds;
            const filesUploaded = await handleMultiUpload(updateFileList);
            const formattedCicIssueDate = values.customerRequestCicIssueDate
                ? dayjs(values.customerRequestCicIssueDate).format('DD/MM/YYYY')
                : undefined;
            const wardValue = typeof values.ward === 'object' ? values.ward?.value : values.ward;
            const data: CreateTicketDto = {
                ...values,
                customerCode: customer?.customerCode || "khongdinhdanh",
                organizationUnitId: customer?.organizationUnit?.organizationUnitId,
                administrativeUnitId: wardValue as number,
                channelId: form.getFieldValue("channelId"),
                serviceTypeId: serviceTypeId,
                uploadFileIds: filesUploaded,
                appointmentDate: form.getFieldValue("appointmentDate"),
                customerRequestCicIssueDate: formattedCicIssueDate,
            };

            // Helper function to remove the current session from localStorage
            const removeCurrentSessionFromStorage = () => {
                if (!currentSessionId) return;

                try {
                    // Read the current sessions from localStorage
                    const storedSessions = localStorage.getItem('customerSupportSession');
                    if (!storedSessions) return;

                    // Parse the sessions
                    const allSessions: CustomerSupportSession[] = JSON.parse(storedSessions);

                    // Find the actual session to determine its type
                    let sessionTypeToUse = sessionType;

                    if (!sessionTypeToUse) {
                        // Look for the session in the array by checking both callId and chatSessionId
                        const currentSession = allSessions.find(session =>
                            (session.callId !== undefined && session.callId === currentSessionId) ||
                            (session.chatSessionId !== undefined && session.chatSessionId === currentSessionId)
                        );

                        // If found, use its explicit type or determine from properties
                        if (currentSession) {
                            if (currentSession.type) {
                                sessionTypeToUse = currentSession.type;
                            } else if (currentSession.callId !== undefined) {
                                sessionTypeToUse = 'call';
                            } else if (currentSession.chatSessionId !== undefined) {
                                sessionTypeToUse = 'chat';
                            }
                        }
                    }

                    // If we still don't have a type, check the currentSessionId against both properties
                    if (!sessionTypeToUse) {
                        let foundAsCall = allSessions.some(session => session.callId === currentSessionId);
                        if (foundAsCall) {
                            sessionTypeToUse = 'call';
                        } else {
                            sessionTypeToUse = 'chat'; // Default to chat if not found as call
                        }
                    }

                    // Filter out the current session based on ID and determined type
                    const updatedSessions = allSessions.filter(session => {
                        if (sessionTypeToUse === 'call') {
                            return session.callId !== currentSessionId;
                        } else {
                            return session.chatSessionId !== currentSessionId;
                        }
                    });

                    // If there are remaining sessions, update localStorage
                    if (updatedSessions.length > 0) {
                        localStorage.setItem('customerSupportSession', JSON.stringify(updatedSessions));
                    } else {
                        // If no sessions remain, remove the key
                        localStorage.removeItem('customerSupportSession');
                    }

                    // Also remove related localStorage items based on session type
                    if (sessionTypeToUse === 'call') {
                        localStorage.removeItem('currentCallDialingEvent');
                        console.log('Removed currentCallDialingEvent from localStorage');
                    } else if (sessionTypeToUse === 'chat') {
                        localStorage.removeItem('omnichannel_chat_session');
                        console.log('Removed omnichannel_chat_session from localStorage');
                    }

                    console.log('Removed current session from localStorage:', currentSessionId, 'type:', sessionTypeToUse);
                } catch (error) {
                    console.error('Error removing current session from localStorage:', error);
                }
            };

            if (submitType === 'orgUnit') {
                onCreateAndTransferToOrg(data)
                    .unwrap()
                    .then((response) => {
                        // if (response.statusCode === 200) {
                        message.success(createTicketMessages.createTicketSuccess);
                        onCreateSuccess?.();
                        onCloseModal?.();
                        removeCurrentSessionFromStorage()
                        localStorage.removeItem(LOCAL_STORAGE_KEY.CUSTOMER_LOOKUP_DETAILED_TABS);
                        // } else if (response.statusCode === 409) {  
                        //     //message.error(response.message || createTicketMessages.createTicketError);
                        //     message.error('lỗi nè')
                        // }
                    })
                    .catch((error) => {
                        console.log('error', error.message);
                        
                        if(error.message === 'Phiếu yêu cầu đã tồn tại') {
                            // const errorMessage = (error as any)?.data?.message;
                            // message.error(errorMessage);
                            onCloseModal?.();
                            Modal.confirm({
                                title: 'Phiếu yêu cầu đã tồn tại',
                                content: 'Loại dịch vụ này đang có phiếu yêu cầu ở trạng thái đang xử lý. Bạn có muốn cập nhật liên hệ lần 2?',
                                onOk: () => {
                                    setIsModalOpen(error.data.ticketId);
                                    showModal(error.data.ticketId);
                                },
                            });
                        }
                    });
            } else if (submitType === 'supervisor') {
                onCreateAndTransferToSupervisor(data)
                    .unwrap()
                    .then((response) => {
                        message.success(createTicketMessages.createTicketSuccess);
                        onCreateSuccess?.();
                        onCloseModal?.();
                        removeCurrentSessionFromStorage()
                        localStorage.removeItem(LOCAL_STORAGE_KEY.CUSTOMER_LOOKUP_DETAILED_TABS);

                    })
                    .catch((error) => {
                        console.log('error', error.message);
                        if (error.message === 'Phiếu yêu cầu đã tồn tại') {
                            // const errorMessage = (error as any)?.data?.message;
                            // message.error(errorMessage);
                            onCloseModal?.();
                            Modal.confirm({
                                title: 'Phiếu yêu cầu đã tồn tại',
                                content: 'Loại dịch vụ này đang có phiếu yêu cầu ở trạng thái đang xử lý. Bạn có muốn cập nhật liên hệ lần 2?',
                                onOk: () => {
                                    setIsModalOpen(error.data.ticketId);
                                    showModal(error.data.ticketId);
                                },
                            });
                        }
                    });
            }

        };

        useEffect(() => {
            if (onChangeLoading) {
                onChangeLoading(isLoadingCreate);
            }
        }, [onChangeLoading, isLoadingCreate]);

        const normFile = ({ fileList }: { fileList: UploadFile[] }) => fileList;

        const validationRules = createTicketValidationRules;

        return (
            <div className='w-[53%] h-[1000px] bg-[#F5F5F7] bg-no-repeat bg-clip-padding] rounded-r-none rounded-l-[12px] overflow-y-scroll'>
                <Form
                    className='mx-6 mt-2'
                    form={form}
                    layout='horizontal'
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    initialValues={{ appointmentDate: defAppointmentDate }}
                    scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
                >
                    <Spin spinning={isLoadingCreate}>
                        <Typography.Text className='mt-6 mb-6 text-left font-semibold text-[16px]/[20px] text-[#141414]'>{createTicketMessages.requesterInformation}</Typography.Text>
                        <div className='flex flex-row gap-6 ml-[182px]'>
                            {customerId !== 0 && (
                                <FormItem>
                                    <Checkbox className='mt-2' onChange={onCheckboxDirectCustomerContact}>
                                        <Typography.Text className='text-left text-[16px]/[20px] tracking-normal text-[#868D97] opacity-100'>
                                            {createTicketMessages.directCustomerContact}
                                        </Typography.Text>
                                    </Checkbox>
                                </FormItem>
                            )}
                            <FormItem>
                                <Checkbox className='mt-2' onChange={onCheckboxCustomerNotEnter}><Typography.Text className='text-left text-[16px]/[20px] tracking-normal text-[#868D97] opacity-100'>{createTicketMessages.customerNotEnter}</Typography.Text></Checkbox>
                            </FormItem>

                        </div>
                        {formFields?.requester && (
                            <FormItem<CreateTicketFormType>
                                name="customerRequestName"
                                label={<Typography.Text className='w-[156px] text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.fullName}</Typography.Text>}
                                rules={validationRules.customerRequestName}
                            >
                                <Input className='w-[552px] h-[40px]' />
                            </FormItem>
                        )}
                        {formFields?.contactPhone && (
                            <FormItem<CreateTicketFormType>
                                name="customerRequestPhoneNumber"
                                label={<Typography.Text className='w-[156px] ml-3 text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.phoneNumber}</Typography.Text>}
                                rules={validationRules.customerRequestPhoneNumber}
                            >
                                <Input placeholder={createTicketMessages.phone} className='w-[272px] h-[40px] ' />
                            </FormItem>
                        )}
                        {formFields?.address && (
                            <FormItem<CreateTicketFormType>
                                name="customerRequestAddress"
                                label={<Typography.Text className='w-[156px] text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.address}</Typography.Text>}
                                rules={validationRules.customerRequestAddress}
                            >
                                <Input placeholder={createTicketMessages.addressAndStreet} className='h-[40px] w-[552px]' />
                            </FormItem>
                        )}
                        {formFields?.area && (
                            <div className='w-[552px] flex flex-row gap-2'>
                                <FormItem
                                    name="district"
                                    label={<Typography.Text className='w-[156px] text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.wardDistrict}</Typography.Text>}
                                    rules={validationRules.district}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={handleSelectWard}
                                        placeholder={createTicketMessages.selectDistrict}
                                        className='h-[40px] w-[272px]'
                                        options={[
                                            ...(districts?.map((itm) => ({
                                                label: itm.administrativeUnitName,
                                                value: itm.administrativeUnitId
                                            })) || [])
                                        ]}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        allowClear
                                    />
                                </FormItem>
                                <FormItem
                                    name="ward"
                                    rules={validationRules.ward}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        className='h-[40px] w-[272px] ml-[182px]'
                                        placeholder={createTicketMessages.selectWard}
                                        options={[
                                            ...(wards?.map((itm) => ({
                                                label: itm.administrativeUnitName,
                                                value: itm.administrativeUnitId
                                            })) || [])
                                        ]}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        allowClear
                                    />
                                </FormItem>
                            </div>
                        )}
                        {formFields?.isNewAddress && (
                            <FormItem
                                name='isAddressChanged'
                                valuePropName='checked'
                            >
                                <Checkbox className='ml-[182px]' onChange={onCheckbox}><Typography.Text className='text-left text-[16px]/[20px] tracking-normal text-[#868D97] opacity-100'>{createTicketMessages.newAddress}</Typography.Text></Checkbox>
                            </FormItem>
                        )}
                        {formFields?.receivingChannel && (
                            <FormItem<CreateTicketFormType>
                                name='channelId'
                                label={<Typography.Text className='w-[156px] text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.receptionChannel}</Typography.Text>}
                                rules={validationRules.channelId}
                            >
                                <SelectChannels className='h-[40px] w-[272px]' />
                            </FormItem>
                        )}
                        {formFields?.cccdInformation && (
                            <>
                                <div className='flex flex-row gap-2'>
                                    <FormItem
                                        name="customerRequestCicNumber"
                                        label={<Typography.Text className='w-[156px] ml-2 text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.citizenId}</Typography.Text>}
                                    >
                                        <Input placeholder={createTicketMessages.citizenIdNumber} className='h-[40px] w-[272px]' />
                                    </FormItem>
                                    <FormItem<CreateTicketFormType>
                                        name="customerRequestCicIssueDate"
                                    >
                                        <DatePicker locale={locale} placeholder={createTicketMessages.issueDate} className='h-[40px] w-[272px]' />
                                    </FormItem>
                                </div>

                                <FormItem name='customerRequestCicIssuePlace'>
                                    <Input placeholder={createTicketMessages.placeOfIssue} className='ml-[182px] h-[40px] w-[552px]' />
                                </FormItem>

                                <FormItem name='customerRequestCicType'>
                                    <Select
                                        className='ml-[182px] h-[40px] w-[272px]'
                                        placeholder={createTicketMessages.contactPerson}
                                        options={[
                                            ...(optionsCicType.map((itm) => ({
                                                label: itm.name,
                                                value: itm.optionId
                                            })) || [])
                                        ]}
                                    />
                                </FormItem>
                            </>
                        )}

                        <Divider style={{ borderColor: '#D4D8DE', borderWidth: '1px' }} className='my-5 w-full' type='horizontal' />
                        <div className='mt-6 pb-6'>
                            <Typography.Text className='text-left font-semibold text-[16px]/[20px] text-[#141414]'>{createTicketMessages.requestServiceInfo}</Typography.Text>
                        </div>
                        {/* {formFields?.serviceType && (
                        <FormItem<CreateTicketFormType>
                            name='serviceType'
                            label={<Typography.Text className='w-[156px] ml-3 text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.serviceType}</Typography.Text>}   
                        >
                            <Select
                                loading={isLoading || isFetching}
                                className='w-[552px] h-[40px]'
                                placeholder={createTicketMessages.serviceType}
                                options={[
                                    ...(serviceTypesOptions?.map((itm) =>({
                                        label: itm.name,
                                        value: itm.serviceTypeId
                                    })) || [])
                                ]}
                            >
                            </Select>
                        </FormItem>
                    )} */}

                        {formFields?.appointmentDate && (
                            <FormItem<CreateTicketFormType>
                                name='appointmentDate'
                                label={<Typography.Text className='w-[156px] ml-3 text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.appointmentDate}</Typography.Text>}
                            >
                                <DatePicker locale={locale} placeholder={DATE_FORMAT} className='w-[272px] h-[40px]' />
                            </FormItem>
                        )}
                        {formFields?.requestContent && (
                            <FormItem<CreateTicketFormType>
                                name='content'
                                label={<Typography.Text className='w-[156px] text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.requestContent}</Typography.Text>}
                                rules={validationRules.content}
                            >
                                <Space className='w-[552px] mt-2 bg-white rounded-lg' direction='vertical'>
                                    <Space className='h-10 flex gap-2 bg-white border-b-[1px] rounded-t-lg'>
                                        {serviceTypeId ? (ticketSampleActive?.map((sampleActive) => (
                                            <Typography.Link key={sampleActive.ticketSampleId} onClick={() => handleTicketSampleClick(sampleActive.content)} className='ml-6 text-left text-[14px]/[19px] tracking-normal text-[#1564E8] opacity-100 text-nowrap'>{sampleActive.title}</Typography.Link>
                                        ))) : []}
                                    </Space>
                                    <FormItem<CreateTicketFormType> className=''
                                        name='content'
                                    >
                                        <Input.TextArea
                                            ref={textareaRef}
                                            value={content} 
                                            onChange={handleHotkeyInput} 
                                            autoSize 
                                            className='border-0 w-[552px] focus:shadow-none' 
                                        />
                                        {isSuggestionVisible && (
                                            <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-md w-full max-h-40 overflow-y-auto">
                                                {suggestions.map((suggestion) => (
                                                    <div
                                                        key={suggestion}
                                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                        onClick={() => handleSuggestionSelect(suggestion)}
                                                    >
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </FormItem>
                                </Space>
                            </FormItem>
                        )}
                        {formFields?.file && (
                            <FormItem<CreateTicketFormType>
                                valuePropName='fileList'
                                name='uploadFileIds'
                                getValueFromEvent={normFile}
                            >
                                {/* <Upload.Dragger className='w-[552px] ml-[182px]' multiple listType='picture-card' maxCount={5} /> */}
                                <Upload.Dragger
                    className="w-[552px] ml-[182px]"
                    multiple
                    listType="picture-card"
                    maxCount={5}
                    fileList={fileList}
                    onChange={handleChange}
                    onPreview={handlePreview}
                />
                            </FormItem>
                        )}




                        {formFields?.phaseNumber && (
                            <FormItem
                                name="phaseNumber"
                                className=''
                                label={<Typography.Text className='w-[156px] text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.phaseNumber}</Typography.Text>}
                            >
                                <Select
                                    className='h-[40px] w-[272px]'
                                    placeholder={createTicketMessages.phaseNumber}
                                    options={[
                                        { value: 1, label: createTicketMessages.phaseNumber1 },
                                        { value: 3, label: createTicketMessages.phaseNumber3 },
                                    ]}>
                                </Select>
                            </FormItem>
                        )}
                        {formFields?.intendedUse && (
                            <FormItem
                                name="usagePurpose"
                                label={<Typography.Text className='w-[156px] text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.usagePurpose}</Typography.Text>}
                            >
                                <Select
                                    className='h-[40px] w-[272px]'
                                    placeholder={createTicketMessages.usagePurpose}
                                    options={[
                                        { value: 'sinhhoat', label: createTicketMessages.residential },
                                        { value: 'ngoaisinhhoat', label: createTicketMessages.nonResidential },
                                    ]}>
                                </Select>
                            </FormItem>
                        )}
                        {formFields?.outageAt && (
                            <FormItem
                                name="outageAt"
                                label={<Typography.Text className='w-[156px] ml-3 text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.outageAt}</Typography.Text>}
                            >
                                <DatePicker locale={locale} showTime className='w-[272px] h-[40px]' />
                            </FormItem>
                        )}
                        {formFields?.incidentAddress && (
                            <FormItem
                                name="incidenAddress"
                                label={<Typography.Text className='w-[156px] ml-3 text-right text-[16px]/[22px] text-[#141414] tracking-normal opacity-100'>{createTicketMessages.incidenAddress}</Typography.Text>}
                            >
                                <Input placeholder={createTicketMessages.incidenAddress} className='w-[552px] h-[40px]' />
                            </FormItem>
                        )}
                        {formFields?.incidentArea && (
                            <div className='w-[552px] flex flex-row gap-2 ml-[182px]'>
                                <FormItem
                                    name="district"
                                >
                                    <Select
                                        onChange={handleSelectWard}
                                        placeholder={createTicketMessages.selectDistrict}
                                        className='h-[40px] w-[272px]'
                                        options={[
                                            ...(districts?.map((itm) => ({
                                                label: itm.administrativeUnitName,
                                                value: itm.administrativeUnitId
                                            })) || [])
                                        ]}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        allowClear
                                    />
                                </FormItem>
                                <FormItem
                                    name="ward"
                                >
                                    <Select
                                        className='h-[40px] w-[272px]'
                                        placeholder={createTicketMessages.selectWard}
                                        options={[
                                            ...(wards?.map((itm) => ({
                                                label: itm.administrativeUnitName,
                                                value: itm.administrativeUnitId
                                            })) || [])
                                        ]}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        allowClear
                                    />
                                </FormItem>
                            </div>
                        )}

                        {formFields?.reason && (
                            <FormItem
                                name="outageReasonId"
                            >
                                <Select 
                                    className='h-[40px] w-[272px] ml-[182px]' 
                                    placeholder={createTicketMessages.selectOutageReason}
                                    options={[
                                        ...(optionsOutageReason.map((itm) => ({
                                            label: itm.name,
                                            value: itm.optionId
                                        })) || [])
                                    ]}
                                />
                            </FormItem>
                        )}
                        {formFields?.outageEntireArea && (
                            <FormItem
                                className=''
                                name='isOutageEntireArea'
                                valuePropName='checked'
                            >
                                <Checkbox className='ml-[182px]' onChange={onCheckbox}><Typography.Text className='text-left text-[16px]/[20px] tracking-normal text-[#868D97] opacity-100'>{createTicketMessages.isOutageEntireArea}</Typography.Text></Checkbox>
                            </FormItem>
                        )}
                        <div className='flex flex-row gap-6 ml-[182px]'>
                            {formFields?.notRequestToSendSms && (
                                <FormItem
                                    className=''
                                    name='isNotRequestToSendSms'
                                    valuePropName='checked'
                                >
                                    <Checkbox onChange={onCheckbox}><Typography.Text className='text-left text-[16px]/[20px] tracking-normal text-[#868D97] opacity-100'>{createTicketMessages.notMessageSendingRequest}</Typography.Text></Checkbox>
                                </FormItem>
                            )}
                            {formFields?.isCalledOut && (
                                <FormItem
                                    className=''
                                    name='outgoingCall'
                                    valuePropName='checked'
                                >
                                    <Checkbox onChange={onCheckbox}><Typography.Text className='text-left text-[16px]/[20px] tracking-normal text-[#868D97] opacity-100'>{createTicketMessages.outgoingCall}</Typography.Text></Checkbox>
                                </FormItem>
                            )}
                        </div>
                        <Divider className='w-full my-5' type='horizontal'></Divider>
                        <FormItem>
                            <div className='flex flex-row gap-3 my-6 ml-[200px]'>
                                <Button
                                    onClick={() => showReviewModal('orgUnit')}
                                    className=''
                                    type='primary'
                                >
                                    <Typography.Text className='text-left font-semibold text-[16px]/[20px] tracking-normal text-white opacity-100'>{createTicketMessages.forwardForProcessing}</Typography.Text>
                                </Button>
                                <Button
                                    onClick={() => showReviewModal('supervisor')}
                                    className=''
                                    type='primary'
                                    danger
                                >
                                    <Typography.Text className='text-left font-semibold text-[16px]/[20px] tracking-normal text-white opacity-100'>{createTicketMessages.redirectToTheCenter}</Typography.Text>
                                </Button>
                            </div>
                        </FormItem>
                    </Spin>
                </Form>
                <Modal
                    title={null} // Ẩn tiêu đề mặc định để custom header
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null} // Ẩn footer mặc định để tự thiết kế
                    width={700} // Tùy chỉnh độ rộng modal
                    closable={false} // Ẩn nút đóng mặc định
                    className='ant-modal-body-p-none'
                >
                    {/* Custom Header */}
                    <div className="flex justify-between items-center px-6 py-2 border-b">
                        {/* Nút đóng (X) chuyển qua bên trái */}
                        <Button shape='circle'
                            icon={<CloseIcon className='' />}
                            onClick={() => setIsModalVisible(false)}
                            className='hover:bg-[#EBEBED] bg-[#F5F5F7] border-[#F5F5F7] bg-transparent bg-no-repeat bg-clip-padding opacity-100'
                        />
                        <Typography.Text className="text-xl font-semibold text-center text-[#141414] flex-1">{createTicketMessages.confirmSendTicket}</Typography.Text>
                        {/* Nút chỉnh sửa bên phải */}
                        <Button
                            type='text'
                            onClick={() => setIsModalVisible(false)} // Đóng modal để chỉnh sửa
                        >
                            <Typography.Text className='text-[#1564E8] text-right font-normal text-[16px]/[22px] '>{createTicketMessages.edit}</Typography.Text>
                        </Button>
                    </div>

                    {/* Nội dung phiếu review */}
                    <div className="px-6 py-6">
                        {reviewData && (
                            <>
                                <Typography.Text className='text-left font-semibold text-[32px]/[40px] text-[#141414]'>{serviceType?.data.name}</Typography.Text>
                                <div className='bg-[#EBEBED] w-full rounded-[5px] flex justify-between items-center h-[64px] mt-8'>
                                    <div className='flex flex-row gap-4 pl-4 items-center'>
                                        <Typography.Text className='text-left font-normal text-[16px]/[16px] text-[#6C737E]'>{createTicketMessages.customerCode}</Typography.Text>
                                        <Typography.Text className='text-left font-semibold text-[16px]/[20px] text-[#141414]'>{reviewData?.customerCode}</Typography.Text>
                                    </div>
                                    <Image src={logoEVN} alt='logoEVN' className='w-8 h-8 mr-4' />
                                </div>
                                <div className='mt-5'>
                                    <Typography.Text className='text-left font-semibold text-[18px]/[22px] text-[#141414]'>{createTicketMessages.requesterInformation}</Typography.Text>
                                </div>
                                {isDirectCustomerContact === true ? (
                                    <div className='flex flex-row gap-2 mt-1'>
                                        <AttentionRedCircleIcon className='w-4 h-4' />
                                        <Typography.Text className='text-left italic font-normal text-xs text-[#D1131D]'>{createTicketMessages.directCustomerContact}</Typography.Text>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className='flex flex-row gap-4 mt-6'>
                                    <div className='w-[140px]'>
                                        <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.fullName}</Typography.Text>
                                    </div>
                                    <div className='flex flex-col gap-1 w-[464px]'>
                                        <Tooltip title={reviewData?.customerRequestName}>
                                            <Typography.Text className='text-left font-normal text-[#141414] text-base truncate'>{reviewData?.customerRequestName}</Typography.Text>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-4 mt-6'>
                                    <div className='w-[140px]'>
                                        <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.phoneNumber}</Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text className='text-left font-normal text-[#141414] text-base'>{reviewData?.customerRequestPhoneNumber}</Typography.Text>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-4 mt-6'>
                                    <div className='w-[140px]'>
                                        <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.address}</Typography.Text>
                                    </div>
                                    <div className='flex flex-col gap-1 w-[464px]'>
                                        {isDirectCustomerContact === true ? (
                                            <Tooltip title={reviewData?.customerRequestAddress}>
                                                <Typography.Text className='text-left font-normal text-[#141414] text-base truncate'>{reviewData?.customerRequestAddress}</Typography.Text>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={reviewData?.customerRequestAddress}>
                                                <Typography.Text className='text-left font-normal text-[#141414] text-base'>{reviewData?.customerRequestAddress}, {reviewData?.wardLabel}</Typography.Text>
                                            </Tooltip>
                                        )}
                                        {reviewData.isAddressChanged === true ? (
                                            <div className='flex flex-row gap-2 mt-1'>
                                                <AttentionRedCircleIcon className='w-4 h-4' />
                                                <Typography.Text className='text-left italic font-normal text-xs text-[#D1131D]'>{createTicketMessages.newAddress}</Typography.Text>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                    </div>
                                </div>
                                <div className='flex flex-row gap-4 mt-6'>
                                    <div className='w-[140px]'>
                                        <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.receptionChannel}</Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text className='text-left font-normal text-[#141414] text-base'>{reviewData?.channelLabel}</Typography.Text>
                                    </div>
                                </div>
                                <Divider></Divider>
                                <div className='flex flex-row gap-4 mt-4'>
                                    <div className='w-[140px]'>
                                        <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.citizenIdNumber}</Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text className='text-left font-normal text-[#141414] text-base'>{reviewData?.customerRequestCicNumber}</Typography.Text>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-4 mt-4'>
                                    <div className='w-[140px]'>
                                        <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.issueDate}</Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text className='text-left font-normal text-[#141414] text-base'>{dayjs(reviewData?.customerRequestCicIssueDate).format('DD/MM/YYYY')}</Typography.Text>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-4 mt-4'>
                                    <div className='w-[140px]'>
                                        <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.placeOfIssue}</Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text className='text-left font-normal text-[#141414] text-base'>{reviewData?.customerRequestCicIssuePlace}</Typography.Text>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-2 mt-5'>
                                    <AttentionRedCircleIcon className='w-4 h-4' />
                                    <Typography.Text className='text-left font-normal text-xs text-[#D1131D]'>{optionsCicType?.find(opt => opt.optionId === reviewData?.customerRequestCicType)?.name || 'N/A'}</Typography.Text>
                                </div>
                                <Divider></Divider>
                                <div className='mt-5'>
                                    <Typography.Text className='text-left font-semibold text-[18px]/[22px] text-[#141414]'>{createTicketMessages.requestInformation}</Typography.Text>
                                </div>
                                <div className='bg-[#D1131D1F] w-full rounded-[5px] flex justify-between items-center h-[64px] mt-8'>
                                    <div className='flex flex-row gap-4 pl-4 items-center'>
                                        <Typography.Text className='text-left font-normal text-[16px]/[16px] text-[#6C737E]'>{createTicketMessages.appointmentDate}</Typography.Text>
                                        <Typography.Text className='text-left font-semibold text-[16px]/[20px] text-[#141414]'>{dayjs(reviewData?.appointmentDate).format('DD/MM/YYYY')}</Typography.Text>
                                    </div>
                                    <CalendarRedIcon className='w-8 h-8 mr-4' />
                                </div>
                                <div className='mt-5'>
                                    <Typography.Paragraph className='font-normal text-[16px]/[22px] text-[#141414] text-left'>{reviewData?.content}</Typography.Paragraph>
                                </div>
                                <Divider></Divider>
                                {/* <Typography.Text className='font-normal text-[16px]/[22px] text-[#141414] text-left'>{createTicketMessages.attachFile}</Typography.Text> */}
                                {/* <TicketIDetailedFileAttachment direction='vertical' files={reviewData?.uploadFileIds?.flatMap(file => Number(file?.uid)) || []} ></TicketIDetailedFileAttachment> */}
                                <Typography.Text className='font-normal text-base text-[#868D97]'>{createTicketMessages.attachFile}:</Typography.Text>
                                <div className="file-preview-container mt-5" style={{ display: 'flex',  }}>
                                    {fileList.map((file) => (
                                        <div key={file.uid} className="file-preview-item" style={{ textAlign: 'center' }}>
                                            {renderFilePreview(file)}
                                        </div>
                                    ))}
                                </div>
                                <div className='flex flex-col gap-5'>
                                    {reviewData.isNotRequestToSendSms === true ? (
                                        <div className='flex flex-row gap-2 mt-1'>
                                            <AttentionRedCircleIcon className='w-4 h-4' />
                                            <Typography.Text className='text-left font-normal text-xs text-[#D1131D]'>{createTicketMessages.notMessageSendingRequest}</Typography.Text>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                    }

                                </div>
                            </>
                        )}
                    </div>

                    {/* Footer Custom với nút xác nhận ở giữa */}
                    <div className="flex justify-center px-6 py-4 border-t">
                        {submitType === 'orgUnit' && (
                            <Button
                                type="primary"
                                className="px-6"
                                onClick={() => {
                                    form.submit(); // Gửi phiếu khi xác nhận
                                    setIsModalVisible(false);
                                }}
                            >
                                {createTicketMessages.transferProcessingUnit} {/* Nút cho orgUnit */}
                            </Button>
                        )}
                        {submitType === 'supervisor' && (
                            <Button
                                type="primary"
                                danger
                                className="px-6"
                                onClick={() => {
                                    form.submit(); // Gửi phiếu khi xác nhận
                                    setIsModalVisible(false);
                                }}
                            >
                                {createTicketMessages.redirectToTheCenter} {/* Nút cho supervisor */}
                            </Button>
                        )}
                    </div>
                </Modal>
                <Modal
                    className='ant-modal-body-p-none max-h-[1000px] w-[1088px]'
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    destroyOnClose
                >
                    <TicketDetailed status={ticketDetail?.status.code} ticketId={selectedTicketId || 0} cancelDetailModal={handleCancel} isSecondCall={true} />
                </Modal>
            </div>
        )
    });

export default CreateTicketForm;

