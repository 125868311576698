import { useUnpauseQueueAgentMutation } from 'services';
import { QueueMemberDto } from 'types';

export function useUnpauseAgent() {

    const [unpauseQueueAgent, { isLoading, error }] = useUnpauseQueueAgentMutation();

    const onUnpauseQueueAgent = async (values: QueueMemberDto) => {
        try {
            return await unpauseQueueAgent(values).unwrap(); // ✅ Now returns a Promise
        } catch (err) {
            console.error("Error in onUnpauseQueueAgent:", err);
            throw err;
        }
    };

    return {
        isLoading,
        error,
        onUnpauseQueueAgent,
    };
}

