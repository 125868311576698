import { TooltipPlacement } from 'antd/es/tooltip';
import { ServiceTypeIcon } from 'assets';
import { Filter } from 'components/common';
import { useLazyServiceTypesOptions, useServiceTypesOptions } from 'hooks';
import { unionBy } from 'lodash';
import { sidebarMenuMessages } from 'messages';
import { PropsWithChildren, useState } from 'react';
import { FindAllServiceTypeDto } from 'types';
import { filterOptions } from 'utils';

type FilterServiceTypesProps = PropsWithChildren &
  Omit<FindAllServiceTypeDto, 'keyword' | 'pageIndex' | 'pageSize'> & {
    placement?: TooltipPlacement;
  };

const FilterServiceTypes = ({ parentId, isActive, serviceTypeId, ...props }: FilterServiceTypesProps) => {
  const { serviceTypesOptions: serviceTypesOptionsSelected } = useServiceTypesOptions(
    {
      serviceTypeId
    },
    { skip: !serviceTypeId || serviceTypeId?.length === 0, refetchOnMountOrArgChange: true }
  );

  const { fetchData, count, serviceTypesOptions, isLoading } = useLazyServiceTypesOptions();
  const [keyword, setKeyword] = useState('');

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const data = unionBy(
    serviceTypeId?.length ? serviceTypesOptionsSelected : [],
    serviceTypesOptions,
    (o) => o.serviceTypeId
  );
  const options = filterOptions(data, keyword, 'name');

  return (
    <Filter.CheckboxSearch
      icon={<ServiceTypeIcon />}
      {...props}
      count={count}
      options={options.map((o) => ({
        label: o.name,
        value: o.serviceTypeId,
        disabled:
          !serviceTypesOptions.map((option) => option.serviceTypeId).includes(o.serviceTypeId) &&
          !serviceTypeId?.includes(o.serviceTypeId)
      }))}
      onSearch={handleSearch}
      title={sidebarMenuMessages.serviceTypes}
      loading={isLoading}
      fetchData={() => {
        setKeyword('');
        fetchData({
          parentId,
          isActive,
          endpoint: 'filter',
          isLoadAll: true
        });
      }}
    />
  );
};
export default FilterServiceTypes;
