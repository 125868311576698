import { Loadable } from 'components/common';
import { lazy, memo } from 'react';
import { agentMapsMessages } from 'messages';

const AgentMapCanvas = lazy(() => import('./AgentMapCanvas'));

const AgentMap = ({}) => {
  // const rowRef = useRef<HTMLDivElement>(null);
  // const colRef = useRef<HTMLDivElement>(null);

  // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
  //   const scrollTop = event.currentTarget.scrollTop;
  //   const scrollLeft = event.currentTarget.scrollLeft;
  //   if (rowRef.current) {
  //     rowRef.current.scrollTo({
  //       top: scrollTop
  //     });
  //   }
  //   if (colRef.current) {
  //     colRef.current.scrollTo({
  //       left: scrollLeft
  //     });
  //   }
  // };

  return (
    <div className='relative flex h-full w-full border border-colorBorder'>
      <Loadable message={agentMapsMessages.drawGrid}>
        <AgentMapCanvas />
      </Loadable>
    </div>
  );
};

export default memo(AgentMap);
