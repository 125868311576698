import * as fabric from 'fabric';
import { findIndex } from 'lodash';
import { FabricAgentMapOtherObject } from 'types/fabric-agent-map';
import { AGENT_MAP_OBJECT_TYPE } from 'utils/enums';

export const onTextEdited = (
  e: {
    target: fabric.IText;
  },
  objectArr: React.MutableRefObject<FabricAgentMapOtherObject[]>
) => {
  const text = e.target as fabric.Textbox & {
    id?: string | number;
    objectTypeId?: AGENT_MAP_OBJECT_TYPE;
  };
  if (text.objectTypeId === AGENT_MAP_OBJECT_TYPE.TEXT) {
    const idxEdited = findIndex(objectArr.current, {
      id: text.id
    });
    if (idxEdited !== -1) {
      objectArr.current[idxEdited].name = text.text ?? '-';
    }
  }
};
