import React from 'react';
import { Divider, Typography } from 'antd';
import { requestform } from 'messages';
import { TicketDetail } from 'types';
import Avatar from 'components/common/avatar/Avatar';
import dayjs from 'dayjs';

interface ticketCreateInformation {
  requestItem: TicketDetail | null;
}

const NormalRequestDetailCreateInformation: React.FC<ticketCreateInformation> = ({ requestItem }) => {
  return (
    <div className='min-h-[937px] border-t-2 bg-[#f5f5f7] p-6'>
      <div className='mt-[20px] space-y-2'>
        <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.acceptChannel}</Typography.Text>
        <br />
        <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
          {requestItem?.channel?.channelName || ''}
        </Typography.Text>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      <div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.dateRequest}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.createdAt && dayjs(requestItem.createdAt).format('DD/MM/YYYY, HH:mm:ss')}
          </Typography.Text>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.ticketCreator}</Typography.Text>
          <div className='flex space-x-2'>
            <Avatar name={requestItem?.createdByName} size={20} />
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.createdByName}
            </Typography.Text>
          </div>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.appointmentDate}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.appointmentDate && dayjs(requestItem?.appointmentDate).format('DD/MM/YYYY, HH:mm:ss')}
          </Typography.Text>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      <div>
        <Typography.Text className='text-[12px]/[16px] font-semibold uppercase text-[#868D97]'>
          {requestform.processByCMIS}
        </Typography.Text>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.receptionDate}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.updatedAt && dayjs(requestItem?.updatedAt).format('DD/MM/YYYY, HH:mm:ss')}
          </Typography.Text>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.recipient}</Typography.Text>
          <div className='flex space-x-2'>
            <Avatar name={requestItem?.createdByName} size={20} />
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.createdByName}
            </Typography.Text>
          </div>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'> {requestform.corporateUnit}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.administrativeUnit.administrativeUnitName}
          </Typography.Text>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      {requestItem?.status.code === 'HOANTAT' && (
        <div>
          <Typography.Text className='text-[12px]/[16px] font-semibold uppercase text-[#868D97]'>
            {requestform.completeTitle}
          </Typography.Text>
          <div className='mt-[20px] space-y-2'>
            <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.completedDate}</Typography.Text>
            <br />
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.closedAt ? dayjs(requestItem?.closedAt).format('DD/MM/YYYY, HH:mm:ss') : ''}
            </Typography.Text>
          </div>
          <div className='mt-[20px] space-y-2'>
            <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>
              {requestform.completedPersion}
            </Typography.Text>
            <br />
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.closedBy !== null ? requestItem?.closedBy : ''}
            </Typography.Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default NormalRequestDetailCreateInformation;
