import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CustomerCompactDto,
  CustomerContactInfoDto,
  CustomerDto,
  FindAllCustomerDto,
  ResponseDto,
  ResponsePagingDto
} from 'types';
import { FulltextSearchCustomerDto } from 'types/dto/customer-lookup';
import { mergeArrays } from 'utils';
import axiosBaseQuery from 'utils/base-api';

export const customersApi = createApi({
  reducerPath: 'customersApi',
  tagTypes: ['customers', 'customer_detail', 'customer_options'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getCustomer: builder.query<ResponsePagingDto<CustomerDto>, FindAllCustomerDto>({
      query: (params) => ({
        url: '/customers',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ customerId }) => ({
              type: 'customers',
              id: customerId
            }))
          : ['customers']
    }),

    getCustomerCount: builder.query<ResponseDto<number>, FindAllCustomerDto>({
      query: (params) => ({
        url: '/customers/count',
        method: 'get',
        params
      }),
      providesTags: (result) => (result?.data ? [{ type: 'customers', id: 'count' }] : [])
    }),

    getCustomerDetail: builder.query<ResponseDto<CustomerDto>, number>({
      query: (customerId) => ({
        url: `/customers/${customerId}`,
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'customer_detail', id: result.data.customerId }] : [])
    }),

    getCustomerContactInfo: builder.query<ResponseDto<CustomerContactInfoDto>, string>({
      query: (customerCode) => ({
        url: `/sms-info-retrieval/customer_contact_info/${customerCode}`,
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'customer_detail', id: result.data.PrimaryPhoneNumber }] : [])
    }),

    getCustomerOptions: builder.query<ResponsePagingDto<CustomerCompactDto>, FindAllCustomerDto>({
      query: (params) => ({
        url: '/customers/options',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'customerId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.keyword !== previousArg?.keyword ||
          currentArg?.pageIndex !== previousArg?.pageIndex ||
          currentArg?.customerCode !== previousArg?.customerCode ||
          currentArg?.customerName !== previousArg?.customerName
        );
      },
      providesTags: ['customers']
    }),

    getCustomersByFullText: builder.query<ResponsePagingDto<CustomerDto>, FulltextSearchCustomerDto>({
      query: (params) => ({
        url: `/customers/fulltext_search`,
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ customerId }) => ({
              type: 'customers',
              id: customerId
            }))
          : ['customers']
    }),

    getCustomerCountByFullText: builder.query<ResponseDto<number>, FulltextSearchCustomerDto>({
      query: (params) => ({
        url: '/customers/fulltext_search_count',
        method: 'get',
        params
      }),
      providesTags: (result) => (result?.data ? [{ type: 'customers', id: 'count' }] : [])
    }),

    getCustomersByFullTextLoadmore: builder.query<ResponsePagingDto<CustomerDto>, FulltextSearchCustomerDto>({
      query: (params) => ({
        url: `/customers/fulltext_search`,
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'customerId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      },
      providesTags: ['customer_options']
    })
  })
});

export const {
  useGetCustomerQuery,
  useGetCustomerDetailQuery,
  useGetCustomerOptionsQuery,
  useGetCustomerCountQuery,
  useGetCustomerContactInfoQuery,
  useGetCustomersByFullTextQuery,
  useGetCustomerCountByFullTextQuery,
  useGetCustomersByFullTextLoadmoreQuery
} = customersApi;
