export const userGroupsMessages = {
  title: 'Nhóm người dùng',
  information: 'Thông tin nhóm',
  userGroupId: 'Mã quản lý',
  name: 'Tên',
  userGroupName: 'Tên nhóm',
  userGroupQuantity: 'Thành viên',
  createNew: 'Tạo nhóm người dùng',
  update: 'Cập nhật nhóm người dùng',
  organizationUnit: 'Đơn vị/phòng đội',
  description: 'Mô tả',
  shortName: 'Tên thu gọn',
  permissions: 'Phân quyền',
  roles: 'Nhóm quyền',
  users: 'Thành viên',
  updateUsers: 'Cập nhật thành viên',
  updatePermissions: 'Cập nhật quyền',
  updateInfo: 'Cập nhật thông tin',
  classify: 'Loại nhóm',
  saveAndUpdatePermissions: 'Lưu & Tùy chỉnh phân quyền',
  general: 'Thông tin chung',
  chooseOrganizationUnitFirst: 'Chọn đơn vị/phòng đội trước',
  addUsers: 'Thêm người dùng',
  grantPermissions: 'Quyền được cấp',
  delete: 'Xóa nhóm người dùng',
  confirmDelete: 'Bạn có chắc chắn muốn xóa nhóm người dùng này không?'
};
