import { createApi } from '@reduxjs/toolkit/query/react';
import { ChangeStatus, FindAllCustomerDto, InprogressTickets, ResponseDto, ResponsePagingDto } from 'types';
import { TicketDto, CreateTicketDto, FindAllTicketCreatedHistoryDto, RequestCreateFinishedTicketQuickSearchDto, ContactEventDto } from 'types/dto/ticket';
import { FindAllTicketCompletedDto } from 'types/dto/ticket/find-all-ticket-completed.dto';
import { FindAllTicketInProgressDto } from 'types/dto/ticket/find-all-ticket-in-progress.dto';
import { FindAllTicketsInProgressCustomerLookupDto } from 'types/dto/ticket/find-all-tickets-in-progress-customer-lookup.dto';
import { RequestUpdateTicketFollowUpDto } from 'types/dto/ticket/request-update-ticket.dto';
import { mergeArrays } from 'utils';

import axiosBaseQuery from 'utils/base-api';

export const ticketsApi = createApi({
  reducerPath: 'ticketApi',
  tagTypes: ['tickets', 'ticket_detail', 'appointment_date', 'created-history', 'user-created-history', 'customer-name-by-phone-number'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getTicketsInProgressByCustomerCode: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketInProgressDto>({
      query: (params) => ({
        url: '/tickets/dtv/in_progress_by_customer_code',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data?.rows.length > 0
          ? result.data?.rows?.map(({ ticketId }) => ({
            type: 'tickets',
            id: ticketId
          }))
          : ['tickets']
    }),
    getTicketsCompletedByCustomerCode: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketCompletedDto>({
      query: (params) => ({
        url: '/tickets/dtv/completed_by_customer_code',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data?.rows.length
          ? result.data?.rows.map(({ customerCode }) => ({
            type: 'tickets',
            id: customerCode
          }))
          : ['tickets']
    }),

    getTicketDetail: builder.query<ResponseDto<TicketDto>, number>({
      query: (ticketId) => ({
        url: `/tickets/detail/${ticketId}`,
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'ticket_detail', id: result.data.ticketId }] : [])
    }),

    getCustomerNameByPhoneNumber: builder.query<ResponseDto<String[]>, string>({
      query: (phoneNumber) => ({
        url: `/tickets/dtv/customer_name_by_phone_number/${phoneNumber}`,
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'customer-name-by-phone-number' }] : [])
    }),

    createTicketAndTransferToOrgUnit: builder.mutation<ResponseDto<TicketDto>, CreateTicketDto>({
      query: (newTicket) => ({
        url: '/tickets/dtv/create_and_transfer_to_org_unit',
        method: 'post',
        data: newTicket
      }),
      invalidatesTags: ['tickets']
    }),

    createTicketAndTransferToSupervisor: builder.mutation<ResponseDto<TicketDto>, CreateTicketDto>({
      query: (newTicket) => ({
        url: '/tickets/dtv/create_and_transfer_to_supervisor',
        method: 'post',
        data: newTicket
      }),
      invalidatesTags: ['tickets']
    }),

    createFinishedTicketQuickSearch: builder.mutation<ResponseDto<TicketDto>, RequestCreateFinishedTicketQuickSearchDto>({
      query: (newTicket) => ({
        url: '/tickets/dtv/create_finished_ticket_quick_search',
        method: 'post',
        data: newTicket
      }),
      invalidatesTags: ['tickets']
    }),

    getAppointmentDate: builder.query<ResponseDto<string>, string>({
      query: () => ({
        url: 'tickets/assumed_appointment_date',
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'appointment_date' }] : [])
    }),

    getCreatedHistory: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketCreatedHistoryDto>({
      query: (params) => ({
        url: 'tickets/dtv/created_history',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'ticketId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.keyword !== previousArg?.keyword ||
          currentArg?.pageIndex !== previousArg?.pageIndex ||
          currentArg?.customerCode !== previousArg?.customerCode ||
          currentArg?.customerRequestPhoneNumber !== previousArg?.customerRequestPhoneNumber ||
          currentArg?.fromDate !== previousArg?.fromDate ||
          currentArg?.toDate !== previousArg?.toDate
        );
      },
      providesTags: ['created-history']
    }),
    getUserCreatedHistory: builder.query<
      ResponsePagingDto<TicketDto>,
      FindAllTicketCreatedHistoryDto & { userId: number }
    >({
      query: ({ userId, ...params }) => ({
        url: `tickets/dtv/created_history/${userId}`,
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'ticketId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.keyword !== previousArg?.keyword ||
          currentArg?.pageIndex !== previousArg?.pageIndex ||
          currentArg?.customerCode !== previousArg?.customerCode ||
          currentArg?.customerRequestPhoneNumber !== previousArg?.customerRequestPhoneNumber ||
          currentArg?.fromDate !== previousArg?.fromDate ||
          currentArg?.toDate !== previousArg?.toDate
        );
      },
      providesTags: ['user-created-history']
    }),
    getTicketsInProgressCustomerLookup: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketsInProgressCustomerLookupDto>({
      query: (params) => ({
        url: '/tickets/in_progress',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data?.rows.length > 0
          ? result.data?.rows?.map(({ ticketId }) => ({
            type: 'tickets',
            id: ticketId
          }))
          : ['tickets']
    }),
    getTicketsInProgressCustomerLookupCount: builder.query<ResponseDto<number>, FindAllTicketsInProgressCustomerLookupDto>({
      query: (params) => ({
        url: '/tickets/total_in_progress',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result?.data ? [{ type: 'tickets', id: 'count' }] : []
    }),
    requestUpdateTicketFollowUp: builder.mutation<ResponseDto<ChangeStatus>, InprogressTickets>({
      query: ({ ticketId, processingContent }) => ({
        url: `/tickets/dtv/update_ticket_follow_up/${ticketId}`,
        method: 'patch',
        data: { content: processingContent }
      }),
    }),
    createContactEvent: builder.mutation<ResponseDto<ContactEventDto>, ContactEventDto>({
      query: (newContactEvent) => ({
        url: '/tickets/contact-event',
        method: 'post',
        data: newContactEvent
      }),
    }),
  })
});

export const {
  useGetTicketsInProgressByCustomerCodeQuery,
  useGetTicketsCompletedByCustomerCodeQuery,
  useCreateTicketAndTransferToOrgUnitMutation,
  useGetTicketDetailQuery,
  useGetAppointmentDateQuery,
  useLazyGetCreatedHistoryQuery,
  useGetCreatedHistoryQuery,
  useLazyGetUserCreatedHistoryQuery,
  useGetUserCreatedHistoryQuery,
  useCreateTicketAndTransferToSupervisorMutation,
  useGetTicketsInProgressCustomerLookupQuery,
  useGetTicketsInProgressCustomerLookupCountQuery,
  useGetCustomerNameByPhoneNumberQuery,
  useRequestUpdateTicketFollowUpMutation,
  useCreateFinishedTicketQuickSearchMutation,
  useCreateContactEventMutation
} = ticketsApi;
