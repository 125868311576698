// Define the enum using the keys from SidebarMenuItems
export enum SIDEBAR_MENU_KEY {
  CUSTOMER_CARE = 'customerCare',
  REQUEST_FORM = 'requestForm',
  CALL_CENTER_MANAGEMENT = 'callCenterManagement',
  REPORTS = 'reports',
  MANAGEMENT = 'management',
  INTERNAL_ANNOUNCEMENTS = 'internalAnnouncements',
  VIP_CUSTOMERS = 'vipCustomers',
  AGENT_MAPS = 'agentMaps',
  AGENT_STATUS = 'agentStatus',
  WORKING_SCHEDULES = 'workingSchedules',
  SERVICE_INSTRUCTIONS = 'serviceInstructions',
  CUSTOMER_SUPPORT_INFORMATION = 'customerSupportInformation',
  FREQUENTLY_ASKED_QUESTIONS = 'frequentlyAskedQuestions',
  FANPAGE_CONNECTION = 'fanpageConnection',
  TICKET_CANCEL_REASONS = 'ticketCancelReasons',
  BUSY_REASONS = 'busyReasons',
  SYSTEM = 'system',
  USERS = 'users',
  USER_GROUPS = 'userGroups',
  PERMISSIONS = 'permissions',
  ORGANIZATION_UNIT = 'organizationUnit',
  SERVICE_TYPES = 'serviceTypes',
  TICKET_SAMPLES = 'ticketSamples',
  PROFILE = 'profile',
  SHIFTS = 'shifts',
  SYSTEM_CONFIGS = 'systemConfigs',
  API_SHARING_PARTNERS = 'apiSharingPartners',
  ORGANIZATION_UNIT_TICKET_DEFAULT_ASSIGNS = 'organizationUnitTicketDefaultAssigns',
  LEADER_SHIFT = 'leaderShift',
  USERS_MANAGEMENT = 'usersManagement',
  CONNECT_MANAGEMENT = 'connectManagement',
  CONFIG_MANAGEMENT = 'configManagement',
  OPERATIONS_MANAGEMENT = 'operationsManagement',
  SHIFT_SUPERVISOR = 'shiftSupervisor',
  DASHBOARD_AGENT_MAP = 'DashboardAgentMap',
  TRACK_TICKET_PROCESSING = 'trackTicketProcessing',
  COORDINATION_REQUIRED = 'coordinationRequired',
  MOVE_CENTER = 'moveCenter',
  TRANSFER_CUSTOMER_SERVICE_TEAM = 'transferCustomerServiceTeam',
  MOVE_TECHNICAL_DEPARTMENT = 'moveTechnicalDepartment',
  FOLLOWUP_REQUEST = 'FollowupRequests',
  INCORRECT_MEASUREMENT_POINT_ADDRESS = 'incorrectMeasurementPointAddress',
  CONFIRM_REFLECTION_REQUEST = 'confirmReflectionRequest',
  COLLECT_PAYMENT = 'collectPayment',
  SMS = 'SMS',
  EXPLAIN_REPORT = 'explainReport',
  USER_GUIDE = 'userGuide',
  CONVERT_UNIT_ROOM_TEAM = 'convertUnitRoomTeam',
  CUSTOMER_SEARCH = 'customerSearch',
}
