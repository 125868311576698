import { useDispatch } from 'react-redux';
import { useCreateAgentStatusChangeMutation } from 'services';
import { setAuthenticated } from 'store/features/auth/auth.slice';
import { AgentStatusChangeDto, LoginDto } from 'types';

export function useAgentStatusChange() {
    const dispatch = useDispatch();

    const [createAgentStatusChange, { isLoading, error }] = useCreateAgentStatusChangeMutation();

    const onCreateAgentStatusChangeHandle = async (values: AgentStatusChangeDto) => {
        try {
            createAgentStatusChange(values)
                .unwrap()
                .then((res) => {
                })
                .catch((err) => console.error(err));
        } catch (err) {
        }
    };
    return {
        isLoading,
        error,
        onCreateAgentStatusChangeHandle
    };
}
