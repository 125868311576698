import { roundToNearestMultipleX } from 'utils/common';
import { AGENT_MAP_GRID_NUM, AGENT_MAP_GRID_SIZE } from 'utils/constants';

export const getMiddlePosition = (parentDiv?: HTMLDivElement | null, scale: number = 1) => {
  if (!parentDiv) return;
  const parentRect = parentDiv.getBoundingClientRect();

  const parentScrollTop = parentDiv.scrollTop;
  const parentScrollLeft = parentDiv.scrollLeft;

  const width = AGENT_MAP_GRID_NUM * AGENT_MAP_GRID_SIZE * scale;

  let middleX = (width - parentRect.width) / 2 + parentScrollLeft;
  let middleY = parentRect.height / 2 + parentScrollTop;

  middleX = roundToNearestMultipleX(middleX, AGENT_MAP_GRID_SIZE / 2);
  middleY = roundToNearestMultipleX(middleY, AGENT_MAP_GRID_SIZE / 2);

  return { x: middleX, y: middleY };
};
