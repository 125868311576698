import { NotificationDto, UpdateAgentMapSocketDto } from 'types';
import { AgentTotalCallAndChatCounter } from 'types/dto/agent-maps/agent-total-call-and-chat-counter.dto';
import { configuration, LOCAL_STORAGE_KEY, NOTIFICATION_EVENT, SocketClient } from 'utils';

// Define a new interface for counter updates


export const notificationsSocketClient = new SocketClient({
  uri: configuration.NOTIFICATION_SOCKET_URL!,
  transports: ['websocket'],
  auth: {
    token: localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
  },
  autoConnect: !!localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
});

export async function connectNotificationSocket() {
  notificationsSocketClient.connect();
}

export async function disconnectNotificationSocket() {
  notificationsSocketClient.disconnect();
}

export async function reconnectNotificationSocket() {
  notificationsSocketClient.reconnect();
}

export function onReceiveNotification(callback?: (data: NotificationDto) => void) {
  notificationsSocketClient.socket.on(NOTIFICATION_EVENT.NOTIFICATION, (data: NotificationDto) => {
    callback?.(data);
  });
}

export function onReceiveAgentUpdateNotification(callback?: (data: UpdateAgentMapSocketDto) => void) {
  notificationsSocketClient.socket.on(NOTIFICATION_EVENT.UPDATE_AGENT_MAP, (data) => {
    console.log('🔥 Event Fired: nhận được hong', data);
    callback?.(data);
  });
}

export function offReceiveNotification() {
  notificationsSocketClient.socket.off(NOTIFICATION_EVENT.NOTIFICATION);
}

export function sendUpdateAgentMapNotification(payload: UpdateAgentMapSocketDto) {
  notificationsSocketClient.socket.emit(NOTIFICATION_EVENT.UPDATE_AGENT_MAP, payload);
}


