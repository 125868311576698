import { useChangeAgentStatusAgentMapSeatMutation } from 'services';
import { ChangeAgentStatusAgentMapSeatDto } from 'types';
export function useChangeagentMapSeatAgentStatus() {

    const [changeAgentStatusAgentMapSeat, { isLoading, error }] = useChangeAgentStatusAgentMapSeatMutation();

    const onChangeAgentStatusAgentMapSeat = async (values: ChangeAgentStatusAgentMapSeatDto) => {
        try {

            return await changeAgentStatusAgentMapSeat(values).unwrap().then((res) => {
            });
        } catch (err) {
            throw err;
        }
    };
    return {
        onChangeAgentStatusAgentMapSeat,
        isLoading,
        error,
    };
}
