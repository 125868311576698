import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessCollectPaymentRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_PAYMENT_COLLECTION]} />,
    children: [
      {
        path: ROUTE.COLLECT_PAYMENT,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/CollectPayment'))
          }
        ]
      }
    ]
  }
];
