import { List, Progress, Typography, Modal, Checkbox, message, notification } from 'antd';
import { MapMarker, PhoneOutline, UserOutline } from 'assets';

import img from '../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import { requestform } from 'messages';
import { usePatchReceptionTicketMutation } from 'services';
import { FollowingRequestDto } from '../../types/dto/following-request';
import { GetFollowingRequestList } from '../../hooks';
import RequestDetail from '../request-form/RequestDetail';
import FollowingRequestDetail from './FollowingRequestDetail';

interface TicketsProps {
  status: string;
  requestData: FollowingRequestDto;
  refreshData: () => void;
}

const FollowingRequestList: React.FC<TicketsProps> = ({ status, requestData, refreshData }) => {
  const { followingList: data, refetch, isLoading } = GetFollowingRequestList({ ...requestData, endPoint: status });
  const completePercentage = 80;

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [openTicket, setOpenTicket] = useState<number>();

  const openDetailModal = (id: number) => {
    setOpenTicket(id);
    setDetailModalOpen(true);
  };
  const cancelDetailModal = () => {
    setDetailModalOpen(false);
  };
  useEffect(() => {
    if (!detailModalOpen) {
      refetch();
      refreshData();
    }
  }, [detailModalOpen, requestData]);

  const statusColors: Record<string, string> = {
    complaint: 'border-[#02D8A0]',
    electrical_safety: 'border-[#FF4D4F]',
    vip_customer: 'border-[#1890FF]',
    outage_entire_area: 'border-[#722ED1]',
    not_on_time: 'border-[#13C2C2]'
  };
  const borderColor = statusColors[status] || '';

  return (
    <div className='relative h-fit w-full'>
      <List
        loading={isLoading}
        className='list-item-hover-animation list-item-hover list-item-px-0-py-4 h-[700px] overflow-y-auto'
        itemLayout='vertical'
        size='large'
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            onClick={() => openDetailModal(item.ticketId)}
            className='flex justify-between'
            key={item.ticketId}
          >
            <div className='flex justify-start space-x-4 align-middle'>
              <div className='my-auto flex w-[55px] space-x-2'>
                <Typography.Text className='font-oswald text-[14px]/[18px] text-[#141414]'>
                  {index + 1 + requestData.pageSize * (requestData.pageIndex - 1)}.
                </Typography.Text>
              </div>

              <div className='my-auto min-w-[75px]'>
                <Typography.Text className='font-oswald text-[22px]/[26px] leading-[1.1] text-[#141414]'>
                  {item.createdAt &&
                    (() => {
                      const date = new Date(item.createdAt);
                      const vietnamTime = new Date(date.getTime());
                      const hours = vietnamTime.getHours().toString().padStart(2, '0');
                      const minutes = vietnamTime.getMinutes().toString().padStart(2, '0');

                      return `${hours}:${minutes}`;
                    })()}
                </Typography.Text>
                <br />
                <Typography.Text className='my-auto font-oswald text-[12px]/[16px] text-[#868D97]'>
                  {item.createdAt.split('T')[0].split('-').reverse().join('/')}
                </Typography.Text>
              </div>

              {/* General Information */}
              <div className={`flex items-center border-l-[3px] pl-8 ${borderColor}`}>
                <div className='space-y-2'>
                  <div className='flex space-x-2'>
                    <Typography.Text className='my-auto text-[16px]/[20px] font-semibold text-[#1564E8]'>
                      {item.ticketId}
                    </Typography.Text>
                    <Typography.Text className='my-auto align-middle text-[16px]/[20px] font-semibold text-[#181818]'>
                      {item.serviceType?.name
                        ? item.serviceType.name.split(' ').slice(0, 20).join(' ') +
                          (item.serviceType.name.split(' ').length > 10 ? '...' : '')
                        : 'Không có thông tin'}
                    </Typography.Text>
                    {item.isPriority ? (
                      <Typography.Text className='rounded-[3px] bg-[#D1131D] px-[6px] py-[2px] text-[#FFFFFF]'>
                        CMIS
                      </Typography.Text>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='flex h-4 space-x-2'>
                    <img className='h-4 w-4' src={img} alt='' />
                    <Typography.Text className='text-[14px]/[18px] text-[#4D5664]'>{item.customerCode}</Typography.Text>
                    <Typography.Text className='relative bottom-3 text-2xl font-bold'>.</Typography.Text>
                    <UserOutline />
                    <Typography.Text className='top-1 text-[14px]/[18px] text-[#4D5664]'>
                      {item.customerRequestName}
                    </Typography.Text>
                    <Typography.Text className='relative bottom-3 text-2xl font-bold'>.</Typography.Text>
                    <Typography.Text>
                      <PhoneOutline />
                    </Typography.Text>
                    <Typography.Text className='top-1 text-[14px]/[18px] text-[#4D5664]'>
                      {item.customerRequestPhoneNumber}
                    </Typography.Text>
                    <Typography.Text className='relative bottom-3 text-2xl font-bold'>.</Typography.Text>
                    <Typography.Text>
                      <MapMarker />
                    </Typography.Text>
                    <Typography.Text className='top-1 text-[14px]/[18px] text-[#4D5664]'>
                      {item.customerRequestAddress}
                    </Typography.Text>
                  </div>
                  <div className='flex h-4 space-x-2'>
                    {item.additionalInfo && item.additionalInfo.usagePurpose ? (
                      <div className='flex h-4 space-x-2'>
                        <Typography.Text className='text-[14px]/[18px] italic text-[#D1131D]'>
                          {item.additionalInfo.usagePurpose}
                        </Typography.Text>
                        <Typography.Text className='relative bottom-3 text-2xl font-bold'>.</Typography.Text>
                      </div>
                    ) : (
                      ''
                    )}

                    {!item.isRequestMultipleTime ? (
                      <>
                        <Typography.Text className='text-[14px]/[18px] italic text-[#06A77D]'>
                          {requestform.notMultiplleTimeRequest}
                        </Typography.Text>
                      </>
                    ) : (
                      <Typography.Text className='text-[14px]/[18px] italic text-[#D1131D]'>
                        {requestform.multiplleTimeRequest}
                      </Typography.Text>
                    )}

                    {item.numOfRequests ? (
                      <>
                        <Typography.Text className='relative bottom-3 text-2xl font-bold'>.</Typography.Text>
                        <Typography.Text className='text-[14px]/[18px] italic text-[#D1131D]'>
                          {item.numOfRequests + requestform.numOfRequest}
                        </Typography.Text>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='space-y-0 text-right'>
              <div className='flex space-x-2 align-middle'>
                <Progress
                  className='top-[5px] rotate-180'
                  percent={completePercentage}
                  showInfo={false}
                  size={[200, 2]}
                  strokeColor={completePercentage >= 70 ? '#02D8A0' : completePercentage >= 40 ? '#FF9500' : '#D1131D'}
                />
                <Typography.Text
                  className='w-full'
                  style={{
                    color: completePercentage >= 70 ? '#02D8A0' : completePercentage >= 40 ? '#FF9500' : '#D1131D'
                  }}
                >
                  {item.updatedAt &&
                    new Date(item.updatedAt).toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}{' '}
                </Typography.Text>
              </div>
            </div>
          </List.Item>
        )}
      />

      {/* Detail Modal */}
      <Modal
        centered
        className='ant-modal-body-p-none min-h-[1000px] w-fit rounded-xl bg-[#F5F4F6]'
        open={detailModalOpen}
        onCancel={cancelDetailModal}
        footer={null}
        destroyOnClose
      >
        {openTicket && (
          <FollowingRequestDetail status={status} ticketId={openTicket} cancelDetailModal={cancelDetailModal} />
        )}
      </Modal>
    </div>
  );
};

export default FollowingRequestList;
