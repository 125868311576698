import React from 'react';

interface PrintWrapperProps {
  children: React.ReactNode;
}

const PrintComponent = React.forwardRef<HTMLDivElement, PrintWrapperProps>(({ children }, ref) => {
  return <div ref={ref}>{children}</div>;
});

export default PrintComponent;
