import { createApi } from '@reduxjs/toolkit/query/react';
import { ResponseDto } from 'types';
import { PowerOutageHistoryDto } from 'types/dto/omis-info-retrieval';
import { CustomerCodeAndCadastralCodeDto } from 'types/dto/omis-info-retrieval/customer-and-cadastral-code.dto';
import axiosBaseQuery from 'utils/base-api';

export const omisInfoRetrievalApi = createApi({
    reducerPath: 'omisInfoRetrievalApi',
    tagTypes: ['PowerOutageHistory'],
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        postPowerOutageHistory: builder.mutation<ResponseDto<PowerOutageHistoryDto>, CustomerCodeAndCadastralCodeDto>({
            query: (cusCodeDetail) => ({
                url: `/oms_info_retrieval/power_outage_history`,
                method: 'post',
                data: cusCodeDetail
            }),
            invalidatesTags: ['PowerOutageHistory'],
        })
    }),
});

export const {
    usePostPowerOutageHistoryMutation,
} = omisInfoRetrievalApi;