import { usePaging } from "hooks/usePaging";
import { useGetTicketsCompletedByCustomerCodeQuery } from "services/tickets";
import { FindAllTicketCompletedDto } from "types/dto/ticket/find-all-ticket-completed.dto";

const pageSize = 3;
export function useTicketsCompletedPaging(params: Omit<FindAllTicketCompletedDto, 'pageIndex'|'pageSize'>) {
    const {
        keyword,
        customerCode,
        organizationUnitId,
        statusId,
        channelId,
        serviceTypeId,
    } = params;
    const {pageIndex, changePage, resetPage} = usePaging(params);
    const {
        data: ticketsCompletedResponse,
        error,
        isFetching,
        isLoading
    } = useGetTicketsCompletedByCustomerCodeQuery(
        {
            pageIndex : pageIndex,
            pageSize,
            keyword,
            customerCode,
            organizationUnitId,
            statusId,
            channelId,
            serviceTypeId
        },
    );
    const handleChangePage = changePage;

    return {
        ticketsCompleted: ticketsCompletedResponse?.data?.rows || [],
        count: ticketsCompletedResponse ? ticketsCompletedResponse?.data?.count : 0,
        isLoading : isFetching || isLoading,
        pageIndex,
        handleChangePage,
        resetPage,
        error
    };
}