import { RouteObject } from 'react-router-dom';
import { CheckPermissionLayout } from '../layouts';
import { PERMISSION } from '../utils';
import { ROUTE } from './constants';
import { lazy } from 'react';

export const accessFollowingRequestRoute: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={PERMISSION.ACCESS_NEED_TRACKING_REQUEST} />,
    children: [
      {
        path: ROUTE.FOLLOWING_REQUEST,
        index: true,
        Component: lazy(() => import('pages/FollowingRequest'))
      }
    ]
  },
];
