export const notificationsMessages = {
  updateAvatarSuccess: 'Cập nhật ảnh đại diện thành công',
  notifications: 'Thông báo',
  newNotif: 'Thông báo mới',
  newNotifRequiredConfirm: 'Thông báo mới cần xác nhận',
  readAll: 'Đã đọc tất cả',
  fileDownload: 'File tải về',
  download: 'Tải về',
  fileExpired: 'File hết hạn',
  fileProcessing: 'File đang xử lý',
  expiredAt: 'Hết hạn sau',
  fileError: 'File bị lỗi',
  notifDownloadTitle: 'File xuất Excel đã sẵn sàng để tải về',
  notifDownloadDescription: (time: string) =>
    `File của bạn sẽ được lưu trữ trong vòng ${time} trước khi xóa khỏi hệ thống.`,
  notifErrorTitle: 'File xuất Excel đã bị lỗi',
  notifErrorDescription: 'File của bạn đã bị lỗi trong quá trình xử lý, vui lòng thử lại.',
  notifProcessingTitle: 'File xuất Excel đang được chuẩn bị',
  notifProcessingDescription: 'File của bạn đang được chuẩn bị vui lòng đợi trong ít phút.'
};
