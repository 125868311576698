import { useCallback, useState } from 'react';
import { useGetChatSessionsWaitingQuery } from 'services';
import { FindAllChatSessions, UseQueryOptionsType } from 'types';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useOmniChatSessionsWaitingList(
  params: Omit<FindAllChatSessions, 'skip' | 'limit'>,
  options?: UseQueryOptionsType
) {
  const { keyword, endpoint } = params;

  const [skip, setSkip] = useState(0);

  const {
    data: dataResponse,
    isLoading,
    isFetching,
    refetch
  } = useGetChatSessionsWaitingQuery(
    {
      skip,
      limit: pageSize,
      keyword,
      endpoint
    },
    {
      ...options
    }
  );

  const { rows, count } = dataResponse?.data || {};

  const hasMore = Boolean(rows && count && rows.length < count);

  const handleLoadMore = useCallback(() => {
    if (hasMore && !isFetching) {
      setSkip(rows?.length ?? 0);
    }
  }, [hasMore, skip, isFetching]);

  const resetPage = () => {
    setSkip(0);
  };

  return {
    chatSessionWaiting: rows || [],
    count: count || 0,
    isFetching,
    isLoading,
    handleLoadMore,
    hasMore,
    resetPage,
    refetch
  };
}
