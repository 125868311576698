export const customerLookupMessages = {
  customer: 'Khách hàng',
  processing: 'Đang xử lý',
  contactHistory: 'Lịch sử liên hệ',
  customerName: 'Tên khách hàng',
  customerAddress: 'Địa chỉ',
  district: 'Quận/Huyện',
  ward: 'Phường/Xã',
  customerPhone: 'Số điện thoại',
  requestCode: 'Mã yêu cầu',
  other: 'Khác',
  customerCode: 'Mã khách hàng',
  newRegister: 'Đăng ký mới',
  lookup: 'Tìm kiếm',
  serialNumber: 'STT',
  organizationUnit: 'Đơn vị',
  lookupInformation: 'Tra cứu thông tin',
  notReady: 'Chưa sẵn sàng',
  notReadyMessage: 'Nhấn sẵn sàng để bắt đầu nhận cuộc gọi của khách hàng.',
  ready: 'Bấm để sẵn sàng',
  isReady: 'Đã sẵn sàng',
  isReadyMessage: 'Vui lòng chú ý! Khách hàng có thể gọi đến bất kì lúc nào.',
  pause: 'Tạm ngưng',
  isPause: 'Đang tạm ngưng',
  CreatingTicket: 'Đang tạo phiếu',
  isCreatingTicket: 'Nhấn sẵn sàng để bắt đầu nhận cuộc gọi khác.',
  inComingCall: 'Đang gọi đến',
  customerWaiting: 'Khách hàng đang chờ hỗ trợ.',
  failedLogin: 'Kết nối phần mềm World Phone thất bại',
  isFailedLogin: 'Vui lòng bấm "Kết nối World Phone" để tiến hành kết nối lại World Phone.',
  login: 'Kết nối World Phone',
  isTalking: 'Đang đàm thoại',
  openWorldPhoneWarning:
    'Hệ thống phát hiện có thể phần mềm World Phone chưa bật. Vui lòng mở phần mềm World Phone và thực hiện kết nối lại.',
  selectDistrict: 'Chọn Quận/Huyện',
  selectWard: 'Chọn Phường/Xã',
  PE: 'PE'
};
