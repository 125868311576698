import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AssignEmployeeTicketRequest,
  ChangeStatus,
  CmisServicesRequestData,
  CmisServicesResponseData,
  CollaborationRequest,
  CompleteCollaborationTicketRequest,
  CreateObstacleTicketRequest,
  InprogressTickets,
  Option,
  OptionDetail,
  OrganizationUnitChildren,
  OrganizationUnitService,
  RequestData,
  ResponseDto,
  ResponsePagingDto,
  TicketDetail,
  TransferCmis,
  TransferToUnit,
  UpdateObstacleTicketRequest,
  UserInOrganizationUnitResponse
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const requestForm = createApi({
  reducerPath: 'requestForm',
  tagTypes: ['pending', 'inprogress', 'collaboration', 'completed'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getPendingTicket: builder.query<ResponsePagingDto<TicketDetail>, number>({
      query: (pageSize) => ({
        url: '/tickets/dienluc/pending-reception',
        method: 'get',
        params: { pageSize }
      })
    }),
    getInProgressTicket: builder.query<ResponsePagingDto<TicketDetail>, number>({
      query: (pageSize) => ({
        url: '/tickets/dienluc/in-progress',
        method: 'get',
        params: { pageSize }
      })
    }),
    getCollaborationTicket: builder.query<ResponsePagingDto<TicketDetail>, number>({
      query: (pageSize) => ({
        url: '/tickets/dienluc/collaboration',
        method: 'get',
        params: { pageSize }
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(() => ({
            type: 'collaboration'
          }))
          : ['collaboration']
    }),
    getCompletedTicket: builder.query<ResponsePagingDto<TicketDetail>, number>({
      query: (pageSize) => ({
        url: '/tickets/dienluc/completed',
        method: 'get',
        params: { pageSize }
      })
    }),

    getTicketDetail: builder.query<ResponseDto<TicketDetail>, number>({
      query: (ticketDetail) => ({
        url: `/tickets/detail/${ticketDetail}`,
        method: 'get'
        // params: { ticketDetail }
      })
    }),

    getTicketList: builder.query<ResponsePagingDto<TicketDetail>, RequestData>({
      query: (requestData) => {
        const params = new URLSearchParams();

        Object.entries(requestData).forEach(([key, value]) => {
          if (key === 'isObstacleTickets' && value === true) {
            params.append(key, value.toString());
          } else if (
            value !== undefined &&
            value !== null &&
            value !== '' &&
            value !== 0 &&
            key !== 'isObstacleTickets'
          ) {
            if (Array.isArray(value)) {
              value.forEach((v) => params.append(key, v.toString()));
            } else {
              params.append(key, value.toString());
            }
          }
        });

        const url =
          requestData.status === 'obstacle' ? `/tickets/dienluc/in-progress` : `/tickets/dienluc/${requestData.status}`;

        return {
          url,
          method: 'get',
          params: params
        };
      }
    }),

    getObstacleTicket: builder.query<ResponsePagingDto<TicketDetail>, RequestData>({
      query: (requestData) => {
        const params = new URLSearchParams();

        Object.entries(requestData).forEach(([key, value]) => {
          if (value !== undefined && value !== null && value !== '' && value !== 0) {
            if (Array.isArray(value)) {
              value.forEach((v) => params.append(key, v.toString()));
            } else {
              params.append(key, value.toString());
            }
          }
        });

        return {
          url: '/tickets/dienluc/in-progress',
          method: 'get',
          params: params
        };
      }
    }),

    //Get Transfer CMIS Services
    getCmisServices: builder.query<ResponsePagingDto<CmisServicesResponseData>, CmisServicesRequestData>({
      query: (requestData) => {
        const params = new URLSearchParams();

        Object.entries(requestData).forEach(([key, value]) => {
          if (value !== undefined && value !== null && value !== '' && value !== 0) {
            if (Array.isArray(value)) {
              value.forEach((v) => params.append(key, v.toString()));
            } else {
              params.append(key, value.toString());
            }
          }
        });

        return {
          url: '/service_types_cmis',
          method: 'get',
          params: params
        };
      }
    }),

    //Organization
    getOrganizatioUnitChildren: builder.query<ResponsePagingDto<OrganizationUnitChildren>, number>({
      query: (organizationUnitId) => {
        return {
          url: `/organization_units/${organizationUnitId}/children`,
          method: 'get'
        };
      }
    }),
    getOrganizatioUnitDetailServices: builder.query<ResponsePagingDto<OrganizationUnitService>, number>({
      query: (organizationUnitId) => {
        return {
          url: `/organization_units/${organizationUnitId}/service_types/options`,
          method: 'get',
          params: {
            pageIndex: 1,
            pageSize: 200
          }
        };
      }
    }),

    getOptionDetail: builder.query<ResponsePagingDto<OptionDetail>, number>({
      query: (optionTypeId) => {
        return {
          url: `/options`,
          method: 'get',
          params: { optionTypeId, service: 'CORE' }
        };
      }
    }),

    getUserInOrganizationUnit: builder.query<ResponsePagingDto<UserInOrganizationUnitResponse>, number>({
      query: (organizationUnitId) => {
        return {
          url: `/organization_units/${organizationUnitId}/users?pageIndex=1&pageSize=100`,
          method: 'get'
        };
      }
    }),

    getReasonOutage: builder.query<ResponsePagingDto<Option>, number>({
      query: (optionTypeId) => {
        return {
          url: `/options?service=CORE&optionTypeId=${optionTypeId}`,
          method: 'get'
        };
      }
    }),

    // Patch API
    patchTransferTo: builder.mutation<ResponseDto<null>, TransferToUnit>({
      query: ({ toUnit, ticketId }) => {
        return {
          url: `/tickets/dtv/transfer_to_${toUnit}/${ticketId}`,
          method: 'patch'
        };
      }
    }),
    patchReceptionTicket: builder.mutation<ResponseDto<ChangeStatus>, number>({
      query: (ticketId) => {
        return {
          url: `/tickets/dienluc/reception/${ticketId}`,
          method: 'patch'
        };
      }
    }),
    patchCompleteTicket: builder.mutation<ResponseDto<ChangeStatus>, number>({
      query: (ticketId) => {
        return {
          url: `/tickets/dienluc/complete/${ticketId}`,
          method: 'patch'
        };
      }
    }),
    patchInprogressTicket: builder.mutation<ResponseDto<ChangeStatus>, InprogressTickets>({
      query: ({ ticketId, processingContent }) => {
        return {
          url: `/tickets/dienluc/in-progress/${ticketId}`,
          method: 'patch',
          data: { processingContent }
        };
      }
    }),
    patchCollaborationTickets: builder.mutation<ResponseDto<ChangeStatus>, CollaborationRequest>({
      query: ({ ticketId, collaborationDetail }) => {
        return {
          url: `/tickets/dienluc/collaboration/${ticketId}`,
          method: 'patch',
          data: collaborationDetail
        };
      }
    }),
    patchNewObstacleTicket: builder.mutation<ResponseDto<ChangeStatus>, CreateObstacleTicketRequest>({
      query: (obstacleTicket) => {
        return {
          url: `/ticket_hold_manager`,
          method: 'post',
          data: obstacleTicket
        };
      }
    }),
    patchTransferToCustomerCareCenter: builder.mutation<ResponseDto<ChangeStatus>, InprogressTickets>({
      query: ({ ticketId, processingContent }) => {
        return {
          url: `/tickets/dienluc/return/${ticketId}`,
          method: 'patch',
          data: { processingContent }
        };
      }
    }),
    patchUpdateObstacleTicket: builder.mutation<ResponseDto<ChangeStatus>, UpdateObstacleTicketRequest>({
      query: (request) => {
        return {
          url: `/ticket_hold_manager/${request.ticketHoldManagerId}`,
          method: 'patch',
          data: request
        };
      }
    }),
    patchCompleteObstacleTicket: builder.mutation<ResponseDto<ChangeStatus>, UpdateObstacleTicketRequest>({
      query: (request) => {
        return {
          url: `/ticket_hold_manager/close/${request.ticketHoldManagerId}`,
          method: 'patch',
          data: request
        };
      }
    }),
    patchCompleteCollaborationTicket: builder.mutation<ResponseDto<ChangeStatus>, CompleteCollaborationTicketRequest>({
      query: (request) => {
        return {
          url: `/tickets/dienluc/collaboration/${request.ticketId}/completed`,
          method: 'patch',
          data: request
        };
      }
    }),
    patchTransferToCmis: builder.mutation<ResponseDto<ChangeStatus>, TransferCmis>({
      query: (request) => {
        return {
          url: `/cmis_crm_integration/send_request`,
          method: 'post',
          data: request
        };
      }
    }),

    patchAssignEmployee: builder.mutation<ResponseDto<null>, AssignEmployeeTicketRequest>({
      query: (request) => {
        return {
          url: `/tickets/dienluc/assignee/${request.ticketId}`,
          method: 'patch',
          data: {
            assigneeId: request.assigneeId
          }
        };
      }
    })
  })
});

export const {
  useGetPendingTicketQuery,
  useGetCollaborationTicketQuery,
  useGetCompletedTicketQuery,
  useGetInProgressTicketQuery,
  useGetTicketDetailQuery,
  useGetTicketListQuery,
  useGetOrganizatioUnitChildrenQuery,
  useGetOrganizatioUnitDetailServicesQuery,
  useGetOptionDetailQuery,
  useGetObstacleTicketQuery,
  useGetCmisServicesQuery,
  useGetUserInOrganizationUnitQuery,
  useGetReasonOutageQuery,

  usePatchReceptionTicketMutation,
  usePatchCompleteTicketMutation,
  usePatchInprogressTicketMutation,
  usePatchCollaborationTicketsMutation,
  usePatchNewObstacleTicketMutation,
  usePatchTransferToCustomerCareCenterMutation,
  usePatchUpdateObstacleTicketMutation,
  usePatchCompleteObstacleTicketMutation,
  usePatchTransferToCmisMutation,
  usePatchAssignEmployeeMutation,
  usePatchCompleteCollaborationTicketMutation,
} = requestForm;
