import { Spin } from 'antd';
import { Loadable } from 'components/common';
import { OmniChatProvider } from 'contexts';
import { lazy, useCallback, useEffect, useRef, useState } from 'react';
import {
  connectOmniChannelSocket,
  disconnectOmniChannelSocket,
  offReceiveChatSessionProcessing,
  onReceiveChatSessionProcessing
} from 'services';
import { twMerge } from 'tailwind-merge';
import OmniChatSession from './OmniChatSession';
import { useOmniChatSessionsProcessingList, useOmniChatSessionsWaitingList, useProfile } from 'hooks';
import { ChatSessionDto } from 'types';
import { find, sumBy } from 'lodash';
import { CHAT_STATUS, PERMISSION } from 'utils';
import { useDispatch } from 'react-redux';
import { setCurrentChatSession, setGoBack } from 'store/features/omni-chat/omni-chat.slice';
import { checkPermissions } from 'utils/check-permission';
const CustomerWaitingSupportList = lazy(() => import('./OmniChatSessionsWaiting'));
const OmniChatSessionsProcessing = lazy(() => import('./OmniChatSessionsProcessing'));

const OMNICHANNEL_CHAT_SESSION_STORAGE_KEY = 'omnichannel_chat_session';

const OmniChannelChat = () => {
  const { userPermissionIds } = useProfile();
  const firstRef = useRef(true);

  const isAllowChat = checkPermissions(userPermissionIds, PERMISSION.CHAT_CUSTOMER);

  const dispatch = useDispatch();
  const [chatSession, setChatSession] = useState<ChatSessionDto | undefined>();

  const {
    chatSessionProcessing,
    isLoading: isLoadingProcessing,
    refetch: refetchProgressing
  } = useOmniChatSessionsProcessingList({ keyword: '' });
  const { isLoading: isLoadingWaiting, refetch } = useOmniChatSessionsWaitingList({ keyword: '' });

  useEffect(() => {
    if (firstRef.current && !chatSession) {
      const storedChatSession = localStorage.getItem(OMNICHANNEL_CHAT_SESSION_STORAGE_KEY);
      if (
        chatSessionProcessing.length &&
        storedChatSession &&
        find(chatSessionProcessing, (o) => {
          return (
            (o as ChatSessionDto).chatSessionId === (JSON.parse(storedChatSession) as ChatSessionDto).chatSessionId
          );
        })
      ) {
        setChatSession(
          storedChatSession && JSON.parse(storedChatSession).chat ? JSON.parse(storedChatSession) : undefined
        );
        firstRef.current = false;
      }
    }
  }, []);

  useEffect(() => {
    if (chatSession) {
      localStorage.setItem(OMNICHANNEL_CHAT_SESSION_STORAGE_KEY, JSON.stringify(chatSession));
      dispatch(setCurrentChatSession(chatSession));
      dispatch(setGoBack(false));
    } else {
      localStorage.removeItem(OMNICHANNEL_CHAT_SESSION_STORAGE_KEY);
    }
  }, [chatSession]);

  useEffect(() => {
    connectOmniChannelSocket();
    return () => {
      disconnectOmniChannelSocket();
    };
  }, []);

  useEffect(() => {
    if (!chatSession && !firstRef.current) {
      refetchProgressing();
      refetch();
    }
  }, [chatSession]);

  const chatSessionRef = useRef<ChatSessionDto | undefined>(chatSession);

  useEffect(() => {
    chatSessionRef.current = chatSession;
  }, [chatSession]);

  useEffect(() => {
    const handleReceiveChat = (data: ChatSessionDto) => {
      if (data.chatId === chatSessionRef.current?.chatId) {
        if (data.status === CHAT_STATUS.COMPLETED) {
          setChatSession(undefined);
          dispatch(setCurrentChatSession(undefined));
        } else {
          setChatSession(data);
          dispatch(setCurrentChatSession(data));
        }
      }
    };

    onReceiveChatSessionProcessing(handleReceiveChat);
    return () => {
      offReceiveChatSessionProcessing();
    };
  }, []);

  if (isLoadingProcessing || isLoadingWaiting) {
    return (
      <div className='relative flex h-full w-[400px] overflow-hidden'>
        <div className='absolute left-1/2 top-1/3 flex -translate-x-1/2 -translate-y-1/3 flex-col items-center'>
          <Spin></Spin>
        </div>
      </div>
    );
  }

  return (
    <div className='relative flex h-full w-[400px] overflow-hidden'>
      <div
        className={twMerge(
          'absolute h-full w-full bg-white transition-all duration-200',
          !chatSession ? 'translate-x-0' : '-translate-x-full'
        )}
      >
        <div className='flex h-full w-full flex-col'>
          {isAllowChat && (
            <div className='max-h-[calc(100%-124px)] min-h-[calc(100%-124px)]'>
              <OmniChatSessionsProcessing
                setChatSession={(value) => {
                  setChatSession(value);
                }}
                chatIdSession={chatSession?.chatSessionId}
              />
            </div>
          )}

          {!chatSession && (
            <div className='h-[124px]] border-t'>
              <CustomerWaitingSupportList />
            </div>
          )}
        </div>
      </div>

      <div
        className={twMerge(
          'absolute h-full w-full bg-colorBgSuccess transition-all duration-200',
          !chatSession ? 'translate-x-full' : 'translate-x-0'
        )}
      >
        {chatSession && (
          <Loadable>
            <OmniChatProvider
              setChatSession={(value: ChatSessionDto) => setChatSession(value)}
              endpointName={''}
              chatSession={chatSession}
              isShowActions={true}
            >
              <OmniChatSession
                key={chatSession.chatId}
                chatSessionId={chatSession.chatSessionId}
                chatId={chatSession.chatId}
                onClose={() => setChatSession(undefined)}
                unreadCount={sumBy(chatSessionProcessing, (o) =>
                  o.chatSessionId === chatSession.chatSessionId ? 0 : o.unreadCount
                )}
              />
            </OmniChatProvider>
          </Loadable>
        )}
      </div>
    </div>
  );
};
export default OmniChannelChat;
