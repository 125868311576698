import { Loadable } from 'components/common';
import { lazy, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import { useOmniChatContext } from 'contexts';
import { Button, Spin } from 'antd';
import { ArrowDownIcon } from 'assets';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
const OmniMessageList = lazy(() => import('./OmniMessageList'));

type OmniChatMessageBoxProps = {};
interface CustomerSupportSessionState {
  chatSessionId?: number;
  searchData?: searchData[]
  callId?: string;
}

interface searchData {
  key: string;
  label: string;
  customerId: number;
  customerCode: string;
  minimizedModals: number[]; // Lưu các modal đã thu nhỏ
  formData: { [key: number]: any }; // Lưu dữ liệu form của từng modal
}

const OmniChatMessageBox: React.FC<OmniChatMessageBoxProps> = () => {
  const {
    positions,
    messagesBoxRef,
    messageListRef,
    messages,
    hasMore,
    hasNew,
    onGetOlderMess,
    onGetNewerMess,
    isAtBottomScroll,
    scrollToEndMessage,
    isFetching,
    isLoading,
    chatSession
  } = useOmniChatContext();
  const dispatch = useDispatch()
  const updateCustomerSearchByChatSessionId = (customerSupportSession: CustomerSupportSessionState) => {
    const state = localStorage.getItem('customerSupportSession');
    if (state) {
      const parsedState: CustomerSupportSessionState[] = JSON.parse(state);
      const existingSessionIndex = parsedState.findIndex((session: any) => session.chatSessionId === customerSupportSession.chatSessionId);
      if (existingSessionIndex === -1) {
        const newState = [...parsedState, { ...customerSupportSession }];
        localStorage.setItem('customerSupportSession', JSON.stringify(newState));
      } else {
        // If chatSessionId exists, merge the existing searchData with the new searchData
        const existingSession = parsedState[existingSessionIndex];
        const mergedSearchData = [
          ...(existingSession.searchData || []),
          ...(customerSupportSession.searchData || []).filter(newTab =>
            !(existingSession.searchData || []).find(existingTab => existingTab.key === newTab.key)
          )
        ];

        parsedState[existingSessionIndex] = {
          ...existingSession,
          ...customerSupportSession,
          searchData: mergedSearchData // This will merge the existing searchData with the new data
        };
        localStorage.setItem('customerSupportSession', JSON.stringify(parsedState));
      }
    } else {
      localStorage.setItem('customerSupportSession', JSON.stringify([customerSupportSession]));
    }
  };

  useEffect(() => {
    const chatSessionId = chatSession?.chatSessionId;
    const state = localStorage.getItem('customerSupportSession');
    const customerSupportSession: CustomerSupportSessionState[] = state ? JSON.parse(state) : [];
    if (!customerSupportSession.length) {
      const obj: CustomerSupportSessionState = {
        chatSessionId: chatSessionId,
        searchData: [],
        callId: undefined
      };
      updateCustomerSearchByChatSessionId(obj);
    } else {
      const exists = customerSupportSession.some((session: any) => session.chatSessionId === chatSessionId);
      if (!exists) {
        const obj: CustomerSupportSessionState = {
          chatSessionId: chatSessionId,
          searchData: [],
          callId: undefined
        };
        updateCustomerSearchByChatSessionId(obj);
      }
    }
  }, []);

  return (
    <div className='relative flex-grow overflow-hidden'>
      <Spin
        prefixCls='spin-omni-chat'
        delay={3000}
        spinning={isFetching || isLoading}
        wrapperClassName='max-h-full h-full'
        className='h-full'
      >
        <div
          ref={messagesBoxRef}
          className='fade-scrollbar h-full'
          style={{
            overflowY: 'auto'
          }}
        >
          <div ref={messageListRef} className='relative min-h-full w-full overflow-hidden px-5'>
            {hasMore ? (
              <InView
                className='absolute h-10 w-full'
                style={{
                  top: positions[5]?.position,
                  height: positions[5]?.height
                }}
                as='div'
                onChange={(inView) => {
                  if (inView) {
                    onGetOlderMess();
                  }
                }}
              />
            ) : null}

            {hasNew ? (
              <InView
                className='absolute h-10 w-full'
                as='div'
                style={{
                  top: positions[positions.length - 5]?.position,
                  height: positions[positions.length - 5]?.height
                }}
                onChange={(inView) => {
                  if (inView) {
                    onGetNewerMess();
                  }
                }}
              />
            ) : null}
            <Loadable>
              <OmniMessageList messages={messages} />
            </Loadable>
          </div>
        </div>
        {!isAtBottomScroll && (
          <div className='absolute bottom-4 left-1/2 z-50 -translate-x-1/2'>
            <Button onClick={scrollToEndMessage} className='h-8 w-8' shape='circle' icon={<ArrowDownIcon />}></Button>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default OmniChatMessageBox;
