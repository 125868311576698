import { useAddQueueMemberMutation } from 'services';
import { AddAndRemoveQueueMemberDto } from 'types';

export function useAddQueueMember() {

    const [addQueueMember, { isLoading, error }] = useAddQueueMemberMutation();

    const onAddQueueMember = async (values: AddAndRemoveQueueMemberDto) => {
        try {

            return await addQueueMember(values).unwrap();
        } catch (err) {
            console.error("Error in onAddQueueMember:", err);
            throw err;
        }
    };

    return {
        isLoading,
        error,
        onAddQueueMember,
    };
}

