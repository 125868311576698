import { usePauseQueueAgentMutation } from 'services';
import { QueueMemberDto } from 'types';

export function usePauseAgent() {

    const [pauseQueueAgent, { isLoading, error }] = usePauseQueueAgentMutation();

    const onPauseQueueAgent = async (values: QueueMemberDto) => {
        try {
            return await pauseQueueAgent(values).unwrap(); // ✅ Now returns a Promise
        } catch (err) {
            console.error("Error in onPauseQueueAgent:", err);
            throw err; // ✅ Ensure errors propagate
        }
    };

    return {
        isLoading,
        error,
        onPauseQueueAgent,
    };
}

