import dayjs from 'dayjs';
import { OmniChannelMessageDto } from 'types';
import { MESSAGE_TYPE } from 'utils/enums';

export const getPropsOmniMessageItem = (
  message: OmniChannelMessageDto,
  nextMess?: OmniChannelMessageDto | null,
  prevMess?: OmniChannelMessageDto | null
) => {
  return {
    isOnSameDate: prevMess ? dayjs(message.createdAt).isSame(prevMess.createdAt, 'date') : false,
    isLastOfSender: nextMess
      ? message.senderId !== nextMess?.senderId || nextMess.messageType === MESSAGE_TYPE.SYSTEM
      : true,
    isFirstOfSender: prevMess
      ? message.senderId !== prevMess?.senderId ||
        (prevMess.messageType !== MESSAGE_TYPE.TEXT && prevMess.messageType !== MESSAGE_TYPE.COMMENT)
      : true
  };
};
