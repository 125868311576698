import { createApi } from '@reduxjs/toolkit/query/react';
import { debounce, findIndex, uniqBy } from 'lodash';
import { AppDispatch } from 'store';
import {
  AddAndRemoveQueueMemberDto,
  AgentDto,
  AgentStatusDto,
  AssignChatSessionDto,
  ChatAgentStatusDto,
  ChatSessionDto,
  CompleteOmniChannelSessionDto,
  CountChatSessionDto,
  CountChatSessionResultDto,
  CreateOmniChannelMessageDto,
  FindAllOmniMessagesDto,
  FindWithKeywordDto,
  GetAgentStatusDto,
  GetCallDetailsDto,
  GetOmniMessageResponseDto,
  GetSoftPhoneTokenResponseDto,
  GetWaitingCallsDto,
  HideCommentDto,
  ListQueueDto,
  OmniChannelMessageDto,
  QueueMemberDto,
  QueueMemberResponseDto,
  QueueNameDto,
  ReadOmniChannelMessageDto,
  ResponseDto,
  ResponsePagingDto
} from 'types';
import { FindAllChatSessions, OmniChannelCallGroup, OmniChannelFanpageDto, OmniChannelGatewayConfigDto } from 'types';
import {
  CHAT_STATUS,
  convertMetadata,
  extractUrls,
  fetchMetadata,
  includesRemoveSpecial,
  mergeArrays,
  MESSAGE_LIMIT_CURRENT,
  MESSAGE_LIMIT_LOAD,
  OMNI_MESSAGE_QUERY_TYPE
} from 'utils';
import axiosBaseQuery from 'utils/base-api';
import {
  offReceiveChatSessionProcessing,
  offReceiveChatSessionWaiting,
  offRemoveChatSessionWaiting,
  onReceiveChatSessionProcessing,
  onReceiveChatSessionWaiting,
  onRemoveChatSessionWaiting
} from './omni-channel.socket-client';
import { GetWorldPhoneTokenDto } from 'types';
import { AddAndRemoveQueueMemberResponseDto } from 'types/dto/omni-channel/add-and-remove-queue-member-response.dto';
import { dummyOmniChannelMessages, chatSessions } from 'utils/dummy-chat-session';

export const omniChannelApi = createApi({
  reducerPath: 'omniChannelApi',
  tagTypes: [
    'omni_channel_gateway_config',
    'omni_channel_group_calls',
    'omni_channel_fanpages',
    'omni_channel_chat_session_processing',
    'omni_channel_chat_session_waiting',
    'omni_channel_message',
    'omni_channel_soft_phone_token',
    'omni_channel_agents',
    'omni_channel_chat_agent_status'
  ],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getGroupCalls: builder.query<ResponseDto<OmniChannelCallGroup[]>, void>({
      query: () => ({
        url: '/omni_channel/get_group_call',
        method: 'get'
      }),
      providesTags: ['omni_channel_group_calls']
    }),
    getGatewayConfig: builder.query<ResponseDto<OmniChannelGatewayConfigDto>, void>({
      query: () => ({
        url: '/omni_channel/omni_gateway_config',
        method: 'get'
      }),
      providesTags: ['omni_channel_gateway_config']
    }),
    getFanpages: builder.query<ResponsePagingDto<OmniChannelFanpageDto>, void>({
      query: () => ({
        url: '/omni_channel/fanpage',
        method: 'get'
      }),
      providesTags: ['omni_channel_fanpages']
    }),

    getChatSessionsWaiting: builder.query<ResponsePagingDto<ChatSessionDto>, FindAllChatSessions>({
      query: ({ endpoint, ...params }) => ({
        url: '/omni_channel/chat_sessions/waiting',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs.endpoint) {
          return endpointName + queryArgs.endpoint;
        }
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.skip !== 0) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'chatSessionId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.skip !== previousArg?.skip;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ chatSessionId }) => ({
              type: 'omni_channel_chat_session_waiting',
              id: chatSessionId
            }))
          : ['omni_channel_chat_session_waiting'],

      async onCacheEntryAdded(arg, { cacheDataLoaded, updateCachedData, cacheEntryRemoved, getState }) {
        try {
          await cacheDataLoaded;

          onReceiveChatSessionWaiting((data) => {
            if (data && data.chatSessionId) {
              debounce(() => {
                updateCachedData((draft) => {
                  const stateQueries = getState().omniChannelApi.queries;

                  const queryKey = 'getChatSessionsWaiting' + arg.endpoint;
                  const dataQueries = stateQueries[queryKey];
                  const originalArgs = dataQueries?.originalArgs as FindAllChatSessions;

                  const chatId = data.chatId;
                  const existingIndex = draft.data.rows.findIndex((chatSession) => chatSession.chatId === chatId);

                  if (existingIndex !== -1) {
                    if (data.status === CHAT_STATUS.WAITING) {
                      // Thay thế nếu data có trong danh sách và đang ở trạng thái WAITING
                      draft.data.rows[existingIndex] = data;
                    } else {
                      // Xóa nếu không ở trạng thái WAITING
                      draft.data.rows.splice(existingIndex, 1);
                      draft.data.count--;
                    }
                  } else if (data.status === CHAT_STATUS.WAITING) {
                    if (!originalArgs?.keyword || includesRemoveSpecial(data.chat.customerName, originalArgs.keyword)) {
                      draft.data.rows.push(data);
                      draft.data.count++;
                    }
                  }
                });
              }, 100)();
            }
          });

          onRemoveChatSessionWaiting((data) => {
            if (data && data.chatSessionId) {
              debounce(() => {
                updateCachedData((draft) => {
                  const chatId = data.chatId;
                  const existingIndex = draft.data.rows.findIndex((chatSession) => chatSession.chatId === chatId);

                  if (existingIndex !== -1) {
                    // Xóa nếu data có trong danh sách
                    draft.data.rows.splice(existingIndex, 1);
                    draft.data.count--;
                  }
                });
              }, 100)();
            }
          });
        } catch (err) {
          console.error({ err });
        }

        await cacheEntryRemoved;
        offReceiveChatSessionWaiting();
        offRemoveChatSessionWaiting();
      }
    }),

    getChatSessionsProcessing: builder.query<ResponsePagingDto<ChatSessionDto>, FindAllChatSessions>({
      query: (params) => ({
        url: '/omni_channel/chat_sessions/processing',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.skip !== 0) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'chatSessionId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.skip !== previousArg?.skip;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ chatSessionId }) => ({
              type: 'omni_channel_chat_session_processing',
              id: chatSessionId
            }))
          : ['omni_channel_chat_session_processing'],
      async onCacheEntryAdded(arg, { cacheDataLoaded, updateCachedData, cacheEntryRemoved, getState }) {
        try {
          await cacheDataLoaded;

          onReceiveChatSessionProcessing((data) => {
            if (data && data.chatSessionId) {
              debounce(() => {
                updateCachedData((draft) => {
                  const chatId = data.chatId;
                  const stateQueries = getState().omniChannelApi.queries;

                  const queryKey = 'getChatSessionsWaiting' + arg.endpoint;
                  const dataQueries = stateQueries[queryKey];
                  const originalArgs = dataQueries?.originalArgs as FindAllChatSessions | undefined;

                  const existingIndex = draft.data.rows.findIndex((chatSession) => chatSession.chatId === chatId);

                  if (existingIndex !== -1) {
                    if (data.status === CHAT_STATUS.COMPLETED) {
                      // Xóa nếu trạng thái là COMPLETED
                      draft.data.rows.splice(existingIndex, 1);
                      draft.data.count--;
                    } else {
                      // Cập nhật nếu đã tồn tại
                      draft.data.rows[existingIndex] = { ...data };
                    }
                  } else if (data.status !== CHAT_STATUS.COMPLETED) {
                    if (!originalArgs?.keyword || includesRemoveSpecial(data.chat.customerName, originalArgs.keyword)) {
                      draft.data.rows.push(data);
                      draft.data.count++;
                    }
                  }
                });
              }, 100)();
            }
          });
        } catch (err) {
          console.error({ err });
        }

        await cacheEntryRemoved;
        // offReceiveChatSessionProcessing();
      }
    }),

    getOmniChatMessages: builder.query<GetOmniMessageResponseDto, FindAllOmniMessagesDto>({
      query: ({ ...params }) => ({
        url: '/omni_channel/messages',
        method: 'get',
        params
      }),

      async transformResponse(response: GetOmniMessageResponseDto) {
        const updatedRows = await addMetadataToOmniMessages(response.data.rows);
        return {
          ...response,
          data: {
            ...response.data,
            rows: updatedRows.map((o) => ({
              ...o,
              senderId: isNaN(Number(o.senderId)) ? o.senderId : Number(o.senderId)
            }))
          }
        };
      },

      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName + queryArgs.chatId,
      merge: (currentCache, newItems, { arg }) => {
        if (arg?.startMessageId) {
          let currentRows = [...currentCache.data.rows];
          if (arg.sortOrder === OMNI_MESSAGE_QUERY_TYPE.NEWER) {
            currentRows =
              currentRows.length >= MESSAGE_LIMIT_CURRENT ? currentRows.slice(MESSAGE_LIMIT_LOAD) : currentRows;
            currentCache.data.rows = [...currentRows, ...newItems.data.rows];
          } else {
            currentRows =
              currentRows.length >= MESSAGE_LIMIT_CURRENT ? currentRows.slice(0, -MESSAGE_LIMIT_LOAD) : currentRows;
            currentCache.data.rows = [...newItems.data.rows, ...currentRows];
          }
          currentCache.data.count = newItems.data.count;
          currentCache.newMess = newItems.data.rows;
          return;
        }

        currentCache.data.count = newItems.data.count;
        currentCache.newMess = newItems.data.rows;
        currentCache.data.rows = newItems.data.rows;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.chatId !== previousArg?.chatId;
      },
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ messageId, chatId }) => ({
              type: 'omni_channel_message',
              id: chatId
            }))
          : ['omni_channel_message']
    }),

    receiveChatSession: builder.mutation<ResponseDto<ChatSessionDto>, void>({
      query: () => ({
        url: '/omni_channel/receive_chat_session',
        method: 'post'
      }),
      invalidatesTags: ['omni_channel_chat_session_waiting', 'omni_channel_chat_session_processing']
    }),

    sendOmniChatMessage: builder.mutation<ResponseDto<OmniChannelMessageDto>, CreateOmniChannelMessageDto>({
      query: (newChat) => ({
        url: '/omni_channel/create_message',
        method: 'post',
        data: newChat
      }),
      transformResponse: (response: ResponseDto<OmniChannelMessageDto>) => {
        return {
          ...response,
          data: {
            ...response.data,
            senderId: isNaN(Number(response.data.senderId)) ? response.data.senderId : Number(response.data.senderId)
          }
        };
      }
    }),

    readOmniChatMessages: builder.mutation<ResponseDto<ChatSessionDto>, ReadOmniChannelMessageDto>({
      query: ({ chatSessionId, ...data }) => ({
        url: `/omni_channel/read_messages/${chatSessionId}`,
        method: 'post',
        data: data
      })
    }),

    completeChatSession: builder.mutation<ResponseDto<ChatSessionDto>, CompleteOmniChannelSessionDto>({
      query: ({ chatSessionId, ...data }) => ({
        url: `/omni_channel/complete_chat_session/${chatSessionId}`,
        method: 'post',
        data: data
      }),
      invalidatesTags: ['omni_channel_chat_session_waiting', 'omni_channel_chat_session_processing']
    }),
    getSoftPhoneToken: builder.mutation<ResponseDto<GetSoftPhoneTokenResponseDto>, GetWorldPhoneTokenDto>({
      query: (getSoftPhoneTokenDto) => ({
        url: '/omni_channel/get_soft_phone_token',
        method: 'post',
        data: getSoftPhoneTokenDto
      })
    }),
    getAgentStatus: builder.query<ResponseDto<AgentStatusDto[]>, GetAgentStatusDto>({
      query: ({ ...params }) => ({
        url: '/omni_channel/agent_status',
        method: 'GET',
        params
      })
    }),
    unpauseQueueAgent: builder.mutation<ResponseDto<QueueMemberResponseDto>, QueueMemberDto>({
      query: (getSoftPhoneTokenDto) => ({
        url: '/omni_channel/unpause_queue',
        method: 'post',
        data: getSoftPhoneTokenDto
      })
    }),

    pauseQueueAgent: builder.mutation<ResponseDto<QueueMemberResponseDto>, QueueMemberDto>({
      query: (getSoftPhoneTokenDto) => ({
        url: '/omni_channel/pause_queue',
        method: 'post',
        data: getSoftPhoneTokenDto
      })
    }),
    listQueueName: builder.query<ResponseDto<QueueNameDto[]>, void>({
      query: (listQueueName) => ({
        url: '/omni_channel/list_queue_name',
        method: 'get',
        data: listQueueName
      })
    }),
    listQueue: builder.query<ResponseDto<ListQueueDto[]>, void>({
      query: (listQueueName) => ({
        url: '/omni_channel/list_queue',
        method: 'get',
        data: listQueueName
      })
    }),
    addQueueMember: builder.mutation<ResponseDto<AddAndRemoveQueueMemberResponseDto[]>, AddAndRemoveQueueMemberDto>({
      query: (listQueueName) => ({
        url: '/omni_channel/add_queue_member',
        method: 'post',
        data: listQueueName
      })
    }),
    removeQueueMember: builder.mutation<ResponseDto<AddAndRemoveQueueMemberResponseDto[]>, AddAndRemoveQueueMemberDto>({
      query: (listQueueName) => ({
        url: '/omni_channel/remove_queue_member',
        method: 'post',
        data: listQueueName
      })
    }),
    getCallDetails: builder.query<ResponseDto<GetCallDetailsDto[]>, void>({
      query: (details) => ({
        url: '/omni_channel/get_call_details',
        method: 'get'
      })
    }),
    getWaitingCalls: builder.query<ResponseDto<GetWaitingCallsDto>, void>({
      query: (details) => ({
        url: '/omni_channel/get_waiting_calls',
        method: 'get'
      })
    }),
    countChatSession: builder.query<ResponseDto<CountChatSessionResultDto>, CountChatSessionDto>({
      query: ({ ...params }) => ({
        url: '/omni_channel/count_chat_session',
        method: 'get',
        params
      })
    }),
    assignChatSession: builder.mutation<ResponseDto<ChatSessionDto>, AssignChatSessionDto>({
      query: (data) => ({
        url: '/omni_channel/assign_chat_session',
        method: 'post',
        data: data
      }),
      invalidatesTags: ['omni_channel_chat_session_waiting', 'omni_channel_chat_session_processing']
    }),
    getAgents: builder.query<ResponsePagingDto<AgentDto>, FindWithKeywordDto>({
      query: ({ endpoint, ...params }) => ({
        url: '/omni_channel/agents',
        method: 'get',
        params
      }),
      providesTags: ['omni_channel_agents']
    }),
    likeComment: builder.mutation<ResponseDto<OmniChannelMessageDto>, number>({
      query: (messageId) => ({
        url: `/omni_channel/like_comment/${messageId}`,
        method: 'post'
      })
    }),
    removeComment: builder.mutation<ResponseDto<OmniChannelMessageDto>, number>({
      query: (messageId) => ({
        url: `/omni_channel/remove_comment/${messageId}`,
        method: 'post'
      })
    }),
    hideComment: builder.mutation<ResponseDto<OmniChannelMessageDto>, HideCommentDto>({
      query: ({ messageId, ...data }) => ({
        url: `/omni_channel/hide_comment/${messageId}`,
        method: 'post',
        data
      })
    }),
    createChatMessenger: builder.mutation<ResponseDto<ChatSessionDto>, number>({
      query: (chatSessionId) => ({
        url: `/omni_channel/create_chat_session_messenger/${chatSessionId}`,
        method: 'post'
      })
    }),

    getChatAgentStatus: builder.query<ResponseDto<ChatAgentStatusDto>, void>({
      query: () => ({
        url: '/omni_channel/chat_agent_status',
        method: 'get'
      }),
      providesTags: ['omni_channel_chat_agent_status']
    }),

    updateChatAgentStatus: builder.mutation<ResponseDto<ChatAgentStatusDto>, Pick<ChatAgentStatusDto, 'status'>>({
      query: (data) => ({
        url: '/omni_channel/chat_agent_status',
        method: 'put',
        data
      }),
      invalidatesTags: ['omni_channel_chat_agent_status']
    })
  })
});

async function addMetadataToOmniMessages(rows: OmniChannelMessageDto[]) {
  return await Promise.all(
    rows.map(async (row) => {
      if (row.content && row.content.length > 0) {
        return await addMetadataOmniMessage(row);
      }
      return row;
    })
  );
  // return dummyOmniChannelMessages;
}

async function addMetadataOmniMessage(message: OmniChannelMessageDto) {
  const urls = extractUrls(message.content ?? '');
  if (urls.length > 0) {
    const metadataTag = await fetchMetadata(urls[0]);
    if (metadataTag) {
      const metadata = convertMetadata(metadataTag);
      return { ...message, metadata };
    }
  }
  return message;
}

function addMessageOmniChat(data: OmniChannelMessageDto, endpointName: any, arg?: Partial<FindAllOmniMessagesDto>) {
  return (dispatch: AppDispatch) => {
    dispatch(
      omniChannelApi.util.updateQueryData(endpointName, arg, (draftData: any) => {
        let currentRows = [...draftData.data.rows];
        currentRows = currentRows.length >= MESSAGE_LIMIT_CURRENT ? currentRows.slice(MESSAGE_LIMIT_LOAD) : currentRows;

        currentRows.push(data);
        draftData.data.count += 1;
        draftData.data.rows = currentRows;
      })
    );
  };
}

function updateMessageOmniChat(
  data: OmniChannelMessageDto,
  tempMessageId: number,
  endpointName: any,
  arg?: Partial<FindAllOmniMessagesDto>
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      omniChannelApi.util.updateQueryData(endpointName, arg, (draftData: any) => {
        const idx = findIndex(draftData.data.rows || [], {
          messageId: tempMessageId
        });
        if (idx !== -1) {
          draftData.data.rows[idx] = {
            ...data
          };
        }
      })
    );
  };
}

function resetMessageOmniChat(endpointName: any, arg?: Partial<FindAllOmniMessagesDto>) {
  return (dispatch: AppDispatch) => {
    dispatch(
      omniChannelApi.util.updateQueryData(endpointName, arg, (draftData: any) => {
        draftData.data.count = 0;
        draftData.data.rows = [];
      })
    );
  };
}

export { resetMessageOmniChat, addMessageOmniChat, addMetadataOmniMessage, updateMessageOmniChat };
export const {
  useLazyGetChatSessionsProcessingQuery,
  useLazyGetChatSessionsWaitingQuery,
  useGetChatSessionsWaitingQuery,
  useGetChatSessionsProcessingQuery,
  useReceiveChatSessionMutation,
  useCompleteChatSessionMutation,
  useLazyGetOmniChatMessagesQuery,
  useReadOmniChatMessagesMutation,
  useSendOmniChatMessageMutation,
  useGetGroupCallsQuery,
  useGetFanpagesQuery,
  useGetGatewayConfigQuery,
  useGetSoftPhoneTokenMutation,
  useGetAgentStatusQuery,
  useLazyGetAgentStatusQuery,
  useUnpauseQueueAgentMutation,
  usePauseQueueAgentMutation,
  useLazyListQueueQuery,
  useLazyListQueueNameQuery,
  useAddQueueMemberMutation,
  useRemoveQueueMemberMutation,
  useListQueueNameQuery,
  useLazyGetCallDetailsQuery,
  useGetWaitingCallsQuery,
  useLazyCountChatSessionQuery,
  useAssignChatSessionMutation,
  useGetAgentsQuery,
  useLikeCommentMutation,
  useRemoveCommentMutation,
  useHideCommentMutation,
  useCreateChatMessengerMutation,
  useUpdateChatAgentStatusMutation,
  useGetChatAgentStatusQuery
} = omniChannelApi;
