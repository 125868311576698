import { CheckOutlined } from '@ant-design/icons';
import { Button, List, notification, Skeleton, Spin, Typography } from 'antd';
import { Avatar, Empty, Modal, message } from 'components/common';
import dayjs from 'dayjs';
import { useAllShiftsOptions, useGetShiftMeActive } from 'hooks';
import { useElectricalRecordingSchedulePaging } from 'hooks/customer-support-information';
import { find } from 'lodash';
import { messages, shiftsMessages } from 'messages';
import { useEffect, useState } from 'react';
import { useActiveShiftMutation, useInactiveShiftMutation, useUpdateActiveShiftMutation } from 'services';
import { twMerge } from 'tailwind-merge';
import { ResponseShiftOptionDto, ShiftOptionDto } from 'types';
import { LOCAL_STORAGE_KEY, WEEKDAY_FORMAT } from 'utils';
import { getShiftDate } from 'utils/helpers';

type SelectedShift = {
  shiftId: number;
  selectedDate: Date;
};

const ActiveShiftsModal = () => {
  const {
    isOpen,
    shiftMeActive,
    isLoading: loadingShiftMeActive,
    handleClose,
    isRequiredShift,
    hasSelectShiftPermission,
    handleRequiredShift
  } = useGetShiftMeActive();

  const { shiftsOptions, isLoading, refetch } = useAllShiftsOptions();
  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  const [inactiveShift, { isLoading: isLoadingInactive }] = useInactiveShiftMutation();
  const [activeShift, { isLoading: isLoadingActive }] = useActiveShiftMutation();
  const [updateActiveShift, { isLoading: isUpdateLoadingActive }] = useUpdateActiveShiftMutation();

  const [selectedShift, setSelectedShift] = useState<SelectedShift | undefined>();

  const handleActiveShift = async () => {
    if (
      !shiftsOptions ||
      Object.values(shiftsOptions)
        .flat()
        .filter((o) => o.isValid).length === 0
    ) {
      if (shiftMeActive) {
        await inactiveShift();
      }
      localStorage.setItem(LOCAL_STORAGE_KEY.SHIFT_ACTIVE, 'active');
      message.systemSuccess(shiftsMessages.startWithoutShift);
      handleClose();
      handleRequiredShift(false);
      return;
    }
    if (selectedShift) {
      const data = {
        shiftId: selectedShift.shiftId,
        selectedDate: selectedShift.selectedDate
      };
      if (shiftMeActive) {
        updateActiveShift(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            handleClose();
          });
      } else {
        activeShift(data)
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            handleClose();
          });
      }
    } else {
      notification.error({ message: shiftsMessages.pleaseSelectShift });
    }
  };

  useEffect(() => {
    if (!shiftsOptions) return;

    let keyFound = shiftMeActive
      ? Object.entries(shiftsOptions).find(([_, shifts]) =>
          shifts.some((o) => o.isValid && o.shiftId === shiftMeActive.shiftId)
        )
      : undefined;

    let shiftAvailable = keyFound
      ? keyFound[1].find((o) => o.isValid && o.shiftId === shiftMeActive?.shiftId)
      : undefined;

    if (!shiftAvailable) {
      keyFound = Object.entries(shiftsOptions).find(([_, shifts]) => shifts.some((o) => o.isValid));
      shiftAvailable = keyFound ? keyFound[1].find((o) => o.isValid) : undefined;
    }

    if (keyFound && shiftAvailable) {
      setSelectedShift({
        shiftId: shiftAvailable.shiftId,
        selectedDate: getShiftDate(keyFound[0] as keyof ResponseShiftOptionDto).toDate()
      });
    }
  }, [isOpen, shiftsOptions]);

  return (
    <Modal
      title={shiftsMessages.activeShift}
      maskClosable={false}
      centered
      width={568}
      open={(isOpen || (isRequiredShift && !shiftMeActive)) && !loadingShiftMeActive && hasSelectShiftPermission}
      zIndex={2000}
      onCancel={handleClose}
      destroyOnClose
      footer={[
        <Button
          loading={isLoadingActive || loadingShiftMeActive || isUpdateLoadingActive || isLoadingInactive}
          type='primary'
          onClick={handleActiveShift}
          key='btn-submit-shift'
          className='w-[101px]'
        >
          {messages.saveButtonText}
        </Button>
      ]}
      styles={{
        body: {
          padding: '32px 24px 32px 24px',
          maxHeight: 'calc(100vh - 240px)',
          overflowY: 'auto'
        }
      }}
      closable={!isRequiredShift || !!shiftMeActive}
    >
      {isLoading && !shiftsOptions ? (
        <Skeleton
          className='w-full'
          title={false}
          paragraph={{
            rows: 5,
            width: '100%'
          }}
        />
      ) : (
        <Spin
          spinning={isLoading || isLoadingActive || loadingShiftMeActive || isUpdateLoadingActive || isLoadingInactive}
        >
          <div className='-mt-8 flex flex-col'>
            {shiftsOptions ? (
              Object.keys(shiftsOptions).map((dateKey) => {
                const date = dateKey as keyof ResponseShiftOptionDto;
                const listOptions = shiftsOptions[date];
                if (listOptions.length === 0) return undefined;
                return (
                  <div key={date}>
                    <Typography.Paragraph className='mb-3 mt-8 text-sm font-semibold'>
                      {getShiftDate(date).format(WEEKDAY_FORMAT)}
                    </Typography.Paragraph>
                    <List
                      dataSource={listOptions}
                      locale={{
                        emptyText: <Empty />
                      }}
                      className='-ml-3 w-[calc(100%+24px)]'
                      renderItem={(shift) => (
                        <List.Item
                          onClick={() =>
                            shift.isValid &&
                            setSelectedShift({ shiftId: shift.shiftId, selectedDate: getShiftDate(date).toDate() })
                          }
                          key={shift.shiftId}
                          className={twMerge(
                            'h-[62px] cursor-pointer rounded-base px-3 transition-all',
                            !shift.isValid ? 'cursor-not-allowed opacity-30' : 'hover:bg-hoverColor1',
                            shift.shiftId === selectedShift?.shiftId &&
                              getShiftDate(date).isSame(dayjs(selectedShift?.selectedDate), 'day')
                              ? 'border border-colorPrimaryActive bg-colorPrimaryBg hover:bg-colorPrimaryBg'
                              : 'border border-transparent'
                          )}
                          style={{
                            borderBlockEnd:
                              shift.shiftId === selectedShift?.shiftId &&
                              getShiftDate(date).isSame(dayjs(selectedShift?.selectedDate), 'day')
                                ? 'inherit'
                                : ''
                          }}
                        >
                          <List.Item.Meta
                            className='items-center'
                            avatar={
                              <Avatar
                                shape='square'
                                className='text-mainTextColor'
                                avatarBackground='transparent'
                                fileId={shift.icon}
                                size={42}
                              />
                            }
                            title={`${shift.code} - ${shift.name}`}
                            description={
                              <span className='text-sm text-subTextColor'>
                                {shiftsMessages.time}: {shift.fromTime} - {shift.toTime}
                              </span>
                            }
                          />
                          <div
                            className={twMerge(
                              'flex h-8 w-8 items-center justify-center rounded-full border border-[#ADADAD] text-[#ADADAD]',
                              shift.shiftId === selectedShift?.shiftId &&
                                getShiftDate(date).isSame(dayjs(selectedShift?.selectedDate), 'day')
                                ? 'bg-colorPrimaryActive text-white'
                                : ''
                            )}
                          >
                            <CheckOutlined />
                          </div>
                        </List.Item>
                      )}
                    />
                  </div>
                );
              })
            ) : (
              <div>
                <Empty description={shiftsMessages.notHasShiftValid} />
              </div>
            )}
          </div>
        </Spin>
      )}
    </Modal>
  );
};
export default ActiveShiftsModal;
