import { useRemoveQueueMemberMutation } from 'services';
import { AddAndRemoveQueueMemberDto } from 'types';

export function useRemoveQueueMember() {

    const [removeQueueMember, { isLoading, error }] = useRemoveQueueMemberMutation();

    const onRemoveQueueMember = async (values: AddAndRemoveQueueMemberDto) => {
        try {
            return await removeQueueMember(values).unwrap();
        } catch (err) {
            console.error("Error in onRemoveQueueMember:", err);
            throw err;
        }
    };

    return {
        isLoading,
        error,
        onRemoveQueueMember,
    };
}

