import { OmniChatBox } from 'components/common';
import { useOmniChatContext } from 'contexts';
import { useOmniChatReplyMessage } from 'hooks/omni-chat/useOmniChatReplyMessage';
import { useEffect } from 'react';
import { FileUpload } from 'types';

type ChatBoxProps = {};

const OmniChatInput: React.FC<ChatBoxProps> = () => {
  const { chatSession, onSentMessage } = useOmniChatContext();
  const { messageReplied, handleRemoveOmniReplyMessage } = useOmniChatReplyMessage();

  useEffect(() => {
    return () => {
      handleRemoveOmniReplyMessage();
    };
  }, []);

  const handleSubmit = async (content: string, files: FileUpload[]) => {
    onSentMessage(content, files);
  };

  return (
    <div className='bg-white transition-all'>
      <OmniChatBox
        chatSession={chatSession}
        onSend={handleSubmit}
        messageReplied={messageReplied}
        removeReplyMessage={handleRemoveOmniReplyMessage}
      />
    </div>
  );
};

export default OmniChatInput;
