import { useGetCustomerNameByPhoneNumberQuery } from "services/tickets";

export function useCustomerNameByPhoneNumber(phoneNumber: string) {
    const { data: customerNameByPhoneNumberRes, isLoading } = useGetCustomerNameByPhoneNumberQuery(phoneNumber!, {
        skip: !phoneNumber,
        refetchOnMountOrArgChange: true
    });

    return {
        customerNameByPhoneNumber: customerNameByPhoneNumberRes?.data,
        isLoading
    };
}