import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessDtvManagementRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_MANAGE_DTV]} />,
    children: [
      {
        path: ROUTE.ACCESS_DTV_MANAGEMENT,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/AccessDtvManagement'))
          }
        ]
      },
      {
        path: ROUTE.TRACKING_TICKETS_PROCESSING,
        Component: lazy(() => import('pages/TrackingTicketsProcessing'))
      },
    ]
  }
];
