import { usePaging } from 'hooks/usePaging';
import { useCallback } from 'react';
import { useGetCustomersByFullTextLoadmoreQuery } from 'services';
import { FulltextSearchCustomerDto } from 'types/dto/customer-lookup';
import { DEFAULT_PAGE_SIZE } from 'utils';

const pageSize = DEFAULT_PAGE_SIZE;

export function useCustomerFulltextLoadmore(params: Omit<FulltextSearchCustomerDto, 'pageIndex' | 'pageSize'>) {
  const { pageIndex, nextPage, resetPage } = usePaging(params);

  const {
    data: customersResponse,
    error,
    isFetching,
    isLoading
  } = useGetCustomersByFullTextLoadmoreQuery({ ...params, pageIndex: pageIndex, pageSize: pageSize });

  const { rows, count } = customersResponse?.data || {};

  const hasMore = Boolean(rows && count && rows.length < count);
  const handleLoadMore = useCallback(() => {
    if (hasMore && !isFetching) {
      nextPage();
    }
  }, [hasMore, pageIndex]);

  return {
    customers: customersResponse?.data.rows || [],
    count: customersResponse?.data.count || 0,
    isLoading: isFetching || isLoading,
    pageIndex,
    handleLoadMore,
    resetPage,
    error
  };
}
