import React, { useState } from 'react';
import { Typography, Button, Avatar as AntdAvatar, Card, Select, DatePicker, Checkbox } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { BlueArrow } from 'assets';
import { ticketDetailedMessages } from 'messages/ticket-detailed.messages';
import Avatar from 'components/common/avatar/Avatar';
import dayjs from 'dayjs';
import { TicketDetail } from 'types';
import progressIcon from '../../assets/svg/progressing-setting.svg';
import { requestform } from 'messages';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { GetReasonOutageService, GetUserListInOrganization } from '../../hooks/request-form';

interface RequestDetailProcessingProps {
  requestItem: TicketDetail | null;
  processingContent: string;
  setProcessingContent: (value: string) => void;
  setAssignUserInUnit?: (value: number) => void;
  setOptionId?: (value: number) => void;
  setDatePowerRestore?: (value: string) => void;
  setTimePowerRestore?: (value: string) => void;
  setIsPowerRestore?: (value: boolean) => void;
}
//Function
const EventItem: React.FC<{ event: any; orgName?: string }> = ({ event, orgName }) => (
  <div className='relative mx-4 flex items-center justify-between border-l pb-10'>
    <div className='absolute -left-4 top-[20%] rounded-full border bg-white p-2'>
      <BlueArrow />
    </div>
    <div className='flex w-full items-center justify-between pt-8'>
      {/*Content*/}
      <div className='mx-6 w-full'>
        <Typography.Text className='text-[16px]/[18px] font-semibold text-[#141414]'>
          {event.createdByName}
        </Typography.Text>{' '}
        <Typography.Text>{event?.actionType?.name}</Typography.Text>
        <br />
        <div className='text-[14px] italic text-[#141414]'>
          {formatEventDate(event.createdAt)}
          {orgName}
        </div>
        <div className='mt-4 max-h-[100px] overflow-auto rounded-[5px] border bg-[#F7F8F9] p-4 text-[16px]/[20px] text-[#141414]'>
          {event.currentContent}
        </div>
      </div>

      {/*Avatar */}
      <Avatar name={event.createdByName} size={32} />
    </div>
  </div>
);
const formatEventDate = (date?: string) => {
  if (!date) return '';
  const formatted = dayjs(date).format('DD [tháng] MM, YYYY - HH:mm');
  const [datePart, timePart] = formatted.split('-');
  return (
    <>
      {timePart}
      <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text> {datePart}
      <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
    </>
  );
};

//Main
const RequestDetailProcessing: React.FC<RequestDetailProcessingProps> = ({
  requestItem,
  processingContent,
  setProcessingContent,
  setAssignUserInUnit,
  setOptionId,
  setDatePowerRestore,
  setTimePowerRestore,
  setIsPowerRestore
}) => {
  const [isShow, setIsShow] = useState(false);
  const location = useLocation().pathname;

  const profile = useSelector((state: RootState) => state.users.userProfile);
  const { userInUnitCount, userInUnitList } = GetUserListInOrganization(
    profile?.organizationUnitId ? profile?.organizationUnitId : 0
  );
  //16: optionTypeId => outage
  const { optionsList, optionsCount, optionLoading } = GetReasonOutageService(16);

  const events = requestItem?.ticketEvents || [];
  const hasMultipleEvents = events.length > 1;

  const handleDatePowerRestoreChange = (value: dayjs.Dayjs | null) => {
    if (value) {
      const formattedDate = value.format('DD/MM/YYYY');
      const formattedTime = value.format('HH:mm');
      setDatePowerRestore?.(formattedDate);
      setTimePowerRestore?.(formattedTime);
    } else {
      setDatePowerRestore?.('');
      setTimePowerRestore?.('');
    }
  };

  const handlePowerRestoreChange = (checked: boolean) => {
    setIsPowerRestore?.(checked);
  };

  return (
    <Card className='border-none'>
      <div className='flex items-center space-x-3'>
        <img src={progressIcon} alt='' />
        <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
          {requestform.processingTitle}
        </Typography.Text>
      </div>

      {/*Power Outage and Restored*/}
      {requestItem?.serviceType.code === 'BMD' && requestItem?.status.code !== 'CHOTIEPNHAN' && (
        <div className='space-y-2 py-5'>
          <div className='flex w-full items-center space-x-3'>
            <div className='w-1/5'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.reasonOutage}
              </Typography.Text>
            </div>

            <Select
              loading={optionLoading}
              className='w-full'
              defaultValue={
                requestItem?.additionalInfo?.outageReasonId != null
                  ? optionsList.find((option) => option.optionId === requestItem.additionalInfo.outageReasonId)
                      ?.optionId || optionsList[0]?.optionId
                  : optionsList[0]?.optionId
              }
              style={{ width: 200 }}
              onChange={(value) => setOptionId && setOptionId(value)}
              options={[
                ...optionsList.map((option) => ({
                  value: option.optionId,
                  label: option.code + ' ' + option.name
                }))
              ]}
            />
          </div>

          <div className='flex w-full items-center space-x-3'>
            <div className='w-1/5'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>{requestform.timeOutage}</Typography.Text>
            </div>

            <div className='flex w-full items-center justify-between'>
              <Typography.Text className='text-[16px]/[16px] text-[#000000]'>
                {requestItem?.additionalInfo?.outageAt
                  ? dayjs(requestItem.additionalInfo.outageAt).format('DD/MM/YYYY HH:mm')
                  : ''}
              </Typography.Text>

              <div>
                <div className='flex items-center space-x-3'>
                  <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                    {requestform.datePowerRestored}
                  </Typography.Text>
                  <DatePicker
                    defaultValue={dayjs()}
                    format='DD/MM/YYYY HH:mm'
                    onChange={handleDatePowerRestoreChange}
                    placeholder='Ngày giờ có điện'
                  />
                </div>
              </div>
            </div>
          </div>

          {requestItem?.status.code !== 'HOANTAT' && (
            <Checkbox onChange={(e) => handlePowerRestoreChange(e.target.checked)}>
              <Typography.Text className='text-[16px]/[16px] text-[#000000]'>
                {requestform.isPowerRestore}
              </Typography.Text>
            </Checkbox>
          )}
        </div>
      )}

      {/*Assign Employee */}
      {(requestItem?.status.code !== 'CHOTIEPNHAN' &&
        requestItem?.status.code !== 'HOANTAT' &&
        location.includes('request-form')) ||
      location.includes('transfer-') ? (
        <div className='flex w-full items-center space-x-3 pb-5'>
          <div className='w-1/5'>
            <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>{requestform.employee}</Typography.Text>
          </div>

          <Select
            className='w-full'
            defaultValue={0}
            style={{ width: 200 }}
            onChange={(value) => setAssignUserInUnit && setAssignUserInUnit(value)}
            options={[
              { value: 0, label: 'Chọn nhân viên' },
              ...userInUnitList
                .filter((user) => user.userId !== profile?.userId)
                .map((user) => ({
                  value: user.userId,
                  label: user.fullName
                }))
            ]}
          />
        </div>
      ) : (
        ''
      )}

      {/* Timeline */}
      <div>
        {(requestItem?.status.code !== 'CHOTIEPNHAN' &&
          requestItem?.status.code !== 'HOANTAT' &&
          location.includes('request-form')) ||
        location.includes('transfer-') ? (
          <>
            <TextArea
              placeholder='Nội dung phản hồi'
              autoSize={{ minRows: 5, maxRows: 10 }}
              className='rounded-lg bg-[#F7F8F6]'
              value={processingContent}
              onChange={(e) => setProcessingContent(e.target.value)}
            />
          </>
        ) : (
          ''
        )}

        {events.length > 0 && (
          <>
            {(isShow ? events : [events[0]]).map((event, index) => (
              <EventItem key={index} event={event} orgName={event.organizationUnitName} />
            ))}

            {hasMultipleEvents && !isShow && (
              <div className='relative flex items-center'>
                <AntdAvatar.Group>
                  {events.slice(1).map((event, index) => (
                    <AntdAvatar
                      key={index}
                      src={<Avatar name={event.createdByName} size={32} />}
                      size={32}
                      alt={`Avatar ${index + 1}`}
                      className={`rounded-full border-2 border-white ${index !== 0 ? '-ml-5' : ''}`}
                    />
                  ))}
                </AntdAvatar.Group>
                <Button
                  className='text border-none bg-transparent text-[#1564E8] shadow-none'
                  onClick={() => setIsShow(true)}
                >
                  {ticketDetailedMessages.buttonShowAllHistoryProcess}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default RequestDetailProcessing;
