import { useState } from 'react';
import { useLazyGetFileQuery } from 'services';
import { fileServerDownload } from 'utils';
export function useDownloadFile() {
  const [loading, setLoading] = useState(false);

  const [fetchFile, { isLoading: isLoadingFetchFile }] = useLazyGetFileQuery();

  const downloadFile = async (fileId?: number) => {
    if (fileId) {
      setLoading(true);
      const fileResponse = await fetchFile(fileId);
      if (fileResponse.data?.data) {
        await fileServerDownload(fileResponse.data?.data);
        setLoading(false);
      }
    }
  };
  return { downloadFile, isLoading: isLoadingFetchFile || loading };
}
