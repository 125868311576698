import { findIndex } from 'lodash';
import { FabricAgentMapOtherObject, FabricCanvasAgentObject } from 'types/fabric-agent-map';
import * as fabric from 'fabric';

export const modifiedText = (
  object: FabricCanvasAgentObject,
  objects: FabricAgentMapOtherObject[],
  canvas: fabric.Canvas
): FabricAgentMapOtherObject[] => {
  const newObjectsArr = [...objects];
  if (object) {
    const idxTarget = findIndex(newObjectsArr, { id: object.id });

    const angle = Math.round(object.angle / 10) * 10;
    object.set({
      angle
    });

    if (idxTarget !== -1) {
      newObjectsArr[idxTarget] = {
        ...newObjectsArr[idxTarget],
        left: object.left,
        top: object.top,
        width: object.width,
        height: object.height,
        angle,
        scaleX: object.scaleX,
        scaleY: object.scaleY
      };
    }
    canvas.requestRenderAll();
  }
  return newObjectsArr;
};
