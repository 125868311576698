import * as fabric from 'fabric';
import { FabricAgentMapOtherObject } from 'types/fabric-agent-map';
import { generateUUID } from 'utils/common';
import { AGENT_MAP_OBJECT_TYPE } from 'utils/enums';
const maxWidth = 256; // Đặt max width tối đa

export const addText = (
  canvas: fabric.Canvas,
  otherObject: Partial<FabricAgentMapOtherObject>
): FabricAgentMapOtherObject => {
  const generateId = generateUUID();

  const textBox = new fabric.Textbox(otherObject.name ?? 'Text', {
    left: otherObject.left ?? 0,
    top: otherObject.top ?? 0,
    fill: '#141414',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'inter',
    angle: otherObject.angle ?? 0,
    width: otherObject.width ?? maxWidth,
    height: otherObject.height ?? 0,
    scaleX: otherObject.scaleX ?? 1,
    scaleY: otherObject.scaleY ?? 1,
    splitByGrapheme: true
  });

  textBox.set({
    id: otherObject.id ?? generateId,
    objectTypeId: AGENT_MAP_OBJECT_TYPE.TEXT
  });

  textBox.on('changed', () => {});
  canvas.add(textBox);
  if (!otherObject.id) {
    canvas.setActiveObject(textBox);
  }
  canvas.renderAll();

  return {
    left: textBox.left,
    top: textBox.top,
    width: textBox.width,
    height: textBox.height,
    id: otherObject.id ?? generateId,
    ...otherObject,
    objectTypeId: AGENT_MAP_OBJECT_TYPE.TEXT
  };
};
