import { CheckPermissionLayout, PermissionLayout, UserLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessUserManagementRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_MANAGE_ACCOUNT]} />,
    children: [
      {
        element: <UserLayout />,
        children: [
          {
            path: ROUTE.USERS,
            element: <CheckPermissionLayout permission={[PERMISSION.READ_USER]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/Users'))
              }
            ]
          },

          {
            path: ROUTE.USER_GROUPS,
            element: <CheckPermissionLayout permission={[PERMISSION.READ_USER_GROUP]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/UserGroups'))
              }
            ]
          }
        ]
      },
      {
        path: `${ROUTE.USERS}/:userId`,
        Component: lazy(() => import('pages/User'))
      },
      {
        element: <PermissionLayout />,
        children: [
          {
            path: ROUTE.ROLES,
            element: <CheckPermissionLayout permission={[PERMISSION.READ_ROLE]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/Roles'))
              }
            ]
          },
          {
            path: ROUTE.PERMISSIONS,
            element: <CheckPermissionLayout permission={[PERMISSION.UPDATE_PERMISSION]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/Permissions'))
              }
            ]
          }
        ]
      }
    ]
  }
];
