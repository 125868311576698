import { notification } from 'antd';
import { DownloadIcon, HourGlassFlipIcon, NoticeCircleIcon, NotificationColoredIcon } from 'assets';
import { notificationsMessages } from 'messages';
import { ROUTE } from 'routes/constants';
import { NotificationDto } from 'types';
import { NOTIFICATION_STATUS } from './enums';
import { convertTimeLeft } from './common';

export const showNewNotification = (notif: NotificationDto) => {
  notification.info({
    message: notificationsMessages.newNotif,
    description: notif.content,
    icon: <NotificationColoredIcon />,
    onClick: () => {
      window.location.href = `${ROUTE.CUSTOMER_LOOKUP}?IA=${notif.refId}`;
    },
    key: notif.notificationId
  });
};

export const showNewNotificationFileDownload = (notif: NotificationDto, onClick?: (data: NotificationDto) => void) => {
  switch (notif.status) {
    case NOTIFICATION_STATUS.ERROR: {
      notification.error({
        message: `${notificationsMessages.notifErrorTitle}`,
        description: notificationsMessages.notifErrorDescription,
        icon: <NoticeCircleIcon />,
        key: notif.notificationId
      });
      break;
    }

    case NOTIFICATION_STATUS.PROCESSING: {
      notification.info({
        message: `${notificationsMessages.notifProcessingTitle}`,
        description: notificationsMessages.notifProcessingDescription,
        icon: <HourGlassFlipIcon className='h-10 w-10 text-colorBgSuccess' />,
        key: notif.notificationId
      });
      break;
    }

    default: {
      notification.info({
        message: `${notificationsMessages.notifDownloadTitle}`,
        description: notificationsMessages.notifDownloadDescription(
          notif.expiredAt ? convertTimeLeft(notif.expiredAt) : '48h'
        ),
        icon: <DownloadIcon />,
        onClick: () => {
          notification.destroy();
          onClick?.(notif);
        },
        key: notif.notificationId
      });
      break;
    }
  }
};
