export const rolesMessages = {
  page: 'Nhóm quyền',
  name: 'Tên nhóm quyền',
  permissions: 'Quyền',
  code: 'Mã quản lý',
  description: 'Mô tả',
  update: 'Cập nhật nhóm quyền',
  create: 'Tạo nhóm quyền',
  title: 'Nhóm quyền',
  edit: 'Sửa',
  rolePermissions: 'Quyền trong nhóm',
  general: 'Thông tin chung',
  editPermission: 'Phân quyền',
  scope: 'Phạm vi áp dụng',
  allOrganizationUnits: 'Tất cả đơn vị',
  listRolePermission: 'Danh sách nhóm quyền',
  updateScopeTitle: 'Cập nhật phạm vi áp dụng',
  scopeTooltip: 'Những đơn vị được phân nhóm quyền này cho người dùng',
  delete: 'Xóa nhóm quyền',
  confirmDelete: 'Bạn có chắc chắn muốn xóa nhóm quyền này không?'
};
