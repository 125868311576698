import React from 'react';
import { Divider, Typography } from 'antd';
import { requestform } from 'messages';
import { TicketDetail } from 'types';
import customerRequest from '../../assets/svg/customer-request.svg';
import img from '../../assets/images/logo.png';
import { conversation } from 'assets';
import { ServerFileList } from 'components/common';
interface ticketCreateInformation {
  requestItem: TicketDetail | null;
}

const RequestDetailMainInformation: React.FC<ticketCreateInformation> = ({ requestItem }) => {
  const fieldValues = requestItem?.files?.map((file) => file.fileId) || [];
  return (
    <div className='max-w-4xl'>
      {/*Customer Information - Thong tin khach hang */}
      <div>
        <div className='flex items-center space-x-3'>
          <img src={img} className='h-8 w-8' alt='' />
          <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
            {requestform.customerInformationTitle}
          </Typography.Text>
        </div>
        <div className='space-y-2 pt-[20px]'>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerCode}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customer?.customerCode ? requestItem?.customer.customerCode : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerName}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customer?.customerName ? requestItem?.customer.customerName : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerPhone}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customer?.customerPhoneNumber ? requestItem?.customer?.customerPhoneNumber : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerAddress}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customer?.customerAddress ? requestItem?.customer?.customerAddress : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.meterSerialNumber}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customer?.meterSerialNumber ? requestItem?.customer?.meterSerialNumber : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>{requestform.routeCode}</Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customer?.routeCode ? requestItem?.customer?.routeCode : ''}
            </Typography.Text>
          </div>
        </div>
      </div>

      {/*Request Information - Thong tin nguoi yeu cau */}
      <Divider className='mt-[20px] bg-[#D4D8DE]' />
      <div>
        <div className='flex items-center space-x-3'>
          <img src={customerRequest} alt='' />
          <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
            {requestform.customerRequestInformationTitle}
          </Typography.Text>
        </div>
        <div className='space-y-2 pt-[20px]'>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestName}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestName ? requestItem.customerRequestName : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestPhone}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestPhoneNumber ? requestItem.customerRequestPhoneNumber : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.numberOfCalls}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.numOfRequests ? requestItem.numOfRequests : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestAddress}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestAddress ? requestItem.customerRequestAddress : ''}
            </Typography.Text>
          </div>
        </div>

        <div className='space-y-2 pt-[20px]'>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestCIN}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestCicNumber ? requestItem.customerRequestCicNumber : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>{requestform.dateOfIssue}</Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestCicIssueDate ? requestItem.customerRequestCicIssueDate : ''}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.placeOfIssue}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestCicIssuePlace ? requestItem.customerRequestCicIssuePlace : ''}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      {/* File  */}
      <div>
        <Typography.Text className='text-[16px]/[16px] text-[#141414]'>File đính kèm</Typography.Text>
        <div className='mt-3 flex space-x-1'>
          <ServerFileList
            isPreview
            fileList={fieldValues}
            itemProps={{ className: 'max-w-[40px] h-[60px]', showInfo: false }}
          ></ServerFileList>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      {/* Request Content */}
      <div>
        <div className='flex items-center space-x-3'>
          <img src={conversation} alt='' />
          <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
            {requestform.requestContentTitle}
          </Typography.Text>
        </div>
        <div className='mt-[20px] rounded-lg bg-[#DDDDDD] p-3'>
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.content ? requestItem.content : ''}
          </Typography.Text>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      {/*Resolution */}
      {requestItem?.status.code === 'HOANTAT' && (
        <>
          <div>
            <div className='flex items-center space-x-3'>
              <img src={conversation} alt='' />
              <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
                {requestform.resolutionTitle}
              </Typography.Text>
            </div>

            <div className='mt-[20px] min-w-[800px] rounded-lg bg-[#DDDDDD] p-3'>
              <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
                {requestItem?.resolution ? requestItem.resolution : ''}
              </Typography.Text>
            </div>
          </div>
          <Divider className='mt-[20px] bg-[#D4D8DE]' />
        </>
      )}
    </div>
  );
};

export default RequestDetailMainInformation;
