import {
  useGetCmisServicesQuery,
  useGetCollaborationTicketQuery,
  useGetCompletedTicketQuery,
  useGetInProgressTicketQuery,
  useGetObstacleTicketQuery,
  useGetOptionDetailQuery,
  useGetOrganizatioUnitChildrenQuery,
  useGetOrganizatioUnitDetailServicesQuery,
  useGetPendingTicketQuery,
  useGetReasonOutageQuery,
  useGetTicketDetailQuery,
  useGetTicketListQuery,
  useGetUserInOrganizationUnitQuery
} from 'services';
import { CmisServicesRequestData, RequestData } from 'types';
import defaultTicketDetail from '../../types/dto/request-form/request-form.dto';

// GET Tickets based on statuss
export function GetPendingTicket(pageSize: number) {
  const {
    data: customerRes,
    isLoading,
    refetch
  } = useGetPendingTicketQuery(pageSize!, {
    skip: !pageSize,
    refetchOnMountOrArgChange: true
  });

  return {
    pending: customerRes?.data.rows || [],
    pendingCount: Number(customerRes?.data.count),
    refetch: refetch ?? (() => {}),
    isLoading
  };
}
export function GetInProgressTicket(pageSize: number) {
  const {
    data: customerRes,
    isLoading,
    refetch
  } = useGetInProgressTicketQuery(pageSize!, {
    skip: !pageSize,
    refetchOnMountOrArgChange: true
  });

  return {
    inprogress: customerRes?.data.rows || [],
    inprogressCount: Number(customerRes?.data.count),
    refetch: refetch ?? (() => {}),
    isLoading
  };
}
export function GetCompletedTicket(pageSize: number) {
  const {
    data: customerRes,
    isLoading,
    refetch
  } = useGetCompletedTicketQuery(pageSize!, {
    skip: !pageSize,
    refetchOnMountOrArgChange: true
  });

  return {
    completed: customerRes?.data.rows || [],
    completedCount: Number(customerRes?.data.count),
    refetch: refetch ?? (() => {}),
    isLoading
  };
}
export function GetCollaborationTicket(pageSize: number) {
  const {
    data: customerRes,
    isLoading,
    refetch
  } = useGetCollaborationTicketQuery(pageSize!, {
    skip: !pageSize,
    refetchOnMountOrArgChange: true
  });

  return {
    collaboration: customerRes?.data.rows || [],
    collaborationCount: Number(customerRes?.data.count),
    refetch: refetch ?? (() => {}),
    isLoading
  };
}
export function GetObstacleTicket(requestData: RequestData) {
  const {
    data: obstacleList,
    isLoading,
    refetch
  } = useGetObstacleTicketQuery(requestData!, {
    skip: !requestData,
    refetchOnMountOrArgChange: true
  });

  return {
    obstacle: obstacleList?.data.rows || [],
    obstacleCount: Number(obstacleList?.data.count),
    refetch: refetch ?? (() => {}),
    isLoading
  };
}

//Ticket Detail
export function GetTicketDetail(ticketId: number) {
  const {
    data: customerRes,
    isLoading,
    refetch
  } = useGetTicketDetailQuery(ticketId, {
    skip: !ticketId,
    refetchOnMountOrArgChange: true
  });

  return {
    requestDetail: customerRes?.data ?? defaultTicketDetail,
    refetch: refetch ?? (() => {}),
    isLoading
  };
}

// GET Tickets with conditional
export function GetTicketList(requestData: RequestData) {
  const {
    data: customerRes,
    isLoading,
    refetch
  } = useGetTicketListQuery(requestData!, {
    skip: !requestData,
    refetchOnMountOrArgChange: true
  });

  return {
    data: customerRes?.data.rows || [],
    dataCount: Number(customerRes?.data.count),
    refetch: refetch ?? (() => {}),
    isLoading
  };
}

export function GetOrganizationUnitChildren(organizationUnitId: number) {
  const { data: organizationUnitChildrenList, isLoading } = useGetOrganizatioUnitChildrenQuery(organizationUnitId!, {
    skip: !organizationUnitId,
    refetchOnMountOrArgChange: true
  });

  return {
    data: organizationUnitChildrenList?.data.rows || [],
    isLoading
  };
}

export function GetOrganizatioUnitDetailServicesQuery(organizationUnitId: number) {
  const { data: organizationUnitChildrenDetail, isLoading } = useGetOrganizatioUnitDetailServicesQuery(
    organizationUnitId!,
    {
      skip: !organizationUnitId,
      refetchOnMountOrArgChange: true
    }
  );

  return {
    data: organizationUnitChildrenDetail?.data.rows || [],
    isLoading
  };
}

export function GetOptionDetailQuery(optionTypeId: number) {
  const { data: optionList, isLoading } = useGetOptionDetailQuery(optionTypeId!, {
    skip: !optionTypeId,
    refetchOnMountOrArgChange: true
  });

  return {
    data: optionList?.data.rows || [],
    serviceCount: optionList?.data.count,
    isLoading
  };
}

export function GetCmisService(requestData: CmisServicesRequestData) {
  const { data: optionList, isLoading } = useGetCmisServicesQuery(requestData!, {
    skip: !requestData,
    refetchOnMountOrArgChange: true
  });

  return {
    cmisServiceCount: optionList?.data.count,
    cmisData: optionList?.data.rows || [],
    isLoading
  };
}

export function GetUserListInOrganization(organizationUnitId: number) {
  const { data: userInOrganizationUnit, isLoading } = useGetUserInOrganizationUnitQuery(organizationUnitId!, {
    skip: !organizationUnitId,
    refetchOnMountOrArgChange: true
  });

  return {
    userInUnitCount: userInOrganizationUnit?.data.count,
    userInUnitList: userInOrganizationUnit?.data.rows || [],
    isLoading
  };
}

export function GetReasonOutageService(optionTypeId: number) {
  const { data: options, isLoading } = useGetReasonOutageQuery(optionTypeId!, {
    skip: !optionTypeId,
    refetchOnMountOrArgChange: true
  });

  return {
    optionsCount: options?.data.count,
    optionsList: options?.data.rows || [],
    optionLoading: isLoading
  };
}
