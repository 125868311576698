import { Rule } from 'antd/es/form';
import { UserFormType } from 'components';
import { usersMessages } from 'messages';
import { PASSWORD_REG_EXP, PHONE_NUMBER_REG_EXP } from 'utils/constants';

export const userValidationRules: Partial<Record<keyof UserFormType, Rule[]>> = {
  fullName: [{ required: true }, { max: 50 }],
  shortName: [{ required: true }, { max: 50 }],
  genderId: [{ required: true }],
  username: [{ required: true }, { max: 255 }],
  password: [
    { required: true },
    { pattern: PASSWORD_REG_EXP, message: usersMessages.passwordMismatchPatern },
    { max: 255 }
  ],
  email: [{ type: 'email' }, { required: true }, { max: 255 }],
  organizationUnitId: [{ required: true }],
  employeeId: [{ required: true }, { max: 50 }],
  phoneNumber: [{ pattern: PHONE_NUMBER_REG_EXP }, { max: 20 }],
  extensionId: [{ required: true }, { max: 255 }],
  omniGroupId: [{ required: true }],
  companyName: [{ max: 255 }],
  dynamicQueues: []
};

export const userUpdateValidationRules: Partial<Record<keyof UserFormType, Rule[]>> = {
  ...userValidationRules,
  password: [{ pattern: PASSWORD_REG_EXP, message: usersMessages.passwordMismatchPatern }]
};
