import { FabricAgentMapOtherObject, FabricAgentMapSeat, FabricCanvasAgentObject } from 'types/fabric-agent-map';
import { agentMapOtherObjectsWithNameTypes } from 'utils/constants';
import { AGENT_MAP_OBJECT_TYPE } from 'utils/enums';

export const onPasteObject = async (
  e: KeyboardEvent,
  clipboard: FabricCanvasAgentObject | null,
  setClipboard: (value: FabricCanvasAgentObject | null) => void,
  handleAddSeat: (seat?: Partial<FabricAgentMapSeat>) => FabricAgentMapSeat[],
  handleAddObject: (otherObject: Partial<FabricAgentMapOtherObject>) => Promise<FabricAgentMapOtherObject[]>
) => {
  if ((e.ctrlKey || e.metaKey) && (e.key === 'v' || e.keyCode === 86)) {
    if (!clipboard) return;
    e.preventDefault();

    return clipboard.clone().then(async (clonedObj) => {
      if (clonedObj) {
        if (clipboard.objectTypeId === AGENT_MAP_OBJECT_TYPE.SEAT) {
          const newSeats = handleAddSeat({
            left: clipboard.left + clipboard.width / 2,
            top: clipboard.top
          });
          let newClipboard = clipboard;
          newClipboard.left = clipboard.left + clipboard.width / 2;
          setClipboard(newClipboard);
          return { clipboard, newSeats };
        }
        if (
          clipboard.objectTypeId &&
          (clipboard.objectTypeId === AGENT_MAP_OBJECT_TYPE.TEXT ||
            agentMapOtherObjectsWithNameTypes.includes(clipboard.objectTypeId))
        ) {
          const newObjects = await handleAddObject({
            left: clipboard.left + clipboard.width / 2,
            top: clipboard.top,
            objectTypeId: clipboard.objectTypeId,
            width: clonedObj.width,
            height: clonedObj.height,
            angle: clonedObj.angle,
            scaleX: clonedObj.scaleX,
            scaleY: clonedObj.scaleY,
            name: clipboard.objectTypeId === AGENT_MAP_OBJECT_TYPE.TEXT ? (clonedObj as any).text : ''
          });
          let newClipboard = clipboard;
          newClipboard.left = clipboard.left + clipboard.width / 2;
          setClipboard(newClipboard);
          return { clipboard, newObjects };
        }
      }
    });
  }
};
