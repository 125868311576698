import { Button, Typography, ModalProps, Form } from 'antd';
import { rolesMessages, messages } from 'messages';
import { useState, useRef } from 'react';
import { useGetRoleDetailQuery } from 'services';
import { Modal } from 'components/common';
import { OrganizationUnitHierarchyCompactDto } from 'types';
import { useOrganizationUnitsHierarchyOptions } from 'hooks';
import UpdateRoleScopeForm, { RoleScopeFormRefProps } from './UpdateRoleScopeForm';

type UpdateRoleScopeModalProps = ModalProps & {
  roleId?: number;
  onClose?: () => void;
};

const UpdateRoleScopeModal = ({ roleId, onClose, ...props }: UpdateRoleScopeModalProps) => {
  const [loading, setLoading] = useState(false);
  const roleFormRef = useRef<RoleScopeFormRefProps>(null);

  const { data: role } = useGetRoleDetailQuery(roleId!, {
    skip: !roleId,
    refetchOnMountOrArgChange: true
  });

  const { organizationsUnitsHierarchy } = useOrganizationUnitsHierarchyOptions({});

  const countData = (items: OrganizationUnitHierarchyCompactDto[]): number =>
    items.reduce((count, item) => count + 1 + (item.children ? countData(item.children) : 0), 0);

  const organizationUnitIds = Form.useWatch('organizationUnitIds', roleFormRef.current?.form) ?? [];

  const isSelectAll = countData(organizationsUnitsHierarchy) === organizationUnitIds.length;

  const getAllOrganizationUnitIds = (units: OrganizationUnitHierarchyCompactDto[]): number[] => {
    return units.flatMap(({ organizationUnitId, children }) => [
      organizationUnitId,
      ...(children ? getAllOrganizationUnitIds(children) : [])
    ]);
  };

  const handleSelectAll = () => {
    if (!isSelectAll) {
      const selectAll = getAllOrganizationUnitIds(organizationsUnitsHierarchy);
      roleFormRef.current?.form.setFieldValue('organizationUnitIds', selectAll);
    } else {
      roleFormRef.current?.form.setFieldValue('organizationUnitIds', []);
    }
  };

  return (
    <Modal.Sticker
      {...props}
      maskClosable={false}
      centered
      width={568}
      destroyOnClose
      footer={
        <div className='flex items-center justify-between'>
          <Button
            type='link'
            className='mr-auto font-normal text-colorPrimary no-underline'
            onClick={(e) => {
              e.stopPropagation();
              handleSelectAll();
            }}
          >
            {isSelectAll ? messages.deleteButtonText : messages.selectAllButtonText}
          </Button>

          <Button
            key='confirm'
            loading={loading}
            onClick={() => {
              if (roleFormRef.current) {
                roleFormRef.current.form.submit();
              }
            }}
            size='large'
            type='primary'
          >
            {messages.confirmButtonText}
          </Button>
        </div>
      }
      title={rolesMessages.updateScopeTitle}
    >
      {/* <Typography.Title className='mb-1 mt-10 text-2.5xl' level={4}>
        {rolesMessages.updateScopeTitle}
      </Typography.Title> */}
      <Typography.Paragraph className='mb-8'>
        {rolesMessages.page}: {role?.data.name}
      </Typography.Paragraph>
      <UpdateRoleScopeForm
        onCreateSuccess={() => {
          onClose?.();
        }}
        ref={roleFormRef}
        onChangeLoading={setLoading}
        roleId={roleId}
      />
    </Modal.Sticker>
  );
};

export default UpdateRoleScopeModal;
