import { CheckPermissionLayout } from 'layouts';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';
import { lazy } from 'react';

export const accessCustomerSearchRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_DIENLUC_CUSTOMER_SEARCH]} />,
    children: [
      {
        path: ROUTE.CUSTOMER_SEARCH,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/CustomerSearch'))
          },
          // {
          //   path: ':customerId/:customerCode',
          //   Component: lazy(() => import('pages/CustomerLookupDetailed'))
          // }
        ]
      }
    ]
  }
];