export enum CORE_OPTION_TYPES {
  CUSTOMER_SUPPORT_INFORMATION_FORMAT = 1,
  WORKING_SCHEDULE_TYPE = 2,
  TICKET_STATUS = 3,
  INTERNAL_ANNOUNCEMENT_STATUS = 4,
  TICKET_CIC_TYPE = 8,
  SERVICE_TYPE_CLASSIFY = 9,
  API_SHARING_PERMISSION = 10,
  OUTAGE_REASON = 16,
}
