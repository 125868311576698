import { Layout, Spin } from 'antd';
import {
  ActiveShiftsModal,
  Header,
  Loadable,
  OmniChannelFloatingIncomingCall,
  SecondSiderMenu,
  SidebarMenu
} from 'components';
import FailedLoginWorldPhoneWarningModal from 'components/customer-lookup/FailedLoginWorldPhoneWarningModal';
import { OmniCallProvider } from 'contexts';
import { useGetShiftMeActive, useGetSoftPhoneToken, useLoginSoftPhone, useProfile } from 'hooks';
import { shiftsMessages } from 'messages';
import ErrorBoundary from 'pages/500';
import { LegacyRef, useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { onReceiveCallDialingEvent, onReceiveCallHangUpEvent } from 'services';
import { twMerge } from 'tailwind-merge';
import { LOCAL_STORAGE_KEY, PERMISSION } from 'utils';
import { checkPermissions } from 'utils/check-permission';

const { Sider } = Layout;

function MainLayout() {
  const { isAuthenticated, profile } = useProfile();
  const { isLoading: isLoadingShiftMeActive, hasSelectShiftPermission } = useGetShiftMeActive();
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  const { onGetWorldPhoneToken } = useGetSoftPhoneToken();
  const { onLoginSoftPhoneHandle } = useLoginSoftPhone();
  const [showPopUpReceiveCall, setShowPopUpReceiveCall] = useState(false);
  const [customerNumber, setCustomerNumber] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [openFailedLoginWorldPhoneWarning, setOpenFailedLoginWorldPhoneWarning] = useState(false);

  const layoutRef = useRef<HTMLDivElement | null>(null);

  const { userPermissionIds } = useProfile();
  const isAllowCallCustomer = checkPermissions(userPermissionIds, PERMISSION.CALL_CUSTOMER);

  const fetchAndLoginSoftPhone = async () => {
    if (!isAuthenticated || !profile?.extensionId) return;

    try {
      const res = await onGetWorldPhoneToken({ userExtension: profile.extensionId });
      if (res?.data?.token) {
        await onLoginSoftPhoneHandle({
          username: profile.username,
          token: res.data.token
        });
      }
    } catch (error) {
      setOpenFailedLoginWorldPhoneWarning(true);
      console.error('Error fetching or logging into softphone:', error);
    }
  };

  useEffect(() => {
    if (layoutRef.current) {
      layoutRef.current.scrollTop = 0;
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchAndLoginSoftPhone();
  }, [isAuthenticated, profile?.extensionId]);

  const navigateToCustomerLookUp = (): void => {
    navigate(`${ROUTE.CUSTOMER_LOOKUP}`);
    setCustomerNumber('');
    setShowPopUpReceiveCall(false);
  };

  useEffect(() => {
    // onReceiveCallDialAnswerEvent((data) => {
    //   if (data?.callUuid && data?.customerNumber) {
    //     setShowPopUpReceiveCall(true)
    //     setCustomerNumber(data?.customerNumber)
    //   }
    // });

    onReceiveCallDialingEvent((data: any) => {
      if (data?.callUuid && data?.customerNumber) {
        const isCustomerLookup = location.pathname.startsWith(ROUTE.CUSTOMER_LOOKUP);
        if (!isCustomerLookup) {
          //setShowPopUpReceiveCall(true);
        }
        setCustomerNumber(data?.customerNumber);
      }
    });
    onReceiveCallHangUpEvent((data: any) => {
      if (data?.callUuid) {
        setShowPopUpReceiveCall(false);
        setCustomerNumber('');
      }
    });
  }, []);

  if (!localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || !isAuthenticated) {
    return <Navigate to={ROUTE.LOGIN} replace />;
  }

  return (
    <OmniCallProvider>
      <Spin tip={shiftsMessages.loadShift} spinning={isLoadingShiftMeActive && hasSelectShiftPermission}>
        <Layout>
          <div onClick={() => navigateToCustomerLookUp()}>
            {showPopUpReceiveCall ? <OmniChannelFloatingIncomingCall customerNumber={customerNumber} r /> : null}
          </div>
          <Sider className='sider-layout'>
            <div className='relative'>
              <div className={twMerge('absolute left-0', collapsedSidebar ? 'z-[100]' : 'z-[2000]')}>
                <SidebarMenu onChangeCollapse={setCollapsedSidebar} />
              </div>
              <SecondSiderMenu />
            </div>
          </Sider>
          <Layout>
            <Header />
            <Layout ref={layoutRef} className='h-[calc(100vh-80px)] max-h-[calc(100vh-80px)] overflow-y-auto px-8 pb-5'>
              <ErrorBoundary>
                <Loadable>
                  <Outlet />
                </Loadable>
              </ErrorBoundary>
            </Layout>
          </Layout>
          <ActiveShiftsModal />
        </Layout>
      </Spin>
      {isAllowCallCustomer && (
        <FailedLoginWorldPhoneWarningModal
          open={openFailedLoginWorldPhoneWarning}
          onCancel={() => {
            setOpenFailedLoginWorldPhoneWarning(false);
          }}
        />
      )}
    </OmniCallProvider>
  );
}

export default MainLayout;
