import {
  useActionsOmniChatMessage,
  useLazyOmniMessages,
  useProfile,
  useScrollOmniMessage,
  useSentOmniChatMessage
} from 'hooks';
import { useReceiveOmniChatMessage } from 'hooks/omni-chat/useReceiveOmniChatMessage';
import { find } from 'lodash';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { offReceiveOmniMessage, onReceiveOmniMessage, useReadOmniChatMessagesMutation } from 'services';
import {
  ChatSessionDto,
  FileUpload,
  OmniChannelMessageDto,
  MessagePosition,
  CreateOmniChannelMessageDto,
  ResponseDto,
  ReadOmniChannelMessageDto
} from 'types';
import { CHAT_SESSION_SOURCE, convertFileType, FILE_TYPE, HTTP_STATUS, MESSAGE_TYPE } from 'utils';

type Variables = {
  systemMessText?: string;
};

export type OmniChatContextProps = {
  messages: OmniChannelMessageDto[];
  positions: MessagePosition[];
  messageListRef: React.RefObject<HTMLDivElement> | null;
  messagesBoxRef: React.RefObject<HTMLDivElement> | null;
  chatSession: ChatSessionDto;
  hasMore: boolean;
  hasNew: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isAtBottomScroll: boolean;
  onGetNewerMess: () => void;
  onGetOlderMess: () => void;
  scrollToEndMessage: () => void;
  scrollToMessageReplied: (repliedMessage: OmniChannelMessageDto, color?: string) => void;
  onSentMessage: (message: string, files: FileUpload[]) => void;
  displayIndex: {
    start: number;
    end: number;
  };
  variables?: Variables;
  isShowActions?: boolean;
  handleLikeMessage: (message: OmniChannelMessageDto) => void;
  handleHideMessage: (message: OmniChannelMessageDto) => void;
  handleDeleteMessage: (message: OmniChannelMessageDto) => void;
  handleChatUser: (message: OmniChannelMessageDto) => void;
};

export const OmniChatContext = createContext<OmniChatContextProps | undefined>(undefined);

export const OmniChatProvider: React.FC<{
  children: React.ReactNode;
  chatSession: ChatSessionDto;
  endpointName?: string;
  setChatSession?: (value: ChatSessionDto) => void;
  variables?: Variables;
  isShowActions?: boolean;
  isRead?: boolean;
}> = ({ children, chatSession, setChatSession, variables, isShowActions, isRead = true }) => {
  const { chatId, chatSessionId } = chatSession;
  const { profile } = useProfile();
  const [loading, setLoading] = useState(false);

  const [onReadMess] = useReadOmniChatMessagesMutation();

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const isAction = isShowActions && chatSession.chat?.source === CHAT_SESSION_SOURCE.FACEBOOK;

  const {
    fetchData: fetchMessage,
    data: messages,
    isFetching,
    isLoading,
    hasMore,
    hasNew,
    getOlderMess,
    getNewerMess,
    endpointName: endpointNameMessages
  } = useLazyOmniMessages(chatSession);

  const {
    isAtBottomScroll,
    displayIndex,
    positionsRef,
    changeHeightChatList,
    messagesBoxRef,
    messageListRef,
    scrollToEndMessage,
    scrollToMessageReplied
  } = useScrollOmniMessage({ chatSession, fetchMessage, hasNew, setLoading, isRead });

  const { handleReceiveNewOmniMessage, handleReceiveNewerOmniMessageList, handleReceiveOlderOmniMessageList } =
    useReceiveOmniChatMessage(chatSession, undefined, isAction);

  const { handleAddOmniMessage, handleUpdateAddOmniMessage, handleSentOmniMessage } = useSentOmniChatMessage(
    chatId,
    chatSessionId,
    endpointNameMessages
  );

  const { handleUpdateMessage, handleLikeMessage, handleHideMessage, handleDeleteMessage, handleChatUser } =
    useActionsOmniChatMessage(chatId, chatSessionId, endpointNameMessages, setChatSession);

  const lastMessageRef = useRef<OmniChannelMessageDto | null>(null);
  const firstMessageRef = useRef<OmniChannelMessageDto | null>(null);

  useEffect(() => {
    if (chatId) {
      onGetMessages();
    }
  }, [chatId]);

  useEffect(() => {
    onReceiveOmniMessage((data) => {
      if (data.chatId === chatId) {
        onReceiveNewMessage(data);
      }
    });
    return () => {
      offReceiveOmniMessage();
    };
  }, [chatSession, chatId, handleAddOmniMessage, hasNew]);

  useEffect(() => {
    lastMessageRef.current = messages[messages.length - 1];
    firstMessageRef.current = messages[0];

    return () => {
      lastMessageRef.current = null;
      firstMessageRef.current = null;
    };
  }, [messages]);

  const handleReadAllMess = (data: ReadOmniChannelMessageDto) => {
    if (isRead) onReadMess(data).unwrap();
  };

  const onGetMessages = () => {
    fetchMessage({
      chatId
    })
      .unwrap()
      .then(async (rs) => {
        positionsRef.current = [];
        const data = await handleReceiveOlderOmniMessageList(rs.data.rows, null, []);

        if (data) {
          changeHeightChatList(data.height, data.height);
          positionsRef.current = data.positions;
        }
        handleReadAllMess({
          chatSessionId,
          messageIds: []
        });
      });
  };

  const onGetOlderMess = () => {
    if (loading || isFetching || isLoading || !hasMore) return;
    getOlderMess().then(async (rs) => {
      if (rs.data?.newMess) {
        const data = await handleReceiveOlderOmniMessageList(
          rs.data?.newMess,
          firstMessageRef.current,
          positionsRef.current
        );
        if (data) {
          positionsRef.current = data.positions;
          const currentScroll = messagesBoxRef.current?.scrollTop ?? 0;
          changeHeightChatList(data.height, currentScroll + data.changedHeight);
        }
      }
    });
  };

  const onGetNewerMess = () => {
    if (loading || isFetching || isLoading || !hasNew) return;
    getNewerMess().then(async (rs) => {
      if (rs.data?.newMess?.length) {
        const data = await handleReceiveNewerOmniMessageList(
          rs.data?.newMess,
          lastMessageRef.current,
          positionsRef.current
        );
        if (data) {
          positionsRef.current = data.positions;
          const currentScroll = messagesBoxRef.current?.scrollTop ?? 0;
          changeHeightChatList(data.height, data.removedHeight ? currentScroll - data.removedHeight : currentScroll);
        }
      }
    });
  };

  const onReceiveNewMessage = async (message: OmniChannelMessageDto) => {
    if (
      positionsRef.current &&
      find(positionsRef.current, (o) => (o as MessagePosition).messageId === message.messageId)
    ) {
      handleUpdateMessage(message);
      return;
    }

    if (
      typeof message.senderId === 'number' &&
      message.senderId === profile?.userId &&
      message.messageType !== MESSAGE_TYPE.SYSTEM
    )
      return;
    if (isAtBottomScroll && !hasNew) {
      const data = await handleReceiveNewOmniMessage(message, lastMessageRef.current, positionsRef.current);
      if (data) {
        positionsRef.current = data.positions;
        const currentScroll = messagesBoxRef.current?.scrollTop ?? 0;
        changeHeightChatList(data.height, data.removedHeight ? currentScroll - data.removedHeight : currentScroll);
        messagesBoxRef.current?.scrollTo({
          top: data.height,
          behavior: 'smooth'
        });
        handleAddOmniMessage(message);
        handleReadAllMess({ chatSessionId, messageIds: [message.messageId] });
      }
    }

    setChatSession?.({
      ...chatSession,
      lastMessageId: message.messageId
    });
  };
  const onSentMessage = async (messageContent: string, files: FileUpload[]) => {
    let imageFiles = files.filter((o) => convertFileType(o.type) === FILE_TYPE.IMAGE);
    let otherFiles = files.filter((o) => convertFileType(o.type) !== FILE_TYPE.IMAGE);
    let content = messageContent;
    const isFanPage = chatSession.chat?.source === CHAT_SESSION_SOURCE.FACEBOOK;

    if (imageFiles.length) {
      for (let i = 0; i < imageFiles.length; i++) {
        const msgData: Omit<CreateOmniChannelMessageDto, 'fileId'> & { fileId?: FileUpload } = {
          chatSessionId,
          messageType: isFanPage ? MESSAGE_TYPE.COMMENT : MESSAGE_TYPE.IMAGE,
          content,
          fileId: imageFiles[i]
        };
        content = '';
        const messageSent = await handleSentOmniMessage(msgData);
        onSendSuccess(messageSent);
      }
    }

    if (otherFiles.length) {
      for (let i = 0; i < otherFiles.length; i++) {
        const msgData: Omit<CreateOmniChannelMessageDto, 'fileId'> & { fileId?: FileUpload } = {
          chatSessionId,
          messageType: isFanPage ? MESSAGE_TYPE.COMMENT : MESSAGE_TYPE.FILE,
          content,
          fileId: otherFiles[i]
        };
        content = '';
        const messageSent = await handleSentOmniMessage(msgData);
        onSendSuccess(messageSent);
      }
    }

    if (content.length) {
      const msgData: Omit<CreateOmniChannelMessageDto, 'fileId'> & { fileId?: FileUpload } = {
        chatSessionId,
        messageType: isFanPage ? MESSAGE_TYPE.COMMENT : MESSAGE_TYPE.TEXT,
        content
      };
      content = '';
      const messageSent = await handleSentOmniMessage(msgData);
      onSendSuccess(messageSent);
    }
  };

  const onSendSuccess = async (messageSent: ResponseDto<OmniChannelMessageDto>) => {
    if (messageSent.statusCode === HTTP_STATUS.OK && messageSent.data) {
      setChatSession?.({
        ...chatSession,
        lastMessageId: messageSent.data.messageId
      });
      handleAddOmniMessage(messageSent.data);
      const data = await handleReceiveNewOmniMessage(messageSent.data, lastMessageRef.current, positionsRef.current);
      if (data) {
        positionsRef.current = data.positions;
        const currentScroll = messagesBoxRef.current?.scrollTop ?? 0;
        changeHeightChatList(data.height, data.removedHeight ? currentScroll - data.removedHeight : currentScroll);
      }
    }
    scrollToEndMessage();
  };

  return (
    <OmniChatContext.Provider
      value={{
        variables,
        chatSession,
        messageListRef,
        messagesBoxRef,
        positions: positionsRef.current,
        displayIndex: {
          start: displayIndex.start,
          end: displayIndex.end
        },
        isAtBottomScroll,
        scrollToEndMessage,
        scrollToMessageReplied,

        hasMore,
        hasNew,
        isFetching,
        isLoading,
        messages,
        onGetNewerMess,
        onGetOlderMess,
        onSentMessage,

        isShowActions: isAction,
        handleLikeMessage,
        handleHideMessage,
        handleDeleteMessage,
        handleChatUser
      }}
    >
      {/* <Spin className='max-h-full overflow-hidden' spinning={isLoading || isFetching}> */}
      {children}
      {/* </Spin> */}
    </OmniChatContext.Provider>
  );
};
export default OmniChatProvider;
