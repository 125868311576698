import { TreeDataNode } from 'antd';
import { Filter } from 'components/common';
import { useOrganizationUnitsHierarchy, useOrganizationUnitsHierarchyOptions } from 'hooks';
import { organizationUnitsMessages } from 'messages';
import React, { PropsWithChildren } from 'react';
import {
  FindOrganizationUnitHierarchyDto,
  OrganizationUnitHierarchyCompactDto,
  OrganizationUnitHierarchyDto
} from 'types';

type FilterTreeOrganizationUnitsProps = PropsWithChildren & {
  icon?: React.JSX.Element;
  title?: string;
};

const FilterTreeOrganizationUnits = ({ title, ...props }: FilterTreeOrganizationUnitsProps) => {
  const { organizationsUnitsHierarchy, isLoading, refetch } = useOrganizationUnitsHierarchyOptions({});

  const convertToTreeNode = (items: OrganizationUnitHierarchyCompactDto[]): TreeDataNode[] => {
    return items.map((item) => ({
      title: String(item.name),
      key: item.organizationUnitId,
      children: item.children ? convertToTreeNode(item.children) : undefined
    }));
  };

  const handleSearch = () => {};

  return (
    <Filter.TreeSearch
      {...props}
      options={convertToTreeNode(organizationsUnitsHierarchy)}
      onSearch={handleSearch}
      title={title ?? organizationUnitsMessages.shortTitle}
      placeholder={organizationUnitsMessages.organizationUnitName}
      loading={isLoading}
      fetchData={() => {
        refetch();
      }}
    />
  );
};
export default FilterTreeOrganizationUnits;
