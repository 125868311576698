import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateRoleDto,
  FindAllDto,
  FindOneRoleOrganizationUnitsDto,
  FindOneRolePermissionsDto,
  FindRolePermissionDto,
  OrganizationUnitCompactDto,
  PermissionCompactDto,
  ResponseDto,
  ResponsePagingDto,
  RoleCompactDto,
  RoleDto,
  UpdateRoleDto,
  UpdateRoleScopeDto
} from 'types';
import { mergeArrays } from 'utils';
import axiosBaseQuery from 'utils/base-api';

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  tagTypes: ['roles', 'roles_detail', 'role_permission_options', 'role_organization_units', 'role_permissions'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getRoles: builder.query<ResponsePagingDto<RoleDto>, FindAllDto>({
      query: (params) => ({
        url: '/roles',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ roleId }) => ({
              type: 'roles',
              id: roleId
            }))
          : ['roles'],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      }
    }),

    getRolesOptions: builder.query<ResponsePagingDto<RoleCompactDto>, FindAllDto>({
      query: (params) => ({
        url: '/roles/options',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows.push(...newItems.data.rows);
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      },
      providesTags: ['roles']
    }),

    getRoleDetail: builder.query<ResponseDto<RoleDto>, number>({
      query: (roleId) => ({ url: `/roles/${roleId}`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'roles_detail', id: result.data.roleId }] : [])
    }),
    createRole: builder.mutation<ResponseDto<RoleDto>, CreateRoleDto>({
      query: (newRole) => ({
        url: '/roles',
        method: 'post',
        data: newRole
      }),
      invalidatesTags: ['roles']
    }),
    updateRole: builder.mutation<ResponseDto<RoleDto>, UpdateRoleDto>({
      query: ({ roleId, ...updateRole }) => ({
        url: `/roles/${roleId}`,
        method: 'patch',
        data: updateRole
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'roles', id: param.roleId },
        { type: 'roles_detail', id: param.roleId },
        { type: 'role_permission_options' },
        { type: 'role_permissions', id: param.roleId }
      ]
    }),
    deleteRole: builder.mutation<ResponseDto<RoleDto>, number>({
      query: (roleId) => ({
        url: `/roles/${roleId}`,
        method: 'delete'
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'roles', id: param },
        { type: 'roles_detail', id: param },
        { type: 'role_permission_options' }
      ]
    }),
    updateRoleScope: builder.mutation<ResponseDto<void>, UpdateRoleScopeDto>({
      query: ({ roleId, ...updateRoleScope }) => ({
        url: `/roles/${roleId}/scopes`,
        method: 'patch',
        data: updateRoleScope
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'roles', id: param.roleId },
        { type: 'roles_detail', id: param.roleId },
        { type: 'role_permission_options' },
        { type: 'role_organization_units', id: param.roleId }
      ]
    }),
    getRolesPermissions: builder.query<ResponsePagingDto<PermissionCompactDto>, FindRolePermissionDto>({
      query: (params) => ({
        url: '/roles/permissions',
        method: 'get',
        params
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.roleIds !== previousArg?.roleIds;
      },
      providesTags: ['role_permission_options']
    }),
    getOneRoleOrganizationUnits: builder.query<
      ResponsePagingDto<OrganizationUnitCompactDto>,
      FindOneRoleOrganizationUnitsDto
    >({
      query: (params) => ({
        url: `/roles/${params.roleId}/organization_units`,
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'organizationUnitId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.keyword !== previousArg?.keyword ||
          currentArg?.pageIndex !== previousArg?.pageIndex ||
          currentArg?.roleId !== previousArg?.roleId
        );
      },
      providesTags: (_, _result, param) => [{ type: 'role_organization_units', id: param.roleId }]
    }),
    getOneRolePermissions: builder.query<ResponsePagingDto<PermissionCompactDto>, FindOneRolePermissionsDto>({
      query: (params) => ({
        url: `/roles/${params.roleId}/permissions`,
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'permissionId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.keyword !== previousArg?.keyword ||
          currentArg?.pageIndex !== previousArg?.pageIndex ||
          currentArg?.roleId !== previousArg?.roleId
        );
      },
      providesTags: (_, _result, param) => [{ type: 'role_permissions', id: param.roleId }]
    })
  })
});

export const {
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetRolesOptionsQuery,
  useGetRolesQuery,
  useGetRoleDetailQuery,
  useUpdateRoleScopeMutation,
  useLazyGetRolesPermissionsQuery,
  useGetRolesPermissionsQuery,
  useGetOneRoleOrganizationUnitsQuery,
  useGetOneRolePermissionsQuery
} = rolesApi;
