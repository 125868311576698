import { Button, Form, Modal, ModalProps, Typography } from 'antd';
import { useEffect } from 'react';
import { messages, usersMessages } from 'messages';
import { FormItem } from 'components/common';
import { TreeSelectOrganizationUnitChangeOptions } from 'components';
import { useChangeOrganizationUnitMutation } from 'services';
import { LOCAL_STORAGE_KEY, refreshTokenApi } from 'utils';
import { DefaultOptionType } from 'antd/es/select';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type ChangeOrganizationUnitProps = ModalProps & {
  onChangeLoading?: (value: boolean) => void;
  onChangeSuccess?: () => void;
};

type FormData = {
  organizationUnitId: DefaultOptionType;
};

const ChangeOrganizationUnitModal = ({ onChangeLoading, onChangeSuccess, ...props }: ChangeOrganizationUnitProps) => {
  const profile = useSelector((state: RootState) => state.users.userProfile);

  const [form] = Form.useForm<FormData>();

  const [changeOrganizationUnit, { isLoading: isLoadingOptions }] = useChangeOrganizationUnitMutation();

  useEffect(() => {
    if (profile?.organizationUnit) {
      form.setFieldValue('organizationUnitId', {
        label: profile.organizationUnit.name,
        value: profile.organizationUnit.organizationUnitId
      });
    }
  }, [profile?.organizationUnit]);

  useEffect(() => {
    onChangeLoading?.(isLoadingOptions);
  }, [isLoadingOptions]);

  const onSubmit = async ({ organizationUnitId }: FormData) => {
    onChangeLoading?.(true);
    const updateRes = await changeOrganizationUnit({
      organizationUnitId: organizationUnitId.value as number
    });
    if (updateRes.error) return;
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
    if (!refreshToken) return;
    const refreshRes = await refreshTokenApi(refreshToken);
    if (!refreshRes?.data) return;

    window.location.reload();

    onChangeSuccess?.();
  };

  return (
    <Modal
      {...props}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button key='btn-submit' loading={props.confirmLoading} onClick={form.submit} size='large' type='primary'>
          {messages.saveButtonText}
        </Button>
      ]}
    >
      <div className='mt-10'>
        <Form form={form} onFinish={onSubmit}>
          <Typography.Title level={5} className='mb-5 text-lg'>
            {usersMessages.organizationUnit}
          </Typography.Title>
          <FormItem.FloatLabel<FormData> name='organizationUnitId' label={usersMessages.organizationUnit}>
            <TreeSelectOrganizationUnitChangeOptions
              treeCheckable={false}
              labelInValue
              showCheckedStrategy='SHOW_ALL'
              treeDefaultExpandAll
            />
          </FormItem.FloatLabel>
        </Form>
      </div>
    </Modal>
  );
};

export default ChangeOrganizationUnitModal;
