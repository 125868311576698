import { useGetPermissionsHierachyOptionsQuery } from 'services';
import { FindPermissionHierarchyDto } from 'types';
export function usePermissionsHierarchyOptions({ keyword, organizationUnitIds, roleIds }: FindPermissionHierarchyDto) {
  const {
    data: permissionResponse,
    error,
    isLoading,
    isFetching
  } = useGetPermissionsHierachyOptionsQuery({
    keyword,
    organizationUnitIds,
    roleIds
  });

  return {
    data: permissionResponse?.data.rows || [],
    count: permissionResponse?.data.count,
    isLoading: isFetching || isLoading,
    error
  };
}
