import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useOrganizationUnitsOptions } from 'hooks';
import { useState } from 'react';
import { FindAllOrganizationUnitDto } from 'types';
import { filterOptions } from 'utils';

type SelectOrganizationUnitsProps = SelectProps &
  Omit<FindAllOrganizationUnitDto, 'keyword' | 'pageIndex' | 'pageSize'> & {
    disableOrganizationUnitId?: number[];
  };

const SelectOrganizationUnits = ({ parentId, disableOrganizationUnitId, ...props }: SelectOrganizationUnitsProps) => {
  const [keyword, setKeyword] = useState('');

  const { organizationsUnits, resetPage, handleLoadMore, hasMore, isLoading, isFetching } = useOrganizationUnitsOptions(
    {
      parentId,
      isLoadAll: true
    }
  );

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const options = filterOptions(organizationsUnits, keyword, 'name');

  return (
    <Select
      {...props}
      onLoadMore={handleLoadMore}
      hasMore={hasMore}
      loading={isLoading || isFetching}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={
        options.map((itm) => ({
          label: itm.name,
          value: itm.organizationUnitId,
          disabled: disableOrganizationUnitId?.includes(itm.organizationUnitId)
        })) || []
      }
    />
  );
};
export default SelectOrganizationUnits;
