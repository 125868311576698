import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessTicketRequestRoute: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={PERMISSION.ACCESS_TICKET} />,
    children: [
      {
        path: ROUTE.REQUEST_FORM,
        index: true,
        Component: lazy(() => import('pages/RequestForm'))
      }
    ]
  },
];
