import { Divider, Layout, List, Typography } from 'antd';
import { ConvertIcon, logo, LogoutIcon } from 'assets';
import { Avatar } from 'components/common';
import { useActiveMenu, useAgentStatusChange, useGetSoftPhoneAuthStatus, useLogout, useLogoutSoftPhone, useProfile, useRemoveQueueMember } from 'hooks';
import { messages, sidebarMenuMessages } from 'messages';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { sizes } from 'themes';
import { MENU_LIST_ITEM, MenuItemType, PERMISSION, SIDEBAR_MENU_KEY } from 'utils';
import MenuItem from './MenuItem';
import SidebarSubsMenu from './SidebarSubsMenu';
import { checkPermissions, checkSomePermissions } from 'utils/check-permission';
import { ChangeOrganizationUnitModal } from 'components';
import { useOmniCallContext } from 'contexts';
import { AGENT_STATUS } from 'utils';
import { useCreateAgentStatusChangeMutation } from 'services';
import { Link } from 'react-router-dom';
import { ROUTE } from 'routes/constants';

const { Sider } = Layout;

type SidebarMenuProps = {
  onChangeCollapse?: (collapsed: boolean) => void;
};

const SidebarMenu = ({ onChangeCollapse }: SidebarMenuProps) => {
  const { profile, userPermissionIds } = useProfile();
  const { onCreateAgentStatusChangeHandle } = useAgentStatusChange();

  const timeoutSubItemRef = useRef<number | null>(null);
  const timeoutItemRef = useRef<number | null>(null);

  const { selectedMenu } = useActiveMenu();

  const { onLogoutHandle } = useLogout();

  const [collapsed, setCollapsed] = useState(true);
  const [collapsedSubMenu, setCollapsedSubMenu] = useState(true);
  const [openChangeOrganizationUnit, setOpenChangeOrganizationUnit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [itemSelected, setMenuSelected] = useState<MenuItemType | undefined>();
  const { softPhoneToken } = useGetSoftPhoneAuthStatus();
  const { onLogoutSoftPhoneHandle } = useLogoutSoftPhone();
  const { getAgentStatus } = useOmniCallContext();
  const { onRemoveQueueMember } = useRemoveQueueMember();


  const onCloseSubSider = () => {
    if (timeoutSubItemRef.current) {
      clearTimeout(timeoutSubItemRef.current);
    }
    timeoutSubItemRef.current = window.setTimeout(() => {
      setCollapsedSubMenu(true);
    }, 200);
  };
  const onOpenSubSider = () => {
    if (timeoutSubItemRef.current) {
      clearTimeout(timeoutSubItemRef.current);
    }
    setCollapsedSubMenu(false);
  };

  const onOpenSider = () => {
    setCollapsed(false);
    onChangeCollapse?.(false);
    if (timeoutItemRef.current) {
      clearTimeout(timeoutItemRef.current);
    }
  };
  const onCloseSider = () => {
    if (timeoutItemRef.current) {
      clearTimeout(timeoutItemRef.current);
    }
    timeoutItemRef.current = window.setTimeout(() => {
      setCollapsed(true);
      if (onChangeCollapse) {
        window.setTimeout(() => {
          onChangeCollapse(true);
        }, 100);
      }
    }, 200);
  };
  const handleCollapsed = () => {
    setCollapsedSubMenu(true);
    setCollapsed(true);
  };
  const handleLogOut = async () => {
    onLogoutHandle();
    if (softPhoneToken) {
      onLogoutSoftPhoneHandle({
        token: softPhoneToken
      });
      if (profile?.userId && profile?.extensionId) {
        await onRemoveQueueMember({ extension: profile.extensionId });
        onCreateAgentStatusChangeHandle({
          userId: profile?.userId,
          extensionId: profile?.extensionId,
          previousState: getAgentStatus?.agentState,
          nextState: AGENT_STATUS.LOGGEDOFF
        });
        localStorage.removeItem("isAgentCreatingTicket")
        localStorage.removeItem('customerSupportSession')
        localStorage.removeItem('omnichannel_chat_session')
      }

    }
  };
  const menuList = MENU_LIST_ITEM.filter(
    (o) =>
      !o.permission ||
      (typeof o.permission === 'number'
        ? checkPermissions(userPermissionIds, o.permission)
        : checkSomePermissions(userPermissionIds, o.permission))
  );

  return (
    <div className='sidebar-menu flex' onMouseEnter={onOpenSider} onMouseLeave={onCloseSider}>
      <Sider
        collapsed={collapsed}
        collapsedWidth={sizes.sidebarMenuCollapsed}
        width={sizes.sidebarMenu}
        className={twMerge('transition-all duration-[300ms]', collapsed && 'main-menu-sider-collapsed')}
      >
        <div className='content flex h-full flex-col pb-10 pt-6'>
          <div className='mb-4 flex flex-col items-center px-2'>
            <img src={logo} alt={messages.appName} className='logo-fade-in h-12 w-12' />
            <Typography.Text ellipsis className='mb-0.5 mt-2 font-bold text-white'>
              {messages.appTitleName}
            </Typography.Text>
            <Typography.Text ellipsis className='text-xs text-white'>
              {collapsed ? messages.appSubSortName : messages.appSubName}
            </Typography.Text>
          </div>
          <List
            className='no-scrollbar w-full overflow-y-auto bg-transparent'
            dataSource={menuList}
            renderItem={(item: MenuItemType, index: number) =>
              item.type === 'divider' ? (
                <Divider key={`divider-${index}`} />
              ) : (
                <MenuItem
                  key={item.key}
                  onClick={() => {
                    if (item.href) {
                      handleCollapsed();
                    }
                    if (item.key === SIDEBAR_MENU_KEY.CONVERT_UNIT_ROOM_TEAM) {
                      setOpenChangeOrganizationUnit(true);
                    }
                  }}
                  item={item}
                  onMouseEnter={() => {
                    if (item.children) {
                      setMenuSelected(item);
                      onOpenSubSider();
                    }
                  }}
                  onMouseLeave={onCloseSubSider}
                  disabled={!item.href && !item.children}
                  isActive={selectedMenu === item.key}
                  className={twMerge(itemSelected?.key === item.key && !collapsedSubMenu && 'bg-spotlightHover')}
                  icon={
                    item.icon === 'avatar' ? (
                      <Avatar size={collapsed ? 40 : 32} fileId={profile?.avatar} name={profile?.fullName} />
                    ) : (
                      <div>{item.icon}</div>
                    )
                  }
                />
              )
            }
            locale={{
              emptyText: <></>
            }}
          />

          <div className='mt-auto' />
          <Divider />
          <Link onClick={() => handleCollapsed()} to={ROUTE.PROFILE}>
            <div
              className={twMerge(
                'item-option item-option-select mt-auto gap-2 px-6 pl-4',
                selectedMenu == SIDEBAR_MENU_KEY.PROFILE && 'item-option-active'
              )}
            >
              <div>
                <Avatar size={40} fileId={profile?.avatar} name={profile?.fullName} />
              </div>
              <div className='flex flex-col justify-center gap-1'>
                <Typography.Text ellipsis className='font-semibold'>
                  {profile?.fullName}
                </Typography.Text>
                <Typography.Text ellipsis className='text-xs'>
                  {profile?.username}
                </Typography.Text>
              </div>
            </div>
          </Link>
          {checkPermissions(userPermissionIds, PERMISSION.CHANGE_ORGANIZATION_UNIT) ? (
            <div
              onClick={() => {
                handleCollapsed();
                setOpenChangeOrganizationUnit(true);
              }}
              className='item-option item-option-select mt-auto gap-2 px-6'
            >
              <div>
                <ConvertIcon key='logout' width={24} height={24} />
              </div>
              <Typography.Text ellipsis>{sidebarMenuMessages.convertUnitRoomTeam}</Typography.Text>
            </div>
          ) : null}

          <Divider />

          <div onClick={() => handleLogOut()} className='item-option item-option-select px-6'>
            <div>
              <LogoutIcon key='logout' width={24} height={24} />
            </div>
            <Typography.Text ellipsis>{messages.logout}</Typography.Text>
          </div>
        </div>
      </Sider>
      <SidebarSubsMenu
        collapsed={collapsed || collapsedSubMenu}
        menuSelected={itemSelected}
        onMouseEnter={() => onOpenSubSider()}
        onMouseLeave={onCloseSubSider}
        onSelect={handleCollapsed}
      />

      <ChangeOrganizationUnitModal
        open={openChangeOrganizationUnit}
        onChangeLoading={setLoading}
        onChangeSuccess={() => {
          setOpenChangeOrganizationUnit(false);
        }}
        onCancel={() => {
          setOpenChangeOrganizationUnit(false);
        }}
        confirmLoading={loading}
      />
    </div>
  );
};

export default SidebarMenu;
