import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, GetProp, Row, Typography, Form, DatePicker, Input, List, message } from 'antd';
import dayjs from 'dayjs';
import { GetOrganizatioUnitDetailServicesQuery } from 'hooks/request-form';
import { requestform } from 'messages';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { OrganizationUnitServices, RequestData } from 'types';

const DEFAULT_PAGEINDEX = 1;
interface RequestFilterFormInterface {
  setRequestData: React.Dispatch<React.SetStateAction<RequestData>>;
  requestData: RequestData;
  refreshData: () => void;
  clearFilterForm: () => void;
  setStoredFilters: React.Dispatch<React.SetStateAction<RequestData>>;
}

const RequestFilterForm: React.FC<RequestFilterFormInterface> = ({
  requestData,
  refreshData,
  clearFilterForm,
  setRequestData,
  setStoredFilters
}) => {
  const profile = useSelector((state: RootState) => state.users.userProfile);
  const { data: organizationUnitChildrenDetail } = GetOrganizatioUnitDetailServicesQuery(
    Number(profile?.organizationUnitId || 0)
  );
  const [searchTerm, setSearchTerm] = useState<string>('');

  //Filter fromDate, toDate
  const [fromDate, setFromDate] = useState<dayjs.Dayjs | null>(null);
  const [toDate, setToDate] = useState<dayjs.Dayjs | null>(null);
  const handleDateChange = (field: 'from' | 'to', value: dayjs.Dayjs | null) => {
    if (field === 'from') setFromDate(value);
    else setToDate(value);
  };

  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const handleCheckboxChange = (serviceTypeId: number) => {
    setSelectedServices((prev) =>
      prev.includes(serviceTypeId) ? prev.filter((id) => id !== serviceTypeId) : [...prev, serviceTypeId]
    );
  };

  // Apply Filter
  const handleApplyFilters = () => {
    if (fromDate && toDate && fromDate.isAfter(toDate)) {
      message.error('Ngày không hợp lệ');
      return;
    }
    const updatedFilters = {
      ...requestData,
      fromDate: fromDate ? fromDate.format('DD/MM/YYYY') : '',
      toDate: toDate ? toDate.format('DD/MM/YYYY') : '',
      pageIndex: DEFAULT_PAGEINDEX,
      serviceTypeId: selectedServices
    };
    setRequestData(updatedFilters);
    setStoredFilters(updatedFilters);
    refreshData();
  };

  //Actions in filter
  const actions: React.ReactNode[] = [
    <div className='flex h-full justify-between bg-[#F5F4F6] px-6'>
      <Button
        onClick={() => clearFilterForm()}
        className='border-none bg-transparent text-[#141414] underline shadow-none hover:text-[#141414]'
      >
        {requestform.deleteFilter}
      </Button>
      <Button onClick={handleApplyFilters} className='bg-[#1564E8] px-8 py-6 text-[#FFFFFF]'>
        {requestform.result}
      </Button>
    </div>
  ];

  return (
    <Card
      className='h-full w-fit rounded-md bg-[#F5F4F6] text-center'
      title={requestform.filter}
      bordered={false}
      actions={actions}
    >
      <div className='h-[800px] overflow-x-hidden overflow-y-scroll text-center'>
        <section className='border-b-2 pb-8 pt-2 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.statusRequestForm}
          </Typography.Text>
        </section>
        <section className='border-b-2 pb-8 pt-8 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.time}
          </Typography.Text>

          <Form layout='vertical' className='w-full pt-8'>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item
                  label={
                    <Typography.Text className='text-[16px]/[20px] font-semibold'>
                      {requestform.creatingFormTime}
                    </Typography.Text>
                  }
                >
                  <div className='flex rounded-lg border-[#EBEBED] bg-white'>
                    <div className='h-fit w-1/2 border-r px-3 pb-0 pt-2'>
                      <Typography.Text className='text-[12px]/[22px] text-[#878787]'>
                        {requestform.fromDate}
                      </Typography.Text>
                      <br />
                      <DatePicker
                        suffixIcon=''
                        placeholder={requestform.fromDate}
                        value={fromDate}
                        onChange={(date) => handleDateChange('from', date)}
                        className='m-0 min-h-0 w-full rounded-none border-none p-0 pb-[8px]'
                      />
                    </div>
                    <div className='h-fit w-1/2 px-3 pb-0 pt-2'>
                      <Typography.Text className='text-[12px]/[22px] text-[#878787]'>
                        {requestform.toDate}
                      </Typography.Text>
                      <br />
                      <DatePicker
                        suffixIcon=''
                        placeholder={requestform.toDate}
                        value={toDate}
                        onChange={(date) => handleDateChange('to', date)}
                        className='m-0 min-h-0 w-full rounded-none border-none p-0 pb-[10px]'
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </section>

        {/*<section className='border-b-2 pb-8 pt-8 text-left'>*/}
        {/*  <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>*/}
        {/*    {requestform.inChargeDepartment}*/}
        {/*  </Typography.Text>*/}

        {/*  <Radio.Group className='w-full pt-[20px]' defaultValue={processingTeam[0]}>*/}
        {/*    <Row gutter={[0, 16]}>*/}
        {/*      {processingTeam.map((status, index) => (*/}
        {/*        <Col key={index} span={12}>*/}
        {/*          <Radio value={status}>{status}</Radio>*/}
        {/*        </Col>*/}
        {/*      ))}*/}
        {/*    </Row>*/}
        {/*  </Radio.Group>*/}
        {/*</section>*/}

        <section className='pb-8 pt-8 text-left'>
          <Typography.Text className='text-[18px]/[24px] font-semibold text-[#141414]'>
            {requestform.typeOfService}
          </Typography.Text>

          <div className='pt-[20px]'>
            <Input
              className='w-fukk h-[40px] bg-white'
              placeholder={requestform.sortBy}
              suffix={<SearchOutlined className='text-[#B2B9C4]' />}
              variant='borderless'
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <div className='mt-2 space-y-4'>
              {organizationUnitChildrenDetail
                ?.filter((type) => type?.name?.toLowerCase().includes(searchTerm))
                .map((type) => (
                  <List.Item key={type?.serviceTypeId} className='flex justify-between'>
                    <Checkbox
                      checked={selectedServices.includes(type?.serviceTypeId)}
                      onChange={() => handleCheckboxChange(type?.serviceTypeId)}
                    >
                      {type?.name}
                    </Checkbox>
                  </List.Item>
                ))}
              {organizationUnitChildrenDetail &&
                organizationUnitChildrenDetail.filter((type) => type?.name?.toLowerCase().includes(searchTerm))
                  .length === 0 && <Typography.Text className='text-[#B2B9C4]'></Typography.Text>}
            </div>
          </div>
        </section>
      </div>
    </Card>
  );
};

export default RequestFilterForm;
