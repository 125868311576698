import { FacebookIcon, LivechatIcon, MessengerIcon, ZaloIcon } from 'assets';
import { CHAT_SESSION_SOURCE } from 'utils';

type Props = {
  source: CHAT_SESSION_SOURCE | undefined;
  size?: number;
};

const OmiChanelChatSourceIcon: React.FC<Props> = ({ source, size = 20 }) => {
  switch (source) {
    case CHAT_SESSION_SOURCE.FACEBOOK:
      return <FacebookIcon height={size} width={size} />;
    case CHAT_SESSION_SOURCE.MESSENGER:
      return <MessengerIcon height={size} width={size} />;
    case CHAT_SESSION_SOURCE.LIVE:
      return <LivechatIcon height={size} width={size} />;
    case CHAT_SESSION_SOURCE.ZALO:
      return <ZaloIcon height={size} width={size} />;
  }
  return <div></div>;
};

export default OmiChanelChatSourceIcon;
