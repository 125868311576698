import { usePaging } from 'hooks/usePaging';
import { useGetAgentMapsQuery } from 'services';
import { FindAllAgentMapDto } from 'types';
import { DEFAULT_PAGE_SIZE } from 'utils';

export function useAgentMapsPaging({
  pageSize = DEFAULT_PAGE_SIZE, // Allow overriding the page size
  ...params
}: Omit<FindAllAgentMapDto, 'pageIndex' | 'pageSize'> & { pageSize?: number }) {
  const { keyword, isActive } = params;
  const { pageIndex, changePage, resetPage } = usePaging(params);
  const {
    data: agentMapsResponse,
    error,
    isLoading,
    isFetching
  } = useGetAgentMapsQuery({
    pageIndex,
    pageSize, // Now accepts dynamic pageSize
    keyword,
    isActive
  });

  return {
    agentMaps: agentMapsResponse?.data.rows,
    count: agentMapsResponse ? agentMapsResponse.data.count : 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    handleChangePage: changePage,
    resetPage
  };
}


