import { AgentMapContextMenuOtherObjectRef } from 'components/agent-map/AgentMapContextMenuOtherObject';
import { AgentMapContextMenuSeatRef } from 'components/agent-map/AgentMapContextMenuSeat';
import { useEffect, useRef, useState } from 'react';
import { FabricAgentMapOtherObject, FabricAgentMapSeat, FabricCanvasAgentObject } from 'types/fabric-agent-map';
import { AGENT_MAP_OBJECT_TYPE, agentMapOtherObjectsWithNameTypes } from 'utils';
import {
  onCanvasContextMenu,
  onCanvasDbClick,
  onCanvasMouseDown,
  onCanvasObjectAdded,
  onCanvasObjectModified,
  onCanvasWheel,
  onCopyObject,
  onKeydownMoveObject,
  onPasteObject,
  onTextEdited
} from 'utils/agent-map-event';
import { movingObject } from 'utils/agent-map';
import * as fabric from 'fabric';
import { AgentMapContextMenuRef } from 'components/agent-map/AgentMapContextMenu';
import { useAgentMapSeatCurd } from './useAgentMapSeatCurd';
import { useAgentMapObjectCurd } from './useAgentMapObjectCurd';

export const useAgentMapCanvas = () => {
  const parentDiv = useRef<HTMLDivElement | null>(null);
  const canvas = useRef<fabric.Canvas | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const contextMenuSeatRef = useRef<AgentMapContextMenuSeatRef>(null);
  const contextMenuObjectRef = useRef<AgentMapContextMenuOtherObjectRef>(null);
  const contextMenuRef = useRef<AgentMapContextMenuRef>(null);

  const seatsArr = useRef<FabricAgentMapSeat[]>([]);
  const objectArr = useRef<FabricAgentMapOtherObject[]>([]);
  const activeCanvasObj = useRef<FabricCanvasAgentObject | fabric.Textbox>();

  const [scale, setScale] = useState(1);
  const [clipboard, setClipboard] = useState<FabricCanvasAgentObject | null>(null);

  const { handleAddSeat, handleRemoveSeat } = useAgentMapSeatCurd();
  const { handleAddObject, handleRemoveObject } = useAgentMapObjectCurd();

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('mousedown', handleMouseDown);
      canvas.current?.dispose();
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleCopyPasteObject);
    return () => {
      document.removeEventListener('keydown', handleCopyPasteObject);
    };
  }, [clipboard]);

  const initCanvas = () => {
    if (!canvasRef.current) return;
    canvas.current = new fabric.Canvas(canvasRef.current);

    canvas.current.on('mouse:dblclick', (e) => {
      if (!canvas.current) return;
      onCanvasDbClick(e, canvas.current, objectArr);
    });

    canvas.current.on('object:moving', (e) => {
      removeNameWhenModified();
      if (!canvas.current) return;
      movingObject(e.target as FabricCanvasAgentObject, canvas.current);
    });

    canvas.current.on('object:scaling', (e) => {
      removeNameWhenModified();
    });

    canvas.current.on('object:rotating', (e) => {
      removeNameWhenModified();
    });

    canvas.current.on('object:modified', (e) => {
      if (!canvas.current) return;
      const newObject = onCanvasObjectModified({
        e,
        canvas: canvas.current,
        objectArr,
        seatsArr
      });
      if (newObject?.objectArr) {
        objectArr.current = newObject.objectArr;
      }
      if (newObject?.seatsArr) {
        seatsArr.current = newObject.seatsArr;
      }
    });

    canvas.current.on('mouse:down', (e) => {
      if (!canvas.current) return;
      onCanvasMouseDown(e, canvas.current, activeCanvasObj);
    });

    canvas.current.on('contextmenu', (e) => {
      if (parentDiv.current) {
        const data = onCanvasContextMenu(e, parentDiv.current);
        if (data) {
          if (data.id && data.objectTypeId) {
            if (data.objectTypeId === AGENT_MAP_OBJECT_TYPE.SEAT && contextMenuSeatRef.current) {
              contextMenuSeatRef.current.open(
                {
                  x: data.x,
                  y: data.y
                },
                data.id
              );
              return;
            }
            if (
              (data.objectTypeId === AGENT_MAP_OBJECT_TYPE.TEXT ||
                agentMapOtherObjectsWithNameTypes.includes(data.objectTypeId)) &&
              contextMenuObjectRef.current
            ) {
              contextMenuObjectRef.current.open(
                {
                  x: data.x,
                  y: data.y
                },
                data.id
              );
              return;
            }
          } else if (contextMenuRef.current) {
            contextMenuRef.current.open({
              x: data.x,
              y: data.y
            });
          }
        }
      }
    });

    canvas.current.on('selection:created', () => {
      const selectedObjects = canvas.current?.getActiveObjects();
      if (selectedObjects?.length && selectedObjects?.length > 1) {
        // Nếu có nhiều hơn một đối tượng được chọn, hủy bỏ hành động nhóm
        canvas.current?.discardActiveObject();
      }
    });

    canvas.current.on('mouse:wheel', (event) => {
      if (!canvas.current) return;
      const scale = onCanvasWheel(event, canvas.current);
      scale && setScale(scale);
    });

    canvas.current.on('object:added', (event) => {
      if (!canvas.current) return;
      onCanvasObjectAdded(canvas.current);
    });

    canvas.current.on('text:editing:exited', (e) => {
      if (!canvas.current) return;
      onTextEdited(e, objectArr);
    });
  };

  const removeNameWhenModified = () => {
    if (activeCanvasObj.current) {
      canvas.current?.remove(activeCanvasObj.current);
      activeCanvasObj.current = undefined;
    }
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (!canvas.current) return;
    onKeydownMoveObject(event, canvas.current);
    onKeyDownDeleteObject(event);
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (!parentDiv.current || !canvas.current) return;
    const rect = parentDiv.current.getBoundingClientRect();

    if (
      event.clientX < rect.left ||
      event.clientX > rect.right ||
      event.clientY < rect.top ||
      event.clientY > rect.bottom
    ) {
      canvas.current.discardActiveObject();
    }
  };

  const resetAgentMap = () => {
    if (canvas.current) {
      canvas.current.clear();
      objectArr.current = [];
      seatsArr.current = [];
    }
  };

  const onKeyDownDeleteObject = (event: KeyboardEvent) => {
    if (!canvas.current) return;
    if (event.key === 'Delete') {
      const activeObject = canvas.current?.getActiveObject() as FabricCanvasAgentObject;
      if (activeObject?.objectTypeId === AGENT_MAP_OBJECT_TYPE.SEAT) {
        if (activeObject.id) {
          seatsArr.current = handleRemoveSeat({ canvas: canvas.current, seatsArr: seatsArr.current })(activeObject.id);
        }
        return;
      }
      if (
        activeObject?.objectTypeId &&
        (activeObject.objectTypeId === AGENT_MAP_OBJECT_TYPE.TEXT ||
          agentMapOtherObjectsWithNameTypes.includes(activeObject?.objectTypeId))
      ) {
        if (activeObject.id) {
          objectArr.current = handleRemoveObject({ canvas: canvas.current, objectArr: objectArr.current })(
            activeObject.id
          );
        }
        return;
      }
    }
  };

  const handleCopyPasteObject = async (event: KeyboardEvent) => {
    if (!canvas.current) return;
    onCopyObject(event, canvas.current, setClipboard);
    const object = await onPasteObject(
      event,
      clipboard,
      setClipboard,
      handleAddSeat({ canvas: canvas.current, seatsArr: seatsArr.current }),
      handleAddObject({ canvas: canvas.current, objectArr: objectArr.current })
    );
    if (object) {
      if (object.newSeats) {
        seatsArr.current = [...object.newSeats];
      }
      if (object.newObjects) {
        objectArr.current = [...object.newObjects];
      }
    }
  };

  return {
    initCanvas,
    resetAgentMap,
    canvas,
    canvasRef,
    parentDiv,
    contextMenuSeatRef,
    contextMenuObjectRef,
    contextMenuRef,
    scale,
    seatsArr,
    objectArr,
    activeCanvasObj
  };
};
