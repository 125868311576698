import { Skeleton, Typography } from 'antd';
import { InfiniteScroll, List } from 'components/common';
import { usePermissionsOrganizationUnits } from 'hooks';

type PermissionOrganizationUnitListProps = {
  permissionId: number;
};

const PermissionOrganizationUnitList = ({ permissionId }: PermissionOrganizationUnitListProps) => {
  const { hasMore, handleLoadMore, isLoading, organizationUnits } = usePermissionsOrganizationUnits({
    permissionId
  });

  return (
    <InfiniteScroll
      hasMore={hasMore}
      next={handleLoadMore}
      isLoading={isLoading}
      loader={<Skeleton active title={false} paragraph={{ rows: 3 }} />}
    >
      <List
        dataSource={organizationUnits}
        rowKey={(item) => item.organizationUnitId}
        renderItem={(item) => (
          <List.Item className='border-0 p-0 pb-4'>
            <Typography.Text>{item.name}</Typography.Text>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

export default PermissionOrganizationUnitList;
