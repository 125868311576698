import * as fabric from 'fabric';
import { FabricAgentMapOtherObject, FabricAgentMapSeat, FabricCanvasAgentObject } from 'types/fabric-agent-map';
import { modifiedObject, modifiedText } from 'utils/agent-map';
import { modifiedSeat } from 'utils/agent-map/modifiedSeat';
import { agentMapOtherObjectsWithNameTypes } from 'utils/constants';
import { AGENT_MAP_OBJECT_TYPE } from 'utils/enums';
type Props = {
  e: fabric.ModifiedEvent<fabric.TPointerEvent>;
  canvas: fabric.Canvas;
  objectArr: React.MutableRefObject<FabricAgentMapOtherObject[]>;
  seatsArr: React.MutableRefObject<FabricAgentMapSeat[]>;
};
export const onCanvasObjectModified = ({ e, canvas, objectArr, seatsArr }: Props) => {
  if (!e.target) return;
  const target = e.target as FabricCanvasAgentObject;

  if (target.objectTypeId && target.objectTypeId === AGENT_MAP_OBJECT_TYPE.TEXT) {
    return { objectArr: modifiedText(target, objectArr.current, canvas) };
  }

  if (target.objectTypeId && agentMapOtherObjectsWithNameTypes.includes(target.objectTypeId) && objectArr.current) {
    return { objectArr: modifiedObject(target, objectArr.current, canvas) };
  }

  if (seatsArr.current && target.objectTypeId === AGENT_MAP_OBJECT_TYPE.SEAT) {
    return { seatsArr: modifiedSeat(target, seatsArr.current) };
  }
};
