import { Card, Divider, Dropdown, Menu, message, Typography } from 'antd';
import { requestform } from 'messages';
import React, { useEffect, useState } from 'react';
import { CmisServicesRequestData, CmisServicesResponseData, TicketDetail, TransferCmis } from 'types';

import exchangeSVG from '../../assets/svg/exchange.svg';
import { DownOutlined } from '@ant-design/icons';
import { usePatchReceptionTicketMutation, usePatchTransferToCmisMutation } from 'services';
import { GetCmisService } from '../../hooks/request-form';

interface ticketDetail {
  closeModalCMIS: () => void;
  requestItem: TicketDetail | null;
}
const ModalTransferCmis: React.FC<ticketDetail> = ({ requestItem, closeModalCMIS }) => {
  const DEFAULT_CMIS_SERVICE_REQUEST: CmisServicesRequestData = {
    pageIndex: 1,
    pageSize: 20,
    code: '',
    prefixCode: '',
    isLoadAll: true
  };
  const { cmisData, cmisServiceCount } = GetCmisService(DEFAULT_CMIS_SERVICE_REQUEST);
  useEffect(() => {
    setSelectedOption(cmisData[0]);
  }, [cmisData]);
  const [selectedOption, setSelectedOption] = useState<CmisServicesResponseData | null>(null);
  const handleMenuClick = (option: CmisServicesResponseData) => setSelectedOption(option);

  const [patchReception] = usePatchReceptionTicketMutation();
  const [transferCmisService] = usePatchTransferToCmisMutation();
  const receptionTicketAction = () => {
    if (requestItem?.ticketId !== undefined) {
      patchReception(requestItem.ticketId)
        .unwrap()
        .then((res) => {
          closeModalCMIS();
          message.success('Tiếp nhận thành công phiếu yêu cầu', 2);
        })
        .catch((err) => {});

      const transferCmisServiceDto: TransferCmis = {
        ticketId: requestItem.ticketId,
        serviceTypeCmisId: selectedOption?.serviceTypeCmisId ? selectedOption?.serviceTypeCmisId : 0
      };
      transferCmisService(transferCmisServiceDto)
        .unwrap()
        .then((res) => {
          closeModalCMIS();
          message.success('Chuyển Cmis thành công', 2);
        })
        .catch((err) => {});
    }
  };

  const menu = (
    <Menu className='h-32 overflow-y-auto'>
      {cmisData.map((option, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleMenuClick(option)}
          className='flex h-[40px] items-center px-4 hover:bg-[#F5F4F6]'
        >
          <Typography.Text>{option.name}</Typography.Text>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Card className='confirm-modal mx-auto bg-[#F5F5F7] py-4 text-center'>
      <div className='py-10'>
        <div className='space-y-4 py-8'>
          <div className='flex justify-center'>
            <img src={exchangeSVG} alt='' />
          </div>{' '}
          <div>
            <Typography.Text className='text-[22px]/[26px] font-semibold text-[#141414]'>
              {requestform.confirmChangeCMIS}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>{requestform.confirmChangeCMISContent}</Typography.Text>
          </div>{' '}
        </div>{' '}
        <div className='mx-6'>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className='flex h-[52px] w-full cursor-pointer items-center justify-between rounded-lg border border-[#EBEBED] bg-[#FFFFFF] px-3 py-2'>
              <Typography.Text>{selectedOption?.name ? selectedOption?.name : ''}</Typography.Text>
              <DownOutlined className='h-2 w-[10px]' />
            </div>
          </Dropdown>
        </div>
      </div>
      <Divider />
      <div className='mx-8 text-right'>
        <button
          onClick={() => {
            receptionTicketAction();
          }}
          className='rounded-[5px] bg-[#1564E8] px-9 py-[10px]'
        >
          <Typography.Text className='text-[#FFFFFF]'>{requestform.confirmButton}</Typography.Text>
        </button>
      </div>
    </Card>
  );
};

export default ModalTransferCmis;
