import { useGetCustomerContractInfoQuery } from "services/customer-lookup";
import { FindAllCustomerContractInfoDto } from "types/dto/customer-lookup/customer-contract-info.dto";

export function useGetCustomerContractInfo(customerCode: string) {
    const { data: customerRes, isLoading } = useGetCustomerContractInfoQuery(customerCode!, {
            skip: !customerCode,
            refetchOnMountOrArgChange: true
        });
    
        return {
            contract: customerRes?.data ?? [],
            isLoading
        };
}