import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useLoginSoftPhoneMutation } from 'services/softphone/softphone.api';
import { setAuthenticatedSoftPhone } from 'store/features/sip-auth/sip-auth.slice';
import { LoginSoftPhoneDto } from 'types/dto/softphone/login-softphone.dto';

export function useLoginSoftPhone() {
  const dispatch = useDispatch();
  const [login, { isLoading, error }] = useLoginSoftPhoneMutation();

  const onLoginSoftPhoneHandle = async (values: LoginSoftPhoneDto) => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;

    try {
      const loginPromise = login(values).unwrap();

      const timeoutPromise = new Promise((_, reject) => {
        timeoutId = setTimeout(() => {
          reject(new Error());
        }, 10000); // 10 seconds
      });

      const res = await Promise.race([loginPromise, timeoutPromise]) as any;

      clearTimeout(timeoutId);

      if (res?.status === 1) {
        message.success('Kêt nối World Phone thành công');
        dispatch(setAuthenticatedSoftPhone(true));
      } else {
        //message.error('Đăng nhập World Phone không thành công');
        dispatch(setAuthenticatedSoftPhone(false));
      }

      return res;
    } catch (err: any) {
      //message.error(err?.message || 'Kết nối World Phone không thành công');
      dispatch(setAuthenticatedSoftPhone(false));
      throw err;
    }
  };

  return {
    isLoading,
    error,
    onLoginSoftPhoneHandle
  };
}
