import { SelectProps } from 'antd';
import { Select } from 'components/common';
import { useOmniQueueNames } from 'hooks';

type Props = SelectProps;

const SelectOmniQueueNames = ({ ...props }: Props) => {
  const { isLoading, data } = useOmniQueueNames();

  return (
    <Select
      {...props}
      loading={isLoading}
      options={
        data.map((itm) => ({
          label: itm.queueName,
          value: itm.queueName
        })) || []
      }
      mode='multiple'
      allowClear
    />
  );
};

export default SelectOmniQueueNames;
