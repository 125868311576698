import { OmniChannelMessageDto } from 'types';
import { MESSAGE_FILE_HEIGHT, MESSAGE_IMAGE_HEIGHT_LARGE } from 'utils/constants';
import { FILE_TYPE } from 'utils/enums';
import { convertFileType, loadFileFromId, loadFileFromUrl } from 'utils/file';

export const calculateHeightOmniMessageComment = async (message: OmniChannelMessageDto) => {
  if (!message) return 0;
  let heightMessage = 0;
  let dataFile;
  if (message.fileId) {
    dataFile = await loadFileFromId(message.fileId);
    if (dataFile && convertFileType(dataFile.fileType) === FILE_TYPE.IMAGE) heightMessage += MESSAGE_IMAGE_HEIGHT_LARGE;
    else heightMessage += MESSAGE_FILE_HEIGHT;
  } else if (message.file) {
    dataFile = await loadFileFromUrl(message.file);
    if (dataFile && convertFileType(dataFile.type) === FILE_TYPE.IMAGE) heightMessage += MESSAGE_IMAGE_HEIGHT_LARGE;
    else heightMessage += MESSAGE_FILE_HEIGHT;
  }

  if (message.content?.length && message.content?.length > 0) {
    const clone = document.createElement('div');
    clone.className =
      'relative min-w-[220px] max-w-[320px] whitespace-pre-wrap break-words rounded-lg px-3 pb-8 pt-2 text-left text-sm';
    clone.innerHTML = message.content;
    document.body.appendChild(clone);
    const height = clone.offsetHeight;
    document.body.removeChild(clone);
    heightMessage += height;
    /** Padding border */
    heightMessage += 2;
  }
  return heightMessage;
};
