import { AgentMapSeatDto } from 'types';
import { FabricAgentMapSeatDashboard } from 'types/fabric-agent-map';

export const transformToFabricAgentMapSeatDashboard = (data: AgentMapSeatDto[]): FabricAgentMapSeatDashboard[] => {
    return data.map((o) => ({
        height: o.height,
        id: o.agentMapSeatId,
        left: o.left,
        seatIp: o.seatIp,
        seatName: o.seatName,
        top: o.top,
        width: o.width,
        extensionId: o.extensionId || '',
        shortName: o.shortName || '',
        agentChangeStatusDate: o.agentChangeStatusDate,
        statusName: o.statusName,
        totalCall: o.totalCall,
        totalChat: o.totalChat
    }));
};
