import { useAnswerSoftPhoneMutation } from 'services/softphone/softphone.api';
import { TokenSoftPhone } from 'types/dto/softphone/token-softphone.dto';
import { message } from 'antd';


export function useAnswerSoftPhone() {


  const [answer, { isLoading, error }] = useAnswerSoftPhoneMutation();

  const onAnswerSoftPhoneHandle = async (values: TokenSoftPhone) => {
    try {
      const res = await answer(values).unwrap(); // ✅ Wait for the API response


      return res; // ✅ Return the API response
    } catch (err) {
      console.error("Error answering softphone:", err);
      throw err; // ✅ Re-throw error so it can be handled by caller
    }
  };

  return {
    isLoading,
    error,
    onAnswerSoftPhoneHandle,
  };
}

