import { Tree, TreeDataNode, TreeProps } from 'antd';
import Search from '../InputSearch';
import { Key, useState } from 'react';
import { removeVietnameseAccents } from 'utils';

type FilterTreeSearchBoxProps = {
  onSearch?: (value: string) => void;
  options: TreeDataNode[];
  placeholder?: string;
  onChange?: (value: any) => void;
  value?: Array<any>;
};

type CheckedKeysType = {
  checked: Key[];
  halfChecked: Key[];
};

const FilterTreeSearchBox: React.FC<FilterTreeSearchBoxProps> = ({
  onSearch,
  options,
  placeholder,
  onChange,
  value
}) => {
  const [keyword, setKeyword] = useState('');

  const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
    onChange?.(checkedKeysValue);
  };

  const searchTree = (data: TreeDataNode[], keyword = ''): TreeDataNode[] => {
    const trimKeyword = removeVietnameseAccents(keyword).toLowerCase();
    const result: TreeDataNode[] = [];
    data.forEach((item) => {
      const isValid = removeVietnameseAccents(item.title as string)
        .toLowerCase()
        .includes(trimKeyword);
      const children = item.children && item.children.length > 0 ? searchTree(item.children, keyword) : [];
      if (isValid || children.length > 0) {
        result.push({
          ...item,
          children: children.length > 0 ? children : undefined
        });
      }
    });
    return result;
  };

  return (
    <div className='w-full'>
      {onSearch && (
        <Search
          className='mb-2'
          onChange={(searchString) => {
            setKeyword(searchString);
          }}
          placeholder={placeholder}
        />
      )}
      {/* <div className='mb-4 flex min-h-5 items-center gap-1'>
        <Typography.Text type='secondary' className='text-sm'>
          {messages.selectedCount}
        </Typography.Text>{' '}
        <Typography.Text>{selectedCount}</Typography.Text>
      </div> */}
      <div className='no-scrollbar -mx-4 max-h-[300px] w-[calc(100%+32px)] max-w-[450px] overflow-y-auto px-4'>
        <Tree
          checkedKeys={value}
          prefixCls='filter-tree'
          selectable={false}
          checkable
          onCheck={onCheck}
          treeData={searchTree(options, keyword)}
          defaultExpandAll
        />
      </div>
    </div>
  );
};
export default FilterTreeSearchBox;
