import { Loadable } from 'components';
import ErrorBoundary from 'pages/500';
import { Outlet } from 'react-router-dom';

function ErrorBoundaryLayout() {
  return (
    <ErrorBoundary>
      <Loadable>
        <Outlet />
      </Loadable>
    </ErrorBoundary>
  );
}

export default ErrorBoundaryLayout;
