import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessOperationsManagementRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_MANAGEMENT]} />,
    children: [
      {
        path: ROUTE.AGENT_MAPS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_AGENT_MAP]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/AgentMaps'))
          },
          {
            path: `${ROUTE.AGENT_MAPS}/new`,
            Component: lazy(() => import('pages/AgentMapCreate'))
          },
          {
            path: ':agentMapId',
            Component: lazy(() => import('pages/AgentMap'))
          }
        ]
      },
      {
        path: ROUTE.ORGANIZATION_UNITS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_ORGANIZATION_UNIT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/OrganizationUnits'))
          }
        ]
      },
      {
        path: ROUTE.INTERNAL_ANNOUNCEMENTS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_INTERNAL_ANNOUNCEMENT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/InternalAnnouncements'))
          },
          {
            path: ':internalAnnouncementId',
            Component: lazy(() => import('pages/InternalAnnouncement'))
          }
        ]
      },
      {
        path: ROUTE.INTERNAL_ANNOUNCEMENTS_DELETED,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_INTERNAL_ANNOUNCEMENT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/InternalAnnouncementsDeleted'))
          }
        ]
      },
      {
        path: ROUTE.VIP_CUSTOMERS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_VIP_CUSTOMER]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/VipCustomers'))
          }
        ]
      },
      {
        path: ROUTE.WORKING_SCHEDULES,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_WORKING_SCHEDULE]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/WorkingSchedules'))
          }
        ]
      },
      {
        path: ROUTE.SERVICE_INSTRUCTIONS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_SERVICE_INSTRUCTION]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/ServiceInstructions'))
          }
        ]
      },
      {
        path: ROUTE.CUSTOMER_SUPPORT_INFORMATION,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_CUSTOMER_SUPPORT_INFORMATION]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/CustomerSupportInformationList'))
          },
          {
            path: ':customerSupportInformationId',
            Component: lazy(() => import('pages/CustomerSupportInformation'))
          }
        ]
      },
      {
        path: ROUTE.FREQUENTLY_ASKED_QUESTION,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_FREQUENTLY_ASKED_QUESTION]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/FrequentlyAskedQuestions'))
          }
        ]
      },
      {
        path: ROUTE.ORGANIZATION_UNIT_TICKET_DEFAULT_ASSIGNS,
        element: <CheckPermissionLayout permission={[PERMISSION.CONFIG_ORGANIZATION_UNIT_DEFAULT_HANDLE_TICKET]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/OrganizationUnitTicketDefaultAssigns'))
          }
        ]
      }
    ]
  }
];
