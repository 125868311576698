import { MenuProps, Typography } from 'antd';
import { DownloadImageIcon } from 'assets';
import { OmniMessageText, File, OmniMessageReplied } from 'components/common';
import dayjs from 'dayjs';
import { internalChatMessages } from 'messages';
import { memo, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { FileDto, OmniChannelMessageDto } from 'types';
import {
  convertFileType,
  FILE_TYPE,
  fileServerDownload,
  loadFileFromUrl,
  MESSAGE_FILE_HEIGHT,
  MESSAGE_IMAGE_HEIGHT_LARGE,
  MESSAGE_REF_TYPE,
  stringToHslColor,
  TIME_FORMAT
} from 'utils';
import MessageOptions, { MessageOptionsRef } from './MessageOptions';
import { useOmniChatContext } from 'contexts';
import MessageActions from './MessageActions';
import { useLazyGetFileQuery } from 'services';
import OtherFile from '../files/OtherFile';

type OmniMessageCommentProps = {
  fileId?: number | null;
  file?: string | null;
  sentAt?: string | Date;
  onForwardMessage?: (message: OmniChannelMessageDto) => void;
  data: OmniChannelMessageDto;
  isFirstOfSender?: boolean;
};

const OmniMessageComment: React.FC<OmniMessageCommentProps> = ({
  onForwardMessage,
  data,
  fileId,
  file,
  sentAt,
  isFirstOfSender
}) => {
  const { chatSession } = useOmniChatContext();

  const [fetchFile] = useLazyGetFileQuery();

  const [fileLoaded, setFileLoaded] = useState<Partial<FileDto>>();
  const optionsRef = useRef<MessageOptionsRef>(null);

  const customerId = chatSession.chat?.customerId;
  const isCustomer = data.senderId === customerId;
  const isReply = Boolean(data.refType === MESSAGE_REF_TYPE.REPLY && data.refMessage);
  const isHasSender = !isCustomer && Boolean(data.senderName && isFirstOfSender);

  const isHasContent = Boolean(data.content);
  const items: MenuProps['items'] = [
    {
      key: 'download',
      label: internalChatMessages.download,
      onClick: () => {
        if (fileId && fileLoaded) {
          fileServerDownload(fileLoaded as FileDto);
        } else if (fileLoaded?.url) {
          fileServerDownload({
            url: fileLoaded.url,
            objectName: fileLoaded.objectName,
            originalName: fileLoaded.objectName
          });
        }
      },
      icon: <DownloadImageIcon />
    }
  ];

  useEffect(() => {
    if (fileId) {
      loadDataFromId();
    } else if (file) {
      loadDataFromUrl();
    }
  }, [file]);

  const loadDataFromUrl = async () => {
    if (!file) return;
    const dataFile = await loadFileFromUrl(file);
    if (dataFile)
      setFileLoaded({
        url: file,
        objectName: dataFile.name,
        fileSize: dataFile.size,
        fileType: dataFile.type,
        originalName: dataFile.name
      });
  };

  const loadDataFromId = async () => {
    if (!fileId) return;
    const dataFile = await fetchFile(fileId);
    if (dataFile)
      setFileLoaded({
        ...dataFile.data?.data
      });
  };

  const renderFile = () => {
    if (!fileLoaded) return null;
    const filetype = convertFileType(fileLoaded?.fileType);
    if (filetype === FILE_TYPE.IMAGE) {
      return (
        <div
          className='relative overflow-hidden'
          style={{
            height: MESSAGE_IMAGE_HEIGHT_LARGE,
            width: isHasContent ? MESSAGE_IMAGE_HEIGHT_LARGE : 'fit-content',
            maxWidth: MESSAGE_IMAGE_HEIGHT_LARGE
          }}
        >
          <File
            className={twMerge(
              'h-full w-full items-center border-0 p-0',
              isHasContent ? 'items-center' : !isCustomer ? 'items-end' : 'items-start',
              twMerge((isReply || isHasContent) && 'rounded-t-none')
            )}
            url={fileLoaded.url}
            type={'image/png'}
            isPreviewImage
          />
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          if (fileLoaded?.url) {
            fileServerDownload({
              url: fileLoaded.url,
              objectName: fileLoaded.objectName,
              originalName: fileLoaded.objectName
            });
          }
        }}
        className='flex items-center px-3'
        style={{
          height: MESSAGE_FILE_HEIGHT
        }}
      >
        <OtherFile
          name={fileLoaded?.originalName || fileLoaded?.objectName}
          fileSize={fileLoaded?.fileSize}
          type={fileLoaded?.fileType}
          iconSize={42}
          className='h-full w-full border-0 p-0'
        ></OtherFile>
      </div>
    );
  };

  return (
    <div
      onContextMenu={(e) => {
        optionsRef.current?.open(e);
      }}
      className='relative'
    >
      {isHasSender && (
        <div
          className={twMerge(
            'flex h-10 items-center rounded-t-lg px-2',
            !isCustomer
              ? 'border-colorBgMyMessage bg-colorBgMyMessage'
              : 'border-colorBgCustomerMessage bg-colorBgCustomerMessage',
            isHasContent && !fileLoaded && !isReply && 'h-8 pt-2'
          )}
        >
          <Typography.Paragraph
            style={{
              color: stringToHslColor(data!.senderName, 80, 35)
            }}
            className='mb-0 text-left text-sm font-semibold'
          >
            {data!.senderName}
          </Typography.Paragraph>
        </div>
      )}
      {isReply && (
        <div
          className={twMerge(
            'min-w-[230px] max-w-[320px] rounded-t-lg p-2',
            isHasSender && 'rounded-t-none',
            !isCustomer
              ? 'border-colorBgMyMessage bg-colorBgMyMessage'
              : 'border-colorBgCustomerMessage bg-colorBgCustomerMessage'
          )}
        >
          <OmniMessageReplied data={data.refMessage!} />
        </div>
      )}
      {fileLoaded && (
        <div
          className={twMerge(
            'w-auto min-w-[230px] max-w-[320px] overflow-hidden rounded-lg',
            !isCustomer
              ? 'border-colorBgMyMessage bg-colorBgMyMessage'
              : 'border-colorBgCustomerMessage bg-colorBgCustomerMessage',
            isHasContent ? 'rounded-b-none border' : 'border-0 bg-transparent',
            (isReply || isHasSender) && 'rounded-t-none',
            data.isHidden && 'opacity-80',
            data.isDeleted && 'opacity-60 brightness-75'
          )}
        >
          {renderFile()}
        </div>
      )}
      {data.content?.length && data.content?.length > 0 ? (
        <div>
          <OmniMessageText
            isImageMessage={true}
            data={data}
            className={twMerge(
              'min-w-[230px] max-w-[320px]',
              (fileLoaded || isReply || isHasSender) && 'rounded-t-none'
            )}
          />
        </div>
      ) : (
        <div className={twMerge('absolute bottom-2 right-1 rounded-2xl bg-textHoverBg px-1')}>
          <Typography.Paragraph className='mb-0 text-xs' type='secondary'>
            {dayjs(sentAt).format(TIME_FORMAT)}
          </Typography.Paragraph>
        </div>
      )}
      <MessageOptions onForwardMessage={onForwardMessage} ref={optionsRef} items={items} message={data} />
      <div
        className={twMerge(
          'absolute bottom-[3px] left-1 rounded-2xl bg-textHoverBg px-2'
          // data.isDeleted && 'opacity-60 brightness-75'
        )}
      >
        <MessageActions data={data} />
      </div>
    </div>
  );
};

export default memo(OmniMessageComment);
