export enum OMNI_CHANNEL_EVENT {
  START = 'START',
  DIALING = 'DIALING',
  DIALANSWER = 'DIALANSWER',
  HANGUP = 'HANGUP',
  CDR = 'CDR',
  TRIM = 'TRIM',
  PBXCDR = 'PBXCDR',
  GET_CALL_DETAILS = 'GET_CALL_DETAILS',
  UPDATE_TOKEN = 'update_token',
  TOKEN_EXPIRED = 'token_expired',
  UNAUTHORIZED = 'unauthorized',
  JOIN_CHAT_SESSION = 'JOIN_CHAT_SESSION',
  LEAVE_CHAT_SESSION = 'LEAVE_CHAT_SESSION',
  ADD_CHAT_SESSION_WAITING = 'ADD_CHAT_SESSION_WAITING',
  REMOVE_CHAT_SESSION_WAITING = 'REMOVE_CHAT_SESSION_WAITING',
  CHAT_SESSION_PROCESSING = 'CHAT_SESSION_PROCESSING',
  MESSAGE = 'MESSAGE',
  GET_WAITING_CALLS = 'GET_WAITING_CALLS',
  UPDATE_CHAT_STAT_DETAILS = 'UPDATE_CHAT_STAT_DETAILS',
  AGENT_COUNTER_UPDATE = 'UPDATE_TOTAL_CALL_AND_CHAT_COUNTER',


  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connect_error',
  ERROR = 'error',
  EXCEPTION = 'exception'
}
