import { Skeleton, Typography } from 'antd';
import { InfiniteScroll, List } from 'components/common';
import { useRolePermissions } from 'hooks/roles';

type RolePermissionListProps = {
  roleId: number;
};

const RolePermissionList = ({ roleId }: RolePermissionListProps) => {
  const { hasMore, handleLoadMore, isLoading, permissions } = useRolePermissions({
    roleId: roleId
  });

  return (
    <InfiniteScroll
      hasMore={hasMore}
      next={handleLoadMore}
      isLoading={isLoading}
      loader={<Skeleton active title={false} paragraph={{ rows: 3 }} />}
    >
      <List
        dataSource={permissions}
        rowKey={(item) => item.permissionId}
        renderItem={(item) => (
          <List.Item className='border-0 p-0 pb-4'>
            <Typography.Text>{item.name}</Typography.Text>
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

export default RolePermissionList;
