import { Col, List, Tag, Tooltip, Typography } from 'antd';
import { messages, rolesMessages } from 'messages';
import { memo } from 'react';
import { RoleDto } from 'types';
import RoleActions from './RoleActions';
import { InfoOutlinedIcon } from 'assets';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'utils';
import { Avatar } from 'components/common';

export type RoleItemProps = {
  data: RoleDto;
  onUpdate?: (value: RoleDto) => void;
  onUpdateScope?: (value: RoleDto) => void;
  onDelete?: (value: RoleDto) => void;
  index?: number;
};
const RoleItem = ({ data, onUpdate, onUpdateScope, onDelete, index }: RoleItemProps) => {
  return (
    <div className='flex w-full items-start justify-between gap-x-[40px]'>
      <Col span={1}>
        <Typography.Paragraph type='secondary' className='mb-1 text-xs font-semibold'>
          {messages.index}
        </Typography.Paragraph>
        <Typography.Paragraph className='mb-0 text-sm'>{index !== undefined ? index + 1 : '-'}</Typography.Paragraph>
      </Col>
      <Col span={4}>
        <Typography.Paragraph className='mb-1 font-semibold'>{data.name}</Typography.Paragraph>
        <Typography.Paragraph type='secondary' className='mb-0 text-sm'>
          {data.description}
        </Typography.Paragraph>
      </Col>
      <Col flex='auto'>
        <Typography.Paragraph type='secondary' className='mb-1 text-xs font-semibold'>
          {rolesMessages.permissions.toUpperCase()}
        </Typography.Paragraph>
        <div className='flex w-full flex-wrap gap-x-6 gap-y-1'>
          {data.permissions?.map((permission) => (
            <Col key={permission.permissionId} span={7}>
              <Typography.Text className='text-sm'>{permission.name}</Typography.Text>
            </Col>
          ))}
        </div>
      </Col>
      <Col span={4}>
        <div className='mb-1 flex items-center gap-1'>
          <Typography.Paragraph type='secondary' className='mb-0 text-xs font-semibold'>
            {rolesMessages.scope.toUpperCase()}
          </Typography.Paragraph>
          <Tooltip title={rolesMessages.scopeTooltip}>
            <InfoOutlinedIcon className='text-subTextColor' width={16} height={16} />
          </Tooltip>
        </div>
        {data.isApplyAll && (
          <Typography.Text type='secondary' className='text-sm'>
            {rolesMessages.allOrganizationUnits}
          </Typography.Text>
        )}
        {!data.isApplyAll && data.organizationUnits && data.organizationUnits.length > 0 && (
          <List
            split={false}
            dataSource={data.organizationUnits}
            rowKey={(item) => item.organizationUnitId}
            renderItem={(item) => (
              <Tag className='me-1 rounded-2xl border-0 bg-backgroundColor4 text-xs text-colorTextBase'>
                {item.name}
              </Tag>
            )}
          />
        )}
      </Col>
      <Col span={2}>
        <Typography.Paragraph type='secondary' className='mb-1 text-xs font-semibold'>
          {messages.updatedInfo.toUpperCase()}
        </Typography.Paragraph>
        {data.updatedByUser ? (
          <div className='flex flex-wrap items-center gap-2'>
            <Typography.Text className='text-sm'>
              {data.updatedAt ? dayjs(data.updatedAt).format(DATE_TIME_FORMAT) : '-'}
            </Typography.Text>
            <div className='flex items-center gap-2'>
              <Avatar fileId={data.updatedByUser.avatar} name={data.updatedByUser.fullName} />
              <Typography.Text className='text-sm'>{data.updatedByUser.fullName}</Typography.Text>
            </div>
          </div>
        ) : (
          '-'
        )}
      </Col>
      <Col span={2}>
        <Typography.Paragraph type='secondary' className='mb-1 text-xs font-semibold'>
          {messages.createdInfo.toUpperCase()}
        </Typography.Paragraph>
        <div className='flex flex-wrap items-center gap-2'>
          <Typography.Text className='text-sm'>
            {data.createdAt ? dayjs(data.createdAt).format(DATE_TIME_FORMAT) : '-'}
          </Typography.Text>
          {data.createdByUser ? (
            <div className='flex items-center gap-2'>
              <Avatar fileId={data.createdByUser.avatar} name={data.createdByUser.fullName} />
              <Typography.Text className='text-sm'>{data.createdByUser.fullName}</Typography.Text>
            </div>
          ) : undefined}
        </div>
      </Col>
      <Col span={1} className='text-end'>
        <RoleActions onDelete={onDelete} role={data} onUpdateInfo={onUpdate} onUpdateScope={onUpdateScope} />
      </Col>
    </div>
  );
};

export default memo(RoleItem);
