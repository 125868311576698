import { Typography } from 'antd';
import { Loadable, PageHeader, TabButtons, TabItem } from 'components';
import { useTitle } from 'hooks';
import { sidebarMenuMessages, userGroupsMessages, usersMessages } from 'messages';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';

type UserLayoutProps = {
  tabExtra?: React.ReactNode;
};

function UserLayout({ tabExtra }: UserLayoutProps) {
  useTitle(sidebarMenuMessages.permissions);
  const location = useLocation();
  const navigate = useNavigate();

  const tabItems: TabItem[] = [
    {
      key: ROUTE.USERS,
      label: usersMessages.title
    },
    {
      key: ROUTE.USER_GROUPS,
      label: userGroupsMessages.title
    }
  ];

  const handleClickTab = (tabKey: string) => {
    navigate(tabKey);
  };

  return (
    <div>
      <PageHeader className='flex items-center gap-1'>
        <Typography.Title className='text-header-label mb-0 font-bold'>
          {sidebarMenuMessages.permissions}
        </Typography.Title>
      </PageHeader>
      <div className='h-full rounded-xl bg-colorBgContainer'>
        <div className='flex min-h-[88px] items-center justify-between p-6'>
          <TabButtons
            className='items-center'
            items={tabItems}
            onClick={(tabKey) => handleClickTab(tabKey as string)}
            activeKey={location.pathname}
          />
          {tabExtra}
        </div>
        <div className='max-h-[calc(100%-56px)]'>
          <Loadable>
            <Outlet />
          </Loadable>
        </div>
      </div>
    </div>
  );
}

export default UserLayout;
