import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ExportExcelSupervisorTrackingTicketDto,
  ExportExcelTicketRequestDto,
  ExportExcelUsersDto,
  ResponseDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const excelsApi = createApi({
  reducerPath: 'excelsApi',
  tagTypes: [],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    exportUsers: builder.mutation<Pick<ResponseDto<null>, 'statusCode' | 'message'>, ExportExcelUsersDto>({
      query: (data) => ({
        url: '/excels/export_users',
        method: 'post',
        data: data
      })
    }),

    exportTickets: builder.mutation<Pick<ResponseDto<null>, 'statusCode' | 'message'>, ExportExcelTicketRequestDto>({
      query: (data) => ({
        url: `/excels/export_${data.status}_tickets`,
        method: 'post',
        data: data
      })
    }),

    exportSupervisorTrackingTickets: builder.mutation<
      Pick<ResponseDto<null>, 'statusCode' | 'message'>,
      ExportExcelSupervisorTrackingTicketDto
    >({
      query: (data) => ({
        url: `/excels/export_supervisor_ticket_tracking_detail_tickets`,
        method: 'post',
        data: data
      })
    })
  })
});

export const { useExportUsersMutation, useExportTicketsMutation, useExportSupervisorTrackingTicketsMutation } =
  excelsApi;
