import { Button, Form, List, Typography } from 'antd';
import { useSearchParamsForm, useServiceTypesHierarchy, useServiceTypesPaging } from 'hooks';
import React, { useEffect, useRef, useState } from 'react'
import { FindWithKeywordDto, ServiceTypeHierarchyDto } from 'types';
import { InputSearch, Table } from 'components/common';
import { serviceTypesMessages } from 'messages';
import { CustomerLookupTableArrowRight } from 'assets';
import { ServiceTypeFromRefProps } from './ServiceTypeForm';
import { ColumnsType } from 'antd/es/table';
import { removeVietnameseAccents } from 'utils';

type FindType = FindWithKeywordDto;
type ServiceTypeListProps = {
    onSelectServiceType: (serviceTypeId: number) => void;
    initialKeyword?: string;
}

const ServiceTypeList: React.FC<ServiceTypeListProps> = ({ onSelectServiceType , initialKeyword }) => {
    const {setSearchParams, getSearchParams} = useSearchParamsForm();
    const [expandedKeys, setExpandedKeys] = useState<number[]>([]);
    
    const [form] = Form.useForm<FindType>();
    const values = Form.useWatch([], form) || getSearchParams();
    const { keyword, ...filterValues } = values;
    // const {serviceTypes, isLoading } = useServiceTypesPaging({...values})
    const { serviceTypesHierarchy, isLoading } = useServiceTypesHierarchy({
        ...values
    });
    useEffect(() => {
      if (initialKeyword) {
          form.setFieldsValue({ keyword: initialKeyword });
          setSearchParams({ keyword: initialKeyword });
      }
  }, [initialKeyword]);
  
    const [open, setOpen] = useState(false);
    const [serviceTypeId, setServiceTypeId] = useState<number | undefined>();
    const expandFirstRef = useRef<boolean>(false);
    const serviceTypeFormRef = useRef<ServiceTypeFromRefProps>(null);
    useEffect(() => {
        if (!open && serviceTypeFormRef.current) {
          setServiceTypeId(undefined);
          serviceTypeFormRef.current.form.resetFields();
        }
    }, [open, serviceTypeFormRef.current]);
    const getAllKeys = (data: ServiceTypeHierarchyDto[]): number[] => {
        let keys: number[] = [];
        if (!data) return [];
        data.forEach((item) => {
          keys.push(item.serviceTypeId);
          if (item.children) {
            keys = keys.concat(getAllKeys(item.children));
          }
        });
        return keys;
    };
    useEffect(() => {
        if (!expandFirstRef.current && serviceTypesHierarchy?.length && serviceTypesHierarchy?.length > 0) {
          setExpandedKeys(getAllKeys(serviceTypesHierarchy || []));
          expandFirstRef.current = true;
        }
    }, [serviceTypesHierarchy]);

    const columns: ColumnsType<ServiceTypeHierarchyDto> = [
        {
            dataIndex: 'name',
            key: 'name',
            render: (val) => (
            <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }} className='mb-0 text-sm'>
                {val ?? '-'}
            </Typography.Paragraph>
            )
        },
    ]

    const handleOnExpand = (expanded: boolean, record: ServiceTypeHierarchyDto) => {
        if (expanded) {
          setExpandedKeys((prev) => [...prev, record.serviceTypeId]);
        } else {
          setExpandedKeys((prev) => prev.filter((key) => key !== record.serviceTypeId));
        }
    };
    const searchServiceTypes = (data: ServiceTypeHierarchyDto[], keyword = ''): ServiceTypeHierarchyDto[] => {
        const trimKeyword = removeVietnameseAccents(keyword).toLowerCase();
        const result: ServiceTypeHierarchyDto[] = [];
        data.forEach((serviceType) => {
          const isValid = removeVietnameseAccents(serviceType.name).toLowerCase().includes(trimKeyword);
          const children =
            serviceType.children && serviceType.children.length > 0
              ? searchServiceTypes(serviceType.children, keyword)
              : [];
          if (isValid || children.length > 0) {
            result.push({
              ...serviceType,
              children: children.length > 0 ? children : undefined
            });
          }
        });
        return result;
      };
    return (
        // <div className='flex flex-col'>
        //     <Form
        //         scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        //         form={form}
        //         initialValues={getSearchParams()}
        //         onValuesChange={() => {
        //             const values = form.getFieldsValue();
        //             setSearchParams(values);
        //         }}
        //     >
        //         <Form.Item<FindType> className='max-w-[520px]' name='keyword'>
        //             <InputSearch className='bg-[#FFFFFF] rounded-lg mt-10 border' placeholder={serviceTypesMessages.name}/>
        //         </Form.Item>
        //     </Form>
        //     <List 
        //         className='mt-6 mb-4'
        //         itemLayout='vertical'
        //         loading={isLoading}
        //         size='large'
        //         dataSource={serviceTypes || []}
        //         renderItem={(item) => (
        //             <List.Item
        //                 className='hover:bg-slate-200 cursor-pointer'
        //                 key={item.serviceTypeId}
        //                 onClick={() => onSelectServiceType(item.serviceTypeId)}
        //             >
        //                 <div className='flex flex-row items-center justify-between'>
        //                     <Typography.Text className='text-left text-[16px]/[20px] text-[#181818] tracking-normal opacity-100 w-[456px]'>{item.name}</Typography.Text>
        //                     <CustomerLookupTableArrowRight width={7} height={14} />
        //                 </div>
        //             </List.Item>
        //         )}
        //     >

        //     </List>
        // </div>
        <div className='container-list'>
          <Form
            scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
            form={form}
            initialValues={getSearchParams()}
            onValuesChange={() => {
              const values = form.getFieldsValue();
              setSearchParams(values);
            }}
          >
            <div>
              <div className='flex items-center gap-4'>
                <Form.Item<FindType> className='mb-0 mr-6 max-w-[400px] flex-1' name='keyword'>
                  <InputSearch placeholder={serviceTypesMessages.name} />
                </Form.Item>
              </div>
            </div>
          </Form>
          <div>
            <Table
            className='rounded-none'
            showHeader={false}
              count={serviceTypesHierarchy?.length ?? 0}
              loading={isLoading}
              columns={columns}
              dataSource={serviceTypesHierarchy ? searchServiceTypes(serviceTypesHierarchy, keyword) : []}
              rowKey={(record: ServiceTypeHierarchyDto) => record.serviceTypeId}
              onRow={(record: ServiceTypeHierarchyDto) => ({
                onClick: () => {
                    // onSelectServiceType(record.serviceTypeId);
                    // setOpen(true);
                    if (!record.children || record.children.length === 0) {
                      onSelectServiceType(record.serviceTypeId);
                      setOpen(true);
                    }
                    const isExpanded = expandedKeys.includes(record.serviceTypeId);
                    if (isExpanded) {
                      setExpandedKeys((prev) => prev.filter((key) => key !== record.serviceTypeId));
                    } else {
                      setExpandedKeys((prev) => [...prev, record.serviceTypeId]);
                    }
                }
              })}
              expandable={{
                expandedRowKeys: expandedKeys,
                onExpand: handleOnExpand
              }}
              scroll={{
                x: 1300
              }}
            />
          </div>

        </div>
    )
}

export default ServiceTypeList