import { Layout, Typography } from 'antd';
import { CustomerLookupWaitingCall } from 'components/customer-lookup';
import { InternalChatManage } from 'components/internal-chat';
import { NotesManage } from 'components/notes';
import { NotificationsManage } from 'components/notifications';
import { OmniChanelWaiting } from 'components/omni-channel-chat';
import { CurrentActiveShift } from 'components/shifts';
import { useProfile } from 'hooks';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectHasSelectShiftPermission } from 'store/features';
import { ID_CRM_HEADER, PERMISSION } from 'utils';
import { checkPermissions } from 'utils/check-permission';

const { Header: HeaderAntd } = Layout;

const Header = () => {
  const location = useLocation();
  const { profile, userPermissionIds } = useProfile();
  const hasSelectShiftPermission = useSelector(selectHasSelectShiftPermission);
  const isAllowChatCustomer = checkPermissions(userPermissionIds, PERMISSION.CHAT_CUSTOMER);
  const isAllowCallCustomer = checkPermissions(userPermissionIds, PERMISSION.CALL_CUSTOMER);

  useEffect(() => {
    const crmHeader = document.getElementById(ID_CRM_HEADER);
    if (crmHeader) {
      crmHeader.innerHTML = '';
    }
  }, [location.pathname]);

  return (
    <div>
      <HeaderAntd className='relative flex items-center bg-colorBgHeader px-8'>
        <div id={ID_CRM_HEADER} />
        <div className='ml-auto' />
        <div className='rounded-full bg-textHoverBg px-4 leading-10'>
          <Typography.Text className='whitespace-nowrap text-sm'>{profile?.fullName}</Typography.Text>
        </div>
        {hasSelectShiftPermission && (
          <div className='ml-2'>
            <CurrentActiveShift />
          </div>
        )}
        <NotesManage />
        <InternalChatManage />
        <NotificationsManage />

        {isAllowCallCustomer && profile?.extensionId ? <CustomerLookupWaitingCall /> : null}
        {isAllowChatCustomer && <OmniChanelWaiting />}
      </HeaderAntd>
    </div>
  );
};

export default memo(Header);
