import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useAddQueueMember, useAgentStatusChange, useAnswerSoftPhone, useChangeagentMapSeatAgentStatus, useGetSoftPhoneAuthStatus, useGetSoftPhoneToken, useHangUpSoftPhone, useLoginSoftPhone, useMuteSoftPhone, usePauseAgent, useProfile, useRemoveQueueMember, useUnpauseAgent } from 'hooks';
import { useOmniCallContext } from 'contexts';
import { sendUpdateAgentMapNotification, useCreateAgentStatusChangeMutation, useLazyGetAgentStatusQuery, useLazyListQueueNameQuery } from 'services';
import { AGENT_STATUS, CALLSTATUS, CALL_DIRECTION, PERMISSION } from 'utils';
import { ChannelObject } from 'utils/constants/contact-event-channel.constant';
import FailedLoginContent from './FailedLoginCotent';
import IncomingCallContent from './IncomingCallContent';
import InCallContent from './InCallContent';
import CreatingTicketContent from './CreatingTicketContent';
import TemporarilyPausedContent from './TemporarilyPausedContent';
import ReadyStatusContent from './ReadyStatusContent';
import InCallMuteContent from './InCallMuteContent';
import { useDispatch } from 'react-redux';
import FailedLoginWorldPhoneWarningModal from './FailedLoginWorldPhoneWarningModal';
import { useCreateContactEventMutation } from 'services/tickets';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { checkPermissions } from 'utils/check-permission';
const sourceDisplayNames: Record<string, string> = {
    'telco': 'VNPT',
    'webcall': 'WEBCALL',
    'appcall': 'APPCALL',
    'voice': 'VOICE',
    'video': 'VIDEO',
};

// Map callSource to channelId
const sourceToChannelMapping: Record<string, number> = {
    'telco': ChannelObject.DIEN_THOAI.id,
    'webcall': ChannelObject.DIEN_THOAI_WEB.id,
    'appcall': ChannelObject.DIEN_THOAI_APP.id,
    'voice': ChannelObject.DIEN_THOAI.id,
    'video': ChannelObject.WEB360_VIDEO.id,
};

const getSourceDisplayName = (source: string): string => {
    return sourceDisplayNames[source.toLowerCase()] || source.toUpperCase();
};

// Get channelId from callSource
const getChannelIdFromSource = (source: string): number => {
    return sourceToChannelMapping[source?.toLowerCase()]  // Default to DIEN_THOAI if mapping not found
};

const CustomerLookupPhone = () => {
    const [currentState, setCurrentState] = useState(AGENT_STATUS.FAILED_LOGIN);
    const { onLoginSoftPhoneHandle } = useLoginSoftPhone();
    const { isAuthenticatedSoftPhone } = useGetSoftPhoneAuthStatus();
    const { onAnswerSoftPhoneHandle } = useAnswerSoftPhone();
    const { onHangUpSoftPhoneHandle } = useHangUpSoftPhone();
    const { onMuteSoftPhoneHandle } = useMuteSoftPhone();
    const { isAuthenticated, profile } = useProfile();
    const { onGetWorldPhoneToken } = useGetSoftPhoneToken();
    const { softPhoneToken } = useGetSoftPhoneAuthStatus();
    const { socketData, agentStatus, setGetAgentStatus } = useOmniCallContext();
    const { onUnpauseQueueAgent } = useUnpauseAgent();
    const { onPauseQueueAgent } = usePauseAgent();
    const [fetchAgentStatus, { data: currentFetchAgentStatus }] = useLazyGetAgentStatusQuery();
    const [fetchListQueueName] = useLazyListQueueNameQuery();
    const { onRemoveQueueMember } = useRemoveQueueMember();
    const { onAddQueueMember } = useAddQueueMember();
    const [seconds, setSeconds] = useState(0);
    const { onCreateAgentStatusChangeHandle } = useAgentStatusChange()
    const [openFailedLoginWorldPhoneWarning, setOpenFailedLoginWorldPhoneWarning] = useState(false);
    const { onChangeAgentStatusAgentMapSeat } = useChangeagentMapSeatAgentStatus()
    const dispatch = useDispatch()
    const isAgentCreatingTicket = localStorage.getItem("isAgentCreatingTicket")
    const [onCreateContactEvent] = useCreateContactEventMutation();
    const shiftMeActive = useSelector((state: RootState) => state.shifts.shiftMeActive);
    const [isAnswering, setIsAnswering] = useState(false);

    const { userPermissionIds } = useProfile();
    const isAllowChatCustomer = checkPermissions(userPermissionIds, PERMISSION.CHAT_CUSTOMER);


    const updateAgentStatusReady = () => {
        setGetAgentStatus({
            worldPhoneState: AGENT_STATUS.IDLE,
            agentState: AGENT_STATUS.AGENT_UNPAUSED
        })
        setCurrentState(AGENT_STATUS.AGENT_UNPAUSED)
        if (profile?.extensionId) {
            sendUpdateAgentMapNotification({ extensionId: profile?.extensionId, nextState: AGENT_STATUS.AGENT_UNPAUSED })
        }
        if (profile?.userId && profile?.extensionId && currentState !== AGENT_STATUS.AGENT_UNPAUSED) {
            onCreateAgentStatusChangeHandle({
                userId: profile?.userId,
                extensionId: profile?.extensionId,
                previousState: currentState,
                nextState: AGENT_STATUS.AGENT_UNPAUSED
            })
            //onChangeAgentStatusAgentMapSeat({ userId: profile?.userId, status: AGENT_STATUS.AGENT_UNPAUSED })

        }
        localStorage.removeItem("isAgentCreatingTicket")
    }


    const updateAgentStatusPause = () => {
        setGetAgentStatus({
            worldPhoneState: AGENT_STATUS.IDLE,
            agentState: AGENT_STATUS.AGENT_PAUSED
        })
        setCurrentState(AGENT_STATUS.AGENT_PAUSED)
        if (profile?.extensionId) {
            sendUpdateAgentMapNotification({ extensionId: profile?.extensionId, nextState: AGENT_STATUS.AGENT_PAUSED })
        }

        if (profile?.userId && profile?.extensionId && currentState !== AGENT_STATUS.AGENT_PAUSED) {
            onCreateAgentStatusChangeHandle({
                userId: profile?.userId,
                extensionId: profile?.extensionId,
                previousState: currentState,
                nextState: AGENT_STATUS.AGENT_PAUSED
            })
            //onChangeAgentStatusAgentMapSeat({ userId: profile?.userId, status: AGENT_STATUS.AGENT_PAUSED })
        }

    }
    const updateAgentStatusCreatingTicket = () => {
        setCurrentState(AGENT_STATUS.CREATING_TICKET);
        setGetAgentStatus({
            worldPhoneState: AGENT_STATUS.IDLE,
            agentState: AGENT_STATUS.CREATING_TICKET
        })
        toggleAgentStatusWithoutChangingLayout(AGENT_STATUS.AGENT_PAUSED)
        if (profile?.userId && profile?.extensionId && currentState !== AGENT_STATUS.CREATING_TICKET) {
            sendUpdateAgentMapNotification({ extensionId: profile?.extensionId, nextState: AGENT_STATUS.CREATING_TICKET })

            onCreateAgentStatusChangeHandle({
                userId: profile?.userId,
                extensionId: profile?.extensionId,
                previousState: currentState,
                nextState: AGENT_STATUS.CREATING_TICKET
            })
            //onChangeAgentStatusAgentMapSeat({ userId: profile?.userId, status: AGENT_STATUS.CREATING_TICKET })
        }
    }
    const updateAgentStatusFailedLogin = () => {
        setCurrentState(AGENT_STATUS.FAILED_LOGIN);
        setGetAgentStatus({
            worldPhoneState: AGENT_STATUS.LOGGEDOFF,
            agentState: AGENT_STATUS.FAILED_LOGIN
        })
        if (profile?.userId && profile?.extensionId && currentState !== AGENT_STATUS.FAILED_LOGIN) {
            sendUpdateAgentMapNotification({ extensionId: profile?.extensionId, nextState: AGENT_STATUS.FAILED_LOGIN })
            onCreateAgentStatusChangeHandle({
                userId: profile?.userId,
                extensionId: profile?.extensionId,
                previousState: currentState,
                nextState: AGENT_STATUS.FAILED_LOGIN
            })
        }

    }
    const updateAgentStatusOnCall = () => {
        setCurrentState(AGENT_STATUS.ONCALL);
        setGetAgentStatus({
            worldPhoneState: AGENT_STATUS.ONCALL,
            agentState: AGENT_STATUS.ONCALL
        })
        if (profile?.userId && profile?.extensionId && currentState !== AGENT_STATUS.ONCALL) {
            sendUpdateAgentMapNotification({ extensionId: profile?.extensionId, nextState: AGENT_STATUS.ONCALL })
            onCreateAgentStatusChangeHandle({
                userId: profile?.userId,
                extensionId: profile?.extensionId,
                previousState: currentState,
                nextState: AGENT_STATUS.ONCALL
            })
            //onChangeAgentStatusAgentMapSeat({ userId: profile?.userId, status: AGENT_STATUS.ONCALL })

        }

    }
    const updateAgentStatusMuteCall = () => {
        // Set currentState to MUTE_ON instead of ONCALL to properly track muted state
        setCurrentState(AGENT_STATUS.MUTE_ON);
        setGetAgentStatus({
            worldPhoneState: AGENT_STATUS.ONCALL,
            agentState: AGENT_STATUS.MUTE_ON
        })
        if (profile?.userId && profile?.extensionId && currentState !== AGENT_STATUS.MUTE_ON) {
            sendUpdateAgentMapNotification({ extensionId: profile?.extensionId, nextState: AGENT_STATUS.ONCALL })
            onCreateAgentStatusChangeHandle({
                userId: profile?.userId,
                extensionId: profile?.extensionId,
                previousState: currentState,
                nextState: AGENT_STATUS.MUTE_ON
            })
        }

    }
    const updateAgentStatusRinging = () => {
        setCurrentState(AGENT_STATUS.RINGING);
        setGetAgentStatus({
            worldPhoneState: AGENT_STATUS.RINGING,
            agentState: AGENT_STATUS.RINGING
        })
        if (profile?.userId && profile?.extensionId && currentState !== AGENT_STATUS.RINGING) {
            sendUpdateAgentMapNotification({ extensionId: profile?.extensionId, nextState: AGENT_STATUS.RINGING })
            onCreateAgentStatusChangeHandle({
                userId: profile?.userId,
                extensionId: profile?.extensionId,
                previousState: currentState,
                nextState: AGENT_STATUS.RINGING
            })
            //onChangeAgentStatusAgentMapSeat({ userId: profile?.userId, status: AGENT_STATUS.RINGING })

        }

    }
    // const updateAgentStatusCreatingTicket = () => {
    //     setCurrentState(AGENT_STATUS.CREATING_TICKET);
    //     setGetAgentStatus({
    //         worldPhoneState: AGENT_STATUS.IDLE,
    //         agentState: AGENT_STATUS.CREATING_TICKET
    //     })
    //     toggleAgentStatusWithoutChangingLayout(AGENT_STATUS.AGENT_PAUSED)
    // }
    // const updateAgentStatusFailedLogin = () => {
    //     setCurrentState(AGENT_STATUS.FAILED_LOGIN);
    //     setGetAgentStatus({
    //         worldPhoneState: AGENT_STATUS.LOGGEDOFF,
    //         agentState: AGENT_STATUS.FAILED_LOGIN
    //     })

    // }
    // const updateAgentStatusOnCall = () => {
    //     setCurrentState(AGENT_STATUS.ONCALL);
    //     setGetAgentStatus({
    //         worldPhoneState: AGENT_STATUS.ONCALL,
    //         agentState: AGENT_STATUS.ONCALL
    //     })

    // }
    // const updateAgentStatusMuteCall = () => {
    //     setCurrentState(AGENT_STATUS.ONCALL);
    //     setGetAgentStatus({
    //         worldPhoneState: AGENT_STATUS.ONCALL,
    //         agentState: AGENT_STATUS.MUTE_ON
    //     })

    // }
    // const updateAgentStatusRinging = () => {
    //     setCurrentState(AGENT_STATUS.INCOMING_CALL);
    //     setGetAgentStatus({
    //         worldPhoneState: AGENT_STATUS.RINGING,
    //         agentState: AGENT_STATUS.INCOMING_CALL
    //     })

    // }
    const handleFetchStatus = () => {
        if (profile?.extensionId) {
            fetchAgentStatus({ extension: profile.extensionId });
        }
    };
    const toggleAgentStatusWithoutChangingLayout = async (action: AGENT_STATUS.AGENT_PAUSED | AGENT_STATUS.AGENT_UNPAUSED) => {
        try {
            if (!profile?.extensionId) {
                message.error("Extension ID không hợp lệ")
                console.error("Invalid profile extension ID");
                return;
            }
            const actionFunction = action === AGENT_STATUS.AGENT_PAUSED ? onRemoveQueueMember : onAddQueueMember;
            const queueResult = await actionFunction({ extension: profile.extensionId });
            const aqmStatuses = queueResult?.data?.map(item => item.aqmStatus);
            if (action === AGENT_STATUS.AGENT_PAUSED) {
                if (aqmStatuses.includes("REMOVED") || aqmStatuses.includes("NOSUCHQUEUE")) {
                    message.success("Chuyển đổi trạng thái: Đang tạo phiếu");
                }
            }
        } catch (error) {
            console.error(`Gặp lỗi khi đổi trạng thái ${action}:`, error);
            message.error(`Gặp lỗi khi đổi trạng thái ${action}`);
            throw error;
        }
    };
    const toggleAgentStatus = async (action: AGENT_STATUS.AGENT_PAUSED | AGENT_STATUS.AGENT_UNPAUSED) => {
        try {
            if (!profile?.extensionId) {
                message.error("Extension ID không hợp lệ")
                console.error("Invalid profile extension ID");
                return;
            }

            // Xác định action phù hợp
            const actionFunction = action === AGENT_STATUS.AGENT_PAUSED ? onRemoveQueueMember : onAddQueueMember;

            // Gọi API để cập nhật trạng thái
            const queueResult = await actionFunction({ extension: profile.extensionId });

            // Lấy danh sách trạng thái từ phản hồi
            const aqmStatuses = queueResult?.data?.map(item => item.aqmStatus);

            // Kiểm tra trạng thái để quyết định chuyển đổi
            if (action === AGENT_STATUS.AGENT_PAUSED) {
                if (aqmStatuses.includes("REMOVED") || aqmStatuses.includes("NOSUCHQUEUE")) {
                    message.success("Chuyển đổi trạng thái: Chưa sẵn sàng");
                    updateAgentStatusPause()

                } else {
                    message.error("Chuyển đổi trạng thái không thành công");
                }
            } else {
                if (aqmStatuses.includes("ADDED") || aqmStatuses.includes("MEMBERALREADY")) {
                    updateAgentStatusReady()
                    message.success("Chuyển đổi trạng thái: Sẵn sàng");
                } else {
                    message.error("Chuyển đổi trạng thái không thành công");
                }
            }
        } catch (error) {
            console.error(`Gặp lỗi khi đổi trạng thái ${action}:`, error);
            message.error(`Gặp lỗi khi đổi trạng thái ${action}`);
            throw error;
        }
    };
    const isAgentReady = async () => {
        try {
            const response = await fetchAgentStatus({ extension: profile?.extensionId as string }).unwrap();

            // const queue = response?.data?.[0]?.queues?.queue;
            // if (!queue?.length) {
            //     console.error("No valid queue data or queue is empty:", response);
            //     return;
            // }

            // Determine which function to call based on action

            // Process queues

            // Determine new state based on queue status
            toggleAgentStatus(AGENT_STATUS.AGENT_UNPAUSED);
        } catch (error) {
            message.error(`Gặp lỗi khi lấy trạng thái điện thoại viên`);
        }
    }
    const fetchAndLoginSoftPhone = async () => {
        if (!isAuthenticated || !profile?.extensionId) return;

        try {
            const res = await onGetWorldPhoneToken({ userExtension: profile.extensionId });

            if (res?.data?.token) {
                try {
                    const response = await onLoginSoftPhoneHandle({
                        username: profile.username,
                        token: res.data.token,
                    });
                    if (response?.status === 1) {
                        updateAgentStatusPause()
                        //setCurrentState(AGENT_STATUS.AGENT_PAUSED)
                        //toggleAgentStatus(AGENT_STATUS.AGENT_PAUSED);
                    }
                } catch (error) {
                    setOpenFailedLoginWorldPhoneWarning(true);
                    // console.error("Error logging into softphone:", error);
                    // message.error("Gặp lỗi khi đăng nhập vào softphone");
                }


            }
        } catch (error) {
            console.log(error)
            //console.error("Error fetching or logging into softphone:", error);
        }
    };


    const answerSoftPhone = async () => {
        const userExtension = profile?.extensionId;

        if (!isAuthenticatedSoftPhone || !userExtension) return;

        try {
            // Set loading state to true
            setIsAnswering(true);

            const res = await onGetWorldPhoneToken({ userExtension });
            if (res?.data?.token) {
                const response = await onAnswerSoftPhoneHandle({ token: res.data.token });
                const { status } = response
                switch (status) {
                    case 1:
                        updateAgentStatusOnCall()
                        message.success('Trả lời cuộc gọi thành công');
                        break;
                    case 41:
                        message.error('Token không hợp lệ');
                        updateAgentStatusFailedLogin()
                        break;
                    case 42:
                        message.error('Không có tài khoản SIP');
                        updateAgentStatusFailedLogin()
                        break;
                    case 43:
                        message.error('Tài khoản SIP không online');
                        updateAgentStatusFailedLogin()
                        break;
                    case 44:
                        message.error('Người dùng hiện đang không trong cuộc gọi');
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            message.error("Gặp lỗi khi trả lời cuộc gọi");
            throw error;
        } finally {
            // Set loading state to false after API completes (success or error)
            setIsAnswering(false);
        }
    };


    const hangUpSoftPhone = async () => {
        try {
            const userExtension = profile?.extensionId;
            if (!isAuthenticatedSoftPhone || !userExtension) return;

            await onGetWorldPhoneToken({ userExtension });

            if (softPhoneToken) {
                const response = await onHangUpSoftPhoneHandle({ token: softPhoneToken });
                const { status } = response
                switch (status) {
                    case 1:
                        updateAgentStatusCreatingTicket()
                        message.success('Gác máy thành công');
                        break;
                    case 51:
                        message.error('Token không hợp lệ');
                        updateAgentStatusFailedLogin()
                        break;
                    case 52:
                        message.error('Không có tài khoản SIP');
                        updateAgentStatusFailedLogin()
                        break;
                    case 53:
                        message.error('Tài khoản SIP không online');
                        updateAgentStatusFailedLogin()
                        break;
                    case 54:
                        message.error('Người dùng hiện đang không trong cuộc gọi');
                        setCurrentState(AGENT_STATUS.AGENT_UNPAUSED);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error("Gặp lỗi khi gác máy", error);
        }
    };


    const muteSoftPhone = async (isMuteAction: boolean) => {
        try {
            const userExtension = profile?.extensionId;
            if (!isAuthenticatedSoftPhone || !userExtension) return;

            const action = isMuteAction ? 'yes' : 'no'
            if (softPhoneToken) {
                const response = await onMuteSoftPhoneHandle({ token: softPhoneToken, mute: action, extension: userExtension });
                const { status } = response
                if (status === 1) {
                    if (isMuteAction) {
                        message.success('Tắt tiếng điện thoại thành công');
                        updateAgentStatusMuteCall()
                    }
                    else {
                        message.success('Bật tiếng điện thoại thành công');
                        updateAgentStatusOnCall()
                    }


                }
                else {
                    message.error('Thao tác không thành công');
                }

            }
        } catch (error) {
            console.error("Gặp lỗi khi thao tác mute/unmute", error);
        }
    };


    useEffect(() => {
        handleFetchStatus()
        switch (agentStatus) {
            case AGENT_STATUS.UNKNOWN:
            case AGENT_STATUS.LOGGEDOFF:
                updateAgentStatusFailedLogin();
                break;
            case AGENT_STATUS.IDLE:
                if (isAgentCreatingTicket && parseInt(isAgentCreatingTicket)) {
                    updateAgentStatusCreatingTicket()
                    return
                }
                if (currentFetchAgentStatus?.data[0].queues.queue.length) {
                    updateAgentStatusReady()
                }
                else {
                    updateAgentStatusPause()

                }
                break;
            case AGENT_STATUS.ONCALL:
                // Check if we're in mute state to preserve it
                if (currentState !== AGENT_STATUS.MUTE_ON) {
                    setCurrentState(AGENT_STATUS.ONCALL);
                }
                break;
            case AGENT_STATUS.RINGING:
                setCurrentState(AGENT_STATUS.RINGING);
                break;
            default:
                setCurrentState(AGENT_STATUS.FAILED_LOGIN);
                break;
        }
    }, [currentFetchAgentStatus]);
    useEffect(() => {

        switch (socketData?.callStatus) {
            case CALLSTATUS.DAILING:
                updateAgentStatusRinging();
                if (profile?.extensionId && profile?.username && shiftMeActive?.shiftId && socketData?.customerNumber && socketData?.callSource) {
                    // Use callSource to determine channelId
                    // const channelId = getChannelIdFromSource(socketData?.callSource);

                    // onCreateContactEvent({
                    //     agentId: profile?.userId,
                    //     agentName: profile?.username,
                    //     channelId: channelId,
                    //     contactAccount: socketData?.customerNumber,
                    //     contactId: socketData?.callUuid,
                    //     flowCode: socketData?.direction,
                    //     shiftId: shiftMeActive?.shiftId,
                    // }).unwrap()
                }
                break;
            case CALLSTATUS.HANGUP:
                updateAgentStatusCreatingTicket()
                localStorage.setItem("isAgentCreatingTicket", "1")
                break;
            default:
                break;
        }
    }, [socketData]);

    const renderStateContent = () => {
        const commonProps = { isMinimized: isAllowChatCustomer ? true : false };

        switch (currentState) {
            case AGENT_STATUS.UNKNOWN:
            case AGENT_STATUS.LOGGEDOFF:
            case AGENT_STATUS.FAILED_LOGIN:
                return <FailedLoginContent {...commonProps} fetchAndLoginSoftPhone={fetchAndLoginSoftPhone} />;
            case AGENT_STATUS.RINGING:
                return <IncomingCallContent
                    {...commonProps}
                    answerSoftPhone={answerSoftPhone}
                    socketData={socketData}
                    getSourceDisplayName={getSourceDisplayName}
                    isAnswering={isAnswering}
                />;
            case AGENT_STATUS.ONCALL:
            case AGENT_STATUS.MUTE_ON:
                return <InCallContent
                    {...commonProps}
                    hangUpSoftPhone={hangUpSoftPhone}
                    muteSoftPhone={muteSoftPhone}
                    currentState={currentState}
                    socketData={socketData}
                    getSourceDisplayName={getSourceDisplayName}
                    isMuted={currentState === AGENT_STATUS.MUTE_ON} // Pass true if muted state
                />;
            case AGENT_STATUS.CREATING_TICKET:
                return <CreatingTicketContent {...commonProps} toggleAgentStatus={toggleAgentStatus} />;
            case AGENT_STATUS.AGENT_PAUSED:
                return <TemporarilyPausedContent {...commonProps} toggleAgentStatus={toggleAgentStatus} />;
            case AGENT_STATUS.AGENT_UNPAUSED:
                return <ReadyStatusContent {...commonProps} toggleAgentStatus={toggleAgentStatus} />;
            case AGENT_STATUS.IDLE:
                return <TemporarilyPausedContent {...commonProps} toggleAgentStatus={toggleAgentStatus} />;
            default:
                return <FailedLoginContent {...commonProps} fetchAndLoginSoftPhone={fetchAndLoginSoftPhone} />;
        }
    };

    return <div className='h-full'>
        <FailedLoginWorldPhoneWarningModal
            open={openFailedLoginWorldPhoneWarning}
            onCancel={() => {
                setOpenFailedLoginWorldPhoneWarning(false);
            }} />
        {renderStateContent()}
    </div>;
};

export default CustomerLookupPhone;
