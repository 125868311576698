import { CheckPermissionLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessConfigManagementRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_SYSTEM]} />,
    children: [
      {
        path: ROUTE.SERVICE_TYPES,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_SERVICE_TYPE]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/ServiceTypes'))
          }
        ]
      },
      {
        path: ROUTE.TICKET_SAMPLES,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_TICKET_SAMPLE]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/TicketSamples'))
          }
        ]
      },
      {
        path: ROUTE.REASONS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_REASON]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/Reasons'))
          }
        ]
      },
      {
        path: ROUTE.SHIFTS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_SHIFT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/Shifts'))
          }
        ]
      },
      {
        path: ROUTE.TICKET_CANCEL_REASONS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_TICKET_CANCEL_REASON]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/TicketCancelReasons'))
          }
        ]
      }
    ]
  }
];
