import { Button, Image, Typography } from 'antd';
import {
    CheckIcon,
    coppywriting,
    PauseIcon,
} from 'assets';
import { useProfile } from 'hooks';
import { customerLookupMessages } from 'messages/customer-lookup.message';
import { AGENT_STATUS } from 'utils';

interface CreatingTicketProps {
    isMinimized: boolean;
    toggleAgentStatus: (status: AGENT_STATUS.AGENT_PAUSED | AGENT_STATUS.AGENT_UNPAUSED) => void;
}

const CreatingTicketContent: React.FC<CreatingTicketProps> = ({ isMinimized, toggleAgentStatus }) => {
    const { profile } = useProfile();

    return isMinimized ? (
        <div className="w-[400px] flex h-[244px] flex-col rounded-tl-xl">
            <div className="m-5 flex flex-col">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col">
                        <Typography.Text className="text-left text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                            EXT #{profile?.extensionId || ''}
                        </Typography.Text>
                        <Typography.Text className="mt-3 text-left font-semibold tracking-normal text-[#000000] opacity-100">
                            {customerLookupMessages.CreatingTicket}
                        </Typography.Text>
                    </div>
                    <Image src={coppywriting} className="h-[58px] w-[58px]" alt="coppywriting" />
                </div>
                <Typography.Text className="mt-3 text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                    {customerLookupMessages.isCreatingTicket}
                </Typography.Text>
                <div className="flex flex-row gap-4">
                    {/* <Button
                        style={{ borderColor: "#D4D8DE" }}
                        className="mt-9 h-9 w-[170px]"
                        icon={<PauseIcon />}
                        onClick={() => setCurrentState(AGENT_STATUS.AGENT_PAUSED)}
                    >
                        <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">
                            {customerLookupMessages.pause}
                        </Typography.Text>
                    </Button> */}
                    <Button
                        style={{ borderColor: "#06A77D" }}
                        className="mt-9 h-9 w-[170px]"
                        icon={<CheckIcon />}
                        onClick={() => toggleAgentStatus(AGENT_STATUS.AGENT_UNPAUSED)}
                    >
                        <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">
                            {customerLookupMessages.ready}
                        </Typography.Text>
                    </Button>
                </div>
            </div>
        </div>
    ) : (
        <div className="flex h-full w-[400px] flex-col rounded-l-xl bg-[#FFFFFF]">
            <div className="m-5 flex flex-col items-center justify-center">
                <Typography.Text className="text-center text-[12px]/[16px] font-semibold tracking-normal text-[#868D87]">
                    EXT #{profile?.extensionId || ''}
                </Typography.Text>
                <Image src={coppywriting} className="mt-[80px] h-[112px] w-[112px]" alt="coppywriting" />
                <Typography.Text className="mt-[52px] text-center font-semibold tracking-normal text-[#000000] opacity-100">
                    {customerLookupMessages.CreatingTicket}
                </Typography.Text>
                <Typography.Text className="mt-2 text-center text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">
                    {customerLookupMessages.isCreatingTicket}
                </Typography.Text>
                <div className="mt-[50px] flex flex-row gap-4">
                    {/* <Button
                        style={{ borderColor: "#D4D8DE" }}
                        className="mt-9 h-9 w-[170px]"
                        icon={<PauseIcon />}
                        onClick={() => setCurrentState(AGENT_STATUS.AGENT_PAUSED)}
                    >
                        <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">
                            {customerLookupMessages.pause}
                        </Typography.Text>
                    </Button> */}
                    <Button
                        style={{ borderColor: "#06A77D" }}
                        className="mt-9 h-9 w-[170px]"
                        icon={<CheckIcon />}
                        onClick={() => toggleAgentStatus(AGENT_STATUS.AGENT_UNPAUSED)}
                    >
                        <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">
                            {customerLookupMessages.ready}
                        </Typography.Text>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreatingTicketContent;
