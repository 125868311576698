import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { useLogoutSoftPhoneMutation } from 'services/softphone/softphone.api';
import { setAuthenticated } from 'store/features/auth/auth.slice';
import { setAuthenticatedSoftPhone } from 'store/features/sip-auth/sip-auth.slice';
import { LogoutSoftPhoneDto } from 'types/dto/softphone/logout-softphone.dto';
import { useOmniCallContext } from 'contexts';
import { AGENT_STATUS } from 'utils';

export function useLogoutSoftPhone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setGetAgentStatus } = useOmniCallContext();

  const [logoutSoftPhone, { isLoading, error }] = useLogoutSoftPhoneMutation();

  const onLogoutSoftPhoneHandle = async (values: LogoutSoftPhoneDto) => {
    try {
      logoutSoftPhone(values)
        .unwrap()
        .then((res: any) => {
          const { status } = res
          switch (status) {
            case 0:
              message.success('Đăng xuất World Phone thành công')
              dispatch(setAuthenticatedSoftPhone(false));
              setGetAgentStatus({
                worldPhoneState: AGENT_STATUS.LOGGEDOFF,
                agentState: AGENT_STATUS.FAILED_LOGIN
              })
              break;
            default:
              message.error('Đăng xuất World Phone không thành công')
              //dispatch(setAuthenticatedSoftPhone(true));
              break;

          }
        })
        .catch((err) => {
          message.error('Đăng xuất World Phone không thành công')
          //dispatch(setAuthenticatedSoftPhone(true));
        });
    } catch (err) {
      message.error('Đăng xuất World Phone không thành công')
      //dispatch(setAuthenticatedSoftPhone(true));
    }
  };
  return {
    isLoading,
    error,
    onLogoutSoftPhoneHandle
  };
}
