export const requestform = {
  //Title
  changeLocationRecord: 'Thay đổi địa chỉ',
  requestTicketCRM: 'PHIẾU YÊU CẦU CRM',
  serviceType: 'Loại dịch vụ',

  //RequestFilterForm
  deleteFilter: 'Xóa lọc',
  result: 'Áp dụng',
  statusRequestForm: 'Trạng thái phiếu yêu cầu',
  waitingConfirm: 'Chờ xác nhận',
  time: 'Thời gian',
  creatingFormTime: 'Thời gian tạo phiếu',
  fromDate: 'Từ ngày',
  toDate: 'Đến ngày',
  receptionTime: 'Thời gian tiếp nhận',
  processingTime: 'Thời gian xử lý',
  inChargeDepartment: 'Phòng đội phụ trách',
  typeOfService: 'Loại dịch vụ',
  delete: 'Xóa',
  filter: 'Bộ lọc',
  inputInformation: 'Nhập thông tin cần tìm',
  serviceTypelist: 'Dịch vụ',

  //RequestFormList
  sortBy: 'sắp xếp theo',
  sort: 'Sắp xếp',
  chooseAll: 'Chọn tất cả',
  ticket: 'phiếu',
  currentChoosenTicket: 'phiếu đang chọn',
  cancelChooseTicket: 'Hủy',
  receiveTicket: 'Tiếp nhận hồ sơ',
  outstagePowerArea: 'Mất điện khu vực',

  // Request Detail
  //Left
  createInformationTitle: 'Thông tin khởi tạo',
  dateRequest: 'Ngày yêu cầu:',
  ticketCreator: 'Người tạo:',
  appointmentDate: 'Ngày hẹn',
  receptionDate: 'Ngày tiếp nhận:',
  recipient: 'Người tiếp nhận:',
  corporateUnit: 'Đơn vị: ',
  completedDate: 'Ngày hoàn tất:',
  completedPersion: 'Người hoàn tất',
  processByCMIS: 'Điện lực xử lý',
  completeTitle: 'THÔNG TIN HOÀN TẤT',
  notCompleteStatus: 'Chưa hoàn thành',
  acceptChannel: 'Kênh tiếp nhận',

  //Right
  requestTicket: 'Phiếu yêu cầu #',
  answerQuestion: 'Hỏi đáp',
  notMultiplleTimeRequest: 'Không phải phiếu yêu cầu nhiều lần',
  multiplleTimeRequest: 'Phiếu yêu cầu nhiều lần',
  detailStatusRequestForm: 'Trạng thái phiếu:',
  isTranferCmisTicket: 'Phiếu chuyển CMIS: ',
  typeCmisTicket: '[Loại dịch vụ CMIS]',
  isCmisTransferTrue: 'Bắt buộc chuyển Cmis',
  isCmisTransferFalse: 'Không bắt buộc chuyển Cmis',
  cmisTransferFail: 'Chuyển CMIS thất bại',
  cmisTranfered: 'Đã chuyển CMIS #',
  tryAgainButton: 'Thử lại',

  customerInformationTitle: 'Thông tin khách hàng',
  customerName: 'Họ tên KH',
  customerPhone: 'Điện thoại KH',
  customerAddress: 'Địa chỉ KH',
  customerCode: 'Mã khách hàng',
  customerAddressUnitCode: 'Địa chính',
  meterSerialNumber: 'Số công tơ',
  routeCode: 'Phiên lộ trình',

  customerRequestInformationTitle: 'Thông tin người yêu cầu',
  customerRequestName: 'Họ tên người Y/C',
  customerRequestPhone: 'Điện thoại người Y/C',
  numberOfCalls: 'Số lần gọi',
  customerRequestAddress: 'Địa chỉ người Y/C',

  customerRequestCIN: 'Số CCCD',
  dateOfIssue: 'Ngày cấp',
  placeOfIssue: 'Nơi cấp',

  requestContentTitle: 'Nội dung yêu cầu',
  processingTitle: 'Quá trình xử lý',
  resolutionTitle: 'Kết quả xử lý yêu cầu',

  employee: 'Nhân viên',
  assignEmployee: 'Chọn nhân viên',
  reasonOutage: 'Nguyên nhân',
  timeOutage: 'Giờ mất điện',
  datePowerRestored: 'Ngày có điện',
  timePowerRestored: 'Giờ có điện',
  isPowerRestore: 'Khách hàng đã có điện lại',

  // Button
  exportExcel: 'Xuất excel',
  printTicket: 'In phiếu',
  transferCustomerCareCenter: 'Chuyển TTCSKH', // CCC - Customer Care Center: TTCSKH
  transferCollaboration: 'Chuyển phòng phối hợp',
  completedButton: 'Hoàn tất',
  completedCollaborationButton: 'Hoàn tất phối hợp',
  acceptTicket: 'Tiếp nhận',
  inprocessTicket: 'Cập nhật quá trình xử lý',
  updateTicket: 'Cập nhật',
  obstacleTickets: 'Trở ngại',
  updateObstacleTicket: 'Cập nhật trở ngại',
  completedObstacleTicket: 'Hoàn tất trở ngại',
  callAgain: 'Khách hàng liên hệ lại',
  update: 'Cập nhật',
  numOfRequest: 'lần gọi',

  // Confirm Modal
  confirmChangeCMIS: 'Xác nhận chuyển CMIS',
  confirmChangeCMISContent: 'Vui lòng xác nhận chuyển phiếu đến hệ thống CMIS với loại dịch vụ',
  confirmButton: 'Xác nhận',

  //Transfer organization unit
  chooseOrganizationUnit: 'Chọn phòng phối hợp',

  //Obstacle modal
  obstacleTicketProcessing: 'Trở ngại xử lý phiếu yêu cầu',
  obstacleContent: 'Nội dung trở ngại',
  estimatedEndDatePicker: 'dd/mm/yyyy',

  //Transfer Ticket To
  cancelTicket: 'Hủy',
  transferToElectricity: 'Chuyển Điện Lực',
  transferToCSKH: 'Chuyển CSKH',
  transferToKT: 'Chuyển phòng KT',
  transferToCenter: 'Chuyển trung tâm',

  changeTicketInformation: 'Thay đổi thông tin phiếu',

  oldLocation: 'Địa chỉ cũ',
  newLocation: 'Địa chỉ mới',
  changeTo: 'Chuyển sang'
};
