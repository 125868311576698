import { Dropdown, MenuProps, Typography } from 'antd';
import { AgentMapDoubleDoor, AgentMapSingleDoor, AgentMapSlidingDoor, AgentMapWall } from 'assets';
import { useAgentMapContext } from 'contexts';
import { agentMapsMessages } from 'messages/agent-maps.messages';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { FabricAgentMapSeat } from 'types/fabric-agent-map';
import { AGENT_MAP_GRID_SIZE, AGENT_MAP_OBJECT_TYPE, roundToNearestMultipleX } from 'utils';

type AgentMapContextMenuOtherObjectProps = {
  items?: MenuProps['items'];
};

export type AgentMapContextMenuRef = {
  open: (position: { x: number; y: number }) => void;
};

const AgentMapContextMenu = forwardRef<AgentMapContextMenuRef, AgentMapContextMenuOtherObjectProps>(
  ({ items }, ref) => {
    useImperativeHandle(ref, () => ({
      open: handleRightClick
    }));

    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [otherObjectId, setSeatId] = useState<string | number>();

    const { handleAddSeat, handleAddObject } = useAgentMapContext();

    const onAddSeat = (seat?: Partial<FabricAgentMapSeat>) => {
      handleAddSeat({
        ...seat,
        left: roundToNearestMultipleX(position.x, AGENT_MAP_GRID_SIZE / 2),
        top: roundToNearestMultipleX(position.y, AGENT_MAP_GRID_SIZE / 2)
      });
    };

    const handleObject = (objectTypeId: AGENT_MAP_OBJECT_TYPE) => {
      handleAddObject({
        objectTypeId,
        left: roundToNearestMultipleX(position.x, AGENT_MAP_GRID_SIZE / 2),
        top: roundToNearestMultipleX(position.y, AGENT_MAP_GRID_SIZE / 2)
      });
    };

    const handleRightClick = (position: { x: number; y: number }) => {
      setPosition({
        x: position.x,
        y: position.y
      });
      setSeatId(otherObjectId);
      setOpen(true);
    };

    const itemsMenu: MenuProps['items'] = [
      ...(items || []),
      {
        key: 'addSeat',
        label: agentMapsMessages.addSeat,
        icon: <div className='h-5 w-5 items-center justify-center rounded-[3px] border border-colorTextBase'></div>,
        onClick: () => onAddSeat()
      },
      {
        key: 'addSingleDoor',
        label: agentMapsMessages.addSingleDoor,
        icon: <AgentMapSingleDoor className='w-10' />,
        onClick: () => handleObject(AGENT_MAP_OBJECT_TYPE.SINGLE_DOOR)
      },
      {
        key: 'addDoubleDoor',
        label: agentMapsMessages.addDoubleDoor,
        icon: <AgentMapDoubleDoor className='w-10' />,
        onClick: () => handleObject(AGENT_MAP_OBJECT_TYPE.DOUBLE_DOOR)
      },
      {
        key: 'addSlidingDoor',
        label: agentMapsMessages.addSlidingDoor,
        icon: <AgentMapSlidingDoor className='w-10' />,
        onClick: () => handleObject(AGENT_MAP_OBJECT_TYPE.SLIDING_DOOR)
      },
      {
        key: 'addWall',
        label: agentMapsMessages.addWall,
        icon: <AgentMapWall className='w-10' />,
        onClick: () => handleObject(AGENT_MAP_OBJECT_TYPE.WALL)
      },
      {
        key: 'addText',
        label: agentMapsMessages.addText,
        icon: <Typography.Text className='text-center font-serif text-[16px]'>T</Typography.Text>,
        onClick: () => handleObject(AGENT_MAP_OBJECT_TYPE.TEXT)
      }
    ];

    if (open)
      return (
        <Dropdown
          menu={{
            items: itemsMenu
          }}
          trigger={['click']}
          open={open}
          onOpenChange={setOpen}
          getPopupContainer={() => document.body}
          overlayClassName='text-sm'
          prefixCls='message-dropdown'
        >
          <div
            style={{
              position: 'absolute',
              top: `${position.y}px`,
              left: `${position.x}px`,
              zIndex: 9999
            }}
          />
        </Dropdown>
      );
    return null;
  }
);

export default AgentMapContextMenu;
