export const serviceTypesMessages = {
  code: 'Mã dịch vụ',
  name: 'Tên loại dịch vụ',
  parent: 'Thuộc nhóm dịch vụ',
  priority: 'Độ ưu tiên',
  processingDeadline: 'Thời hạn xử lý',
  status: 'Trạng thái hoạt động',
  requester: 'Người yêu cầu',
  address: 'Địa chỉ',
  area: 'Phường/Quận',
  isNewAddress: 'Đây là địa chỉ mới',
  contactPhone: 'Điện thoại liên lạc',
  receivingChannel: 'Kênh tiếp nhận',
  cccdInformation: 'Thông tin CCCD',
  serviceType: 'Loại dịch vụ',
  appointmentDate: 'Ngày hẹn',
  file: 'Tệp tải lên',
  requestContent: 'Nội dung yêu cầu',
  notRequestToSendSms: 'Không phải yêu cầu gửi SMS',
  isCalledOut: 'Đây là cuộc gọi ra',
  electricalStatus: 'Tình trạng lưới điện',
  outageAt: 'Ngày giờ mất điện',
  reason: 'Nguyên nhân',
  outageEntireArea: 'Mất điện cả khu vực',
  notUpdateOutageManagement: 'ĐL không cập nhật QLMĐ',
  incidentAddress: 'Địa chỉ sự cố',
  incidentArea: 'Phường/Quận sự cố',
  phaseNumber: 'Số pha',
  intendedUse: 'Mục đích sử dụng',
  documentsCustomerNeedToPrepare: 'Các giấy tờ khách hàng cần chuẩn bị',
  create: 'Tạo mới dịch vụ',
  update: 'Cập nhật dịch vụ',
  requesterInfo: 'Thông tin người yêu cầu',
  requestInfo: 'Thông tin yêu cầu',
  settingRequestForm: 'Thiết lập phiếu yêu cầu',
  allServiceType: 'Tất cả loại dịch vụ',
  delete: 'Xóa loại dịch vụ',
  confirmDelete: 'Bạn có chắc chắn muốn xóa loại dịch vụ này không?',
  general: 'Thông tin chung',
  classify: 'Phân loại',
  serviceTypeCMIS: 'Loại dịch vụ CMIS',
  receiveDeadline: 'Thời hạn tiếp nhận',
  electricMeterReading: 'Chỉ số điện'
};
