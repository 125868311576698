import { Typography, Card, Modal, message, Dropdown, Menu } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { AssignEmployeeTicketRequest, InprogressTickets, TicketDetail, UpdateObstacleTicketRequest } from 'types';
import { requestform } from 'messages';
import { GreenCheck, PrinterIcon } from 'assets';
import { GetTicketDetail, GetUserListInOrganization } from 'hooks/request-form';
import RequestDetailCreateFormInformation from './RequestDetailCreateFormInformation';
import RequestDetailMainInformation from './RequestDetailMainInformation';
import { DownOutlined } from '@ant-design/icons';
import {
  usePatchAssignEmployeeMutation,
  usePatchCompleteCollaborationTicketMutation,
  usePatchCompleteObstacleTicketMutation,
  usePatchCompleteTicketMutation,
  usePatchInprogressTicketMutation,
  usePatchReceptionTicketMutation,
  usePatchTransferToCustomerCareCenterMutation,
  usePatchUpdateObstacleTicketMutation
} from 'services';
import ModalTransferCmis from './ModalTransferCmis';
import ModalTransferUnit from './ModalTransferUnit';
import RequestStatusBar from './RequestStatusBar';
import defaultTicketDetail, { CompleteCollaborationTicketRequest } from 'types/dto/request-form/request-form.dto';
import RequestDetailProcessing from './RequestDetailProcessing';
import ModalObstacle from './ModalObstacle';
import { useLocation } from 'react-router-dom';
import PrintComponent from '../print-ticket/PrintTikcet';
import NormalTicket from '../print-ticket-detail/NormalTicket';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface ticketFromDetail {
  status?: string;
  ticketId: number;
  cancelDetailModal: () => void;
}

const RequestDetail: React.FC<ticketFromDetail> = ({ status, ticketId, cancelDetailModal }) => {
  const location = useLocation();
  //Print ticket
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const [isModal, setIsModal] = useState(false);
  const [isOpenObstacleModal, setIsOpenObstacleModal] = useState(false);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [processingContent, setProcessingContent] = useState<string>('');
  const [requestItem, setRequestItem] = useState<TicketDetail>(defaultTicketDetail);

  const { requestDetail } = GetTicketDetail(ticketId);

  // Control Modal Open / Close
  const openModalCMIS = () => setIsModal(true);
  const closeModalCMIS = () => {
    setIsModal(false);
    cancelDetailModal();
  };
  const openTransferModal = () => setIsOpenTransferModal(true);
  const openObstacleModal = () => setIsOpenObstacleModal(true);
  const closeTransferModal = () => setIsOpenTransferModal(false);
  const closeObstacleModal = () => setIsOpenObstacleModal(false);

  const [open, setOpen] = useState(false);
  const [assignUserInUnit, setAssignUserInUnit] = useState<number>(0);
  const [assigneeEmployee] = usePatchAssignEmployeeMutation();
  const assignEmployee = () => {
    if (assignUserInUnit == 0) {
      message.error('Chọn nhân viên tiếp nhận');
      return;
    }

    const assigneeData: AssignEmployeeTicketRequest = {
      assigneeId: assignUserInUnit,
      ticketId
    };

    Modal.confirm({
      title: 'Xác nhận giao phiếu',
      okText: 'Tiếp nhận',
      cancelText: 'Hủy',
      onOk() {
        assigneeEmployee(assigneeData)
          .unwrap()
          .then(() => {
            message.success('Giao phiếu thành công');
            cancelDetailModal();
            setOpen(false);
          });
      }
    });
  };

  useEffect(() => {
    if (requestDetail) {
      const formattedRequestDetail: TicketDetail = Object.assign({}, defaultTicketDetail, requestDetail);
      setRequestItem(formattedRequestDetail);
    } else setRequestItem(defaultTicketDetail);
  }, [requestDetail]);

  const [patchReception] = usePatchReceptionTicketMutation();
  const receptionTicketAction = () => {
    if (requestItem?.ticketId !== undefined) {
      Modal.confirm({
        title: 'Tiếp nhận phiếu yêu cầu',
        okText: 'Tiếp nhận',
        cancelText: 'Hủy',
        onOk() {
          patchReception(requestItem.ticketId)
            .unwrap()
            .then((res) => {
              closeModalCMIS();
              message.success('Tiếp nhận thành công phiếu yêu cầu', 2);
            })
            .catch((err) => { });
        }
      });
    }
  };

  const [updateObstacle] = usePatchUpdateObstacleTicketMutation();
  const updateObstacleTicket = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Vui lòng nhập nội dung xử lý!', 3);
      return;
    }
    const date = new Date(requestDetail.ticketHoldManager[0].estimatedEndDate);
    const estimatedEndDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

    const updateObstacleTicket: UpdateObstacleTicketRequest = {
      ticketId: requestDetail.ticketId,
      content: processingContent,
      estimatedEndDate: estimatedEndDate,
      obstacleSourceId: requestDetail.ticketHoldManager[0].obstacleSourceId,

      ticketHoldManagerId: requestDetail.ticketHoldManager[0].ticketHoldManagerId
    };
    Modal.confirm({
      title: 'Xác nhận cập nhật trở ngại',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        updateObstacle(updateObstacleTicket)
          .unwrap()
          .then(() => {
            message.success('Cập nhật trở ngại thành công', 2);
            cancelDetailModal();
          })
          .catch();
      }
    });
  };

  const [isPowerRestore, setIsPowerRestore] = useState<boolean>(false);
  const [completeObstacle] = usePatchCompleteObstacleTicketMutation();
  const [completeCollaboration] = usePatchCompleteCollaborationTicketMutation();
  const completeObstacleTicket = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Vui lòng nhập nội dung xử lý!', 3);
      return;
    }
    const date = new Date(requestDetail.ticketHoldManager[0].estimatedEndDate);
    const estimatedEndDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

    const updateObstacleTicket: UpdateObstacleTicketRequest = {
      ticketId: requestDetail.ticketId,
      content: processingContent,
      estimatedEndDate: estimatedEndDate,
      obstacleSourceId: requestDetail.ticketHoldManager[0].obstacleSourceId,

      ticketHoldManagerId: requestDetail.ticketHoldManager[0].ticketHoldManagerId
    };
    Modal.confirm({
      title: 'Xác nhận hoàn tất trở ngại',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        completeObstacle(updateObstacleTicket)
          .unwrap()
          .then(() => {
            message.success('Hoàn tất trở ngại thành công', 2);
            cancelDetailModal();
          })
          .catch();
      }
    });
  };

  const completeCollaborationTicket = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Vui lòng nhập nội dung xử lý!', 3);
      return;
    }

    const updateCollaborationTicket: CompleteCollaborationTicketRequest = {
      ticketId: requestDetail.ticketId,
      processingContent: processingContent,
    };
    Modal.confirm({
      title: 'Xác nhận hoàn phối hợp',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        completeCollaboration(updateCollaborationTicket)
          .unwrap()
          .then(() => {
            message.success('Hoàn tất phối hợp thành công', 2);
            cancelDetailModal();
          })
          .catch();
      }
    });
  };

  const [completeTicket] = usePatchCompleteTicketMutation();
  const completeTicketAction = () => {
    if (requestDetail.serviceType.code === 'BDM' && isPowerRestore) {
      message.error('Vui lòng xác nhận khách hàng đã có điện lại!');
      return;
    }
    Modal.confirm({
      title: 'Xác nhận hoàn thành phiếu yêu cầu',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketId) {
          completeTicket(ticketId)
            .unwrap()
            .then((res) => {
              message.success('Hoàn thành phiếu yêu cầu', 2);
              cancelDetailModal();
            })
            .catch((err) => { });
        }
      }
    });
  };

  const [inprocessTicket] = usePatchInprogressTicketMutation();
  const inprogressTicketAction = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Vui lòng nhập nội dung xử lý!', 3);
      return;
    }
    const data: InprogressTickets = {
      ticketId,
      processingContent
    };
    Modal.confirm({
      title: 'Xác nhận cập nhật phiếu',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketId) {
          inprocessTicket(data)
            .unwrap()
            .then((res) => {
              cancelDetailModal();
              setOpen(false);
              message.success('Cập nhật phiếu thành công', 2);
            })
            .catch((err) => { });
        }
      }
    });
  };

  const [transferToCustomerCareCenter] = usePatchTransferToCustomerCareCenterMutation();
  const transferCustomerCareCenter = () => {
    if (!processingContent || processingContent.trim() === '') {
      message.error('Nhập nội dung xử lý trước khi chuyển về TTCSKH', 3);
      return;
    }
    const data: InprogressTickets = {
      ticketId,
      processingContent
    };
    Modal.confirm({
      title: 'Xác nhận cập nhật phiếu',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        if (ticketId) {
          transferToCustomerCareCenter(data)
            .unwrap()
            .then((res) => {
              cancelDetailModal();
              message.success('Chuyển phiếu về TTCSKH thành công', 2);
            })
            .catch((err) => { });
        }
      }
    });
  };

  const menu = {
    items: [
      {
        key: '1',
        label: (
          <button
            onClick={inprogressTicketAction}
            className='w-full rounded-lg bg-[#FF9500] px-6 py-[10px] text-left text-[16px]/[20px] font-semibold text-[#FFFFFF]'
          >
            {requestform.inprocessTicket}
          </button>
        )
      },
      {
        key: '2',
        label: (
          <button
            onClick={assignEmployee}
            className='w-full rounded-lg bg-[#FF9500] px-6 py-[10px] text-left text-[16px]/[20px] font-semibold text-[#FFFFFF]'
          >
            {requestform.assignEmployee}
          </button>
        )
      }
    ]
  };

  const handleAction = (action: () => Promise<any>, successMsg: string, requireContent = false) => {
    if (requireContent && !processingContent.trim()) {
      message.error('Please provide processing content.', 3);
      return;
    }
    Modal.confirm({
      title: `Confirm ${successMsg.toLowerCase()}`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () =>
        action()
          .then(() => {
            message.success(successMsg, 2);
            cancelDetailModal();
          })
          .catch(() => { })
    });
  };

  const actions = {
    assign: () =>
      handleAction(() => assigneeEmployee({ assigneeId: assignUserInUnit, ticketId }), 'Giao phiếu thành công'),
    complete: () => handleAction(() => completeTicket(ticketId), 'Hoàn tất phiếu thành công'),
    inprogress: () =>
      handleAction(() => inprocessTicket({ ticketId, processingContent }), 'Cập nhật phiếu thành cônh', true)
  };

  return (
    <Card
      title={
        <Typography.Text className='w-full text-center text-[16px]/[22px] font-semibold'>
          {requestform.requestTicket} {ticketId}
        </Typography.Text>
      }
      className='request-form-detail-card min-h-[1000px] text-center'
    >
      <div style={{ display: 'none' }}>
        <PrintComponent ref={contentRef}>
          <NormalTicket ticket={requestDetail} status={status} ticketId={ticketId} />
        </PrintComponent>
      </div>

      <div className='flex w-full text-left'>
        <RequestDetailCreateFormInformation requestItem={requestItem} />

        <section className='flex flex-col justify-between'>
          <section className='w-[870px] p-6'>
            <section className='flex items-center justify-between'>
              <div className='w-4/5'>
                <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
                  {requestItem?.serviceType && requestItem?.serviceType?.name ? requestItem?.serviceType?.name : ''}
                </Typography.Text>
              </div>
              <button onClick={() => reactToPrintFn()} className='flex items-center space-x-2'>
                <PrinterIcon />
                <Typography.Text className='text-[16px]/[20px] text-[#1564E8]'>
                  {requestform.printTicket}
                </Typography.Text>
              </button>
            </section>

            <section>
              <Typography.Text
                className={`text-[14px]/[18px] ${requestItem?.isRequestMultipleTime ? 'text-[#D1131D]' : 'text-[#06A77D]'}`}
              >
                {requestItem?.isRequestMultipleTime
                  ? requestform.multiplleTimeRequest
                  : requestform.notMultiplleTimeRequest}
              </Typography.Text>
            </section>

            <section>
              {status && (
                <div className='pb-6'>
                  <RequestStatusBar status={status} />
                  {/* CMIS Transfer */}
                  {status === 'pending-reception' ? (
                    <div className='mt-1 justify-between rounded-[5px] bg-[#EBEBED] px-4 py-[11px]'>
                      <Typography.Text className='text-[14px]/[18px] font-semibold text-[#141414]'>
                        {requestform.isTranferCmisTicket}
                      </Typography.Text>
                      <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                        {requestItem && requestItem?.serviceType
                          ? requestItem?.serviceType?.isRequiredCmis != null
                            ? requestItem?.serviceType?.isRequiredCmis
                              ? requestform.isCmisTransferTrue
                              : requestform.isCmisTransferFalse
                            : ''
                          : 'Thông tin không có sẵn'}
                      </Typography.Text>

                      {/*<DownOutlined className='h-2 w-[10px]' />*/}
                    </div>
                  ) : requestItem?.cmisRequestId == null &&
                    requestItem?.serviceType &&
                    requestItem?.serviceType?.isRequiredCmis != null ? (
                    <div className='mt-1 flex justify-between rounded-[5px] border border-[#D1131D] bg-[#06A77D00] px-4 py-[11px]'>
                      <div>
                        <Typography.Text className='text-[14px]/[18px] font-semibold text-[#D1131D]'>
                          {requestform.cmisTransferFail}
                        </Typography.Text>{' '}
                        <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                          {requestform.typeCmisTicket}
                        </Typography.Text>{' '}
                        <DownOutlined className='h-2 w-[10px]' />
                      </div>
                      <button>
                        <Typography.Text className='text-[14px]/[18px] text-[#1564E8]'>
                          {requestform.tryAgainButton}
                        </Typography.Text>
                      </button>
                    </div>
                  ) : requestItem?.serviceType && requestItem?.serviceType?.isRequiredCmis == null ? (
                    <div className='mt-1 flex justify-between rounded-[5px] bg-[#06A77D00] py-[11px]'>
                      <div className='flex space-x-1'>
                        <GreenCheck />
                        <Typography.Text className='text-[14px]/[18px] italic text-[#141414]'>
                          {requestform.isCmisTransferFalse}
                        </Typography.Text>
                      </div>{' '}
                    </div>
                  ) : (
                    <div className='mt-1 flex justify-between rounded-[5px] bg-[#06A77D00] py-[11px]'>
                      <div className='flex space-x-1'>
                        <GreenCheck />
                        <Typography.Text className='text-[14px]/[18px] italic text-[#141414]'>
                          {requestform.cmisTranfered}
                          {requestItem?.cmisRequestId}
                        </Typography.Text>
                      </div>{' '}
                      <Typography.Text className='text-[14px]/[18px] italic text-[#868D97]'>
                        Lúc 16:32, 27/12/2024
                      </Typography.Text>
                    </div>
                  )}
                </div>
              )}
            </section>

            <section
              className={`${status === 'completed' || status === 'collaboration' ? 'h-[688px] overflow-y-auto' : 'max-h-[600px] overflow-y-auto'}`}
            >
              <RequestDetailMainInformation requestItem={requestItem} />
              <RequestDetailProcessing
                requestItem={requestItem}
                processingContent={processingContent}
                setProcessingContent={setProcessingContent}
                setAssignUserInUnit={setAssignUserInUnit}
                setIsPowerRestore={setIsPowerRestore}
              />
            </section>
          </section>

          {/* Button */}
          {location.pathname.includes('request-form') && status !== 'completed' && (
            <div className='flex h-[88px] items-center justify-end border-t-2 p-6 text-left'>
              {status === 'pending-reception' && (
                <button
                  onClick={() => {
                    if (requestItem?.serviceType?.isRequiredCmis) openModalCMIS();
                    else receptionTicketAction();
                  }}
                  className='rounded-lg bg-[#1564E8] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                >
                  {requestform.acceptTicket}
                </button>
              )}

              <div className='flex space-x-4'>
                {(status === 'in-progress' || status === 'collaboration') && (
                  <>
                    <button
                      onClick={openObstacleModal}
                      className='rounded-lg border border-[#D1131D] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#D1131D]'
                    >
                      {requestform.obstacleTickets}
                    </button>

                    {status !== 'collaboration' && (
                      <>
                        <button
                          onClick={transferCustomerCareCenter}
                          className='rounded-lg border border-[#D1131D] bg-transparent px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#D1131D]'
                        >
                          {requestform.transferCustomerCareCenter}
                        </button>
                        <button
                          onClick={openTransferModal}
                          className='rounded-lg border border-[#1564E8] bg-transparent px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#1564E8]'
                        >
                          {requestform.transferCollaboration}
                        </button>
                      </>
                    )}

                    <Dropdown
                      placement='top'
                      menu={menu}
                      trigger={['click']}
                      open={open}
                      onOpenChange={(flag) => setOpen(flag)}
                    >
                      <button className='rounded-lg bg-[#FF9500] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'>
                        {requestform.updateTicket}
                      </button>
                    </Dropdown>
                    {status === 'collaboration' ?
                      <button
                        onClick={completeCollaborationTicket}
                        className='rounded-lg bg-[#28A745] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                      >
                        {requestform.completedCollaborationButton}
                      </button>
                      :
                      <button
                        onClick={completeTicketAction}
                        className='rounded-lg bg-[#28A745] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                      >
                        {requestform.completedButton}
                      </button>
                    }


                  </>
                )}

                {status === 'obstacle' && (
                  <>
                    <button
                      onClick={updateObstacleTicket}
                      className='rounded-lg bg-[#FF9500] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                    >
                      {requestform.updateObstacleTicket}
                    </button>
                    <button
                      onClick={completeObstacleTicket}
                      className='rounded-lg bg-[#28A745] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                    >
                      {requestform.completedObstacleTicket}
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </section>
      </div>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isOpenObstacleModal}
        onCancel={closeObstacleModal}
        footer={null}
        destroyOnClose
      >
        <ModalObstacle ticketId={ticketId} closeObstacleModal={closeObstacleModal} />
      </Modal>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isModal}
        onCancel={closeModalCMIS}
        footer={null}
        destroyOnClose
      >
        <ModalTransferCmis requestItem={requestItem} closeModalCMIS={closeModalCMIS} />
      </Modal>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isOpenTransferModal}
        onCancel={closeTransferModal}
        footer={null}
        destroyOnClose
      >
        <ModalTransferUnit
          requestItem={requestItem}
          processingContent={processingContent}
          cancelDetailModal={cancelDetailModal}
        />
      </Modal>
    </Card>
  );
};

export default RequestDetail;
