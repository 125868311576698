import { Button } from 'antd';
import { CloseIcon, ReplyIcon } from 'assets';
import React from 'react';
import { ChatSessionDto, OmniChannelMessageDto } from 'types';
import { stringToHslColor } from 'utils';
import { twMerge } from 'tailwind-merge';
import { OmniMessageReplied } from '../omni-message-chat';

type OmniChatReplyMessageProps = {
  message?: OmniChannelMessageDto;
  chatSession: ChatSessionDto;
  removeReplyMessage?: () => void;
};

const OmniChatReplyMessage: React.FC<OmniChatReplyMessageProps> = ({ message, removeReplyMessage, chatSession }) => {
  const senderString = message?.senderName;
  const color = stringToHslColor(senderString ?? 'reply-message', 80, 35);

  return (
    <>
      <div
        className={twMerge(
          'flex h-0 items-center',
          message && message.chatSessionId === chatSession.chatSessionId && 'h-12 px-[10px] pt-[6px]'
        )}
        style={{
          transition: 'height 0.175s  ease-in-out'
        }}
      >
        {message && message.chatSessionId === chatSession.chatSessionId && (
          <>
            <div
              className='flex h-8 w-8 items-center justify-center'
              style={{
                color: color
              }}
            >
              <ReplyIcon />
            </div>
            <OmniMessageReplied isInChatInput className='ml-[10px] mr-4' data={message} />
            <Button
              type='text'
              className='h-8 w-8'
              shape='circle'
              icon={<CloseIcon className='text-colorPrimary' />}
              onClick={removeReplyMessage}
            ></Button>
          </>
        )}
      </div>
    </>
  );
};

export default OmniChatReplyMessage;
