export enum PERMISSION {
  READ_USER = 1,
  CREATE_USER = 2,
  UPDATE_USER = 3,
  DELETE_USER = 4,
  READ_ORGANIZATION_UNIT = 5,
  CREATE_ORGANIZATION_UNIT = 6,
  UPDATE_ORGANIZATION_UNIT = 7,
  DELETE_ORGANIZATION_UNIT = 8,
  READ_USER_GROUP = 9,
  CREATE_USER_GROUP = 10,
  UPDATE_USER_GROUP = 11,
  DELETE_USER_GROUP = 12,
  READ_ROLE = 13,
  CREATE_ROLE = 14,
  UPDATE_ROLE = 15,
  DELETE_ROLE = 16,
  READ_CUSTOMER_SUPPORT_INFORMATION = 17,
  CREATE_CUSTOMER_SUPPORT_INFORMATION = 18,
  UPDATE_CUSTOMER_SUPPORT_INFORMATION = 19,
  DELETE_CUSTOMER_SUPPORT_INFORMATION = 20,
  READ_FREQUENTLY_ASKED_QUESTION = 21,
  CREATE_FREQUENTLY_ASKED_QUESTION = 22,
  UPDATE_FREQUENTLY_ASKED_QUESTION = 23,
  DELETE_FREQUENTLY_ASKED_QUESTION = 24,
  READ_SERVICE_TYPE = 25,
  CREATE_SERVICE_TYPE = 26,
  UPDATE_SERVICE_TYPE = 27,
  DELETE_SERVICE_TYPE = 28,
  READ_SERVICE_INSTRUCTION = 29,
  UPDATE_SERVICE_INSTRUCTION = 30,
  READ_WORKING_SCHEDULE = 31,
  CREATE_WORKING_SCHEDULE = 32,
  UPDATE_WORKING_SCHEDULE = 33,
  DELETE_WORKING_SCHEDULE = 34,
  READ_SHIFT = 35,
  CREATE_SHIFT = 36,
  UPDATE_SHIFT = 37,
  DELETE_SHIFT = 38,
  READ_REASON = 39,
  CREATE_REASON = 40,
  UPDATE_REASON = 41,
  DELETE_REASON = 42,
  READ_VIP_CUSTOMER = 43,
  CREATE_VIP_CUSTOMER = 44,
  UPDATE_VIP_CUSTOMER = 45,
  DELETE_VIP_CUSTOMER = 46,
  READ_TICKET_SAMPLE = 47,
  CREATE_TICKET_SAMPLE = 48,
  UPDATE_TICKET_SAMPLE = 49,
  DELETE_TICKET_SAMPLE = 50,
  READ_TICKET_CANCEL_REASON = 51,
  CREATE_TICKET_CANCEL_REASON = 52,
  UPDATE_TICKET_CANCEL_REASON = 53,
  DELETE_TICKET_CANCEL_REASON = 54,
  READ_CHANNEL = 55,
  CREATE_CHANNEL = 56,
  UPDATE_CHANNEL = 57,
  DELETE_CHANNEL = 58,
  READ_CUSTOMER = 59,
  READ_INTERNAL_ANNOUNCEMENT = 60,
  CREATE_INTERNAL_ANNOUNCEMENT = 61,
  UPDATE_INTERNAL_ANNOUNCEMENT = 62,
  DELETE_INTERNAL_ANNOUNCEMENT = 63,
  SELECT_SHIFT = 64,
  UPDATE_PERMISSION = 65,
  UPDATE_SYSTEM_CONFIG = 66,
  READ_AGENT_MAP = 67,
  CREATE_AGENT_MAP = 68,
  UPDATE_AGENT_MAP = 69,
  DELETE_AGENT_MAP = 70,
  READ_API_SHARING_PARTNER = 71,
  CREATE_API_SHARING_PARTNER = 72,
  UPDATE_API_SHARING_PARTNER = 73,
  DELETE_API_SHARING_PARTNER = 74,
  ACCESS_CUSTOMER_LOOKUP = 75,
  ACCESS_TICKET = 76,
  ACCESS_MANAGE_DTV = 77,
  ACCESS_REPORT = 78,
  ACCESS_MANAGEMENT = 79,
  ACCESS_SYSTEM = 80,
  CONFIG_ORGANIZATION_UNIT_DEFAULT_HANDLE_TICKET = 81,
  READ_PENDING_RECEPTION_TICKETS = 82,
  READ_IN_PROGRESS_TICKETS = 83,
  READ_COMPLETED_TICKETS = 84,
  READ_COLLABORATION_TICKETS = 85,
  READ_TICKET = 86,
  READ_TICKET_DETAIL = 87,
  CREATE_TICKET = 88,
  UPDATE_TICKET = 89,
  UPDATE_TICKET_ORG_UNIT = 90,
  READ_SUPERVISOR_TICKET_TRACKING = 91,
  READ_TICKET_CREATED_HISTORY = 92,
  READ_EVALUATION_TICKET = 93,
  CHANGE_ORGANIZATION_UNIT = 94,
  ACCESS_MANAGE_ACCOUNT = 95,
  ACCESS_MANAGE_CONNECTION = 96,
  ACCESS_NEED_COOPERATE_REQUEST = 97,
  ACCESS_NEED_TRACKING_REQUEST = 98,
  ACCESS_INCORRECT_RECORDING_LOCATION = 99,
  ACCESS_CONFIRM_FEEDBACK = 100,
  ACCESS_PAYMENT_COLLECTION = 101,
  ACCESS_SMS = 102,
  ACCESS_REPORT_EXPLANATION = 103,
  ACCESS_USER_GUIDE = 104,
  READ_ADDRESS_CHANGE_TICKETS = 105,
  UPDATE_ADDRESS_CHANGE_TICKET = 106,
  READ_TICKET_TRANSFERED_TO_TRUONGCA = 107,
  READ_TICKET_TRANSFERED_TO_CSKH = 108,
  READ_TICKET_TRANSFERED_TO_KYTHUAT = 109,
  UPDATE_RETURN_TICKET_TO_CSKH = 110,
  UPDATE_TICKET_ALL = 111,
  CHAT_CUSTOMER = 112,
  CALL_CUSTOMER = 113,
  READ_PENDING_COMPLAINTS_FEEDBACK = 114,
  ASSIGN_CHAT_CUSTOMER = 115,
  ACCESS_DIENLUC_CUSTOMER_SEARCH = 117,
}
