import { Typography } from 'antd';
import { PageHeader, OmniChanelChatCustomer, CustomerLookupCall } from 'components';
import { useProfile, useTitle } from 'hooks';
import { sidebarMenuMessages } from 'messages';
import { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setIsCallSelected } from 'store/features/customer-support-session/customer-support-session.slice';
import { twMerge } from 'tailwind-merge';
import { PERMISSION } from 'utils';
import { checkPermissions } from 'utils/check-permission';

function CustomerSupportLayout({ children }: PropsWithChildren) {
  const { userPermissionIds } = useProfile();
  const isAllowChatCustomer = checkPermissions(userPermissionIds, PERMISSION.CHAT_CUSTOMER);
  const isAllowAssignCustomer = checkPermissions(userPermissionIds, PERMISSION.ASSIGN_CHAT_CUSTOMER);
  const isAllowCallCustomer = checkPermissions(userPermissionIds, PERMISSION.CALL_CUSTOMER);

  useTitle(sidebarMenuMessages.customerSupport);
  const dispatch = useDispatch();
  const isCallSelected = useSelector((state: RootState) => state.customerSupportSession.isCallSelected);

  const updateIsCallSelected = () => {
    dispatch(setIsCallSelected(true)); // Chọn call khi click vào phần call
  };

  const updateIsChatSelected = () => {
    dispatch(setIsCallSelected(false)); // Chọn chat (bằng cách đặt isCallSelected = false)
  };

  useEffect(() => {
    console.log(isCallSelected ? 'Call selected' : 'Chat selected');
  }, [isCallSelected]);

  // Tạo class border dựa vào trạng thái isCallSelected
  const callBorderClass = isCallSelected
    ? 'w-full border-l cursor-pointer border-l-blue-500 glow-border-left'
    : 'w-full border-l cursor-pointer';

  // Class cho chat dựa vào trạng thái isCallSelected (phủ định)
  const chatBorderClass = !isCallSelected
    ? 'w-full border-b cursor-pointer border-b-blue-500 glow-border-bottom glow-border-left'
    : '';

  return (
    <div>
      <PageHeader className='flex items-center gap-1'>
        <Typography.Title className='text-header-label mb-0 font-bold'>
          {sidebarMenuMessages.customerSupport}
        </Typography.Title>
      </PageHeader>
      <div className='container-list relative max-h-[calc(100vh-100px)] overflow-hidden border p-0'>
        <div className='flex max-h-full flex-1 overflow-hidden'>
          {(isAllowCallCustomer || isAllowChatCustomer) && (
            <div className='flex w-[400px] flex-col'>
              <div
                className={twMerge('flex-1', callBorderClass)}
                onClick={() => isAllowCallCustomer && updateIsCallSelected()}
              >
                <CustomerLookupCall />
              </div>
              <div
                className={twMerge(
                  `border-r border-t border-solid ${chatBorderClass}`,
                  isAllowChatCustomer ? 'h-full' : isAllowAssignCustomer ? 'h-[124px]' : 'h-auto'
                )}
                onClick={() => isAllowChatCustomer && updateIsChatSelected()}
              >
                <OmniChanelChatCustomer />
              </div>
            </div>
          )}
          <div
            className={twMerge(
              'relative',
              isAllowCallCustomer || isAllowChatCustomer ? 'w-[calc(100%-400px)]' : 'w-full'
            )}
          >
            <div className='slim-scrollbar slim-scrollbar-horizontal max-h-full overflow-auto'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerSupportLayout;
