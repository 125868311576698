import { Typography } from 'antd';
import { memo, useEffect, useState } from 'react';
import { FileDto, OmniChannelMessageDto } from 'types';
import { convertFileType, FILE_TYPE, loadFileFromUrl, stringToHslColor } from 'utils';
import { internalChatMessages } from 'messages';
import { twMerge } from 'tailwind-merge';
import { File } from '../files';
import { useOmniChatContext } from 'contexts';
import { useLazyGetFileQuery } from 'services';

type OmniMessageRepliedProps = {
  data: OmniChannelMessageDto;
  className?: string;
  isInChatInput?: boolean;
};

const OmniMessageReplied: React.FC<OmniMessageRepliedProps> = ({ data, className, isInChatInput }) => {
  const { scrollToMessageReplied } = useOmniChatContext();

  const [fetchFile] = useLazyGetFileQuery();
  const [fileLoaded, setFileLoaded] = useState<Partial<FileDto>>();

  const senderName = data.senderName;

  const color = stringToHslColor(senderName ?? 'reply-message', 80, 35);
  const bgcolor = stringToHslColor(senderName ?? 'reply-message', 80, 45, 1);

  const { fileId, file } = data;

  useEffect(() => {
    if (fileId) {
      loadDataFromId();
    } else if (file) {
      loadDataFromUrl();
    }
  }, [file]);

  const loadDataFromUrl = async () => {
    if (!file) return;
    const dataFile = await loadFileFromUrl(file);
    if (dataFile)
      setFileLoaded({
        url: file,
        objectName: dataFile.name,
        fileSize: dataFile.size,
        fileType: dataFile.type,
        originalName: dataFile.name
      });
  };

  const loadDataFromId = async () => {
    if (!fileId) return;
    const dataFile = await fetchFile(fileId);
    if (dataFile)
      setFileLoaded({
        ...dataFile.data?.data
      });
  };

  const renderMessage = () => {
    if (data.content) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: `${data?.content?.replace(/<br\s*\/?>/gi, ' ') ?? ''}`
          }}
          className='mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-colorTextDescription'
        ></div>
      );
    }
  };

  const renderFile = () => {
    if (!fileLoaded) return null;
    const filetype = convertFileType(fileLoaded?.fileType);
    if (filetype === FILE_TYPE.IMAGE) {
      return (
        <div className='relative mr-2 h-8 min-h-8 w-8 min-w-8 overflow-hidden'>
          <File
            fileClassName={'rounded-md'}
            className={twMerge('h-full w-full items-center border-0 p-0')}
            url={fileLoaded.url}
            type={'image/png'}
            isPreviewImage
          />
        </div>
      );
    }
  };

  return (
    <div
      onClick={() => scrollToMessageReplied(data, bgcolor)}
      className={twMerge(
        `relative flex h-[42px] w-full flex-1 cursor-pointer overflow-hidden overflow-y-hidden rounded-[3px]`,
        className
      )}
    >
      <div className='absolute h-full w-full opacity-10 hover:opacity-30' style={{ background: bgcolor }}></div>
      <div
        className='h-[42px] w-[4px] overflow-hidden'
        style={{
          backgroundColor: color
        }}
      ></div>
      <div className='ml-3 flex max-w-[calc(100%-20px)] flex-1 select-none items-center gap-1 py-[2px]'>
        {fileLoaded ? renderFile() : null}
        <div className='flex w-full max-w-full flex-col items-start text-left'>
          <Typography.Text
            ellipsis
            className='whitespace-nowrap text-xs font-semibold'
            style={{
              color: color
            }}
          >
            {isInChatInput && `${internalChatMessages.reply} `}
            {senderName}
          </Typography.Text>
          <div className='min-w-28 max-w-[calc(100%-48px)] text-xs'>{renderMessage()}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(OmniMessageReplied);
