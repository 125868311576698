import { TabsProps } from 'antd';
import { Loadable, TabButtons } from 'components';
import { useTitle } from 'hooks';
import { sidebarMenuMessages, systemConfigsMessages } from 'messages';
import { PropsWithChildren } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';

function SystemConfigLayout({ children }: PropsWithChildren) {
  useTitle(sidebarMenuMessages.systemConfigs);
  const location = useLocation();
  const navigate = useNavigate();

  const tabItems: TabsProps['items'] = [
    {
      key: ROUTE.RATING_SYSTEM,
      label: systemConfigsMessages.ratingSystem
    },
    {
      key: ROUTE.CALL_CENTER_SYSTEM,
      label: systemConfigsMessages.callCenterSystem
    }
  ];

  const handleClickTab = (tabKey: string) => {
    navigate(tabKey);
  };

  return (
    <div>
      <div className='rounded-xl bg-colorBgContainer p-5'>
        <TabButtons
          className='justify-center'
          items={tabItems}
          onClick={(tabKey) => handleClickTab(tabKey as string)}
          activeKey={location.pathname}
        />
        <Loadable>
          <Outlet />
        </Loadable>
      </div>
    </div>
  );
}

export default SystemConfigLayout;
