import { Rule } from "antd/es/form";
import { CreateTicketFormType } from "components/create-ticket/CreateTicketForm";
import { create } from "lodash";
import { createTicketMessages } from "messages/create-ticket.messages";

export const createTicketValidationRules: Record<keyof CreateTicketFormType, Rule[]> = {
    customerCode: [{ required: true }],
    organizationUnitId: [],
    administrativeUnitId: [{ required: true, message: createTicketMessages.administrativeUnitRequired }],
    customerRequestName: [
        { required: true, message: createTicketMessages.customerRequestNameRequired },
        { max: 255, message: createTicketMessages.customerRequestNameTooLong }
    ],
    customerRequestAddress: [
        { required: true, message: createTicketMessages.customerRequestAddressRequired },
        { max: 255, message: createTicketMessages.customerRequestAddressTooLong }
    ],
    customerRequestPhoneNumber: [
        { 
            pattern: /^[0-9]+$/, 
            message: createTicketMessages.customerRequestPhoneNumberInvalid 
        },
        { max: 20, message: createTicketMessages.customerRequestPhoneNumberTooLong }
    ],
    content: [
        { required: true, message: createTicketMessages.contentRequired },
        { max: 1000, message: createTicketMessages.contentTooLong } 
    ],
    channelId: [{ required: true, message: createTicketMessages.channelRequired }],
    serviceTypeId: [{ required: true }],
    appointmentDate: [],
    customerRequestCicNumber: [
        { max: 20, message: createTicketMessages.customerRequestCicNumberTooLong }
    ],
    customerRequestCicIssueDate: [],
    customerRequestCicIssuePlace: [
        { max: 255, message: createTicketMessages.customerRequestCicIssuePlaceTooLong }
    ],
    customerRequestCicType: [],
    uploadFileIds: [],
    district: [{ required: true, message: createTicketMessages.districtRequired }],
    ward: [{ required: true, message: createTicketMessages.wardRequired }],
    phaseNumber: [],
    usagePurpose: [],
    outageAt: [],
    outageReasonId: [],
    powerRestorationAt: [],
    isOutageEntireArea: [],
    isNotRequestToSendSms: [],
    incidenAddress: [],
    incidentAdministrativeUnitId: [],
    isAddressChanged: [],
    wardLabel: [],
    districtLabel: [],
    channelLabel: []
}