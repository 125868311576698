import { usePaging } from 'hooks';
import { useCallback } from 'react';
import { useGetOneRolePermissionsQuery } from 'services';
import { FindOneRolePermissionsDto } from 'types';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;

export function useRolePermissions(params: Omit<FindOneRolePermissionsDto, 'pageIndex' | 'pageSize'>) {
  const { pageIndex, resetPage, nextPage } = usePaging(params);

  const {
    data: dataResponse,
    isLoading,
    isFetching
  } = useGetOneRolePermissionsQuery({
    pageIndex: pageIndex,
    pageSize,
    ...params
  });

  const { rows, count } = dataResponse?.data || {};

  const hasMore = Boolean(rows && count && rows.length < count);
  const handleLoadMore = useCallback(() => {
    if (hasMore && !isFetching) {
      nextPage();
    }
  }, [hasMore, pageIndex]);

  return {
    permissions: rows || [],
    count: count || 0,
    isFetching,
    isLoading,
    handleLoadMore,
    hasMore,
    resetPage
  };
}
