import { Button, Form, FormInstance, Input, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FormItem, message } from 'components/common';
import { SelectOptions } from 'components/options';
import { apiSharingPartnersMessages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useCreateApiSharingPartnerMutation,
  useGetApiSharingPartnerDetailQuery,
  useUpdateApiSharingPartnerMutation
} from 'services';
import { CreateApiSharingPartnerDto } from 'types';
import {
  apiSharingPartnerValidationRules,
  CORE_OPTION_TYPES,
  createApiSharingPartnerInitialValues,
  MICROSERVICES,
  updateApiSharingPartnerInitialValues
} from 'utils';

export type ApiSharingPartnerFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  apiSharingPartnerId?: number;
};

export type ApiSharingPartnerFormRefProps = {
  form: FormInstance<ApiSharingPartnerFormType>;
  isLoading: boolean;
};

export type ApiSharingPartnerFormType = Pick<
  CreateApiSharingPartnerDto,
  'apiKey' | 'code' | 'description' | 'isActive' | 'name'
> & {
  permissions?: DefaultOptionType[];
};
const ApiSharingPartnerForm = forwardRef<ApiSharingPartnerFormRefProps, ApiSharingPartnerFormProps>(
  ({ onChangeLoading, onCreateSuccess, apiSharingPartnerId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingUpdate
    }));

    const { data: apiSharingPartner, isLoading: isLoadingDetail } = useGetApiSharingPartnerDetailQuery(
      apiSharingPartnerId!,
      {
        skip: !apiSharingPartnerId,
        refetchOnMountOrArgChange: true
      }
    );

    useEffect(() => {
      if (apiSharingPartner?.data && apiSharingPartnerId) {
        form.setFieldsValue({
          apiKey: apiSharingPartner.data.apiKey,
          code: apiSharingPartner.data.code,
          description: apiSharingPartner.data.description,
          isActive: apiSharingPartner.data.isActive,
          name: apiSharingPartner.data.name,
          permissions: apiSharingPartner.data.permissions?.map((o) => ({
            label: o.name,
            value: o.optionId
          }))
        });
      }
    }, [apiSharingPartner, apiSharingPartnerId]);

    const [form] = Form.useForm<ApiSharingPartnerFormType>();

    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateApiSharingPartnerMutation();
    const [onCreate, { isLoading: isLoadingCreate }] = useCreateApiSharingPartnerMutation();

    const onFinish = ({ isActive, ...values }: ApiSharingPartnerFormType) => {
      const data: CreateApiSharingPartnerDto = {
        ...values,
        permissions: values.permissions?.map((o) => o.value as string) ?? []
      };

      if (!apiSharingPartnerId) {
        onCreate({ ...data, isActive: true })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          apiSharingPartnerId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            message.systemSuccess(rs.message);
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingUpdate || isLoadingCreate);
      }
    }, [onChangeLoading, isLoadingUpdate, isLoadingCreate]);

    const generateApiKey = (length = 12) => {
      const charactersAllowed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let apiKey = '';

      for (let i = 0; i < length; i++) {
        apiKey += charactersAllowed.charAt(Math.floor(Math.random() * charactersAllowed.length));
      }

      return apiKey;
    };

    return (
      <Form
        scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name='apiSharingPartnerForm'
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
        initialValues={
          apiSharingPartnerId ? updateApiSharingPartnerInitialValues : createApiSharingPartnerInitialValues
        }
      >
        <Spin spinning={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.name}
            name='name'
            rules={apiSharingPartnerValidationRules.name}
          >
            <Input />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.description}
            name='description'
            rules={apiSharingPartnerValidationRules.description}
          >
            <Input.TextArea />
          </FormItem.FloatLabel>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.code}
            name='code'
            rules={apiSharingPartnerValidationRules.code}
          >
            <Input />
          </FormItem.FloatLabel>
          <div className='mb-4 flex items-center gap-4'>
            <FormItem.FloatLabel<ApiSharingPartnerFormType>
              label={apiSharingPartnersMessages.apiKey}
              name='apiKey'
              rules={apiSharingPartnerValidationRules.apiKey}
              className='mb-0 flex-1'
              normalize={(value) => value.trim()}
            >
              <Input maxLength={50} />
            </FormItem.FloatLabel>
            <Button
              onClick={() => {
                const apiKey = generateApiKey();
                form.setFields([
                  {
                    name: 'apiKey',
                    value: apiKey,
                    errors: undefined
                  }
                ]);
              }}
            >
              {apiSharingPartnersMessages.generateApiKey}
            </Button>
          </div>
          <FormItem.FloatLabel<ApiSharingPartnerFormType>
            label={apiSharingPartnersMessages.permissions}
            name='permissions'
            rules={apiSharingPartnerValidationRules.permissions}
          >
            <SelectOptions
              mode='multiple'
              labelInValue
              allowClear
              optionTypeId={CORE_OPTION_TYPES.API_SHARING_PERMISSION}
              service={MICROSERVICES.CORE}
            />
          </FormItem.FloatLabel>
        </Spin>
      </Form>
    );
  }
);
export default ApiSharingPartnerForm;
