import { useEffect, useRef } from 'react';
import SocketConnectionSingleton from '../utils/socket/socketConnectionSingleton';

/**
 * Hook for registering socket event handlers with a stable callback implementation
 * @param handlerId Unique identifier for this handler
 * @param callback Function to be called when event is received
 */
export function useSocketHandler(handlerId: string, callback: (payload: any) => void): void {
    // Keep a ref to the socket singleton
    const socketRef = useRef(SocketConnectionSingleton.getInstance());

    // Keep a stable reference to the latest callback
    const callbackRef = useRef(callback);

    // Update the callback ref when it changes
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Set up socket handler once on mount
    useEffect(() => {
        // Create stable handler function that uses the latest callback
        const stableHandler = (payload: any) => {
            callbackRef.current(payload);
        };

        // Register the handler with our unique ID
        socketRef.current.onAgentUpdate(handlerId, stableHandler);

        // Clean up on unmount
        return () => {
            socketRef.current.offAgentUpdate(handlerId);
        };
    }, [handlerId]); // Only depends on handlerId, not the callback
}
