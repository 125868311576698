export const permissionsMessages = {
  page: 'Phân quyền',
  name: 'Tên quyền',
  description: 'Mô tả',
  user: 'Người dùng',
  group: 'Nhóm',
  title: 'Quyền',
  scope: 'Phạm vi áp dụng',
  organizationUnit: 'Đơn vị / phòng đội được áp dụng',
  updateScope: 'Cập nhật phạm vi',
  allOrganizationUnits: 'Tất cả đơn vị',
  noPermission: 'Không có quyền',
  listPermission: 'Danh sách quyền',
  updateScopeTitle: 'Cập nhật phạm vi áp dụng',
  scopeTooltip: 'Những đơn vị được phân quyền này cho người dùng'
};
