import { useGetElectricalRecordingSchedulesQuery } from "services";
import { useGetCustomerContractInfoQuery, useGetCustomerElectricalRecordingScheduleQuery, useGetCustomerMeterReadingCodeQuery, useGetCustomerOTTInformationQuery, useGetCustomerPaymentCodeQuery, useGetCustomerPriceContractQuery, useGetCustomerThirdPartyPaymentQuery, useGetThirdPartyPaymentHistoryQuery } from "services/customer-lookup";
import { FindAllCustomerDto } from "types";
import { FindAllCustomerContractInfoDto } from "types/dto/customer-lookup/customer-contract-info.dto";

export function usePostCustomerPriceContract(customerCode: String) {
    const { data: customerRes, isLoading } = useGetCustomerPriceContractQuery(customerCode!, {
        skip: !customerCode,
        refetchOnMountOrArgChange: true
    });

    return {
        price: customerRes?.data || [],
        isLoading
    };
}

export function GetCustomerPriceContract(customerCode: String) {
    const { data: customerRes, isLoading } = useGetCustomerPriceContractQuery(customerCode!, {
        skip: !customerCode,
        refetchOnMountOrArgChange: true
    });

    return {
        priceData: customerRes?.data || [],
        isLoading
    };
}

export function GetCustomerMeterReadingCode(customerCode: String) {
    const { data: customerRes, isLoading } = useGetCustomerMeterReadingCodeQuery(customerCode!, {
        skip: !customerCode,
        refetchOnMountOrArgChange: true
    });

    return {
        meterCode: customerRes?.data || [],
        isLoading
    };
}

export function GetCustomerThirdParty(customerCode: String) {
    const { data: customerRes, isLoading } = useGetCustomerThirdPartyPaymentQuery(customerCode!, {
        skip: !customerCode,
        refetchOnMountOrArgChange: true
    });

    return {
        thirdPartyInfo: customerRes?.data || [],
        isLoading
    };
}

export function GetCustomerThirdPartyHistory(customerCode: String) {
    const { data: customerRes, isLoading } = useGetThirdPartyPaymentHistoryQuery(customerCode!, {
        skip: !customerCode,
        refetchOnMountOrArgChange: true
    });

    return {
        thirdPartyHistory: customerRes?.data || [],
        isLoading
    };
}

export function GetCustomerOTTInformation(customerCode: String) {
    const { data: customerRes, isLoading } = useGetCustomerOTTInformationQuery(customerCode!, {
        skip: !customerCode,
        refetchOnMountOrArgChange: true
    });

    return {
        ott: customerRes?.data || [],
        isLoading
    };
}

export function GetCustomerElectricalRecordSchedule(sessionNumber: String) {
    const { data: customerRes, isLoading } = useGetCustomerElectricalRecordingScheduleQuery(sessionNumber!, {
        skip: !sessionNumber,
        refetchOnMountOrArgChange: true
    });

    return {
        schedule: customerRes?.data.rows || [],
        count: customerRes?.data.count || 0,
        isLoading
    };
}

export function useGetCustomerContractInfo(customerCode: string) {
    const { data: customerRes, isLoading } = useGetCustomerContractInfoQuery(customerCode!, {
            skip: !customerCode,
            refetchOnMountOrArgChange: true
        });
    
        return {
            contract: customerRes?.data ?? [],
            isLoading
        };
}

export function useGetCustomerPaymentCode(customerCode: string) {
    const { data: customerRes, isLoading } = useGetCustomerPaymentCodeQuery(customerCode!, {
            skip: !customerCode,
            refetchOnMountOrArgChange: true
    });
    
    return {
        customerPaymentCode: customerRes?.data ?? [],
        isLoading
    };
}


