import Icon from '@ant-design/icons';
import { Spin, TreeDataNode, TreeSelect, TreeSelectProps } from 'antd';
import { ArrowDownIcon, LoupIcon } from 'assets';
import React, { useState } from 'react';
import { useGetOrganizationUnitsHierachyOptionsQuery } from 'services';
import { FindOrganizationUnitHierarchyDto, OrganizationUnitHierarchyCompactDto } from 'types';
import { removeVietnameseAccents } from 'utils';

type TreeSelectOrganizationUnitsProps = TreeSelectProps & FindOrganizationUnitHierarchyDto;

const TreeSelectOrganizationUnits: React.FC<TreeSelectOrganizationUnitsProps> = ({
  parentId,
  organizationUnitId,
  showCheckedStrategy = 'SHOW_PARENT',
  treeCheckable = true,
  ...props
}) => {
  const [keyword, setKeyword] = useState('');

  const { data: organizationsUnitsHierarchy, isLoading } = useGetOrganizationUnitsHierachyOptionsQuery({
    parentId,
    organizationUnitId
  });

  const handleSearch = (val: string) => {
    setKeyword(val);
  };
  const transformData = (treeData: OrganizationUnitHierarchyCompactDto[]): any[] => {
    if (treeData.length === 0) return [];

    return treeData.map((org) => ({
      title: org.name,
      value: org.organizationUnitId,
      key: org.organizationUnitId,
      children: org.children?.length ? transformData(org.children) : []
    }));
  };

  const searchTree = (data: TreeDataNode[], keyword = ''): any[] => {
    const trimKeyword = removeVietnameseAccents(keyword).toLowerCase();
    const result: TreeDataNode[] = [];
    data.forEach((item) => {
      const isValid = removeVietnameseAccents(item.title as string)
        .toLowerCase()
        .includes(trimKeyword);
      const children = item.children && item.children.length > 0 ? searchTree(item.children, keyword) : [];
      if (isValid || children.length > 0) {
        result.push({
          ...item,
          children: children.length > 0 ? children : undefined
        });
      }
    });
    return result;
  };

  return (
    <TreeSelect
      {...props}
      loading={isLoading}
      onSearch={handleSearch}
      filterTreeNode={false}
      showCheckedStrategy={showCheckedStrategy}
      treeData={searchTree(transformData(organizationsUnitsHierarchy?.data ?? []), keyword)}
      suffixIcon={
        isLoading ? (
          <Spin spinning />
        ) : props.showSearch ? (
          <LoupIcon className='h-5 w-5 text-colorTextPlaceholder' />
        ) : (
          <ArrowDownIcon className='h-[10px] w-[10px] text-colorTextBase' />
        )
      }
    />
  );
};
export default TreeSelectOrganizationUnits;
