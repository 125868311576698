import { useEffect, useRef } from 'react';
import {
    connectNotificationSocket,
    disconnectNotificationSocket,
    notificationsSocketClient,
    offReceiveNotification,
    omniChatSocketClient,
    onReceiveAgentUpdateNotification,
    onReceiveTotalCallandChat
} from 'services';
import { NOTIFICATION_EVENT, OMNI_CHANNEL_EVENT } from 'utils';
import { parseComplexDate } from 'utils/dateUtils';

/**
 * Hook to manage agent status socket connections
 * This centralizes socket logic to ensure consistent behavior across components
 */
export function useAgentStatusSocket(
    componentName: string,
    statusHandler?: (payload: any) => void,
    counterHandler?: (payload: any) => void
) {
    const isConnected = useRef(false);

    // Store handlers in refs so they don't change between renders
    const statusHandlerRef = useRef(statusHandler);
    const counterHandlerRef = useRef(counterHandler);

    // Use refs to track if we've already set up handlers
    const statusHandlerSetupRef = useRef(false);
    const counterHandlerSetupRef = useRef(false);

    // Update refs when handlers change
    useEffect(() => {
        statusHandlerRef.current = statusHandler;
        counterHandlerRef.current = counterHandler;
    }, [statusHandler, counterHandler]);

    useEffect(() => {
        console.log(`🔌 Socket ${componentName}: Connecting to notification socket`);

        // Always ensure we have a socket connection
        if (!isConnected.current) {
            connectNotificationSocket();
            isConnected.current = true;
        }

        // Wrap the handlers to use the current ref values
        const statusHandlerWrapper = (data: any) => {
            // Parse any dates in the payload
            if (data.agentChangeStatusDate) {
                // Convert the date format from "2025-03-19 10:06:27.8550000 +07:00" to a timestamp
                const parsedTimestamp = parseComplexDate(data.agentChangeStatusDate);

                // Add the parsed timestamp to the data
                data.parsedTimestamp = parsedTimestamp;

                console.log(`Parsed date from socket: ${data.agentChangeStatusDate} -> ${new Date(parsedTimestamp).toISOString()}`);
            }

            console.log(`🔌 Socket ${componentName}: Status update received:`, data);
            statusHandlerRef.current?.(data);
        };

        const counterHandlerWrapper = (data: any) => {
            console.log(`🔌 Socket ${componentName}: Counter update received:`, data);
            console.log(`🔌 Socket ${componentName}: Has counter handler:`, !!counterHandlerRef.current);
            counterHandlerRef.current?.(data);
        };

        // Set up status update listener only once
        if (statusHandler && !statusHandlerSetupRef.current) {
            console.log(`🔌 Socket ${componentName}: Setting up status update listener`);
            onReceiveAgentUpdateNotification(statusHandlerWrapper);
            statusHandlerSetupRef.current = true;
        }

        // Set up counter update listener only once
        if (counterHandler && !counterHandlerSetupRef.current) {
            console.log(`🔌 Socket ${componentName}: Setting up counter update listener`);
            onReceiveTotalCallandChat(counterHandlerWrapper);
            counterHandlerSetupRef.current = true;
        }

        // Cleanup function - store specific handler references
        return () => {
            console.log(`🔌 Socket ${componentName}: Cleaning up listeners`);

            // Remove specific listeners instead of just calling offReceiveNotification
            if (statusHandler && statusHandlerSetupRef.current) {
                notificationsSocketClient.socket.off(NOTIFICATION_EVENT.UPDATE_AGENT_MAP, statusHandlerWrapper);
                statusHandlerSetupRef.current = false;
            }

            if (counterHandler && counterHandlerSetupRef.current) {
                omniChatSocketClient.socket.off(OMNI_CHANNEL_EVENT.AGENT_COUNTER_UPDATE, counterHandlerWrapper);
                counterHandlerSetupRef.current = false;
            }
        };
    }, [componentName]); // Only depend on componentName, not the handlers

    // On final unmount (app shutdown), we should disconnect completely
    useEffect(() => {
        return () => {
            console.log(`🔌 Socket ${componentName}: Final cleanup`);
            // This will only run when the app is shutting down
            disconnectNotificationSocket();
            isConnected.current = false;
        };
    }, []);
}
