import { Button, Form, Input, Modal } from 'antd';
import { FormItem } from 'components/common';
import { validateMessages } from 'messages';
import { agentMapsMessages } from 'messages/agent-maps.messages';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { CreateAgentMapSeatDto } from 'types';
import { FabricAgentMapSeat } from 'types/fabric-agent-map';

type AgentMapModalInfoSeatProps = {
  handleSubmit: (value: AgentMapFormInfoSeatType) => void;
};

export type AgentMapFormInfoSeatType = Pick<CreateAgentMapSeatDto, 'seatName' | 'seatIp'> & {
  id?: string | number;
  existingSeats?: FabricAgentMapSeat[];
};

export type AgentMapModalInfoSeatRef = {
  setFieldsValue: (value: AgentMapFormInfoSeatType) => void;
  setOpenInfo: (value: boolean) => void;
};

const AgentMapModalInfoSeat = forwardRef<AgentMapModalInfoSeatRef, AgentMapModalInfoSeatProps>(
  ({ handleSubmit }, ref) => {
    useImperativeHandle(ref, () => ({
      setFieldsValue,
      setOpenInfo
    }));
    const [openInfo, setOpenInfo] = useState(false);

    const [form] = Form.useForm<AgentMapFormInfoSeatType>();

    const setFieldsValue = (values: AgentMapFormInfoSeatType) => {
      form.setFieldsValue(values);
    };

    const onSubmit = (values: AgentMapFormInfoSeatType) => {
      const { existingSeats, seatIp, seatName, id } = values;
      const currentSeats = (existingSeats && existingSeats.filter((o) => o.id !== id)) ?? [];

      let err = false;

      if (currentSeats.map((o) => o.seatIp).includes(seatIp)) {
        form.setFields([
          {
            name: 'seatIp',
            errors: [agentMapsMessages.seatIpDuplicate]
          }
        ]);
        err = true;
      }
      if (currentSeats.map((o) => o.seatName).includes(seatName)) {
        form.setFields([
          {
            name: 'seatName',
            errors: [agentMapsMessages.seatNameDuplicate]
          }
        ]);
        err = true;
      }

      if (err === true) return;

      handleSubmit(values);
      setOpenInfo(false);
    };

    return (
      <Modal
        width={568}
        title={agentMapsMessages.editSeatFormTitle}
        open={openInfo}
        onCancel={() => setOpenInfo(false)}
        footer={
          <Button
            onClick={() => {
              form.submit();
            }}
            type='primary'
          >
            {agentMapsMessages.save}
          </Button>
        }
      >
        <Form
          labelAlign='right'
          scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
          form={form}
          onFinish={onSubmit}
          labelCol={{
            flex: '180px'
          }}
          validateMessages={validateMessages}
        >
          <Form.Item<AgentMapFormInfoSeatType> noStyle name='existingSeats' />
          <Form.Item<AgentMapFormInfoSeatType> noStyle name='id' />

          <FormItem.FloatLabel<AgentMapFormInfoSeatType>
            rules={[{ required: true }]}
            name='seatName'
            label={agentMapsMessages.seatName}
          >
            <Input />
          </FormItem.FloatLabel>

          <FormItem.FloatLabel<AgentMapFormInfoSeatType>
            label={agentMapsMessages.seatIp}
            name='seatIp'
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem.FloatLabel>
        </Form>
      </Modal>
    );
  }
);

export default AgentMapModalInfoSeat;
