import React, { useState } from 'react';
import { Typography } from 'antd';
import { BlueArrow } from 'assets';
import Avatar from 'components/common/avatar/Avatar';
import dayjs from 'dayjs';
import { TicketDetail } from 'types';
import progressIcon from '../../assets/svg/progressing-setting.svg';
import { requestform } from 'messages';

interface RequestDetailProcessingProps {
  requestItem: TicketDetail | null;
}

const NormalTicketOpenProcessing: React.FC<RequestDetailProcessingProps> = ({ requestItem }) => {
  return (
    <div>
      <div className='flex items-center space-x-3'>
        <img src={progressIcon} alt='' />
        <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
          {requestform.processingTitle}
        </Typography.Text>
      </div>
      {requestItem?.ticketEvents.map((event, index) => (
        <div key={event.ticketId} className='relative mx-4 flex items-center justify-between'>
          <div className={`absolute -left-4 top-[60%] rounded-full border bg-white p-2`}>
            <BlueArrow />
          </div>
          <div className={`flex w-full items-center justify-between border-l pt-16`}>
            <div className='mx-6 w-full'>
              <Typography.Text className='text-[16px]/[18px] font-semibold text-[#141414]'>
                {event.createdByName}
              </Typography.Text>{' '}
              <Typography.Text> {event?.actionType?.name}</Typography.Text>
              <br />
              {(() => {
                const formatDate = (date: string) => {
                  return date ? dayjs(date).format('DD [tháng] MM, YYYY - HH:mm') : '';
                };

                const formattedDate = formatDate(event.createdAt);

                return (
                  <div className='text-[14px] italic text-[#141414]'>
                    {formattedDate.split('-')[1]}{' '}
                    <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                    {formattedDate.split('-')[0]}{' '}
                    <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                    {requestItem?.ticketEvents?.[0]?.organizationUnitName}
                  </div>
                );
              })()}
            </div>
            <Avatar name={requestItem?.ticketEvents[index].createdByName} size={32} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NormalTicketOpenProcessing;
