import { Button, ButtonProps } from 'antd';
import { LeftIcon } from 'assets';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';

type NavigateBackType = ButtonProps & {
  defaultUrl?: string;
};

const NavigateBack = ({ defaultUrl = ROUTE.HOME, ...props }: NavigateBackType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const handleGoBack = () => {
    const queryString = state ? new URLSearchParams(state).toString() : undefined;
    navigate(`${defaultUrl}${queryString ? `?${queryString}` : ''}`);
  };

  return (
    <Button onClick={handleGoBack} icon={<LeftIcon />} type='text' ghost size='small' className='h-8 w-8' {...props} />
  );
};
export default NavigateBack;
