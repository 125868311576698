import { Card, Table } from 'antd';
import { useEffect, useState } from 'react';
import { WaitingCallsDto } from 'types';

type WaitingCallsDetailProps = {
    waitingCalls: WaitingCallsDto[];
};

const calculateElapsedSeconds = (startTime: string): number => {
    const start = new Date(startTime).getTime();
    const now = Date.now();
    const elapsedSeconds = Math.floor((now - start) / 1000);
    return elapsedSeconds > 0 ? elapsedSeconds : 0;
};

const WaitingCallsDetail: React.FC<WaitingCallsDetailProps> = ({ waitingCalls }) => {
    const [tick, setTick] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTick(tick => tick + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    // Table column definitions
    const columns = [
        {
            title: 'Số điện thoại',
            dataIndex: 'customerNumber',
            key: 'customerNumber',
            align: 'center' as const,
        },
        {
            title: 'Thời gian',
            key: 'startTime',
            align: 'center' as const,
            render: (_: any, record: WaitingCallsDto) => `${calculateElapsedSeconds(record.startTime)}s`,
        },
        {
            title: 'Đầu số',
            dataIndex: 'dnis',
            key: 'dnis',
            align: 'center' as const,
        },
    ];

    return (
        <Card
            size="small"
            title={<div className="text-center w-full font-semibold">Số cuộc gọi đang chờ</div>}
            style={{ width: 400 }}
        >
            <Table
                columns={columns}
                dataSource={waitingCalls}
                rowKey={(record) => record.customerNumber.toString()}
                pagination={false}
                bordered
                className="w-full"
                locale={{ emptyText: 'Không có cuộc gọi nào đang chờ' }}
            />
        </Card>
    );
};

export default WaitingCallsDetail;