import { useGetCustomerContactInfoQuery } from "services";

export function useCustomerContactInfo(customerCode: string) {
    const { data: customerContactInfoRes, isLoading } = useGetCustomerContactInfoQuery(customerCode!, {
        skip: !customerCode,
        refetchOnMountOrArgChange: true
    });

    return {
        customerContactInfo: customerContactInfoRes?.data,
        isLoading
    };
}