import { Checkbox, Col, Row, Typography } from 'antd';
import { find } from 'lodash';
import React, { useState } from 'react';
import FilterOrigin, { FilterPopoverOptionItem } from './Filter';
import { twMerge } from 'tailwind-merge';
import { messages } from 'messages';

type FilterCheckboxProps = React.ComponentProps<typeof FilterOrigin> & {
  options: FilterPopoverOptionItem[];
  count?: number;
};
const FilterCheckbox = ({ value = [], options, children, icon, title, count, ...props }: FilterCheckboxProps) => {
  const [valueTemp, setValueTemp] = useState(value);
  const content = (
    <Checkbox.Group
      onChange={(val) => {
        setValueTemp(val);
      }}
      value={valueTemp}
    >
      <Row
        style={{ maxWidth: 250 }}
        className='no-scrollbar -mx-4 max-h-[300px] w-[calc(100%+32px)] overflow-auto'
        align='middle'
      >
        {options.map((o, idx) => (
          <Col
            span={24}
            key={o.value?.toString() || `key-${idx}`}
            className='flex h-11 items-center hover:bg-hoverColor1'
          >
            <Checkbox style={{ width: 'calc(100% - 14px)' }} className='px-4' value={o.value}>
              {o.label}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );

  const classNameValue = 'mb-0 max-w-[50px] xl:max-w-[135px] text-sm';

  const childrenLabel = children ?? (
    <>
      {icon}
      <div>
        {value && value?.length > 0 ? (
          <Typography.Paragraph
            className={twMerge(classNameValue)}
            ellipsis={{
              rows: 1,
              tooltip: true
            }}
          >
            {value.length === count
              ? `${title}: ${messages.all}`
              : value.map((o: any) => find(options, { value: o })?.label).join(', ')}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph
            className={twMerge(classNameValue)}
            ellipsis={{
              rows: 1,
              tooltip: true
            }}
          >
            {`${title}: ${messages.all}`}
          </Typography.Paragraph>
        )}
      </div>
    </>
  );

  return (
    <FilterOrigin
      {...props}
      title={title}
      content={content}
      valueTemp={valueTemp}
      clearFilter={() => {
        setValueTemp([]);
      }}
      value={value}
      setValueTemp={setValueTemp}
      selectedCount={Array.isArray(valueTemp) ? valueTemp.length : valueTemp ? 1 : 0}
    >
      {childrenLabel}
    </FilterOrigin>
  );
};

export default FilterCheckbox;
