import { useSetMuteSoftPhoneMutation } from "services/softphone/softphone.api";
import { MuteSoftPhoneDto } from "types";

export function useMuteSoftPhone() {
  const [mute, { isLoading, error }] = useSetMuteSoftPhoneMutation();

  const onMuteSoftPhoneHandle = async (values: MuteSoftPhoneDto) => {
    try {
      const res = await mute(values).unwrap(); // ✅ Wait for the API response


      return res; // ✅ Return the API response
    } catch (err) {
      console.error("Error answering softphone:", err);
      throw err; // ✅ Re-throw error so it can be handled by caller
    }
  };

  return { isLoading, error, onMuteSoftPhoneHandle };
}
