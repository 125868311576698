import { OmniChannelMessageDto, OmniChannelSenderDto } from 'types';
import {
  MESSAGE_DATE_HEIGHT,
  MESSAGE_FILE_HEIGHT,
  MESSAGE_REPLY_HEIGHT_IN_IMAGE,
  MESSAGE_REPLY_HEIGHT_IN_TEXT,
  MESSAGE_SENDER_NAME_IN_COMMENT,
  MESSAGE_SPACING,
  MESSAGE_SPACING_LARGE
} from 'utils/constants';
import { MESSAGE_REF_TYPE, MESSAGE_TYPE } from 'utils/enums';
import { calculateHeightOmniMessageImage } from './calculateHeightOmniMessageImage';
import { calculateHeightOmniMessageSystem } from './calculateHeightOmniMessageSystem';
import { calculateHeightOmniMessageText } from './calculateHeightOmniMessageText';
import { getPropsOmniMessageItem } from './getPropsOmniMessageItem';
import { calculateHeightOmniMessageComment } from './calculateHeightOmniMessageComment';

export const calculateHeightOmniMessageItem = async (
  message: OmniChannelMessageDto,
  nextMess?: OmniChannelMessageDto | null,
  prevMess?: OmniChannelMessageDto | null,
  isGroup?: boolean,
  profileId?: number,
  sender?: OmniChannelSenderDto,
  isShowActions?: boolean,
  customerId?: string
) => {
  let heightMessage = 0;

  const { isFirstOfSender, isOnSameDate } = getPropsOmniMessageItem(message, nextMess, prevMess);
  const isMe = message.senderId === profileId;
  const isReply = Boolean(message.refType === MESSAGE_REF_TYPE.REPLY && message.refMessage);
  const isCustomer = message.senderId === customerId;

  const isHasSender = !isCustomer && Boolean(message.senderName && isFirstOfSender);

  switch (message.messageType) {
    case MESSAGE_TYPE.SYSTEM:
      heightMessage = calculateHeightOmniMessageSystem(message, {
        isGroup,
        isMe,
        isFirstOfSender,
        sender
      });
      break;
    case MESSAGE_TYPE.STICKER:
    case MESSAGE_TYPE.TEXT:
      if (message.content) {
        heightMessage = calculateHeightOmniMessageText(message, {
          isGroup,
          isMe,
          isFirstOfSender
        });
      } else heightMessage = 0;

      break;

    case MESSAGE_TYPE.IMAGE:
      if (typeof message.fileId === 'number' || message.file) {
        heightMessage = calculateHeightOmniMessageImage(message);
      }
      if (isHasSender) {
        heightMessage += MESSAGE_SENDER_NAME_IN_COMMENT;
      }

      break;

    case MESSAGE_TYPE.FILE:
      if (typeof message.fileId === 'number' || message.file) {
        heightMessage = MESSAGE_FILE_HEIGHT;
      }

      break;
    case MESSAGE_TYPE.COMMENT:
      heightMessage = await calculateHeightOmniMessageComment(message);
      if (isReply) {
        heightMessage += MESSAGE_REPLY_HEIGHT_IN_IMAGE;
      }
      if (isHasSender) {
        heightMessage +=
          message.content && !message.file && !message.fileId && !isReply
            ? MESSAGE_SENDER_NAME_IN_COMMENT - 8
            : MESSAGE_SENDER_NAME_IN_COMMENT;
      }
      break;
  }

  /** Chiều cao của ngày gửi tin nhắn khác ngày nhau */
  heightMessage += !!heightMessage && !isOnSameDate ? MESSAGE_DATE_HEIGHT : 0;

  /** Khoảng cách giữa các tin nhắn của cùng người gửi hay khác người gửi */
  heightMessage += !!heightMessage
    ? message.senderId !== nextMess?.senderId
      ? MESSAGE_SPACING_LARGE
      : MESSAGE_SPACING
    : 0;

  return heightMessage;
};
