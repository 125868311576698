import { EDITOR_PLUGINS } from 'utils/enums';

export const EDITOR_PLUGINS_INIT = [
  EDITOR_PLUGINS.ACCORDION,
  EDITOR_PLUGINS.ADV_LIST,
  EDITOR_PLUGINS.ANCHOR,
  EDITOR_PLUGINS.AUTOLINK,
  EDITOR_PLUGINS.CHAR_MAP,
  EDITOR_PLUGINS.CODE,
  EDITOR_PLUGINS.CODE_SAMPLE,
  EDITOR_PLUGINS.DIRECTIONALITY,
  EDITOR_PLUGINS.EMOTICONS,
  EDITOR_PLUGINS.FULLSCREEN,
  EDITOR_PLUGINS.HELP,
  EDITOR_PLUGINS.IMAGE,
  EDITOR_PLUGINS.IMPORT_CSS,
  EDITOR_PLUGINS.INSERT_DATETIME,
  EDITOR_PLUGINS.LINK,
  EDITOR_PLUGINS.LISTS,
  EDITOR_PLUGINS.MEDIA,
  EDITOR_PLUGINS.NONBREAKING,
  EDITOR_PLUGINS.PAGEBREAK,
  EDITOR_PLUGINS.PREVIEW,
  EDITOR_PLUGINS.SAVE,
  EDITOR_PLUGINS.SEARCH_REPLACE,
  EDITOR_PLUGINS.TABLE,
  // EDITOR_PLUGINS.TEMPLATE,
  EDITOR_PLUGINS.VISUAL_BLOCKS,
  EDITOR_PLUGINS.VISUAL_CHARS,
  EDITOR_PLUGINS.WORD_COUNT
];
