import { Button, Descriptions, Skeleton, Space, Typography } from 'antd';
import { IdCardIcon, MailIcon, PasswordIcon, PhoneIcon, UserIcon } from 'assets';
import { ChangeUserPasswordModal } from 'components';
import { Avatar, NavigateBack, PageHeader } from 'components/common';
import { useUpdateUser, useUser } from 'hooks';
import { usersMessages } from 'messages/users.messages';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { FileDto } from 'types';
import { DATE_TIME_FORMAT, DEFAULT_PASSWORD_CHART } from 'utils';
import { UserChangePasswordFormRefProps } from './UserChangePasswordForm';
import { ROUTE } from 'routes/constants';
import { messages } from 'messages';
import dayjs from 'dayjs';

type UserPersonalInfoProps = {
  userId: number;
};
const UserPersonalInfo = ({ userId }: UserPersonalInfoProps) => {
  const { user, isLoading } = useUser(userId);
  const { isLoading: isLoadingUpdate, onUpdateUserHandle } = useUpdateUser(userId);
  const [openChangePass, setOpenChangePass] = useState(false);

  const changePassFormRef = useRef<UserChangePasswordFormRefProps>(null);

  useEffect(() => {
    if (!openChangePass && changePassFormRef.current) {
      changePassFormRef.current.form.resetFields();
    }
  }, [openChangePass, changePassFormRef.current]);

  const onUpdateAvatarHandle = (value: FileDto) => {
    if (value) {
      onUpdateUserHandle({
        avatar: value.fileId
      });
    }
  };
  const onDeleteAvatarHandle = () => {
    onUpdateUserHandle({
      avatar: null
    });
  };

  const descriptionHandleBy = [
    {
      key: 'updatedAt',
      label: messages.updatedAt,
      children: user?.updatedAt && user?.updatedByUser ? dayjs(user.updatedAt).format(DATE_TIME_FORMAT) : '-'
    },
    {
      key: 'updatedBy',
      label: messages.updatedByUser,
      children: user?.updatedByUser ? (
        <div className='flex gap-2'>
          <Avatar fileId={user?.updatedByUser?.avatar} name={user?.updatedByUser?.fullName} />
          <Typography.Text>{user?.updatedByUser?.fullName}</Typography.Text>
        </div>
      ) : (
        '-'
      )
    },
    {
      key: 'createdAt',
      label: messages.createdAt,
      children: user?.createdAt ? dayjs(user.createdAt).format(DATE_TIME_FORMAT) : '-'
    },
    {
      key: 'createdBy',
      label: messages.createdByUser,
      children: user?.createdByUser ? (
        <div className='flex gap-2'>
          <Avatar fileId={user?.createdByUser?.avatar} name={user?.createdByUser?.fullName} />
          <Typography.Text>{user?.createdByUser?.fullName}</Typography.Text>
        </div>
      ) : (
        '-'
      )
    }
  ];

  return (
    <div className='flex flex-col gap-4'>
      <PageHeader className='flex items-center gap-1'>
        <NavigateBack defaultUrl={ROUTE.USERS} />
        <Skeleton active loading={isLoading}>
          <Typography.Title className='text-header-label mb-0 font-bold'>{user?.fullName}</Typography.Title>
        </Skeleton>
      </PageHeader>
      <div
        className={twMerge(
          'flex min-h-[156px] w-full items-center rounded-xl bg-colorBgContainer px-5 py-5',
          isLoading && 'skeleton-active'
        )}
      >
        <div className='flex w-full gap-6'>
          <div className='flex flex-1 flex-col items-center justify-center gap-4'>
            <Avatar.WithUpdate
              onUploadSuccess={onUpdateAvatarHandle}
              fileId={user?.avatar}
              size={84}
              name={user?.fullName}
              isLoading={isLoadingUpdate}
              onDeleteSuccess={onDeleteAvatarHandle}
            />
            <Typography.Paragraph
              ellipsis={{ rows: 2, tooltip: true }}
              className='mb-0 text-center text-lg font-semibold'
            >
              {user?.fullName}
            </Typography.Paragraph>
          </div>
          {user && (
            <div className='flex h-fit flex-1 flex-col justify-center gap-4'>
              <Space direction='vertical' size={0}>
                <Typography.Text className='text-xs'>{usersMessages.employeeId}</Typography.Text>
                <Typography.Text className='font-medium'>{user.employeeId}</Typography.Text>
              </Space>

              <Space direction='vertical' size={0}>
                <Typography.Text className='text-xs'>{usersMessages.organizationUnit}</Typography.Text>
                <Typography.Text className='font-medium'>{user.organizationUnit?.name ?? '-'}</Typography.Text>
              </Space>
            </div>
          )}
        </div>
      </div>
      <div
        className={twMerge(
          'flex flex-col gap-4 rounded-xl bg-colorBgContainer p-5',
          isLoading && 'skeleton-active min-h-[264px]'
        )}
      >
        {user && !isLoading && (
          <>
            <Space size={12}>
              <IdCardIcon className='h-6 w-6' />
              <Typography.Text type='secondary'>{user.username ?? '-'}</Typography.Text>
            </Space>
            <Space size={12}>
              <MailIcon className='h-6 w-6' />
              <Typography.Text type='secondary'>{user.email ?? '-'}</Typography.Text>
            </Space>
            <Space size={12}>
              <PhoneIcon className='h-6 w-6' />
              <Typography.Text type='secondary'>{user.phoneNumber ?? '-'}</Typography.Text>
            </Space>
            <Space size={12}>
              <UserIcon className='h-6 w-6' />
              <Typography.Text type='secondary'>{user.gender?.name ?? '-'}</Typography.Text>
            </Space>
            <div className='flex w-full justify-between'>
              <Space align='center' size={12} className='w-full'>
                <PasswordIcon className='h-6 w-6' />
                <Typography.Text type='secondary'>{DEFAULT_PASSWORD_CHART}</Typography.Text>
              </Space>
              <Button
                onClick={() => {
                  setOpenChangePass(true);
                }}
                type='link'
              >
                {usersMessages.changePassword}
              </Button>
            </div>
          </>
        )}
      </div>
      <div
        className={twMerge(
          'flex flex-col gap-4 rounded-xl bg-colorBgContainer p-5',
          isLoading && 'skeleton-active min-h-[60px]'
        )}
      >
        {user && (
          <>
            <Space size={12}>
              <Typography.Text>{usersMessages.userGroup}</Typography.Text>
              <Typography.Text type='secondary'>{user.userGroup?.name || '-'}</Typography.Text>
            </Space>
          </>
        )}
      </div>
      <div
        className={twMerge(
          'flex flex-col gap-4 rounded-xl bg-colorBgContainer p-5',
          isLoading && 'skeleton-active min-h-[60px]'
        )}
      >
        {user && <Descriptions colon={false} items={descriptionHandleBy} column={1} />}
      </div>

      <ChangeUserPasswordModal
        open={openChangePass}
        userId={userId}
        onCancel={() => {
          setOpenChangePass(false);
        }}
        onClose={() => {
          setOpenChangePass(false);
        }}
      />
    </div>
  );
};
export default UserPersonalInfo;
