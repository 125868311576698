import { usePaging } from 'hooks/usePaging';
import { useCallback } from 'react';
import { useGetOneRoleOrganizationUnitsQuery } from 'services';
import { FindOneRoleOrganizationUnitsDto } from 'types';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useRoleOrganizationUnits(params: Omit<FindOneRoleOrganizationUnitsDto, 'pageIndex' | 'pageSize'>) {
  const { pageIndex, resetPage, nextPage } = usePaging(params);
  const {
    data: dataResponse,
    isLoading,
    isFetching
  } = useGetOneRoleOrganizationUnitsQuery({
    pageIndex: pageIndex,
    pageSize,
    ...params
  });

  const { rows, count } = dataResponse?.data || {};

  const hasMore = Boolean(rows && count && rows.length < count);
  const handleLoadMore = useCallback(() => {
    if (hasMore && !isFetching) {
      nextPage();
    }
  }, [hasMore, pageIndex]);

  return {
    organizationUnits: rows || [],
    count: count || 0,
    isFetching,
    isLoading,
    handleLoadMore,
    hasMore,
    resetPage
  };
}
