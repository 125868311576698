export enum REQUEST_FORM {
  TICKET_FILTER_BY_CUSTOMER_CODE = 71,
  TICKET_FILTER_BY_CUSTOMER_NAME = 72,
  TICKET_FILTER_BY_CUSTOMER_ADDRESS = 73,
  TICKET_FILTER_BY_CUSTOMER_PHONE_NUMBER = 74,
  TICKET_FILTER_BY_TICKET_ID = 75,
  TICKET_FILTER_BY_CMIS_ID = 76,
  TICKET_FILTER_BY_CIC_NUMBER = 77,

  DEFAULT_PAGE_SIZE = 20,
  DEFAULT_PAGEINDEX = 1,
}