import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CollaborationRequestData,
  ResponseDto,
  ResponsePagingDto,
  ticketType,
  TransferTicketRequestToData, UpdateTicketInformationRequest,
  updateTicketRequest
} from 'types';
import axiosBaseQuery from 'utils/base-api';



export const collaborationRequestApi = createApi({
  reducerPath: 'collaboration-request',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getTicketTransferTo: builder.query<ResponsePagingDto<ticketType>, CollaborationRequestData>({
      query: (requestData) => {
        const params = new URLSearchParams();
        params.append('pageIndex', requestData.pageIndex.toString());
        params.append('pageSize', requestData.pageSize.toString());
        params.append('fromDate', requestData.fromDate.toString());
        params.append('toDate', requestData.toDate.toString());

        let url = `/tickets/dtv/ticket_transfered_to_${requestData.currentStatus}`;
        if (requestData.status === 'completed') {
          url = `${url}/resolved`;
        }

        return {
          url: url,
          method: 'get',
          params: params,
        };
      },
    }),

    getResolvedTicketTransferTo: builder.query<ResponsePagingDto<ticketType>, CollaborationRequestData>({
      query: (requestData) => {
        const params = new URLSearchParams();
        params.append('pageIndex', requestData.pageIndex.toString());
        params.append('pageSize', requestData.pageSize.toString());

        return ({
          url: `/tickets/dtv/ticket_transfered_to_${requestData.currentStatus}/resolved`,
          method: 'get',
          params: params
        })
      },
    }),


    patchTicketTransferTo: builder.mutation<ResponseDto<string>, TransferTicketRequestToData>({
      query: (requestData) => {

        const data = {
          content: requestData.content,
          source: requestData.source,
        }
        return ({
          url: `/tickets/dtv/transfer_to_${requestData.urlEndPoint}/${requestData.ticketId}`,
          method: 'patch',
          data: data
        })
      },
    }),
    patchCancelTicket: builder.mutation<ResponseDto<string>, updateTicketRequest>({
      query: (requestData) => {
        return {
          url: `/tickets/dtv/update_info/${requestData.ticketId}/cancel`,
          method: 'patch',
          data: { content: requestData.content }
        };
      },
    }),
    patchUpdateTicketInformation: builder.mutation<ResponseDto<string>, UpdateTicketInformationRequest>({
      query: (requestData) => {
        return {
          url: `/tickets/dtv/update_info/${requestData.ticketId}`,
          method: 'patch',
          data: requestData
        };
      },
    }),

  })
});

export const {
  useGetTicketTransferToQuery,
  useGetResolvedTicketTransferToQuery,

  usePatchTicketTransferToMutation,
  usePatchCancelTicketMutation,
  usePatchUpdateTicketInformationMutation
} = collaborationRequestApi;
