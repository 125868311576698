import { useState, useEffect } from "react";
import { useGetServiceTypeDetailQuery } from "services";
import { debounce } from "lodash";

export const useServiceTypeDetail = (initialServiceTypeId?: number) => {
    const [serviceTypeId, setServiceTypeId] = useState<number | undefined>(initialServiceTypeId);
    const [debouncedServiceTypeId, setDebouncedServiceTypeId] = useState<number | undefined>(serviceTypeId);

    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedServiceTypeId(serviceTypeId);
        }, 300); // Debounce 300ms

        handler();
        return () => handler.cancel();
    }, [serviceTypeId]);

    const { data: serviceType, isLoading: isLoadingServiceTypeDetail } = useGetServiceTypeDetailQuery(debouncedServiceTypeId!, {
        skip: debouncedServiceTypeId === undefined,
        refetchOnMountOrArgChange: true
    });

    return { serviceType, isLoadingServiceTypeDetail, serviceTypeId, setServiceTypeId };
};