import { useGetAdministrativeUnitDetailByCodeQuery } from "services";

export function useAdministrativeUnitDetailedByCode(administrativeUnitCode: string, p0: { skip: boolean; refetchOnMountOrArgChange: boolean; }) {
    const { data: administrativeUnitRes, isLoading } = useGetAdministrativeUnitDetailByCodeQuery(administrativeUnitCode , {
        skip: !administrativeUnitCode ,
        refetchOnMountOrArgChange: true
    });

    return {
        administrativeUnitByCode: administrativeUnitRes?.data,
        isLoading
    };
}