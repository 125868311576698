import { AgentMapFormInfoSeatType, AgentMapModalInfoSeatRef } from 'components/agent-map/AgentMaModalInfoSeat';
import { find, findIndex } from 'lodash';
import { FabricAgentMapSeat } from 'types/fabric-agent-map';
import { addSeat, editInfoSeat, removeSeat } from 'utils/agent-map';
import * as fabric from 'fabric';

type Props = {
  canvas: fabric.Canvas | null;
  updateSeatMd: AgentMapModalInfoSeatRef | null;
  seatsArr: FabricAgentMapSeat[] | null;
};

export const useAgentMapSeatCurd = () => {
  const handleAddSeat =
    ({ canvas, seatsArr }: Pick<Props, 'canvas' | 'seatsArr'>) =>
    (seat?: Partial<FabricAgentMapSeat>) => {
      if (canvas) {
        const seatAdded = addSeat(canvas, {
          ...seat
        });
        return [...(seatsArr ?? []), seatAdded];
      }
      return seatsArr ?? [];
    };

  const handleOpenEditSeatMd =
    ({ canvas, seatsArr, updateSeatMd }: Pick<Props, 'canvas' | 'seatsArr' | 'updateSeatMd'>) =>
    (seatId: string | number) => {
      if (canvas) {
        const seat = find(seatsArr, { id: seatId });
        if (seat && updateSeatMd) {
          updateSeatMd.setFieldsValue({
            seatIp: seat.seatIp,
            seatName: seat.seatName,
            id: seatId,
            existingSeats: seatsArr ?? []
          });
          updateSeatMd.setOpenInfo(true);
        }
      }
      return seatsArr ?? [];
    };

  const handleEditSeat =
    ({ canvas, seatsArr }: Pick<Props, 'canvas' | 'seatsArr'>) =>
    (values: AgentMapFormInfoSeatType) => {
      if (canvas) {
        let newSeatArr = [...(seatsArr ?? [])];
        const seatIdx = findIndex(seatsArr, { id: values.id });
        if (seatIdx !== -1) {
          newSeatArr[seatIdx] = {
            ...newSeatArr[seatIdx],
            seatIp: values.seatIp,
            seatName: values.seatName
          };
          editInfoSeat(values, canvas);
          return newSeatArr;
        }
      }
      return seatsArr ?? [];
    };

  const handleRemoveSeat =
    ({ canvas, seatsArr }: Pick<Props, 'canvas' | 'seatsArr'>) =>
    (seatId: string | number) => {
      if (canvas) {
        const seats: FabricAgentMapSeat[] = removeSeat(seatId, canvas, seatsArr ?? []);
        return [...seats];
      }
      return seatsArr ?? [];
    };

  return { handleAddSeat, handleOpenEditSeatMd, handleRemoveSeat, handleEditSeat };
};
