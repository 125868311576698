import { Checkbox, List, Skeleton, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CloseCircleIcon } from 'assets';
import { TreeSelectOrganizationUnits } from 'components';
import { Avatar, InfiniteScroll, InputSearchV2 } from 'components/common';
import { useProfile, useUsersOptions } from 'hooks';
import { uniqBy } from 'lodash';
import { usersMessages } from 'messages';
import { memo, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { UserCompactDto } from 'types';

type CheckboxGroupUsersProps = {
  className?: string;
  renderItem?: (item: UserCompactDto) => ReactNode;
  placeholder?: string;
  value?: UserCompactDto[];
  onChange?: (value?: UserCompactDto[]) => void;
  disableItemIds?: number[];
  filterOrganizationUnit?: boolean;
  showExtensionId?: boolean;
};

const CheckboxGroupUsers: React.FC<CheckboxGroupUsersProps> = ({
  value,
  onChange,
  className,
  renderItem,
  placeholder,
  disableItemIds,
  filterOrganizationUnit,
  showExtensionId
}) => {
  const { profile } = useProfile();

  const [keyword, setKeyword] = useState('');
  const [organizationUnitId, setOrganizationUnitId] = useState(profile?.organizationUnitId);

  const { usersOptions, handleLoadMore, isLoading, resetPage, hasMore } = useUsersOptions({
    keyword,
    organizationUnitId: organizationUnitId ? [organizationUnitId] : undefined,
    isIgnoreUserOrganizationUnitId: filterOrganizationUnit ? true : false
  });

  const handleSearch = (val: string) => {
    resetPage();
    setKeyword(val);
  };

  const valueGroup = value?.map((itm) => itm.userId);

  const handleChange = (item: UserCompactDto) => (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onAdd(item);
    } else {
      onRemove(item);
    }
  };

  const onAdd = (item: UserCompactDto) => {
    const newValue = [...(value ?? []), item];
    onChange?.(uniqBy(newValue, 'userId'));
  };
  const onRemove = (item: UserCompactDto) => {
    const newValue = value?.filter((o) => o.userId !== item.userId);
    onChange?.(uniqBy(newValue, 'userId'));
  };

  const renderCheckedItem = () => {
    return value?.map((user) => {
      if (user) {
        return (
          <CheckedUser
            key={user.userId}
            onRemove={() => {
              onRemove(user as UserCompactDto);
            }}
            user={user}
            showExtensionId={showExtensionId}
          />
        );
      }
      return null;
    });
  };

  return (
    <div className={twMerge('flex max-h-full flex-col', className)}>
      <InputSearchV2
        className='mb-4'
        placeholder={placeholder ?? usersMessages.fullName}
        onChange={(e) => handleSearch(e.toString())}
      />
      {filterOrganizationUnit ? (
        <div className='mb-2 w-fit'>
          <TreeSelectOrganizationUnits
            popupMatchSelectWidth={520}
            treeCheckable={false}
            labelInValue
            showCheckedStrategy='SHOW_ALL'
            treeDefaultExpandAll
            value={organizationUnitId}
            onSelect={setOrganizationUnitId}
            showSearch
          />
        </div>
      ) : undefined}
      {value?.length ? (
        <div className='slim-scrollbar-horizontal mb-2 mt-0 flex max-w-full gap-2 overflow-x-auto overflow-y-hidden pb-2'>
          {renderCheckedItem()}
        </div>
      ) : null}
      <Checkbox.Group value={valueGroup ?? []} className='-ml-6 w-[calc(100%+48px)]'>
        <InfiniteScroll
          className='flex-1'
          isLoading={Boolean(isLoading)}
          next={handleLoadMore}
          hasMore={Boolean(hasMore)}
          loader={
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 3
              }}
            />
          }
          endMessage={<p></p>}
        >
          <List
            dataSource={usersOptions}
            rowKey={(item) => item.userId}
            renderItem={(item) => (
              <label
                key={item.userId}
                className='flex h-18 w-full cursor-pointer items-center px-6 hover:bg-hoverColor1'
                htmlFor={`user-${item.userId}`}
              >
                <Checkbox
                  disabled={disableItemIds?.includes(item.userId)}
                  onChange={handleChange(item)}
                  id={`user-${item.userId}`}
                  value={item.userId}
                />
                <div className='pl-4'>
                  {renderItem ? (
                    renderItem(item)
                  ) : (
                    <div className='flex w-full cursor-pointer items-center gap-4'>
                      <Avatar name={item.fullName} fileId={item.avatar} size={48} />
                      <Typography.Text className='font-semibold'>
                        {item.fullName}
                        {showExtensionId && item.extensionId ? ` (${item.extensionId})` : ''}
                      </Typography.Text>
                    </div>
                  )}
                </div>
              </label>
            )}
          />
        </InfiniteScroll>
      </Checkbox.Group>
    </div>
  );
};

const CheckedUser = memo(
  ({
    user,
    onRemove,
    showExtensionId
  }: {
    user: UserCompactDto;
    onRemove: (user: UserCompactDto) => void;
    showExtensionId?: boolean;
  }) => {
    return (
      <div
        key={user.userId}
        className='flex h-8 w-fit max-w-[144px] items-center gap-1 rounded-full bg-backgroundColor3 px-1'
      >
        <Avatar size={24} className='border border-white' shape='circle' fileId={user.avatar} name={user.fullName} />
        <Typography.Text className='text-sm text-white' ellipsis>
          {user.fullName}
          {showExtensionId && user.extensionId ? ` (${user.extensionId})` : ''}
        </Typography.Text>
        <div
          onClick={() => onRemove(user as UserCompactDto)}
          className='h-4 w-4 cursor-pointer rounded-full hover:bg-hoverColor1 hover:brightness-50'
        >
          <CloseCircleIcon />
        </div>
      </div>
    );
  }
);

export default CheckboxGroupUsers;
