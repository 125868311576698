import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateMessageOmniChat,
  useCreateChatMessengerMutation,
  useHideCommentMutation,
  useLikeCommentMutation,
  useRemoveCommentMutation
} from 'services';
import { AppDispatch } from 'store';
import { ChatSessionDto, OmniChannelMessageDto } from 'types';
export function useActionsOmniChatMessage(
  chatId: number,
  chatSessionId: number,
  endpointNameMessages?: string,
  setChatSession?: (value: ChatSessionDto) => void
) {
  const dispatch = useDispatch<AppDispatch>();

  const [onLikeComment] = useLikeCommentMutation();
  const [onRemoveComment] = useRemoveCommentMutation();
  const [onHideComment] = useHideCommentMutation();
  const [onCreateChat] = useCreateChatMessengerMutation();

  const dispatchUpdateMessage = useCallback(
    (data: OmniChannelMessageDto, messageId: number) => {
      if (endpointNameMessages)
        dispatch(updateMessageOmniChat(data, messageId, endpointNameMessages, { chatId, chatSessionId }));
    },
    [endpointNameMessages]
  );

  const handleLikeMessage = (message: OmniChannelMessageDto) => {
    onLikeComment(message.messageId)
      .unwrap()
      .finally(() => {
        dispatchUpdateMessage(
          {
            ...message,
            isLiked: true
          },
          message.messageId
        );
      });
  };

  const handleHideMessage = (message: OmniChannelMessageDto) => {
    onHideComment({
      messageId: message.messageId,
      isHidden: !message.isHidden
    })
      .unwrap()
      .then(() => {
        dispatchUpdateMessage(
          {
            ...message,
            isHidden: !message.isHidden
          },
          message.messageId
        );
      });
  };

  const handleDeleteMessage = (message: OmniChannelMessageDto) => {
    onRemoveComment(message.messageId)
      .unwrap()
      .then(() => {
        dispatchUpdateMessage(
          {
            ...message,
            isDeleted: true
          },
          message.messageId
        );
      });
  };

  const handleChatUser = (message: OmniChannelMessageDto) => {
    onCreateChat(message.chatSessionId)
      .unwrap()
      .then((rs) => {
        setChatSession?.(rs.data);
      });
  };

  const handleUpdateMessage = (message: OmniChannelMessageDto) => {
    dispatchUpdateMessage(
      {
        ...message
      },
      message.messageId
    );
  };

  return { handleUpdateMessage, handleLikeMessage, handleHideMessage, handleDeleteMessage, handleChatUser };
}
